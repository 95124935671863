<template>
  <div class="main">
    <router-view v-slot="{ Component }">
      <keep-alive>
        <component :is="Component" />
      </keep-alive>
    </router-view>
    <FooterTabbar :tabbarList="tabbarList" />
  </div>
</template>

<script setup>
import { RouterView } from "vue-router";
import { useStore } from "@/store/store";
import FooterTabbar from "@/components/FooterTabbar.vue";
import { ref, onBeforeMount } from "vue";

const store = useStore();

const tabItemurl_1 = store.imgBaseUrl + "/tabbar/home.png";
const tabItemurl_1_active = store.imgBaseUrl + "/tabbar/home_active.png";
const tabItemurl_2 = store.imgBaseUrl + "/tabbar/rank.png";
const tabItemurl_2_active = store.imgBaseUrl + "/tabbar/rank_active.png";
const tabItemurl_3 = store.imgBaseUrl + "/tabbar/competition.png";
const tabItemurl_3_active = store.imgBaseUrl + "/tabbar/competition_active.png";
const tabItemurl_4 = store.imgBaseUrl + "/tabbar/my.png";
const tabItemurl_4_active = store.imgBaseUrl + "/tabbar/my_active.png";
const tabItemurl_5 = store.imgBaseUrl + "/tabbar/challenge.png";
const tabItemurl_5_active = store.imgBaseUrl + "/tabbar/challenge_active.png";

const tabbarList = [
  {
    text: "首页",
    name: "home",
    id: 1,
    activeIcon: tabItemurl_1_active,
    inactiveIcon: tabItemurl_1,
    path: "/home",
  },
  {
    text: "挑战",
    activeIcon: tabItemurl_5_active,
    inactiveIcon: tabItemurl_5,
    id: 4,
    path: "/challenge",
    name: "challenge",
  },
  {
    text: "联赛",
    activeIcon: tabItemurl_3_active,
    inactiveIcon: tabItemurl_3,
    id: 3,
    path: "/competition",
    name: "competition",
  },
  {
    text: "排行",
    name: "rank",
    activeIcon: tabItemurl_2_active,
    inactiveIcon: tabItemurl_2,
    id: 2,
    path: "/rank",
  },
  {
    text: "我的",
    activeIcon: tabItemurl_4_active,
    inactiveIcon: tabItemurl_4,
    id: 5,
    path: "/my",
    name: "my",
  },
];

onBeforeMount(() => {});
</script>

<style lang="scss" scoped>
.main {
  width: 100%;
  min-height: 100vh;
}
</style>
