<template>
  <div class="navbar_wrapper">
    <div class="navbar_list_wrapper">
      <div
        class="navbar_list_item"
        v-for="navlist in navbarListData"
        :key="navlist.id"
        @click="handleNavClickEvent(navlist.id)"
      >
        <div class="nav_item_icon_wrap">
          <div
            :class="{
              nav_item_icon: true,
              turntable_full_animation:
                navlist.id == 'turntable' &&
                store.player_turntable_data.progress == 100,
            }"
          >
            <van-image
              width="100%"
              height="100%"
              fit="fill"
              :src="navlist.iconUrl"
              :show-loading="false"
            />
          </div>

          <div class="e_text2" v-if="navlist.id == 'xinshou'"></div>

          <div class="wheel_progress_wraper" v-if="navlist.id == 'turntable'">
            <div class="progress_value">
              {{ store.player_turntable_data.progress }} %
            </div>
            <div
              class="wheel_progress"
              :style="{
                transform: `translateX(-${
                  100 - store.player_turntable_data.progress
                }%)`,
              }"
            ></div>
          </div>
        </div>

        <p class="f-12 pb-6 pt-6" style="white-space: nowrap">
          {{ navlist.text }}
        </p>
      </div>
    </div>

    <!-- 福利罐 -->
    <van-popup
      v-model:show="wealBoxShow"
      @open="pupOpenCallBack('weal')"
      :style="{
        background: 'none',
        width: '90vw',
        padding: '20px 20px 0 20px',
      }"
    >
      <div class="weal_box_wrapper">
        <div class="weal_box_title">
          <div
            class="weal_box_close_icon"
            @click="wealBoxShowToggle(false)"
          ></div>
          <div class="weal_box_title_icon">
            <van-image
              width="100%"
              height="auto"
              fit="fill"
              position="center"
              :show-loading="false"
              :src="weal_box_title_icon"
            />
          </div>
          <div class="weal_box_title_right_icon"></div>
        </div>

        <div class="weal_content_wrapper">
          <div class="weal_content">
            <p class="weal_content_title">下周待领取</p>
            <div class="weal_gold_wrapper">
              <div class="weal_gold_number">
                {{ store.wealBoxData.next_gold }}
              </div>
              <div class="weal_gold_icon_wrapper"></div>
            </div>

            <div class="weal_content_info_item">
              <div>可领取时间</div>
              <div>周一 00:01</div>
            </div>
            <div class="weal_content_info_item">
              <div>本周可领取</div>
              <div class="cur_gold_number">{{ store.wealBoxData.gold }}</div>
            </div>
          </div>
        </div>

        <div class="weal_box_bottom">
          <div
            :class="[
              'weal_box_btn_wrapper',
              'van-haptics-feedback',
              store.wealBoxData.is_get || Number(store.wealBoxData.gold) == 0
                ? 'weal_box_btn_ed'
                : '',
            ]"
            @click="handleGetWealReward(store.wealBoxData)"
          >
            {{
              store.wealBoxData.is_get || Number(store.wealBoxData.gold) == 0
                ? Number(store.wealBoxData.gold) == 0
                  ? "暂无可领"
                  : "已领取"
                : "立即领取"
            }}
          </div>
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script setup>
import { useRouter } from "vue-router";
import { useStore } from "@/store/store";
import { sendInstructions, asyncSendInstructions } from "@/api/sendData";

import { ref, reactive, watch, computed, onMounted, onBeforeMount } from "vue";

import { showToast } from "vant";
const router = useRouter();
const store = useStore();

const zp =
  "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/spin/entrance_icon.png";

// const icon_ChongZhi = store.imgBaseUrl + "/navMenu/icon_ChongZhi@2x.png";
const icon_HuiYuan = store.imgBaseUrl + "/navMenu/icon_HuiYuan@2x.png";
const icon_FuLi = store.imgBaseUrl + "/navMenu/icon_FuLi@2x.png";
const icon_JiKe = store.imgBaseUrl + "/navMenu/icon_JiKe@2x.png";
const icon_QianDao = store.imgBaseUrl + "/navMenu/icon_QianDao@2x.png";
const icon_LiBao = store.imgBaseUrl + "/navMenu/icon_LiBao@2x.png";
const icon_RenWu = store.imgBaseUrl + "/navMenu/icon_RenWu@2x.png";
const icon_YaoQing = store.imgBaseUrl + "/navMenu/icon_YaoQing@2x.png";

const icon_Xinshou =
  "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/sprog/enter_icon2.png";

const gift_bg_top = store.imgBaseUrl + "/pay/gift_bg_top.png";
const gift_bg_content = store.imgBaseUrl + "/pay/gift_bg_content.png";
const gift_bg_bottom = store.imgBaseUrl + "/pay/gift_bg_bottom.png";

const gold1 = store.imgBaseUrl + "/pay/pay_gold_6.png";
const gold2 = store.imgBaseUrl + "/pay/pay_gold_9.png";
const gold3 = store.imgBaseUrl + "/pay/pay_gold_12.png";

const gift_pay_btn = store.imgBaseUrl + "/pay/gift_pay_btn.png";
const gift_pay_btn_ed = store.imgBaseUrl + "/pay/gift_pay_btn_ed.png";

const weal_box_title_icon = store.imgBaseUrl + "/weal_box_title_icon.png";

// const emits = defineEmits(["nav_item_click"]);
const emits = defineEmits(["when_sign_click"]);

const navbarListData = [
  {
    iconUrl: icon_QianDao,
    text: "每日签到",
    id: "sign",
  },

  {
    iconUrl: icon_RenWu,
    text: "任务中心",
    id: "task",
  },
  {
    iconUrl: icon_LiBao,
    text: "充值中心",
    id: "pay",
  },

  {
    iconUrl: icon_HuiYuan,
    text: "会员中心",
    id: "vip",
  },
  {
    iconUrl: zp,
    text: "幸运转盘",
    id: "turntable",
  },

  {
    iconUrl: icon_FuLi,
    text: "福利宝箱",
    id: "weal",
  },
  {
    iconUrl: icon_YaoQing,
    text: "邀请好友",
    id: "invite",
  },
  {
    iconUrl: icon_JiKe,
    text: "极客秀",
    id: "jikeshow",
  },
];

const payImg = [gold1, gold2, gold3];

const wealBoxShow = ref(false);

const handleNavClickEvent = (id) => {
  // emits("nav_item_click", id);

  switch (id) {
    case "vip":
      router.push("/vip");
      break;
    case "task":
      router.push("/task");
      break;
    case "xinshou":
      if (store.userInfo.register_time) {
        store.storeShowToggle("sprog_task_show", true);
      } else {
        router.push("/task");
      }

      break;
    case "turntable":
      store.storeShowToggle("turntable_show", true);
      break;
    case "sign":
      emits("when_sign_click");
      break;
    case "pay":
      store.payPupToggle(true, "first");
      // starttype.value = 0;
      // payPupShow.value = true;
      // router.push("/pay");
      break;
    case "weal":
      wealBoxShowToggle(true);
      break;
    case "invite":
      router.push("/invitation");
      break;
    case "jikeshow":
      router.push("/forum");
      break;
  }
};

const pupOpenCallBack = (type) => {
  switch (type) {
    case "sign":
      sendInstructions({
        msg_type: "get_login_reward",
      });
      break;
    case "weal":
      sendInstructions(
        {
          msg_type: "get_player_weal_box",
        },
        0
      );
      break;
  }
};

//#region 福利罐子
const wealBoxShowToggle = (statu = true) => {
  wealBoxShow.value = statu;
};

const handleGetWealReward = (data) => {
  if (data.is_get) return;

  if (Number(data.gold) === 0) {
    showToast({
      type: "fail",
      message: "当前无金币",
    });
    return;
  }

  sendInstructions(
    {
      msg_type: "exchange_player_weal_box",
    },
    0
  );
};

//#endregion
</script>

<style lang="scss" scoped>
.navbar_list_wrapper {
  text-align: center;
  display: flex;
  flex-wrap: wrap;
  .navbar_list_item {
    margin-top: 12px;
    width: 25%;
    .nav_item_icon_wrap {
      position: relative;
      width: 36px;
      height: 36px;
      margin: 0 auto;

      .line_sss {
        position: absolute;
        left: 50%;
        top: 50%;
        z-index: 1;
        width: 150%;
        height: 150%;
        transform: translate(-50%, -50%);
        background-image: url("https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/spin/win_light_bg.png");
        background-size: 100% 100%;
        background-repeat: no-repeat;
        animation: wheeling 5s;
        animation-timing-function: linear;
        animation-iteration-count: infinite;
        z-index: 1;
      }

      .e_text2 {
        position: absolute;
        bottom: -10px;
        left: -10px;
        // top: 0px;
        // left: 0px;
        width: 26px;
        height: 14px;
        background-image: url("https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/sprog/enter_icon_text2.png");
        background-size: 100% 100%;
        background-repeat: no-repeat;
        z-index: 3;
      }

      .nav_item_icon {
        position: relative;
        width: 100%;
        height: 100%;
        transform-origin: center center;
        z-index: 2;
      }

      .wheel_progress_wraper {
        position: absolute;
        left: 0;
        bottom: 0;
        height: 8px;
        width: 100%;
        line-height: 8px;
        font-size: 8px;
        color: #fff;
        border-radius: 4px;
        background-color: #4c3545;
        text-align: center;
        overflow: hidden;
        z-index: 3;
        .progress_value {
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
          text-align: center;
          z-index: 3;
        }

        .wheel_progress {
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
          background: linear-gradient(180deg, #5dfc5a 0%, #09af03 100%);
          z-index: 1;

          border-radius: 4px;
        }
      }
    }

    .turntable_full_animation {
      // animation: turntable_full_hint 1.5s;
      // animation-timing-function: linear;
      // -webkit-animation: wobble-hor-bottom 1.5s both;
      // animation: wobble-hor-bottom 1.5s both;
      -webkit-animation: rotate-center 3s;
      animation: rotate-center 3s;
      // animation-iteration-count: 1;
      animation-iteration-count: infinite;
    }
  }
}

.weal_box_wrapper {
  position: relative;
  width: 100%;
  background: linear-gradient(90deg, #ffd2a7 0%, #f7e8d3 100%);
  border-radius: 15px 15px 15px 15px;
  z-index: 2;

  .weal_box_title {
    padding: 15px 22px;
    position: relative;

    .weal_box_close_icon {
      position: absolute;
      right: -10px;
      top: -10px;
      width: 40px;
      height: 40px;
      background-image: url("https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/pup_close.png");
      background-size: 100% 100%;
      background-repeat: no-repeat;
      z-index: 2;
    }

    .weal_box_title_icon {
      // display: inline-block;
      width: 140px;
    }
    .weal_box_title_right_icon {
      position: absolute;
      right: -6px;
      top: -2px;
      width: 137px;
      height: 137px;
      background-image: url("https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/weal_box_title_right_icon.png");
      background-size: 100% 100%;
      background-repeat: no-repeat;
      z-index: 1;
    }
  }

  .weal_content_wrapper {
    padding: 0 14px;

    .weal_content {
      background-color: #fff;
      padding: 20px 18px;

      border-radius: 15px 15px 15px 15px;
      .weal_content_title {
        color: #000000;
        font-weight: bold;
      }
      .weal_gold_wrapper {
        display: flex;
        .weal_gold_number {
          padding-right: 4px;
          color: #ff7f00;
          font-size: 40px;
          font-weight: bold;
        }

        .weal_gold_icon_wrapper {
          width: 40px;
          position: relative;

          &::after {
            content: "";
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 40px;
            height: 40px;
            background-image: url("https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/global/gold.png");
            background-size: 100% 100%;
            background-repeat: no-repeat;
          }
        }
      }

      .weal_content_info_item {
        display: flex;
        justify-content: space-between;
        font-weight: bold;
        color: #000000;
        line-height: 24px;

        .cur_gold_number {
          padding-left: 26px;
          position: relative;
          color: #ff7f00;
          font-size: 14px;
          &::after {
            content: "";
            position: absolute;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
            background-image: url("https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/global/gold.png");
            background-size: 100%;
            background-repeat: no-repeat;
            width: 24px;
            height: 24px;
          }
        }
      }
    }
  }

  .weal_box_bottom {
    width: 100%;
    padding: 12px 15px;
    position: relative;
    // top: -40px;
    background-size: cover;
    background-repeat: no-repeat;

    .weal_box_bottom_bg {
      width: 100%;
      height: auto;
    }

    .weal_box_btn_wrapper {
      // position: absolute;
      // left: 50%;
      // top: 50%;
      // transform: translate(-50%, -50%);
      width: 100%;
      height: 48px;
      background-image: linear-gradient(180deg, #f0d24e 0%, #fe8304 100%);
      border-radius: 15px;
      text-align: center;
      line-height: 48px;
      font-weight: bold;
      color: #000000;
      font-size: 20px;
    }

    .weal_box_btn_ed {
      background-image: linear-gradient(
        180deg,
        #ccc4a1 0%,
        #bf8d59 100%
      ) !important;
    }
  }
}

.gift_box_wrapper {
  width: 100%;

  padding-bottom: 40px;

  position: relative;
  .close_icon {
    position: absolute;
    bottom: 0px;
    transform: translateX(-50%);
    left: 50%;
    width: 40px;
    height: 40px;
    z-index: 8;
    background-image: url("https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/pup_close_icon.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
  }

  .gift_box_top {
    width: 100%;
    position: relative;
    border-collapse: collapse;
    .price_text {
      position: absolute;
      left: 50%;
      bottom: 14%;
      transform: translateX(-50%);
      color: #c90d23;
      font-size: 12px;
      text-align: center;
      font-weight: bold;
    }
  }

  .gift_box_container {
    width: 100%;
    overflow: hidden;
    border-collapse: collapse;
    position: relative;

    .gift_content_bg {
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      z-index: 1;
    }
    .steps_wrapper {
      padding: 0 50px;
      width: 100%;
      position: relative;
      margin-top: 10px;
      z-index: 2;
      .step_tab {
        position: relative;
        z-index: 2;
        display: flex;
        justify-content: space-between;

        .step_tab_item {
          background-color: #ede2c0;
          height: 18px;
          width: 18px;
          border-radius: 50%;
          text-align: center;
          line-height: 18px;
          color: rgba($color: #280923, $alpha: 0.5);
        }
        .step_tab_item_active {
          background-color: #ffc60b !important;
          color: #280923 !important;
        }
      }

      .step_line {
        position: absolute;
        width: calc(50% - 50px);
        height: 3px;
        top: 50%;
        transform: translateY(-50%);
      }
    }
    .gift_list_wrapper {
      display: flex;
      padding: 0 30px;
      justify-content: space-between;
      position: relative;
      z-index: 2;
      .gift_list_item {
        width: 32%;
        text-align: center;
        .item_buff {
          color: #280923;
          font-size: 12px;
          font-weight: bold;
          margin: 4px 0;
        }
        .item_card {
          background-color: #b29170;
          min-height: 100px;
          border-radius: 6px;
          padding: 6px;

          .yield_wrapper {
            background-color: #ffc60b;
            border-radius: 6px;
            font-weight: 800;
            color: #280923;
          }
          // .gold_icon_wrapper {}

          .gold_number {
            font-size: 16px;
            color: #fff;
            font-weight: bold;
          }
        }
        .item_text {
          color: rgba($color: #724b60, $alpha: 0.5);

          font-weight: bold;
        }
      }

      .gift_list_item_active {
        .item_card {
          background-color: #ff7f00;
        }
        .item_text {
          color: #c5046c;
        }
      }
    }
  }

  .gift_bottom_wrapper {
    padding-top: 60px;
    height: 185px;

    position: relative;

    .gift_bttom_bg {
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      z-index: 1;
    }
    .gift_tip_text {
      margin: 0 auto;
      width: 80%;
      color: #ffffff;
      font-size: 12px;
      white-space: wrap;
      text-align: center;
      position: relative;

      z-index: 2;
    }

    .pay_btn {
      position: relative;
      width: 185px;
      margin: 0 auto;
      margin-top: 20px;
      z-index: 2;
      .pay_btn_text {
        position: absolute;
        left: 50%;
        top: 40%;
        transform: translate(-50%, -50%);
        font-weight: 800;
        color: #fff;
        font-size: 18px;
        white-space: nowrap;
      }
      .pay_btn_text_ed {
        position: absolute;
        left: 50%;
        top: 40%;
        transform: translate(-50%, -50%);
        font-weight: 800;
        color: #fff;
        font-size: 18px;
        white-space: nowrap;
      }
      .pay_btn_buff_text {
        position: absolute;
        top: 0;
        right: 0;
        transform: translate(30%, -50%);
        border-top-right-radius: 20px;
        border-top-left-radius: 20px;
        border-bottom-right-radius: 20px;
        padding: 4px 6px;

        background: linear-gradient(180deg, #fcff6c 3%, #febe1b 100%);
        font-weight: 800;
        color: #280923;
      }
    }
  }
}

.shake-bottom-class {
  -webkit-animation: shake-bottom 3.2s cubic-bezier(0.455, 0.03, 0.515, 0.955)
    both infinite;
  animation: shake-bottom 3.2s cubic-bezier(0.455, 0.03, 0.515, 0.955) both
    infinite;
  animation-delay: 1s;
}

@-webkit-keyframes shake-bottom {
  0%,
  25% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transform-origin: 50% 100%;
    transform-origin: 50% 100%;
  }
  2.5% {
    -webkit-transform: rotate(2deg);
    transform: rotate(2deg);
  }
  5%,
  10%,
  15% {
    -webkit-transform: rotate(-4deg);
    transform: rotate(-4deg);
  }
  7.5%,
  12.5%,
  17.5% {
    -webkit-transform: rotate(4deg);
    transform: rotate(4deg);
  }
  20% {
    -webkit-transform: rotate(-2deg);
    transform: rotate(-2deg);
  }
  22.5% {
    -webkit-transform: rotate(2deg);
    transform: rotate(2deg);
  }
}
@keyframes shake-bottom {
  0%,
  25% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transform-origin: 50% 100%;
    transform-origin: 50% 100%;
  }
  2.5% {
    -webkit-transform: rotate(2deg);
    transform: rotate(2deg);
  }
  5%,
  10%,
  15% {
    -webkit-transform: rotate(-4deg);
    transform: rotate(-4deg);
  }
  7.5%,
  12.5%,
  17.5% {
    -webkit-transform: rotate(4deg);
    transform: rotate(4deg);
  }
  20% {
    -webkit-transform: rotate(-2deg);
    transform: rotate(-2deg);
  }
  22.5% {
    -webkit-transform: rotate(2deg);
    transform: rotate(2deg);
  }
}

@-webkit-keyframes rotate-center {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }

  50% {
    -webkit-transform: rotate(720deg);
    transform: rotate(720deg);
  }

  100% {
    -webkit-transform: rotate(720deg);
    transform: rotate(720deg);
  }
}
@keyframes rotate-center {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }
  50% {
    -webkit-transform: rotate(720deg);
    transform: rotate(720deg);
  }
  100% {
    -webkit-transform: rotate(720deg);
    transform: rotate(720deg);
  }
}

@keyframes wheeling {
  0% {
    transform: translate(-50%, -50%) rotate(0deg);
  }

  100% {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}

@-webkit-keyframes wheeling

/* Safari 与 Chrome */ {
  0% {
    transform: translate(-50%, -50%) rotate(0deg);
  }

  100% {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}
</style>
