<template>
  <transition name="van-slide-left">
    <div
      class="luck_red_bag_wrapper"
      v-if="tshow && server_data_list.length > 0"
    >
      <div class="close_icon" @click.stop="handleClose"></div>
      <!-- <div class="bg_1"></div> -->
      <!-- <div class="bg_2"></div> -->
      <div
        class="get_btn"
        @click="handleGetCode(server_data_list[0].code)"
      ></div>
      <div class="head_wrap">
        <HeadPortrait
          :src="server_data_list[0].head"
          fe
          ae
          :ae_id="server_data_list[0].select_title"
          :level="server_data_list[0].recharge_level"
        />
      </div>
      <div class="tz_player_name">
        {{ server_data_list[0].player_name }}
      </div>

      <div class="tiaozhan_info">挑战赛挑战成功！</div>
    </div>
  </transition>

  <div class="get_pup_wrapper scale_in_center_class" v-if="get_gold_show">
    <div class="gold_num_wrap">
      <div class="num">{{ get_gold_num }}</div>
    </div>
    <div class="get_btn" @click="handleGet"></div>
  </div>
</template>

<script setup>
import HeadPortrait from "@/components/HeadPortrait.vue";

import { ref, watch, reactive, onBeforeUnmount, nextTick } from "vue";
import { useStore } from "@/store/store";
import { Howl, Howler } from "howler";
import { useToggle } from "@vant/use";
import { addCommas, decodeBase64String } from "@/utils/utils";
import { asyncSendInstructions } from "@/api/sendData";
import { showToast } from "vant";

//#region 声音

import openBoxHint from "@/assets/audios/sign_hint.mp3";
import red_packHint from "@/assets/audios/red_pack.mp3";

const openBoxSound = new Howl({
  src: [openBoxHint],
  autoplay: false,
  loop: false,
});
const redPackHintSound = new Howl({
  src: [red_packHint],
  autoplay: false,
  loop: false,
});
//#endregion

const store = useStore();

const server_data_list = reactive([]);
const [tshow, tShowToggle] = useToggle(false);

let setOutTimer = null;

watch(
  () => store.create_tiaozhan_red_package_result,
  (newV) => {
    if (newV && newV.tiaozhan_player.uid != parseInt(store.userInfo.uid)) {
      const initO = {
        head: newV.tiaozhan_player.head || newV.tiaozhan_player.head_frame,
        player_name: decodeBase64String(newV.tiaozhan_player.player_name),
        select_title: newV.tiaozhan_player.select_title,
        level: newV.tiaozhan_player.level,
        recharge_level: newV.tiaozhan_player.recharge_level,
        code: newV.code,
      };

      server_data_list.push(initO);
      !tshow.value && tShowToggle(true);
      setOutTimer && clearTimeout(setOutTimer);
      setOutTimer = setTimeout(() => {
        handleClose();
      }, 12e3);
    }
  }
);

const handleClose = () => {
  server_data_list.length > 0 && server_data_list.shift();
  tShowToggle(false);
  nextTick(() => {
    server_data_list.length > 0 && tShowToggle(true);
  });
};

const get_gold_num = ref(0);
const [get_gold_show, get_gold_show_toggle] = useToggle(false);

const handleGet = () => {
  openBoxSound.play();
  get_gold_show_toggle(false);
};

const handleGetCode = async (code) => {
  handleClose();
  get_gold_num.value = 0;
  try {
    const res = await asyncSendInstructions({
      msg_type: "get_tiaozhan_red_package_reward",
      code,
    });

    // store.$patch((state) => {
    //   state.get_tiaozhan_red_package_reward = res;
    // });

    if (res.error == "ok") {
      get_gold_num.value = res.gold;
      get_gold_show_toggle(true);
      store.$patch((state) => {
        state.userInfo.gold = state.userInfo.gold + Number(res.gold);
      });
    } else {
      showToast({
        type: "text",
        message: "来晚了,很遗憾没沾到好运",
        teleport: "#rotate_box",
        onClose() {
          handleClose();
        },
      });
    }
  } catch (error) {
    showToast({
      type: "text",
      message: "来晚了,很遗憾没沾到好运",
      teleport: "#rotate_box",
      onClose() {
        handleClose();
      },
    });
  }
};

onBeforeUnmount(() => {
  server_data_list.length = 0;
  store.$patch((state) => {
    state.create_tiaozhan_red_package_result = null;
  });
});
</script>

<style lang="scss" scoped>
.luck_red_bag_wrapper {
  position: absolute;
  top: 90px;
  left: 10px;
  width: 260px;
  height: 60px;
  z-index: 999;
  background-image: url("https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/challenge/luck_red_bag_bg_1.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;

  .get_btn {
    position: absolute;
    right: 32px;
    width: 50px;
    height: 100%;
    z-index: 5;
  }

  .close_icon {
    position: absolute;
    right: 0px;
    top: 0;
    width: 20px;
    height: 20px;
    background-image: url("https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/pup_close_icon.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    z-index: 3;
  }

  // .bg_1 {
  //   position: absolute;
  //   left: 0;
  //   top: 0;
  //   width: 100%;
  //   height: 100%;
  //   z-index: 1;
  //   background-image: url("https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/challenge/luck_red_bag_bg_1.png");
  //   background-size: 100% 100%;
  //   background-repeat: no-repeat;
  // }

  .user_info {
    position: absolute;
    height: 26px;
    left: 50px;
    top: 6px;
    display: flex;
    z-index: 2;
  }

  .tz_player_name {
    position: absolute;
    top: 10px;
    left: 92px;
    // line-height: 26px;
    max-width: 80px;
    // background-color: red;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    z-index: 2;
  }

  .head_wrap {
    position: absolute;
    left: 50px;
    top: 6px;
    width: 36px;
    height: 36px;
    z-index: 2;
  }

  .tiaozhan_info {
    position: absolute;
    left: 92px;
    bottom: 14px;
    white-space: nowrap;
    font-size: 10px;
    z-index: 2;
  }
}

.get_pup_wrapper {
  position: absolute;
  left: 50%;
  top: 40%;
  width: 350px;
  height: 346px;
  // background-color: skyblue;
  transform: translate(-50%, -50%);
  background-image: url("https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/active/win_pup_bg.png");
  background-size: 100% auto;
  background-repeat: no-repeat;

  z-index: 13;

  .gold_num_wrap {
    position: absolute;
    top: 220px;
    left: 50%;
    transform: translate(-50%, 0);
    width: 52px;
    height: 52px;
    background-color: #9369e1;
    border-radius: 14px;
    background-image: url("https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/global/gold.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;

    .num {
      position: absolute;
      width: 100%;
      height: 22px;
      bottom: 0;
      line-height: 22px;
      text-align: center;
      font-size: 16px;
      color: #ffae00;
      transform: translate(0%, 100%);
    }
  }

  .get_btn {
    position: absolute;
    bottom: -14px;
    left: 50%;
    width: 116px;
    height: 38px;
    transform: translateX(-50%);
    background-image: url("https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/active/get_box_btn_bg.png");

    background-size: 100% auto;
    background-repeat: no-repeat;
    z-index: 11;
  }
}
</style>
