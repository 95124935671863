<template>
  <div class="user_wallet_wapper" @click="handleIconClickEvent">
    <div class="wallet_type_icon">
      <van-image
        width="100%"
        height="100%"
        block
        :show-loading="false"
        :src="gold_icon"
        v-if="props.type == 'gold'"
      />
      <van-image
        width="100%"
        height="100%"
        block
        :show-loading="false"
        :src="ticket_icon"
        v-else
      />
    </div>
    <div class="wallet_value_icon">
      {{
        props.type == "gold"
          ? addCommas(store.userInfo.gold)
          : addCommas(store.userInfo.lottery)
      }}
    </div>
    <div
      class="wallet_add_icon"
      :style="{
        backgroundImage: `url(${
          props.type == 'gold' ? wallet_add_icon : wallet_change_icon
        })`,
      }"
    ></div>
  </div>
</template>

<script setup>
import { useStore } from "@/store/store";
import { addCommas } from "@/utils/utils";
const store = useStore();

const props = defineProps({
  type: {
    type: String,
    default: "gold",
  },
});

// https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/global/lottery.png
const gold_icon = store.imgBaseUrl + "/gold_icon.png";
const ticket_icon = store.imgBaseUrl + "/ticket.png";
const wallet_add_icon = store.imgBaseUrl + "/wallet_add_icon.png";
const wallet_change_icon = store.imgBaseUrl + "/wallet_change_icon.png";

const handleIconClickEvent = () => {
  if (props.type == "gold") {
    store.payPupToggle(true, "first");
  } else store.payPupToggle(true, "exchange");
};
</script>

<style lang="scss" scoped>
.user_wallet_wapper {
  min-width: 70px;
  height: 20px;
  display: flex;
  background-color: rgba(0, 0, 0, 0.4);
  border-radius: 10px;

  .wallet_type_icon {
    width: 20px;
    height: 20px;
  }

  .wallet_value_icon {
    flex: 1;
    text-align: center;
    font-size: 14px;
    font-weight: bold;
    padding: 0 4px;
  }

  .wallet_add_icon {
    width: 20px;
    height: 20px;
    background-size: 16px 16px;
    background-position: center center;
    background-repeat: no-repeat;
  }

  //   background-color: rgba($color: #000, $alpha: 0.4);
  //   padding: 0px 24px 0px 24px;
  //   height: 20px;
  //   line-height: 20px;
  //   border-radius: 12px;
  // &::before {
  //   content: "";
  //   position: absolute;
  //   left: 0px;
  //   top: 50%;
  //   transform: translateY(-50%);
  //   width: 22px;
  //   height: 22px;
  //   background-image: url("https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/global/gold.png");
  //   background-size: 100% 100%;
  //   background-repeat: no-repeat;
  // }
  // &::after {
  //   content: "";
  //   position: absolute;
  //   right: 0px;
  //   top: 50%;
  //   transform: translateY(-50%);
  //   width: 18px;
  //   height: 18px;
  //   background-image: url("https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/gold_add_icon.png");
  //   background-size: 100% 100%;
  //   background-repeat: no-repeat;
  // }
}
</style>
