import { ref, onUnmounted } from "vue";

export function useThrottle(callback, delay = 5e2) {
  const lastCall = ref(0);
  const timeout = ref(null);

  const throttledFunction = (...args) => {
    const now = Date.now();
    if (now - lastCall.value >= delay) {
      if (timeout.value !== null) {
        clearTimeout(timeout.value);
        timeout.value = null;
      }
      callback(...args);
      lastCall.value = now;
    } else if (timeout.value === null) {
      timeout.value = setTimeout(() => {
        callback(...args);
        lastCall.value = Date.now();
        timeout.value = null;
      }, delay - (now - lastCall.value));
    }
  };

  onUnmounted(() => {
    if (timeout.value !== null) {
      clearTimeout(timeout.value);
    }
  });

  return throttledFunction;
}
