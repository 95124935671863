<template>
  <div
    class="server_time_wrapper"
    :style="{
      left: _left,
      top: _top,
      right: _right,
      bottom: _bottom,
    }"
  >
    <!-- {{ store.server_time_data }} -->
    {{ RealTime.timeParts.value.format }}
  </div>
</template>

<script setup>
import { useStore } from "@/store/store";
import { computed, onMounted, onBeforeUnmount, watch, ref } from "vue";
import { sendInstructions } from "@/api/sendData";
import { useRealTime } from "@/hooks";
const store = useStore();
// useRealTime
const RealTime = useRealTime();

// 系统时间

watch(
  () => store.server_time_data,
  (newV) => {
    if (newV != 0) {
      RealTime.ResetTime(newV);
    }
  }
);

const getInitStartTime = () => {
  sendInstructions({
    msg_type: "get_server_time",
  });
  setTimeout(() => {
    if (store.server_time_data == 0) {
      getInitStartTime();
    }
  }, 2000);
};

setTimeout(() => {
  getInitStartTime();
}, 1000);
onBeforeUnmount(() => {
  store.server_time_data = 0;
});

const props = defineProps({
  left: {
    type: [String, Number],
    default: "auto",
  },
  top: {
    type: [String, Number],
    default: "auto",
  },
  right: {
    type: [String, Number],
    default: "auto",
  },
  bottom: {
    type: [String, Number],
    default: "auto",
  },
});

const _top = computed(() => {
  if (props.top == "auto") return "auto";
  const rem = parseFloat(document.documentElement.style.fontSize);
  return (props.top / rem).toFixed(5) + "rem";
});

const _right = computed(() => {
  if (props.right == "auto") return "auto";
  const rem = parseFloat(document.documentElement.style.fontSize);
  return (props.right / rem).toFixed(5) + "rem";
});
const _bottom = computed(() => {
  if (props.bottom == "auto") return "auto";
  const rem = parseFloat(document.documentElement.style.fontSize);
  return (props.bottom / rem).toFixed(5) + "rem";
});

const _left = computed(() => {
  if (props.left == "auto") return "auto";
  const rem = parseFloat(document.documentElement.style.fontSize);
  return (props.left / rem).toFixed(5) + "rem";
});
</script>

<style lang="scss" scoped>
.server_time_wrapper {
  position: absolute;
  font-weight: bold;
  font-size: 12px;
  z-index: 4;
  color: #fff;
  // background: rgba(0, 0, 0, 0.4);
  // transform: translateX();
  transform-origin: center center;
  padding: 4px 16px;
  border-bottom-left-radius: 10px;
}
</style>
