<template>
  <div class="forum_view">
    <NavBar fixed />
    <div class="img_container">
      <van-image
        v-for="img_url in imgs"
        width="100%"
        height="auto"
        :show-loading="false"
        block
        :src="img_url"
      />
    </div>
  </div>
</template>

<script setup>
import NavBar from "@/components/NavBar.vue";
const imgs = [
  "https://jikejingji.oss-cn-guangzhou.aliyuncs.com/appClientStatic/jk_forum/1_01.jpg",
  "https://jikejingji.oss-cn-guangzhou.aliyuncs.com/appClientStatic/jk_forum/1_02.jpg",
  "https://jikejingji.oss-cn-guangzhou.aliyuncs.com/appClientStatic/jk_forum/1_03.jpg",
  "https://jikejingji.oss-cn-guangzhou.aliyuncs.com/appClientStatic/jk_forum/1_04.jpg",
  "https://jikejingji.oss-cn-guangzhou.aliyuncs.com/appClientStatic/jk_forum/1_05.jpg",
  "https://jikejingji.oss-cn-guangzhou.aliyuncs.com/appClientStatic/jk_forum/1_06.jpg",
  "https://jikejingji.oss-cn-guangzhou.aliyuncs.com/appClientStatic/jk_forum/1_07.jpg",
  "https://jikejingji.oss-cn-guangzhou.aliyuncs.com/appClientStatic/jk_forum/1_08.jpg",
  "https://jikejingji.oss-cn-guangzhou.aliyuncs.com/appClientStatic/jk_forum/1_09.jpg",
  "https://jikejingji.oss-cn-guangzhou.aliyuncs.com/appClientStatic/jk_forum/1_10.jpg",
];
</script>

<style lang="scss" scoped>
// .forum_view {
//   .img_container {
//     width: 100%;
//     // height: auto;
//     // height: 100vh;
//   }
// }
</style>
