import { defineStore } from "pinia";
import { sendInstructions } from "@/api/sendData";
export const useStore = defineStore("ba2fc1d6", {
  // 为了完整类型推理，推荐使用箭头函数
  state: () => {
    return {
      imgBaseUrl:
        "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images",
      // 游戏引擎与web状态
      net_close_statu: false, // 断线
      // 是否需要app更新
      isUpdatePop: false,
      // 当前是否是重新打开游戏
      isFirstOpen: true,
      JikeAdAppId: "5623989", //极客广告app_id
      // 青少年模式
      child_info: {
        is_open: false,
        is_lock: false,
        child_show: false,
      },
      gameDataList: {
        mgc: [],
        mxt: [],
        bbl: [],
        vip_tbj: [],
        duobao: [],
        hw3: [],
        zww: [],
        msqy: [],
        bird: [],
        other: [],
      },
      msqyLineListData: [],
      duobaoListData: [],
      gameTabList: [],
      curTabActive: {
        parent: "msqy_line",
        children: "msqy",
        children_index: 0,
      },
      gameIsWatch: true,
      // 结算结果
      settlementResult: {
        type: "normal", // normal / challenge / active
        ret: "faild",
        reward: 500,
        game_name: "",
        machine_index: "",
        device_name: "",
        index: 10086,
        result_show: false,
        dashi_card: false,
        zongshi_card: false,
        lottery: 10086,
        score: 10086, // active de lottery
        dashi_score: 10086,
      },
      // 挑战断线重连状态
      get_player_tiaozhan_info_show: false,

      // 当前游戏数据对象
      current_machine: {
        sp_url: "",
        return_time: 60,
        visit_time: 60,
        site_limit: 100,
        product_id: 0,
        up_coin: 100,
        exchange: 10,
        product_name: "bbl",
        level: 1,
        device_info: [],
        machine_index: 1,
        tiaozhan_index: 1,
        visits: [],
        child_name: "",
        occupy: [
          // {
          //   head_frame: 1,
          //   head: "",
          //   player_name: "11111",
          // },
        ],
        line_reward_index: "",
        group: "",
      },
      // 当前用户所在机位
      current_device: {
        net_line_index: "",
        product_name: "",
        device_name: "", //设备名称，game_ctrl协议中需要填入
        status: "1",
        machine_index: "1",
        device_index: "1", //设备编号 对应p几
        check_num: 0,
        server_time: 0,
        occupy: {},
      },

      // 游戏收集宝珠反馈
      collect_gem_event_result: [],
      // 游戏收集卡片反馈
      collect_event_result: [],
      // 游戏榜
      dayRankShow: false,
      // 充值数据
      initPayPupData: {
        base: [],
        first: [],
        day: [],
        card: {},
        gift: {},
        typeList: [],
        tz_card: [],
        userPayInfo: {
          level: 1,
          exchangeRatio: 10,
          extraGoldRatio: 8,
          total_recharge: 0,
          vip_exp: 0,
        },
      },

      // 用户信息
      userInfo: {
        uid: "8888888",
        // uid: "00031",
        gold: 0,
        level: 1, // 天梯等级
        lottery: 0,
        player_name: "******",
        headimgurl: "",
        share_code: "cctv101",
        id_verification: false,
        tiaozhan_ticket: 0,
        dashi_score: 0,
        select_head_kuang: {
          type: "recharge",
          level: 1,
        },
        phone: "",
        address: "",
        is_gm: false, // 是否是机修
        register_time: false, // 注册时间

        head_frame: 1,
        select_title: 0,
        dashi_card: false,
        zongshi_card: false,
        recharge_level: 2, // 充值等级
        is_custom_name: false,
        is_custom_head: false,
      },

      // 单独抽出周卡月卡任务
      cardTaskList: [],
      // 马戏团彩券提示
      mxtHintDataList: [],
      mxtHintDataNumberCount: 0,
      // 服务器时间
      server_time_data: 0,

      // 存钱罐
      wealBoxData: {
        is_get: false,
        uid: 4,
        update_time: 1698595200,
        next_gold: 588,
        gold: 0,
      },
      notify_mqtt_counting_result_key: 0,
      // 记录查询
      recordDataList: {
        game_log: [],
        gold_log: [],
        recharge_log: [],
        tiaozhan_ticket_log: [],
        lottery_log: [],
        dashi_score_log: [],
        active_log: [],
      },
      // 顶部安全区
      areaInsetTopValue: 34,
      // 渠道信息
      channelType: "IOS",
      // 是否横屏
      isLandscape: false,
      auth_user_show: false, // 实名认证弹窗
      // 充值弹窗
      payPupStatu: {
        show: false,
        type: "first",
      },
      // 推币任务
      coinPushTask: {
        gameName: "none",
        allTaskIndex: 8,
        allTaskName: "完成今日所有任务",
        allTaskRewardType: "lottery",
        allTaskReward: 500,
        allTaskStatu: 0, // 0 未完成 1 待领取 2 已完成
        getCount: 1,
        taskList: [],
      },
      gameTaskData: {
        mgc: {
          task_icon: "",
          statu: 0,
          task_list: [],
          task_award: [],
        },
        bbl: {
          task_icon: "",
          statu: 0,
          task_list: [],
          task_award: [],
        },
        hw3: {
          task_icon: "",
          statu: 0,
          task_list: [],
          task_award: [],
        },
      },
      // 比赛奖励列表数据
      activeData: {
        active_type: "",
        game_name: "",
        active_index: 0,
        reward_data_list: [],
      },
      // 玩家比赛剩余时间
      sync_player_free_match_time: 0,
      cardBagData: {
        waitting: [],
        expired: [],
        using: [],
      },
      cardNotifyData: {
        first: true,
        show: false,
        list: [],
      },
      send_marquee_config: {
        show: false,
        content: "",
        color: "#fff",
        speed: 60,
      },
      turntable_show: false,
      player_turntable_data: {
        progress: 0,
        jp_num: 20035,
        win_log: [],
        jp_log: [],
        turn_info: {
          1: {
            pro_info: [
              {
                index: ["1", "2", "3", "4", "5"],
                pro: 60000,
              },
              {
                index: ["6", "7", "8"],
                pro: 30000,
              },
              {
                index: ["9", "10"],
                pro: 10000,
              },
            ],
            level_limit: [3, 21],
            reward: {
              1: {
                type: "lottery",
                reward_num: 888,
              },
              2: {
                type: "lottery",
                reward_num: 1000,
              },
              3: {
                type: "lottery",
                reward_num: 1500,
              },
              4: {
                type: "lottery",
                reward_num: 2000,
              },
              5: {
                type: "lottery",
                reward_num: 2500,
              },
              6: {
                type: "lottery",
                reward_num: 3000,
              },
              7: {
                type: "lottery",
                reward_num: 3500,
              },
              8: {
                type: "lottery",
                reward_num: 4000,
              },
              9: {
                type: "lottery",
                reward_num: 4500,
              },
              10: {
                type: "lottery",
                reward_num: 5000,
              },
            },
          },
          2: {
            pro_info: [
              {
                index: ["2", "3", "4", "5", "6"],
                pro: 70000,
              },
              {
                index: ["7", "8", "9"],
                pro: 20000,
              },
              {
                index: ["1", "10"],
                pro: 10000,
              },
            ],
            level_limit: [3, 19],
            reward: {
              1: {
                type: "jp",
                reward_num: 0,
              },
              2: {
                type: "lottery",
                reward_num: 1000,
              },
              3: {
                type: "lottery",
                reward_num: 2000,
              },
              4: {
                type: "lottery",
                reward_num: 2500,
              },
              5: {
                type: "lottery",
                reward_num: 3000,
              },
              6: {
                type: "lottery",
                reward_num: 4000,
              },
              7: {
                type: "lottery",
                reward_num: 4500,
              },
              8: {
                type: "lottery",
                reward_num: 5000,
              },
              9: {
                type: "lottery",
                reward_num: 6000,
              },
              10: {
                type: "lottery",
                reward_num: 8000,
              },
            },
          },
          3: {
            pro_info: [
              {
                index: ["2", "3", "4", "5", "6"],
                pro: 80000,
              },
              {
                index: ["7", "8", "9"],
                pro: 15000,
              },
              {
                index: ["1", "10"],
                pro: 5000,
              },
            ],
            level_limit: [3, 18],
            reward: {
              1: {
                type: "jp",
                reward_num: 0,
              },
              2: {
                type: "lottery",
                reward_num: 1688,
              },
              3: {
                type: "lottery",
                reward_num: 2000,
              },
              4: {
                type: "lottery",
                reward_num: 3000,
              },
              5: {
                type: "lottery",
                reward_num: 4000,
              },
              6: {
                type: "lottery",
                reward_num: 5000,
              },
              7: {
                type: "lottery",
                reward_num: 6000,
              },
              8: {
                type: "lottery",
                reward_num: 7000,
              },
              9: {
                type: "lottery",
                reward_num: 8000,
              },
              10: {
                type: "lottery",
                reward_num: 18888,
              },
            },
          },
        },
      },
      sprog_task_show: false,
      sprogTaskData: {
        current_step: -1,
        end_time: 0,
        datas: [
          {
            step: 1,
            p_x: "5.86667rem",
            p_y: "3rem",
            bg_x: 0,
            bubble_x: 0,
            task_info: {
              step: 1,
              game_name: "mxt",
              isNoDone: false,
              task: [
                {
                  task_t: "消耗50币",
                  complete_num: 3,
                  num: 0,
                },
                {
                  task_t: "获得1000彩券",
                  complete_num: 1000,
                  num: 0,
                },
              ],
              task_award: [],
            },
          },
          {
            step: 2,
            p_x: "13.2rem",
            p_y: "2.4rem",
            bg_x: "-5.86667rem",
            bubble_x: "-2.6667rem",
            task_info: {
              step: 2,
              game_name: "mgc",
              isNoDone: false,
              task: [
                {
                  task_t: "收集魔法召唤",
                  complete_num: 1,
                  num: 0,
                },
                {
                  task_t: "收集骷髅暴君",
                  complete_num: 1,
                  num: 0,
                },
              ],
              task_award: [],
            },
          },
          {
            step: 3,
            p_x: "20.72rem",
            p_y: "3rem",
            bg_x: "-11.73334rem",
            bubble_x: "-5.333333rem",
            task_info: {
              step: 3,
              game_name: "msqy",
              isNoDone: false,
              task: [
                {
                  task_t: "消耗300币",
                  complete_num: 300,
                  num: 0,
                },
                {
                  task_t: "魔术奇缘获得连线",
                  complete_num: 1,
                  num: 0,
                },
              ],
              task_award: [],
            },
          },
          {
            step: 4,
            p_x: "28rem",
            p_y: "3rem",
            bg_x: "-17.6rem",
            bubble_x: "3rem",
            task_info: {
              step: 4,
              game_name: "home",
              isNoDone: false,
              task: [
                {
                  task_t: "解锁前三个宝箱",
                  complete_num: 3,
                  num: 0,
                },
              ],
              task_award: [],
            },
          },
          {
            step: 5,
            p_x: "35.28rem",
            p_y: "3rem",
            bg_x: "-23.4667rem",
            bubble_x: "-8rem",
            task_info: {
              step: 5,
              game_name: "bbl",
              isNoDone: false,
              task: [
                {
                  task_t: "收集太棒啦",
                  complete_num: 1,
                  num: 0,
                },
                {
                  task_t: "收集幸运降临",
                  complete_num: 1,
                  num: 0,
                },
              ],
              task_award: [],
            },
          },
          {
            step: 6,
            p_x: "42.5rem",
            p_y: "3rem",
            bg_x: "-29.3333rem",
            bubble_x: "-10.6667rem",
            task_info: {
              step: 6,
              game_name: "hw3",
              isNoDone: false,
              task: [
                {
                  task_t: "收集觉醒一次",
                  complete_num: 1,
                  num: 0,
                },
                {
                  task_t: "收集雷霸龙",
                  complete_num: 1,
                  num: 0,
                },
                {
                  task_t: "收集浴火凤凰",
                  complete_num: 1,
                  num: 0,
                },
              ],
              task_award: [],
            },
          },
          {
            step: 7,
            p_x: "49.8rem",
            p_y: "3rem",
            bg_x: "-35.2rem",
            bubble_x: "3rem",
            task_info: {
              step: 7,
              game_name: "home",
              isNoDone: false,
              task: [
                {
                  task_t: "解锁前六个宝箱",
                  complete_num: 6,
                  num: 0,
                },
              ],
              task_award: [],
            },
          },
          {
            step: 8,
            p_x: "57.2rem",
            p_y: "1.36rem",
            bg_x: "-41.0667rem",
            bubble_x: "-2.6667rem",
            task_info: {
              step: 8,
              game_name: "mgc",
              isNoDone: false,
              task: [
                {
                  task_t: "魔鬼城常规赛挑战成功",
                  complete_num: 1,
                  num: 0,
                },
              ],
              task_award: [],
            },
          },
        ],
      },
      await_up_coin_result: false,
      gemShopShow: false,
      longShopShow: false,
      long_is_open_time: false,

      user_gem_info: {
        update_time: 1729180800,
        buff: {},
        shop: {},
        gem: {
          feng: 0,
          huo: 0,
          lei: 0,
          dian: 0,
          long: 0,
        },
      },
      create_tiaozhan_red_package_result: null,
      post_details: {}, //帖子详情
      forumDetailsShow: false,
      adConfigData: {}, //广告配置
      initAdStatus: false, //广告初始化
      soltsAdData: {
        ad_status: false,
        showAgainBtn: false,
        showAdReward: false,
      },
      welfareAdData: {
        ad_status: false,
      },
      // 挂机任务
      onlineTask: {
        mgc: {
          keep_time: 600,
          step: 2,
          task: {
            1: { num: 0, complete: 1, reward: 30 },
            2: { num: 0, complete: 3, reward: 60 },
            3: { num: 0, complete: 5, reward: 100 },
          },
        },
        hw3: {
          keep_time: 600,
          step: 1,
          task: {
            1: { num: 0, complete: 1, reward: 30 },
            2: { num: 0, complete: 3, reward: 60 },
            3: { num: 0, complete: 5, reward: 100 },
          },
        },
        bird: {
          keep_time: 600,
          step: 1,
          task: {
            1: { num: 0, complete: 1, reward: 30 },
            2: { num: 0, complete: 3, reward: 60 },
            3: { num: 0, complete: 5, reward: 100 },
          },
        },
      },
    };
  },
  actions: {
    initStatu() {
      this.gameIsWatch = true;
      this.mxtHintDataList = [];
      this.mxtHintDataNumberCount = 0;
      this.payPupStatu.show = false;
      this.get_player_tiaozhan_info_show = false;
      this.settlementResult.type = "normal";
      this.settlementResult.score = 0;
      this.settlementResult.dashi_card = false;
      this.settlementResult.zongshi_card = false;
      this.settlementResult.result_show = false;
      this.settlementResult.index = 0;
      this.settlementResult.dashi_score = 0;
      this.settlementResult.ret = "faild";
      this.settlementResult.machine_index = "";
      this.settlementResult.reward = "";
      this.settlementResult.game_name = "";
      this.settlementResult.device_name = "";
      this.settlementResult.lottery = 0;
    },

    payPupToggle(statu, type = "first") {
      this.payPupStatu.show = statu;
      this.payPupStatu.type = type;
    },
    storeShowToggle(key, val) {
      this[key] = val;
    },
  },
});
