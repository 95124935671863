import { ref, onUnmounted } from "vue";

export function usePolling(
  callback,
  options = {
    repetition: 0,
    duration: Infinity,
    interval: 1e3,
  }
) {
  const { repetition, duration, interval } = options;

  const pollingTimer = ref(null);
  const count = ref(0);
  const startTime = ref(0);
  let _callback = callback;
  const pollingFunction = () => {
    _callback();
    const elapsed =
      duration !== Infinity ? Date.now() - startTime.value : Infinity;
    const shouldContinue =
      (duration === Infinity || elapsed < duration) &&
      (repetition === 0 || count.value < repetition);
    if (shouldContinue) {
      repetition > 0 && count.value++;
      pollingTimer.value = setTimeout(pollingFunction, interval);
    } else stop();
  };

  function start() {
    stop();
    options.duration != Infinity && (startTime.value = Date.now());
    pollingFunction();
  }
  function stop() {
    if (pollingTimer.value) {
      clearTimeout(pollingTimer.value);
      pollingTimer.value = null;
    }
  }
  function reset(newCallBack) {
    stop();
    _callback = newCallBack;
  }

  onUnmounted(() => {
    stop();
  });

  return {
    start,
    stop,
    reset,
  };
}
