<template>
  <template v-if="isReady">
    <router-view v-slot="{ Component }">
      <keep-alive>
        <component :is="Component" v-if="route.meta.keepAlive" />
      </keep-alive>
      <component :is="Component" v-if="!route.meta.keepAlive" />
    </router-view>
  </template>

  <!-- 游戏断线 -->
  <van-popup
    v-model:show="store.net_close_statu"
    round
    :close-on-click-overlay="false"
    z-index="99999"
  >
    <div class="net_close_pup">
      <p class="close_title">温馨提示</p>
      <p class="close_content">当前设备已离线，请重新登录</p>
      <div class="close_btn" @touchend="handleNetClose">确定</div>
    </div>
  </van-popup>

  <!-- 青少年模式 -->
  <ChildModel />

  <Turntable />
  <SprogTask v-if="store.userInfo.register_time" />
  <GemShop />
  <LongShop />
  <!-- 更新通知 -->

  <van-overlay :show="store.isUpdatePop" z-index="9999999" :lock-scroll="true">
    <div class="up_date_pop">
      <van-image
        :src="update_text_img"
        :show-loading="false"
        width="100%"
        height="100%"
      />

      <div class="confirm_btn_wrap" @click="goToUpDate"></div>
      <div
        class="cancel_btn_wrap"
        @click="store.storeShowToggle('isUpdatePop', false)"
      ></div>
    </div>
  </van-overlay>

  <!-- 退分结果 -->

  <RotateWrapper v-show="store.settlementResult.result_show">
    <GameResult @exitBtnClick="confirmResilt" />
  </RotateWrapper>

  <!-- 充值 -->
  <RechargeView />
  <!-- 实名认证 -->
  <AuthUserIdCard @close="store.storeShowToggle('auth_user_show', true)" />
  <!-- 卡券消息通知 -->
</template>

<script setup>
import { useStore } from "@/store/store";
import {
  onMounted,
  onUpdated,
  onBeforeMount,
  ref,
  watch,
  nextTick,
  KeepAlive,
} from "vue";
import { sendInstructions, asyncSendInstructions } from "@/api/sendData";
import safeAreaInsets from "safe-area-insets";
import { getCurrentDevice } from "@/utils/utils";
import { useRouter, useRoute } from "vue-router";

import { showToast, closeToast } from "vant";
import SprogTask from "@/components/SprogTask.vue";
import update_text_img from "@/assets/images/updateapp/update_text.png";

import {
  getTianti,
  playerRechargeStatus,
  getPlayerSprogTask,
  getGuanggaoConfig,
} from "@/api";
import ChildModel from "@/components/ChildModel.vue";

import Turntable from "@/components/Turntable.vue";
import GemShop from "@/components/GemShop.vue";
import LongShop from "@/components/LongShop.vue";
import GameResult from "@/components/GameResult.vue";
import RotateWrapper from "@/components/RotateWrapper.vue";

import RechargeView from "@/components/RechargeView.vue";
import AuthUserIdCard from "@/components/AuthUserIdCard.vue";

import Vconsole from "vconsole";

let noInitializeSucceedCallBackTimer = setTimeout(() => {
  if (!isReady.value) {
    sendInstructions(
      {
        msg_type: "get_player_common",
      },
      100
    );

    sendInstructions(
      {
        msg_type: "get_player_common",
      },
      1e3
    );
  }
}, 6e3);

const store = useStore();
const router = useRouter();
const route = useRoute();
// 项目初始化
const isReady = ref(false);

store.$patch((state) => {
  state.channelType = getCurrentDevice();
});

const _initialize = async () => {
  clearTimeout(noInitializeSucceedCallBackTimer);
  if (isReady.value) return;

  // (store.userInfo.ios_version != "2.0.0" ||
  // parseInt(store.userInfo.ios_version) < 2)
  if (getCurrentDevice() !== "IOS" && store.userInfo.ios_version != "2.0.0") {
    store.$patch((state) => {
      state.isUpdatePop = true;
    });
  }

  try {
    const params = {
      uid: parseInt(store.userInfo.uid),
    };
    const res = await getTianti(params);
    const res2 = await playerRechargeStatus(params);
    let _register_time = store.userInfo.register_time;
    // console.log("_register_time", _register_time);

    if (_register_time) {
      const res3 = await getPlayerSprogTask(params);
      const fresh_time = Number(res3.data.player_sprog_task.end_time) * 1000;
      // console.log("fresh_time", fresh_time);
      // console.log("Date.now()Date.now()", Date.now());

      _register_time =
        Number(res3.data.player_sprog_task.step) < 9 && Date.now() < fresh_time;
    }

    store.$patch((state) => {
      state.userInfo.dashi_card = !!res.data.dashi_card;
      state.userInfo.zongshi_card = !!res.data.zongshi_card;
      state.userInfo.level = res.data.level;
      state.userInfo.recharge_level = res2.data.level;
      state.userInfo.register_time = _register_time;
    });

    init53servicefunc();
    isReady.value = true;

    const validUids = [18, 31, 65];
    if (validUids.includes(parseInt(store.userInfo.uid))) {
      new Vconsole();
    }
  } catch (error) {
    console.log("_initialize ERROR----->", error);

    // sendInstructions({
    //   msg_type: "restart_game",
    // });

    init53servicefunc();
    isReady.value = true;
  }
};

watch(
  () => store.userInfo,
  (newV) => {
    if (newV.share_code != "cctv101" || newV.uid != "8888888") {
      _initialize();
    }
  },
  {
    deep: true,
  }
);

sendInstructions(
  {
    msg_type: "get_player_child_info",
  },
  100
);
// 转盘
sendInstructions(
  {
    msg_type: "get_player_turntable",
  },
  500
);

function styleCallback(style) {
  if (style.top > 0) {
    store.$patch((state) => {
      state.areaInsetTopValue = safeAreaInsets.top;
    });
  }
}

safeAreaInsets.onChange(styleCallback);

const handleNetClose = () => {
  sendInstructions(
    {
      msg_type: "restart_game",
    },
    0
  );
};

const confirmResilt = () => {
  store.settlementResult.result_show = false;
  const isGame =
    store.settlementResult.game_name == store.current_machine.product_name;
  const isMachine =
    store.settlementResult.machine_index == store.current_machine.machine_index;
  const isDevice =
    store.settlementResult.device_name == store.current_device.device_name;

  if (isMachine && isDevice && isGame) {
    router.replace({
      name: "home",
    });
    store.initStatu();
    return;
  }

  store.settlementResult.result_show = false;
};

const getAdConfig = async () => {
  const device = getCurrentDevice() == "IOS" ? "ios" : "android";
  if (device == "ios") return false;

  try {
    const res = await getGuanggaoConfig(device);
    if (res.Message == "ok") {
      store.adConfigData = res.data.filter(
        (item) => item.app_id == store.JikeAdAppId
      );

      //初始化广告
      sendInstructions({
        msg_type: "init_ad",
        app_id: store.JikeAdAppId,
      });

      console.log("获取广告配置", store.adConfigData);

      // sendInstructions({ msg_type: "is_init_ad" });
    }
  } catch (error) {
    console.log("getAdConfigError:", error);
  }
};

//#region 跳转更新
const goToUpDate = () => {
  const dev = getCurrentDevice();
  if (dev == "IOS") {
    store.isUpdatePop = false;
    return;
  }

  sendInstructions({
    msg_type: "open_android_store",
  });
};

//#endregion

onMounted(() => {
  sendInstructions({
    msg_type: "get_player_common",
  });

  sendInstructions(
    {
      msg_type: "get_player_child_info",
    },
    500
  );

  getAdConfig();
});

//#region 53客服

const init53servicefunc = () => {
  window._$53 = window._$53 || []; //注：预定义对象，变量名不可更改，使用前请检查是否和自身网站变量名冲突
  window._$53.push([
    "_setGuestInfo",
    {
      id: parseInt(store.userInfo.uid),
      name: parseInt(store.userInfo.uid),
      email: parseInt(store.userInfo.uid),
      qq: parseInt(store.userInfo.uid),
      phone: parseInt(store.userInfo.uid),
      company: parseInt(store.userInfo.uid),
      address: parseInt(store.userInfo.uid),
      notes: "xcsh",
      crm_fields: "",
      custom_info: "",
    },
  ]);
  // 注：请使用自身53账号后台提供的挂码，不要使用以下实例挂码
  (function () {
    var _53code = document.createElement("script");
    _53code.src =
      "https://tb.53kf.com/code/code/1b51a5c15af623278655bbd31ca922d80/1";
    var s = document.getElementsByTagName("script")[0];
    _53code.onload = function () {
      window.$_c53_api = $53.createApi();
      window.$_c53_api.terminal = "mobile";
    };
    s.parentNode.insertBefore(_53code, s);
  })();
};

//#endregion
</script>

<style lang="scss">
@import "./assets/css/main.scss";

#app {
  // width: 100%;
  // overflow-x: hidden;
  // overflow-y: auto;

  background-color: #3a1b67;
  color: #fff;
  font-size: 12px;
  font-family: SOURCEHANSANSCN, Source Han Sans CN-Bold, Source Han Sans CN;
}

.up_date_pop {
  position: absolute;
  width: 300px;
  height: 290px;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);

  .confirm_btn_wrap {
    position: absolute;
    width: 130px;
    height: 42px;
    right: 13px;
    bottom: 20px;
    background-image: url("@/assets/images/updateapp/confirm_btn.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    z-index: 3;
  }

  .cancel_btn_wrap {
    position: absolute;
    width: 130px;
    height: 42px;
    left: 13px;
    bottom: 20px;
    background-image: url("@/assets/images/updateapp/cancel_btn.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    z-index: 3;
  }
}

#outer_wrapper {
  position: absolute;
}

.hh_dialog {
  background-color: transparent;
  background-image: linear-gradient(
    180deg,
    #bbd8f9 0%,
    #ffffff 58%,
    #eef6f9 100%
  ) !important;

  .van-dialog__header {
    font-size: 24px;
    background: linear-gradient(90deg, #3967ff 0%, #7091ff 44%, #8c85ff 100%);
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    font-weight: 1000;
  }

  .van-dialog__message {
    margin-top: 20px;
    color: #3e4056;
    font-size: 16px;
    font-weight: bold;
  }

  .van-dialog__cancel {
    background: linear-gradient(90deg, #81c3ff 0%, #5034ff 100%);
    // opacity: 0.7;
    border-radius: var(--van-radius-max);
    margin-left: 4px;
    margin-right: 4px;
  }

  .van-dialog__confirm {
    background: linear-gradient(90deg, #ff819b 0%, #ff3450 100%);
    // opacity: 0.7;
    border-radius: var(--van-radius-max);
    margin-left: 4px;
    margin-right: 4px;
  }
}

:root:root {
  --van-nav-bar-background: none;
  --van-nav-bar-icon-color: #fff;
  --van-nav-bar-text-color: #fff;
  --van-nav-bar-title-text-color: #fff;
  // --van-cell-background: rgb(75, 35, 128) !important;
  // --van-cell-text-color: #fff;
  // --van-cell-right-icon-color: #fff;
}

.return-lottery-pup-wapper {
  width: 300px;
  height: 120px;
  background-image: url("https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/pup_bg.png");
  background-size: 100% 100%;
  position: relative;
  padding: 20px 10px;
  text-align: center;
  font-size: 16px;

  .pup-close-icon {
    position: absolute;
    right: 8px;
    top: 8px;
    width: 26px;
    height: 26px;
    background-image: url("https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/pup_close.png");
    background-size: 100% 100%;
  }
}

.net_close_pup {
  width: 90vw;
  padding-top: 30px;
  padding-bottom: 20px;
  min-height: 200px;
  background: linear-gradient(180deg, #bbd8f9 0%, #ffffff 58%, #eef6f9 100%);
  text-align: center;

  .close_title {
    font-size: 24px;
    background: linear-gradient(90deg, #3967ff 0%, #7091ff 44%, #8c85ff 100%);
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    font-weight: 1000;
  }

  .close_content {
    margin-top: 20px;
    color: #3e4056;
    font-size: 16px;
    margin-bottom: 34px;
  }

  .close_btn {
    width: 164px;
    height: 42px;
    font-size: 16px;
    border-radius: 22px;
    line-height: 42px;
    color: #fff;
    margin: 0 auto;
    background: linear-gradient(180deg, #81c3ff 0%, #5034ff 100%);
  }
}

.scale_in_center_class {
  -webkit-animation: scale-in-center 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94)
    both;
  animation: scale-in-center 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

@-webkit-keyframes scale-in-center {
  0% {
    -webkit-transform: translate(-50%, -50%) scale(0);
    transform: translate(-50%, -50%) scale(0);
    opacity: 1;
  }

  100% {
    -webkit-transform: translate(-50%, -50%) scale(1);
    transform: translate(-50%, -50%) scale(1);
    opacity: 1;
  }
}

@keyframes scale-in-center {
  0% {
    -webkit-transform: translate(-50%, -50%) scale(0);
    transform: translate(-50%, -50%) scale(0);
    opacity: 1;
  }

  100% {
    -webkit-transform: translate(-50%, -50%) scale(1);
    transform: translate(-50%, -50%) scale(1);
    opacity: 1;
  }
}
</style>
