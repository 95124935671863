<template>
  <div
    class="level_wrapper"
    :style="{
      minWidth: _w,
      height: _h,
      borderRadius: radiusValue,
      border: props.border ? '0.026667rem solid #9a44e7' : '',
      display: props.block ? 'block' : 'inline-block',
      background:
        props.background != 'none' ? props.background : 'rgba(0,0,0,0.4)',
    }"
  >
    <div class="level_content">
      <div
        class="level_icon"
        :style="{
          width: _h,
          height: _h,
        }"
      >
        <van-image
          width="100%"
          height="100%"
          block
          :show-loading="false"
          :src="gradLevelIcon[props.level_value].iconUrl"
          v-if="props.level_type == 'recharge_level'"
        />
        <van-image
          width="100%"
          height="100%"
          block
          :show-loading="false"
          :src="gradLevelIcon[props.level_value].iconUrl"
          v-else
        />
      </div>
      <div
        class="level_text"
        :style="{
          lineHeight: lineHeightValue + 'px',
          color: props.color,
        }"
      >
        <span v-if="props.level_type == 'recharge_level'">{{
          gradLevelIcon[props.level_value].level_text
        }}</span>
        <span v-else>{{ rankLevelIcon[props.level_value].level_text }}</span>
      </div>
    </div>
  </div>
</template>

<script setup>
import { rankLevelIcon } from "@/utils/levelData";
import { computed, h } from "vue";
import { useStore } from "@/store/store";
import { gradLevelIcon } from "@/utils/levelData";
import Decimal from "decimal.js";
const store = useStore();
const props = defineProps({
  width: {
    type: Number,
    default: 30,
  },
  height: {
    type: Number,
    default: 14,
  },
  level_type: {
    // level  or  recharge_level
    type: String,
    default: "level",
  },
  level_value: {
    type: Number,
    default: 1,
  },
  border: {
    type: Boolean,
    default: false,
  },
  block: {
    type: Boolean,
    default: false,
  },
  background: {
    type: String,
    default: "rgba(0,0,0,0.4)",
  },
  color: {
    type: String,
    default: "#FFF",
  },
});

const rem = parseFloat(document.documentElement.style.fontSize);

const _w = computed(() => {
  return (props.width / rem).toFixed(5) + "rem";
});

const _h = computed(() => {
  return (props.height / rem).toFixed(5) + "rem";
});

const radiusValue = computed(() => {
  return (props.height / rem / 2).toFixed(5) + "rem";
});
const lineHeightValue = computed(() => {
  if (props.border) {
    return ((props.height - 2) / rem).toFixed(5) + "rem";
  } else return (props.height / rem).toFixed(5) + "rem";
});
</script>

<style scoped lang="scss">
.level_wrapper {
  border-radius: 50%;
  display: inline-block;
  height: 14px;
  line-height: 14px;
  .level_content {
    display: flex;
    width: 100%;
    height: 100%;

    padding: 0 4px;

    justify-content: center;
    align-items: center;

    .level_icon {
      // margin-top: 2px;
      width: auto;
      height: 100%;
    }

    .level_text {
      font-size: 10px;
      padding-left: 2px;
      white-space: nowrap;
    }
  }
}
</style>
