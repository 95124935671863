import { useStore } from "@/store/store";
import {
  formatTimestamp,
  decodeBase64String,
  uidStringLengthFixed,
  initRecordData,
  initGameViewTaskData,
  getCurrentDevice,
} from "@/utils/utils";
import { closeToast, showToast } from "vant";
import Decimal from "decimal.js";
import findCollectCardInfo from "@/utils/CollectClassID";
import { mockBackResult } from "@/utils/mockResult";

// 指令key
const scheme = "shipin";
const isMock = false; // 数据模拟

function initGameData(dataList) {
  let gameDataList = [];
  // let gameTabsList = [];

  for (const _key in dataList) {
    const infoItem = dataList[_key];

    if (infoItem.group == "msqy_line") continue;

    let occupys = [];

    let visits = [];

    if (!Array.isArray(infoItem.device_info)) {
      infoItem.device_info = [];
    }

    if (infoItem.device_info.length == 0) {
      infoItem.device_info = [];
    }

    infoItem.device_info.forEach((devItem) => {
      if (devItem.occupy && devItem.occupy.player_id) {
        occupys.push(devItem.occupy);
      }
    });

    // JSON 对象形式
    // let visitsNumber = Object.keys(infoItem.visits);
    // if (visitsNumber.length > 4) {
    //   visitsNumber = infoItem.visits.slice(0, 4);
    // }
    // if (visitsNumber.length > 0) {
    //   visitsNumber.forEach((visit) => {
    //     const visitUserInfo = {
    //       uid: visit,
    //       head: infoItem.visits[visit].head || "",
    //     };
    //     visits.push(visitUserInfo);
    //   });
    // }
    // 数组data 形式
    if (infoItem.visits && infoItem.visits.length > 4) {
      visits = infoItem.visits.slice(0, 4);
    } else if (infoItem.visits && infoItem.visits.length > 0) {
      visits = infoItem.visits;
    }

    const gameItem = {
      sp_url: infoItem.sp_url,
      return_time: Number(infoItem.return_time) || 0,
      visit_time: infoItem.visit_time,
      site_limit: infoItem.site_limit,
      product_id: infoItem.product_id,
      up_coin: infoItem.up_coin,
      exchange: infoItem.exchange,
      product_name: infoItem.product_name,
      level: infoItem.level,
      device_info: infoItem.device_info,
      machine_index: infoItem.machine_index,
      tiaozhan_index: infoItem.tiaozhan_index,
      status: infoItem.status,
      child_name: infoItem.child_name,
      visits: visits || [],
      occupy: occupys || [],
      line_reward_index: infoItem.line_reward_index,
      group: infoItem.group,
    };
    gameDataList.push(gameItem);
  }

  // gameTabsList = Array.from(new Set(gameTabsList));
  gameDataList.sort((a, b) => Number(a.exchange) - Number(b.exchange));
  gameDataList.sort((a, b) => Number(a.level) - Number(b.level));
  return gameDataList;
}

function initPayConfig(info) {
  const { recharge_def, player_recharge_status } = info;

  const devType = getCurrentDevice() == "IOS" ? "ios" : "android";
  //
  const _dev_recharge = recharge_def[devType];

  const pinitData = {
    base: [],
    first: [],
    day: [],
    card: [],
    typeList: [],
    tz_card: [],
    userPayInfo: {
      level: 1,
      exchangeRatio: 10,
      extraGoldRatio: 8,
      total_recharge: player_recharge_status.total_recharge,
      vip_exp: player_recharge_status.vip_exp,
    },
  };

  // const
  let payTypeKeys = ["first", "card", "day", "base"];

  // payTypeKeys = Object.keys(_dev_recharge).filter(
  //   (key) =>
  //     key != "level_info" &&
  //     key != "gift" &&
  //     key != "tz_card" &&
  //     key != "foot" &&
  //     key != "lspmdci"
  // );

  payTypeKeys.forEach((typeItem) => {
    const libaoIndex = Object.keys(_dev_recharge[typeItem]);

    libaoIndex.forEach((keyIndex) => {
      const payListItem = _dev_recharge[typeItem][keyIndex];

      let payListItemObj = {
        yield: parseInt(
          (Number(payListItem.gold) / Number(payListItem.rmb)) * 10
        ),
        gold: payListItem.gold,
        price: Number(payListItem.rmb).toFixed(2),
        product_id: payListItem.product_id,
        payIndex: keyIndex,
        isPay: true,
      };

      let isPayKeys = [];

      if (typeItem == "first") {
        isPayKeys = Object.keys(player_recharge_status.first);
        isPayKeys.includes(keyIndex) && (payListItemObj.isPay = false);
        payListItemObj.yield = 400;
      }
      if (typeItem == "day") {
        payListItemObj.isPay = player_recharge_status.day[keyIndex]
          ? player_recharge_status.day[keyIndex] < 3
          : true;
      }

      if (typeItem == "base") payListItemObj.isPay = true;
      if (typeItem == "card") {
        payListItemObj = {
          yield: 0,
          gold: payListItem.gold,
          price: Number(payListItem.rmb).toFixed(2),
          challengeCard: Number(payListItem.tiaozhan),
          daily_gold: Number(payListItem.day_gold),
          days: Number(payListItem.free_day),
          product_id: payListItem.product_id,
          payIndex: keyIndex,
          type: "",
          isPay: true,
        };
        payListItemObj.type =
          Number(payListItem.free_day) == 7 ? "week" : "month";

        if (payListItemObj.type == "week" && payListItemObj.price > 30)
          payListItemObj.type = "superWeek";

        if (payListItemObj.type == "month" && payListItemObj.price > 128)
          payListItemObj.type = "superMonth";

        payListItemObj.yield =
          (payListItemObj.days * payListItemObj.daily_gold +
            Number(payListItemObj.gold)) /
          Number(payListItemObj.price);

        payListItemObj.yield = parseInt(payListItemObj.yield * 10);
      }

      pinitData[typeItem].push(payListItemObj);
    });
  });

  payTypeKeys = ["first", "card", "day", "base", "exchange"];

  // getCurrentDevice() == "IOS" && (payTypeKeys = ["base", "card", "exchange"]);
  pinitData.typeList = payTypeKeys;

  const levelIndex =
    Number(player_recharge_status.level) - 1 <= 0
      ? 0
      : Number(player_recharge_status.level) - 1;
  pinitData.userPayInfo.exchangeRatio =
    recharge_def.level_info[levelIndex].exchange_radio;
  pinitData.userPayInfo.extraGoldRatio = parseInt(
    Number(recharge_def.level_info[levelIndex].recharge_add_radio) * 100
  );

  pinitData.userPayInfo.level = Number(player_recharge_status.level);

  const giftList = [];

  const giftKeys = Object.keys(_dev_recharge.gift);

  giftKeys.forEach((gift) => {
    const obj = {
      p_index: gift,
      buff: 0,
      yield: 0,
      price: 0,
      product_id: _dev_recharge.gift[gift].product_id,
      payIndex: gift,
      gold: Number(_dev_recharge.gift[gift].gold),
    };

    obj.buff = Number(_dev_recharge.gift[gift].buff) * 100;
    obj.price = Number(_dev_recharge.gift[gift].rmb);
    obj.yield = parseInt(obj.gold / obj.price) * 10;

    giftList.push(obj);
  });

  const tz_card_indexs = Object.keys(_dev_recharge.tz_card);
  const tiaozhan_card_pay_list = [];

  tz_card_indexs.forEach((pay_index) => {
    tiaozhan_card_pay_list.push({
      payIndex: pay_index,
      ..._dev_recharge.tz_card[pay_index],
    });
  });
  pinitData.tz_card = tiaozhan_card_pay_list;

  // const gradLevels = [];
  // recharge_def.level_info.forEach((litem, lindex) => {
  //   const levelData = {
  //     ...litem,
  //     level_text: gradLevelIcon[lindex + 1].level_text,
  //     iconUrl: gradLevelIcon[lindex + 1].iconUrl,
  //   };

  //   gradLevels.push(levelData);
  // });

  const cardTaskIndexList = Object.keys(player_recharge_status.card);
  const cardTaskList = [];

  if (cardTaskIndexList.length > 0) {
    const cardName = ["周卡奖励", "超级周卡奖励", "月卡奖励", "超级月卡奖励"];

    cardTaskIndexList.forEach((cardIndex) => {
      const cardTask = {
        taskName: cardName[Number(cardIndex) - 1],
        current: 0,
        complete: player_recharge_status.card[cardIndex].free_day,
        taskType: "card",
        taskID: `index_${cardIndex}`,
        awardType: "gold",
        awardNumber: _dev_recharge.card[cardIndex].day_gold,
        statu: player_recharge_status.card[cardIndex].is_get ? "done" : "get",
      };
      cardTaskList.push(cardTask);
    });
  }

  return { pinitData, cardTaskList };
}

function initCardBagData(info) {
  const cardBagList = {
    waitting: [],
    expired: [],
    using: [],
  };
  if (!Array.isArray(info) || info.length == 0) return cardBagList;

  const cardNameList = [
    {
      type: "zhekou_card",
      name: "折扣卡",
    },
    {
      type: "jiacheng_card",
      name: "加成卡",
    },
    {
      type: "jifen_card",
      name: "积分卡",
    },
    {
      type: "tianti_buff_card",
      name: "联赛buff加成卡",
    },
    {
      type: "gem_gaobao_card",
      name: "宝珠高爆卡",
    },
    {
      type: "gem_fanbei_card",
      name: "宝珠翻倍卡",
    },
  ];

  info.forEach((item) => {
    const cardItem = {
      type: item.item_type,
      card_use_name: "",
      card_name: "",
      item_id: item.item_id,
      start_time: formatTimestamp(item.start_time, "yyyy-mm-dd"),
      end_time: formatTimestamp(
        Number(item.start_time) + Number(item.use_limit),
        "yyyy-mm-dd"
      ),
      statu: item.status,
      buff_end_time:
        item.item_type == "tianti_buff_card" ? item.buff_end_time : 0,
      add_mul: Number(item.add_mul),
    };

    const cardInfo = cardNameList.find((card) => {
      return card.type == item.item_type;
    });

    if (cardInfo) cardItem.card_name = cardInfo.name;

    const _add_mul = new Decimal(cardItem.add_mul);

    const add_mul_type =
      _add_mul.toNumber() > 1
        ? _add_mul.sub(1).mul(100).toNumber() + "%"
        : _add_mul.mul(100).div(10).toNumber() + "折";

    if (cardItem.type == "jifen_card") {
      cardItem.card_use_name = "积分加成" + add_mul_type;
    }
    if (cardItem.type == "jiacheng_card") {
      cardItem.card_use_name = "充值加成" + add_mul_type;
    }
    if (cardItem.type == "zhekou_card") {
      cardItem.card_use_name = "充值" + add_mul_type + "卡";
    }
    if (cardItem.type == "tianti_buff_card") {
      cardItem.card_use_name = "联赛积分加成" + add_mul_type;
    }
    if (cardItem.type == "gem_fanbei_card") {
      cardItem.card_use_name = `宝珠翻倍卡(${_add_mul}倍)`;
    }
    if (cardItem.type == "gem_gaobao_card") {
      cardItem.card_use_name = `宝珠高爆卡(${_add_mul}倍)`;
    }

    cardBagList[item.status].push(cardItem);
  });

  return cardBagList;
}

// 指令发送
/**
 * 自定义协议指令请求
 * @param {object} params - 对象参数
 * @param {number} latencyTime - 延时
 * @param {boolean} isLoding - loding状态
 * @return {void}
 */
export const sendInstructions = (params, latencyTime = 50) => {
  // 注意!：使用Promise 优先级过高可能会导致数据丢失  退而其次 使用 setTimeout
  let instruct = Object.entries(params)
    .map(([key, value]) => `${key}=${value}`)
    .join("&");

  // 兼容
  setTimeout(() => {
    document.location = `${scheme}://${instruct}`;
  }, latencyTime);

  // 数据模拟
  if (isMock) {
    const noResultTypes = [
      "net_close",
      "get_player_common",
      "get_machine_info",
    ];

    const msg_type = noResultTypes.includes(params.msg_type)
      ? params.msg_type
      : params.msg_type + "_result";

    mockBackResult(msg_type, 1000);
  }
};
export const asyncSendInstructions = (params, timeout = 1e4) => {
  const instruct = Object.entries(params)
    .map(([key, value]) => `${key}=${value}`)
    .join("&");

  setTimeout(() => {
    document.location = `${scheme}://${instruct}`;
  }, 0);

  const noResultTypes = [
    "net_close",
    "get_player_common",
    "get_machine_info",
    "play_ad",
  ];

  const _Msg_type = noResultTypes.includes(params.msg_type)
    ? params.msg_type
    : params.msg_type + "_result";

  isMock && mockBackResult(_Msg_type, 1000);

  const timeoutPromise = new Promise((_, reject) => {
    setTimeout(() => {
      if (window.setDataPromiseResolveMap.get(_Msg_type)) {
        window.setDataPromiseResolveMap.delete(_Msg_type);
      }
      reject(new Error(`Timeout waiting for ${_Msg_type} set_data`));
    }, timeout);
  });

  const handlePromise = new Promise((resolve, _) => {
    window.setDataPromiseResolveMap.set(_Msg_type, resolve);
  });
  return Promise.race([handlePromise, timeoutPromise]);
  // return handlePromise;
};
// 断线;
const net_close = (store, info) => {
  store.$patch((state) => {
    state.net_close_statu = true;
  });
};

// 外调 app_store open_ios_store open_android_store

// 用户信息
const get_player_common = (store, info) => {
  const iniUserInfo = {
    ...info,
    recharge_level: 1,
    phone: "",
    address: "",
  };

  if (iniUserInfo.headimgurl == "") {
    iniUserInfo.headimgurl = iniUserInfo.head_frame;
  }

  iniUserInfo.player_name = decodeBase64String(iniUserInfo.player_name);
  const rt = Number(info.register_time) * 1000;
  const isNt = 432000000;

  iniUserInfo.register_time = Date.now() < rt + isNt;

  iniUserInfo.uid = uidStringLengthFixed(iniUserInfo.uid);

  store.$patch((state) => {
    state.userInfo = iniUserInfo;
  });
};

// 游戏列表
const get_machine_info = (store, info) => {
  const keysN = Object.keys(info);
  if (keysN.length == 0) return;

  const game_name = info[keysN[0]].product_name;
  store.$patch((state) => {
    const gameDataList = initGameData(info);

    state.gameDataList[game_name] &&
      (state.gameDataList[game_name] = gameDataList);

    gameDataList.some((item) => {
      if (
        item.product_id == state.current_machine.product_id &&
        item.machine_index == state.current_machine.machine_index
      ) {
        state.current_machine = item;

        item.device_info.some((dev) => {
          if (
            dev.device_name == state.current_device.device_name &&
            dev.device_index == state.current_device.device_index
          ) {
            state.current_device.check_num = dev.check_num;
            return true;
          } else return false;
        });

        return true;
      } else return false;
    });
  });
};
// 退分
const return_lottery_result = (store, info) => {
  const noAddLotteryProductNames = [
    "mxt",
    "msqy",
    "msqy_line",
    "bbxc",
    "bjl",
    "bql",
    "bjwg",
    "hdcz",
    "yhtd",
    "cssxp",
    "hjj",
    "cbjl",
  ];
  store.$patch((state) => {
    state.settlementResult.result_show = true;
    state.settlementResult.lottery = info.lottery;
    state.settlementResult.game_name = info.game_name;
    state.settlementResult.machine_index = info.machine_index;
    state.settlementResult.device_name = info.device_name;

    if (!noAddLotteryProductNames.includes(info.game_name)) {
      state.userInfo.lottery =
        Number(state.userInfo.lottery) + Number(info.lottery);
    }
  });
};
// 退分状态
const notify_mqtt_counting_result = (store, info) => {
  store.$patch((state) => {
    state.notify_mqtt_counting_result_key = Date.now();
  });
};

// 上分
const up_coin_result = (store, info) => {
  if (info.error === "ok") {
    store.$patch((state) => {
      state.await_up_coin_result = false;
      state.userInfo.gold = Number(state.userInfo.gold) - Number(info.dec_gold);
    });
  } else store.await_up_coin_result = false;
};

// 充值数据
const get_player_recharge_status_result = (store, info) => {
  closeToast();

  store.$patch((state) => {
    const { pinitData: pdata, cardTaskList } = initPayConfig(info);

    pdata.day.unshift(pdata.day.pop());

    state.initPayPupData = pdata;
    state.userInfo.recharge_level = pdata.userPayInfo.level;

    state.cardTaskList = cardTaskList;
  });
};
// 彩劵兑换
const exchange_lottery_result = (store, info) => {
  store.$patch((state) => {
    if (info.error == "ok" && info.gold) {
      showToast({
        type: "success",
        message: `获得${info.gold}电玩币`,
      });
      state.userInfo.gold = Number(state.userInfo.gold) + Number(info.gold);

      state.userInfo.lottery = 0;
    } else {
      showToast({
        type: "fail",
        message: "兑换失败",
      });
    }
  });
};
// 充值反馈
const player_recharge_result = (store, info) => {
  closeToast();
  showToast({
    type: "success",
    message: `充值成功`,
    forbidClick: true,
    onClose() {
      store.$patch((state) => {
        info.tiaozhan &&
          (state.userInfo.tiaozhan_ticket =
            Number(state.userInfo.tiaozhan_ticket) + Number(info.tiaozhan));

        info.gold &&
          (state.userInfo.gold =
            Number(state.userInfo.gold) + Number(info.gold));

        info.level && (state.userInfo.recharge_level = Number(info.level));

        state.payPupStatu.show = false;
        sendInstructions({
          msg_type: "get_player_recharge_status",
        });

        sendInstructions({
          msg_type: "get_item",
        });
      });
    },
  });
};
// 取消充值
const player_recharge_cancel_result = (store, info) => {
  closeToast();
  showToast({
    type: "fail",
    message: "取消充值",
  });
};

// 獲取服務器時間 ;
const get_server_time_result = (store, info) => {
  console.log("接到服务器时间");

  store.$patch((state) => {
    state.server_time_data = info.server_time;
  });
};
// 返回馬戲團實時lottery
const tuibiji_return_lottery_result = (store, info) => {
  const _nowKey = Date.now();

  store.$patch((state) => {
    // 账户
    state.mxtHintDataNumberCount =
      state.mxtHintDataNumberCount + Number(info.lottery);

    state.userInfo.lottery =
      Number(state.userInfo.lottery) + Number(info.lottery);

    // game 提示

    if (state.mxtHintDataList.length == 0) {
      state.mxtHintDataList.push({
        lottery: info.lottery,
        key: _nowKey,
      });
    } else if (
      _nowKey - state.mxtHintDataList[state.mxtHintDataList.length - 1].key >=
      1000
    ) {
      if (state.mxtHintDataList.length > 12) {
        state.mxtHintDataList = [
          state.mxtHintDataList[7],
          state.mxtHintDataList[8],
          state.mxtHintDataList[9],
          state.mxtHintDataList[10],
          state.mxtHintDataList[11],
          {
            lottery: info.lottery,
            key: Date.now(),
          },
        ];
      } else {
        state.mxtHintDataList.push({
          lottery: info.lottery,
          key: _nowKey,
        });
      }
    }
  });
};

// 福利罐子
const get_player_weal_box_result = (store, info) => {
  store.$patch((state) => {
    state.wealBoxData = info;
  });
};
// 领取福利罐子
const exchange_player_weal_box_result = (store, info) => {
  if (info.error == "ok" || info.gold) {
    showToast({
      type: "success",
      message: "领取成功！",
      onClose: () => {
        sendInstructions({
          msg_type: "get_player_weal_box",
        });
      },
    });

    store.$patch((state) => {
      state.userInfo.gold = Number(state.userInfo.gold) + Number(info.gold);
    });
  }
};

// 外部兑换码奖励
const gm_exchange_code_reward_result = (store, info) => {
  showToast({
    type: "success",
    message: "奖励到账",
  });
  if (info.gold) {
    store.$patch((state) => {
      state.userInfo.gold = state.userInfo.gold + Number(info.gold);
    });
  } else {
    sendInstructions(
      {
        msg_type: "get_player_common",
      },
      50
    );
  }
};
// 各项记录
const get_all_log_result = (store, info) => {
  store.$patch((state) => {
    const recordData = initRecordData(info, state.initPayPupData);
    recordData.type != "none" &&
      (state.recordDataList[recordData.type] = recordData.list);
  });
};

// 新收集反馈
const sync_update_player_gem_collect_result = (store, info) => {
  store.$patch((state) => {
    state.collect_gem_event_result.push({
      ...info,
      key: Date.now(),
    });
    setTimeout(() => {
      state.collect_gem_event_result.shift();
    }, 3000);
  });
};

// 开启青少年结果
const open_child_result = (store, info) => {
  let child_show = false;
  if (info.error == "ok") {
    if (store.child_info.error_num != info.child_info.error_num) {
      showToast({
        message: `密码错误\n 还有${10 - info.child_info.error_num}次机会`,
      });
      return;
    }

    showToast({
      message: info.type == "close" ? "青少年模式关闭" : "青少年模式开启",
    });

    if (info.type == "close") child_show = false;
    if (info.type == "open") child_show = true;

    store.child_info = {
      ...info.child_info,
      child_show,
    };
  }

  if (info.error != "ok") {
    showToast({
      type: "fail",
      message: info.error,
    });
  }
};
// 青少年数据
const get_player_child_info_result = (store, info) => {
  store.child_info = {
    ...info,
    child_show: info.is_open,
  };
};

// 获取推币任务;
const get_player_game_task_result = (store, info) => {
  store.$patch((state) => {
    try {
      state.coinPushTask = initGameViewTaskData(info);
    } catch (error) {
      state.get_player_game_task_result = info;
    }
  });
};

// 推币任务同步
const update_player_game_task_result = (store, info) => {
  const taskList = JSON.parse(JSON.stringify(store.coinPushTask.taskList));
  if (info.update_type == "update_num") {
    if (info.class_id == 0 || info.class_id) {
      store.$patch((state) => {
        state.collect_event_result.push({
          game_name: info.game_name,
          class_id: info.class_id,
          num: info.add_num,
          key: Date.now(),
        });

        setTimeout(() => {
          state.collect_event_result.shift();
        }, 3000);
      });
    }
    taskList.some((item) => {
      if (
        info.type_name == "collect" &&
        item.task_type == info.type_name + "_" + info.class_id
      ) {
        item.currentNumer = item.currentNumer + info.add_num;
        item.currentNumer >= item.complete &&
          item.statu == 0 &&
          (item.statu = 1);
        return true;
      } else if (
        info.type_name == "up_coin" &&
        info.type_name == item.task_type
      ) {
        item.currentNumer = item.currentNumer + info.add_num;
        item.currentNumer >= item.complete &&
          item.statu == 0 &&
          (item.statu = 1);
        return true;
      } else return false;
    });
  }

  if (info.update_type == "sync_data") {
    taskList.some((item) => {
      if (item.taskIndex == info.index && info.type_name != "all_complete") {
        const step_data = info.data[info.step - 1];
        item.currentNumer = info.num;
        item.complete = step_data.complete;
        item.reward = step_data.reward;
        item.rewardType = step_data.reward_type;

        item.statu =
          info.num >= step_data.complete && !step_data.is_get ? 1 : 0;

        step_data.is_get && (item.statu = 2);

        return true;
      } else return false;
    });
  }

  let getCount = 0;
  taskList.forEach((item) => {
    if (item.statu == 1) getCount++;
  });

  store.$patch((state) => {
    state.coinPushTask.getCount = getCount;

    if (info.type_name == "all_complete") {
      state.coinPushTask.allTaskStatu = 2;
    }

    state.coinPushTask.taskList = taskList;
  });
};

// 领取推币任务
const get_player_game_task_reward_result = (store, info) => {
  if (info.error == "ok") {
    showToast({
      type: "success",
      message: "领取成功",
      forbidClick: true,
    });

    const allStatu = store.coinPushTask.taskList.some((task) => {
      return task.statu == 0 || task.statu == 1;
    });

    store.$patch((state) => {
      !allStatu && (state.coinPushTask.allTaskStatu = 1);

      info.reward_type == "lottery" &&
        (state.userInfo.lottery += Number(info.reward));

      info.reward_type == "gold" &&
        (state.userInfo.gold += Number(info.reward));

      info.reward_type == "tiaozhan_ticket" &&
        (state.userInfo.tiaozhan_ticket += Number(info.reward));
    });
  } else {
    showToast({
      type: "fail",
      message: "领取失败",
    });
  }
};

// 比赛同步
const sync_active_config_result = (store, info) => {
  store.$patch((state) => {
    if (info.type == "delete") {
      state.initActiveConfigList = state.initActiveConfigList.filter((item) => {
        return item.active_index != info.active_index;
      });
    } else if (info.type == "update_data") {
      sendInstructions({
        msg_type: "get_shipin_active_config",
      });
    }
  });
};

// 玩家比赛信息同步
const sync_player_active_result = (store, info) => {
  store.$patch((state) => {
    // 挑战时间
    state.sync_player_free_match_time = info.free_match_time || 0;

    // 继续挑战

    if (
      info.game_name &&
      state.gameIsWatch &&
      !state.get_player_tiaozhan_info_show
    ) {
      state.get_player_tiaozhan_info_show = true;
    } else if (!info.game_name && state.gameIsWatch) {
      state.get_player_tiaozhan_info_show = false;
    }

    // 类型
    if (info.active_type && state.settlementResult.type != info.active_type) {
      state.settlementResult.type =
        info.active_type == "tiaozhan_tz" ? "challenge" : "active";
    }

    // 游戏名
    if (info.game_name && state.settlementResult.game_name != info.game_name) {
      state.settlementResult.game_name = info.game_name;
    }
  });
};
// 玩家比赛最终结果
const active_result = (store, info) => {
  store.$patch((state) => {
    if (info.shipin_active_rank) {
      state.settlementResult.type = "active";
      state.settlementResult.score = info.shipin_active_rank.num;
    } else {
      state.settlementResult.type = "challenge";
      state.settlementResult.ret = info.ret;
      state.settlementResult.reward = info.reward;
      state.settlementResult.lottery = info.lottery;
      state.settlementResult.dashi_score = info.dashi_score;
      state.settlementResult.dashi_card = info.dashi_card;
      state.settlementResult.zongshi_card = info.zongshi_card;
    }

    // 防掉线关闭
    state.get_player_tiaozhan_info_show &&
      (state.get_player_tiaozhan_info_show = false);

    // 资源增加
    // info.lottery && (state.userInfo.lottery += Number(info.lottery));
    info.reward && (state.userInfo.gold += Number(info.reward));
    info.dashi_score &&
      (state.userInfo.dashi_score += Number(info.dashi_score));

    info.dashi_card && (state.userInfo.dashi_card = true);
    info.zongshi_card && (state.userInfo.zongshi_card = true);
  });
};

// 物品信息
const get_item_result = (store, info) => {
  store.cardBagData = initCardBagData(info);
};
// 物品消息通知
const get_item_notify_result = (store, info) => {
  if (!Array.isArray(info) || info.length == 0) return;

  if (store.cardBagData.waitting.length == 0) return;

  const list = [];

  info.forEach((notify) => {
    store.cardBagData.waitting.some((card) => {
      if (card.item_id == notify.item_id) {
        list.push(card);
        return true;
      } else return false;
    });
  });

  store.$patch((state) => {
    state.cardNotifyData.show = true;
    if (list.length > 2) list.length = 2;
    state.cardNotifyData.list = list;
  });
};

// 失败金币返回
const device_up_coin_error_return_result = (store, info) => {
  if (info.gold) {
    store.$patch((state) => {
      state.userInfo.gold = state.userInfo.gold + Number(info.gold);
    });
  }
};

let send_marquee_timer = null;
const send_marquee_result = (store, info) => {
  // content
  // color
  // delay_time
  // const _delay_time = info.delay_time * 1000;
  const _content = decodeBase64String(info.content);
  const _color = info.font_color;
  const _speed = parseInt(info.speet);
  send_marquee_timer != null && clearTimeout(send_marquee_timer);

  store.$patch((state) => {
    // store.send_marquee_result = info;
    state.send_marquee_config.show = true;
    state.send_marquee_config.content = _content;
    state.send_marquee_config.color = _color;
    // state.send_marquee_config.speed = _speed;
    send_marquee_timer = setTimeout(() => {
      send_marquee_timer = null;
      state.send_marquee_config.show = false;
    }, _speed);
  });
};

const get_machine_info_by_group_result = (store, info) => {
  if (!Array.isArray(info)) return;
  if (info.length == 0) return;

  if (info[0].child_name == "msqy_line" || info[0].group == "msqy_line") {
    // 思路：魔术奇缘是以line_reward_index 相同为一组 其他的可能就是单纯以 group 分组
    const groupedData = info.reduce((acc, curr) => {
      if (!acc[curr.line_reward_index]) {
        const _line_reward_data = {
          quanpan: 300,
          jp1: 500,
          jp2: 1000,
          jp3: 2000,
        };

        acc[curr.line_reward_index] = {
          line_reward_index: curr.line_reward_index,
          line_reward_data: curr.line_reward_data ?? _line_reward_data,
          level: curr.level,
          exchange: curr.exchange,
          machines: [], // 初始化id列表
        };
      }

      let occupys = [];

      if (Array.isArray(curr.device_info)) {
        curr.device_info.forEach((devItem) => {
          if (devItem.occupy && devItem.occupy.player_id) {
            occupys.push(devItem.occupy);
          }
        });
      }

      acc[curr.line_reward_index].machines.push({
        sp_url: curr.sp_url,
        return_time: Number(curr.return_time) || 0,
        visit_time: curr.visit_time,
        site_limit: curr.site_limit,
        product_id: curr.product_id,
        up_coin: curr.up_coin,
        exchange: curr.exchange,
        product_name: curr.product_name,
        level: curr.level,
        device_info: curr.device_info,
        machine_index: curr.machine_index,
        tiaozhan_index: curr.tiaozhan_index,
        status: curr.status,
        child_name: curr.child_name,
        visits: [],
        occupy: occupys,
        line_reward_index: curr.line_reward_index,
        group: curr.group,
      });
      return acc;
    }, {});
    // 将对象转换为数组（如果需要的话）
    // return Object.values(groupedData);
    const transformArr = Object.values(groupedData);

    transformArr.sort((a, b) => Number(a.exchange) - Number(b.exchange));
    transformArr.sort((a, b) => Number(a.level) - Number(b.level));

    store.$patch((state) => {
      state.msqyLineListData = transformArr;
    });
  }

  if (info[0].group == "vip_tbj") {
    const gameDataList = initGameData(info);

    store.$patch((state) => {
      state.gameDataList.vip_tbj = gameDataList;
    });
  }
};

const get_player_turntable_result = (store, info) => {
  if (info.error == "ok") {
    const _score = new Decimal(info.player_turntable.score);
    const _spin_num = new Decimal(info.spin_num);
    const _progress = _score.div(_spin_num).mul(100).toNumber();

    const _win_log = [];
    const _jp_log = [];

    if (Array.isArray(info.spin_log) && info.spin_log.length > 0) {
      info.spin_log.forEach((item) => {
        const _i_data = {
          player_name: decodeBase64String(item.player_name),
          head: item.head || item.head_frame,
          num: item.reward_num,
        };
        _win_log.push(_i_data);
      });
    }

    if (Array.isArray(info.spin_jp_log) && info.spin_jp_log.length > 0) {
      info.spin_jp_log.forEach((item) => {
        const _i_data = {
          player_name: decodeBase64String(item.player_name),
          head: item.head || item.head_frame,
          num: item.reward_num,
        };
        _jp_log.push(_i_data);
      });
    }

    store.$patch((state) => {
      state.player_turntable_data.jp_num = parseInt(info.jp_num);
      state.player_turntable_data.progress = _progress;
      state.player_turntable_data.turn_info = info.turn_info;
      state.player_turntable_data.win_log = _win_log;
      state.player_turntable_data.jp_log = _jp_log;
    });
  } else {
    // showToast({
    //   type: "fail",
    //   message: "获取失败，稍后重试",
    // });
  }
};

const sync_player_turntable_result = (store, info) => {
  const _score = new Decimal(info.score);
  const _spin_num = new Decimal(info.spin_num);
  const _progress = _score.div(_spin_num).mul(100).toNumber();

  store.$patch((state) => {
    state.player_turntable_data.progress = _progress;
  });
};

// 夺宝
const get_game_table_list_result = (store, info) => {
  const isPass = info && Array.isArray(info) && info.length > 0;
  if (!isPass) return;

  const duobaoList = [];

  let group_num = parseInt(info.length / 3);

  info.length % 3 > 0 && group_num++;

  for (let i = 0; i < group_num; i++) {
    duobaoList.push({
      level: 1,
      exchange: 20,
      machines: [], // 初始化id列表
    });
  }

  let _index = 0;
  info.forEach((item) => {
    const player_info = {
      uid: "",
      head: "",
      select_title: 0,
      recharge_level: 1,
    };
    const _occupy = [];
    if (item.uid && item.player_head_info) {
      player_info.uid = item.uid;
      player_info.head =
        item.player_head_info.head || item.player_head_info.head_frame;

      player_info.recharge_level = item.player_head_info.recharge_level;
      player_info.select_title = item.player_head_info.select_title;

      _occupy.push(player_info);
    } else if (item.wait_enter != -1 || item.wait_enter > 0) {
      player_info.head = 1;
      player_info.select_title = 0;
      player_info.recharge_level = 1;
      player_info.uid = item.wait_enter;
      _occupy.push(player_info);
    }

    const initItem = {
      sp_url: "",
      return_time: 0,
      visit_time: 60,
      site_limit: item.gold_limit || 0,
      product_id: "",
      up_coin: 1,
      exchange: item.exchange_bet,
      product_name: "duobao",
      level: item.level_limit ?? 1,
      machine_index: item.table_id,
      tiaozhan_index: 1,
      status: item.table_lock ? 0 : 1,
      child_name: "duobao",
      visits: [],
      occupy: _occupy,
      line_reward_index: 0,
      group: "",
    };

    if (item.table_id % 3 == 1) {
      duobaoList[_index].level = item.level_limit;
      duobaoList[_index].exchange = item.exchange_bet;
    }

    duobaoList[_index].machines.push(initItem);
    item.table_id % 3 == 0 && _index++;
  });

  store.$patch((state) => {
    state.duobaoListData = duobaoList;
  });
};

const sync_player_sprog_task_result = (store, info) => {
  store.$patch((state) => {
    state.sprogTaskData.current_step = info.step;
    state.sprogTaskData.end_time = info.end_time * 1000;

    state.sprogTaskData.datas[3].task_info.task[0].num = info.step;
    state.sprogTaskData.datas[6].task_info.task[0].num = info.step;

    info.task.forEach((iitt, iind) => {
      let isNoDone = false;
      for (const _i in iitt) {
        const _item = iitt[_i];
        const _index = parseInt(_i) - 1;

        state.sprogTaskData.datas[iind].task_info.task[_index].complete_num =
          _item.complete_num;
        state.sprogTaskData.datas[iind].task_info.task[_index].num = _item.num;
        if (_item.complete_num > _item.num && !isNoDone) {
          isNoDone = true;
        }
      }
      state.sprogTaskData.datas[iind].task_info.isNoDone = isNoDone;
    });

    _task_award_all.forEach((item, sIndex) => {
      state.sprogTaskData.datas[sIndex].task_info.task_award = item;
    });
  });
};

const create_tiaozhan_red_package_result = (store, info) => {
  store.$patch((state) => {
    state.create_tiaozhan_red_package_result = info;
  });
};

// 新 游戏收集任务
const get_player_collect_task_result = (store, info) => {
  try {
    const initGameTaskData = {
      mgc: {
        task_icon: "",
        statu: 0,
        task_list: [],
        task_award: [],
      },
      bbl: {
        task_icon: "",
        statu: 0,
        task_list: [],
        task_award: [],
      },
      hw3: {
        task_icon: "",
        statu: 0,
        task_list: [],
        task_award: [],
      },
    };

    const { player_collect_task } = info;
    // const { take_out_num } = player_collect_task;

    for (const _gm in player_collect_task.task) {
      const itemArr = player_collect_task.task[_gm];

      if (itemArr.length == 1) {
        const tItem = itemArr[0];
        const _TaskItem = {
          id: tItem.id,
          task_type: tItem.task_type,
          tname: "",
          num: tItem.condition.num,
          complete: tItem.condition.complete,
        };

        initGameTaskData[_gm].statu = Number(
          tItem.condition.num >= tItem.condition.complete
        );

        if (tItem.condition.class_id == 100000 || tItem.task_type == "C") {
          initGameTaskData[_gm].task_icon = "";
          _TaskItem.tname = `挑战赛成功${tItem.condition.complete}次`;

          if (tItem.is_get) {
            initGameTaskData[_gm].statu = 2;
            continue;
          }
        } else {
          const collect_info = findCollectCardInfo(
            _gm,
            tItem.condition.class_id
          );

          initGameTaskData[_gm].task_icon = collect_info.url;

          _TaskItem.tname = collect_info.cardName;
        }

        tItem.reward.forEach((rewardI) => {
          const isSameType = initGameTaskData[_gm].task_award.some((iniR) => {
            if (iniR.type == rewardI.type) {
              iniR.num = iniR.num + Number(rewardI.num);
              return true;
            } else if (
              rewardI.type == "jiacheng_card" ||
              rewardI.type == "zhekou_card"
            ) {
              rewardI.num =
                new Decimal(rewardI.num) > 1
                  ? new Decimal(rewardI.num).sub(1).mul(100) + "%"
                  : new Decimal(rewardI.num).mul(100) + "%";

              return false;
            } else return false;
          });
          !isSameType && initGameTaskData[_gm].task_award.push(rewardI);
        });
        initGameTaskData[_gm].task_list.push(_TaskItem);
      } else if (itemArr.length > 1) {
        initGameTaskData[_gm].task_icon = "";

        let _statu = 1;
        itemArr.forEach((tItem) => {
          const _TaskItem = {
            id: tItem.id,
            task_type: tItem.task_type,
            tname: "",
            num: tItem.condition.num,
            complete: tItem.condition.complete,
          };

          if (tItem.condition.num < tItem.condition.complete) {
            _statu = 0;
          }

          const collect_info = findCollectCardInfo(
            _gm,
            tItem.condition.class_id
          );

          initGameTaskData[_gm].task_icon = collect_info.url;

          _TaskItem.tname = collect_info.cardName;

          tItem.reward.forEach((rewardI) => {
            const isSameType = initGameTaskData[_gm].task_award.some((iniR) => {
              if (iniR.type == rewardI.type) {
                iniR.num = iniR.num + Number(rewardI.num);
                return true;
              } else if (
                rewardI.type == "jiacheng_card" ||
                rewardI.type == "zhekou_card"
              ) {
                rewardI.num =
                  new Decimal(rewardI.num) > 1
                    ? new Decimal(rewardI.num).sub(1).mul(100) + "%"
                    : new Decimal(rewardI.num).mul(100) + "%";

                return false;
              } else return false;
            });
            !isSameType && initGameTaskData[_gm].task_award.push(rewardI);
          });

          initGameTaskData[_gm].task_list.push(_TaskItem);
        });
        initGameTaskData[_gm].statu = _statu;
      }
    }
    store.$patch((state) => {
      state.gameTaskData.mgc = initGameTaskData.mgc;
      state.gameTaskData.hw3 = initGameTaskData.hw3;
      state.gameTaskData.bbl = initGameTaskData.bbl;
    });
  } catch (error) {
    console.error("errorerror", error);
  }
};
// 游戏任务同步
const sync_player_collect_task_result = (store, info) => {
  get_player_collect_task_result(store, { player_collect_task: info });
};

// 宝珠商店 仅需要用户宝珠数量
const get_player_gem_collect_result = (store, info) => {
  store.$patch((state) => {
    state.user_gem_info.gem.feng = info.player_gem_collect.feng;
    state.user_gem_info.gem.huo = info.player_gem_collect.huo;
    state.user_gem_info.gem.lei = info.player_gem_collect.lei;
    state.user_gem_info.gem.dian = info.player_gem_collect.dian;
    state.user_gem_info.gem.long = info.player_gem_collect.long;
    state.user_gem_info.buff = info.player_gem_collect.buff;
    state.user_gem_info.shop = info.player_gem_collect.shop;
  });
};

//广告初始化结果
const is_init_ad_result = (store, info) => {
  console.log("广告初始化成功", info);

  store.$patch((state) => {
    state.initAdStatus = true;
  });
};

const init_ad_error_result = (store, info) => {
  console.log("初始化失败", info);
  store.$patch((state) => {
    state.initAdStatus = false;
  });
};

//同步广告奖励
const sync_guanggao_reward_result = (store, info) => {
  console.log("同步广告奖励", info);
  closeToast();
  //转轮广告
  if (info.type == "login_slots") {
    store.$patch((state) => {
      state.soltsAdData.ad_status = true;
      state.soltsAdData.showAgainBtn = false;
      state.soltsAdData.showAdReward = true;
    });
  }

  //福利厅
  if (info.type == "exchange_code") {
    store.$patch((state) => {
      state.welfareAdData.ad_status = true;
    });
  }

  if (info.type == "must_exchange_code") {
    store.$patch((state) => {
      state.welfareAdData.ad_status = true;
    });
  }
};

// 挂机任务
const get_player_online_task_result = (store, info) => {
  store.$patch((state) => {
    state.onlineTask = info.data;
  });
};

const game_hide_result = (store, info) => {
  console.log("后台");
};

const game_show_result = (store, info) => {
  console.log("前台");
};

const callBackEventList = {
  create_tiaozhan_red_package_result,
  get_machine_info_by_group_result,
  net_close,
  get_player_common,
  get_machine_info,
  return_lottery_result,
  up_coin_result,
  notify_mqtt_counting_result,
  sync_update_player_gem_collect_result,
  get_player_recharge_status_result,
  exchange_lottery_result,
  player_recharge_result,
  get_server_time_result,
  tuibiji_return_lottery_result,
  get_player_weal_box_result,
  exchange_player_weal_box_result,
  get_all_log_result,
  open_child_result,
  get_player_child_info_result,
  player_recharge_cancel_result,
  get_player_game_task_result,
  update_player_game_task_result,
  get_player_game_task_reward_result,
  gm_exchange_code_reward_result,
  sync_active_config_result,
  active_result,
  sync_player_active_result,
  get_item_result,
  get_item_notify_result,
  device_up_coin_error_return_result,
  send_marquee_result,
  sync_player_turntable_result,
  get_player_turntable_result,
  get_game_table_list_result,
  sync_player_sprog_task_result,
  get_player_collect_task_result,
  sync_player_collect_task_result,
  get_player_gem_collect_result,
  is_init_ad_result,
  sync_guanggao_reward_result,
  init_ad_error_result,
  get_player_online_task_result,
  game_hide_result,
  game_show_result,
};
// 这里是数据处理
export const handleCallBackEvent = (msg_type, info) => {
  let _msg_type = msg_type;

  callBackEventList[_msg_type] &&
    callBackEventList[_msg_type](useStore(), info);
};
