import service from "@/utils/request";
import CryptoJS from "crypto-js";
export function addFeedBack(data, url) {
  return service({
    url: "/Feedback/addFeedback",
    method: "post",
    data,
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
}
export function addRepairReport(data) {
  return service({
    url: "/Feedback/addRepairReport",
    method: "post",
    data,
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
}

export function headSculpture(data, url) {
  return service({
    url,
    method: "post",
    data,
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
}

export function getGameList(data) {
  return service({
    url: "/Share/gameList",
    method: "get",
    params: data,
  });
}

//发表评论
export function addForumReplies(data) {
  return service({
    url: "/Share/addForumReplies",
    method: "post",
    data,
  });
}

//获取极客秀轮播
export function getShowBanner(data) {
  return service({
    url: "/Share/getForumBanner",
    method: "get",
    params: data,
  });
}

// 获取极客秀
export function getForumList(data) {
  return service({
    url: "/Share/forumList",
    method: "get",
    params: data,
  });
}
// 发布极客秀
export function forumPost(data) {
  return service({
    url: "/Share/forumPost",
    method: "post",
    data,
  });
}

// 删除极客秀
export function forumDel(data) {
  return service({
    url: "/Share/forumDel",
    method: "post",
    data,
  });
}
// 极客秀点赞
export function likePost(data) {
  return service({
    url: "/Share/likePost",
    method: "post",
    data,
  });
}
// 图片上传
export function imageUpload(data) {
  return service({
    url: "/ImageUpload/forumImages",
    method: "post",
    data,
  });
}

// 在线头像
export function userOnline() {
  return service({
    url: "/Share/userOnline",
    method: "get",
  });
}
// 首页公告
export function homeBanner(data) {
  return service({
    url: "/Share/getNotice",
    method: "get",
    params: data,
  });
}
export function changeAwardSend(data) {
  return service({
    url: "/Share/changeAwardSend",
    method: "post",
    data: data,
  });
}
// 获取兑换码
export function lobbyCode() {
  return service({
    url: "/Share/lobbyCode",
    method: "get",
  });
}

export function isExchange(data) {
  return service({
    url: "/Share/isExchange",
    method: "post",
    data,
  });
}
export function getRankLog(data) {
  return service({
    url: "/Share/rankLog",
    method: "get",
    params: data,
  });
}

export function playerRechargeStatus(data) {
  return service({
    url: "/Share/playerRechargeStatus",
    method: "get",
    params: data,
  });
}

export function mailStatus() {
  return service({
    url: "/Share/mailStatus",
    method: "get",
  });
}
export function messageboardList(data) {
  return service({
    url: "/Share/messageboardList",
    method: "get",
    params: data,
  });
}

export function playerFootLog(data) {
  return service({
    url: "/Share/playerFootLog",
    method: "get",
    params: data,
  });
}

export function playerFootConfig(data) {
  return service({
    url: "/Share/playerFootConfig",
    method: "get",
    params: data,
  });
}

export function addMessage(data) {
  return service({
    url: "/Share/addMessage",
    method: "post",
    data,
  });
}

export function getTianti(data) {
  return service({
    url: "/Share/getTianti",
    method: "get",
    params: data,
  });
}

export function getTiaozhan(data) {
  return service({
    url: "/Share/getTiaozhan",
    method: "get",
    params: data,
  });
}
export function getSys(data) {
  return service({
    url: "/Share/getSys",
    method: "get",
    params: data,
  });
}
export function getCode(data) {
  return service({
    url: "/Share/getCode",
    method: "get",
    params: data,
  });
}

export function getGuoqingReward(data) {
  return service({
    url: "/Share/getGuoqingReward",
    method: "get",
    params: data,
  });
}

export function getPlayerSprogTask(data) {
  return service({
    url: "/Game/getPlayerSprogTask",
    method: "get",
    params: data,
  });
}
export function loginSlotsLog(data) {
  return service({
    url: "/Share/loginSlotsLog",
    method: "get",
    params: data,
  });
}
export function getGameStatus(data) {
  return service({
    url: "/Share/getGameStatus",
    method: "get",
    params: {
      type: "lbl",
    },
  });
}
export function getGameServer(data) {
  return service({
    url: "/Share/getGameServer",
    method: "get",
    params: {
      type: "lbl",
    },
  });
}

export function getGuanggaoConfig(data) {
  return service({
    url: "/Share/getGuanggaoConfig",
    method: "get",
    params: {
      type: data
    },
  });
}

export function addGuanggaoCode(data) {
  return service({
    url: "/Share/addGuanggaoCode",
    method: "post",
    data,
  });
}


// export function controlRequest(data) {
//   const signString = `${data.product_id}&${data.channel}&${data.type}&${data.device}&${data.uid}&shipin_game`;

//   const signMD5 = CryptoJS.MD5(signString);

//   const sign = signMD5.toString(CryptoJS.enc.Hex);

//   const _data = {
//     ...data,
//     sign,
//   };

//   return service({
//     url: "/mqtt/controlRequest",
//     method: "post",
//     data: _data,
//   });
// }
// export function childControlRequest(data) {
//   const signString = `${data.product_id}&${data.child_name}&${data.device}&${data.uid}&shipin_game`;

//   const signMD5 = CryptoJS.MD5(signString);

//   const sign = signMD5.toString(CryptoJS.enc.Hex);

//   const _data = {
//     ...data,
//     sign,
//   };

//   return service({
//     url: "/mqtt/childControlRequest",
//     method: "post",
//     data: _data,
//   });
// }

import axios from "axios";

const apiClient = axios.create();
export function controlRequest(data) {
  const signString = `${data.product_id}&${data.channel}&${data.type}&${data.device}&${data.uid}&shipin_game`;

  const signMD5 = CryptoJS.MD5(signString);

  const sign = signMD5.toString(CryptoJS.enc.Hex);

  const _data = {
    ...data,
    sign,
  };

  return apiClient({
    url: "https://ipv6.weipinwl.com/index.php/Home/mqtt/controlRequest",
    method: "post",
    data: _data,
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
}

export function childControlRequest(data) {
  const signString = `${data.product_id}&${data.child_name}&${data.device}&${data.uid}&shipin_game`;

  const signMD5 = CryptoJS.MD5(signString);

  const sign = signMD5.toString(CryptoJS.enc.Hex);

  const _data = {
    ...data,
    sign,
  };

  return apiClient({
    url: "https://ipv6.weipinwl.com/index.php/Home/mqtt/childControlRequest",
    method: "post",
    data: _data,
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
}
