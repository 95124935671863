const RewardIconMaps = {
  gold: {
    name: "金币",
    img_url:
      "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/global/gold.png",
  },
  lottery: {
    name: "彩券",
    img_url:
      "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/global/lottery.png",
  },
  jiacheng_card: {
    name: "加成卡",
    img_url:
      "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/global/jiacheng_card.png",
  },
  tiaozhan_ticket: {
    name: "挑战卡",
    img_url:
      "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/global/tz_card.png",
  },

  vip_exp: {
    name: "vip经验",
    img_url:
      "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/global/vip_exp.png",
  },
  tianti_buff_card: {
    name: "buff卡",
    img_url:
      "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/global/tianti_buff_card.png",
  },
  long: {
    name: "龙珠",
    img_url:
      "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/gemShop/long_2.png",
  },
  gem_gaobao_card: {
    name: "宝珠高爆卡",
    img_url:
      "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/global/gem_gaobao_card.png",
  },
  gem_fanbei_card: {
    name: "宝珠翻倍卡",
    img_url:
      "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/global/gem_fanbei_card.png",
  },
  jiacheng_card_img: {
    name: "加成卡",
    img_url:
      "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/global/jiacheng_card.png",
  },
};

export default RewardIconMaps;
