const s_typeMaps = {
  login_reward: "登录奖励",
  recharge: "充值",
  up_coin: "投币",
  base: "基础礼包",
  day: "每日礼包",
  first: "首冲礼包",
  card: "周卡/月卡奖励",
  apply_tiaozhan: "参加挑战",
  recharge_card: "购买周卡/月卡",
  exchange: "积分兑换",
  gift: "特惠礼包",
  code_reward: "兑换码奖励",
  weal_box: "福利罐奖励",
  task: "任务奖励",
  tiaozhan: "挑战赛",
  tiaozhan_success: "挑战成功",
  nangua_rank: "南瓜榜奖励",
  bbl_rank: "爆爆乐高分榜奖励",
  mxt_rank: "马戏团小丑榜奖励",
  mgc_rank: "魔鬼城排行榜",
  hw3_rank: "海王3觉醒榜",
  recharge_level: "充值等级达成",
  invite_reward: "邀请奖励",
  tianqi_week: "天梯周榜奖励",
  tianqi_month: "天梯月榜奖励",
  tianqi_jidu: "天梯季榜奖励",
  tianzhan_rank: "挑战排行榜奖励",
  tribe_rank: "部落排行榜",
  mxttribe_rank: "马戏团排行榜奖励",
  mgctribe_rank: "魔鬼城排行榜奖励",
  hw3tribe_rank: "海王3觉醒榜奖励",
  bbltribe_rank: "爆爆乐排行榜奖励",
  birdtribe_rank: "捕鸟排行榜奖励",
  msqy_rank: "魔术奇缘排行榜",
  day_rank: "每日排行榜",
  collect: "收集任务兑换奖励",
  tz_card: "挑战卡",
  lottery_tz_rank: "积分挑战赛",
  mali: "幸运小玛莉",
  baoji: "爆机奖励",
  foot: "囤币活动",
  foot_vs: "欧洲杯大竞猜",
  foot_rank: "欧洲杯竞猜排行榜",
  mapgie: "七夕活动",
  turntable: "幸运转盘",
  guoqing: "中秋国庆活动",
  sprog_task: "新手任务",
  login_slots: "幸运转轮",
  tiaozhan_red_package: "沾好运",
  get_title: "成就达成",
  title: "成就达成",
  gem_shop: "宝珠商店",
  halloween: "万圣节活动",
  collect_task: "每日任务",
  long_tiaozhan: "龙珠商店",
  long_mali: "龙珠商店",
  long: "龙珠礼包",
  long_rank: "龙珠周榜",
  active_reward: "比赛奖励",
  kzbz_bet: "空中霸主投币",
  kzbz: "空中霸主",
  kzbz_exchang: "空中霸主兑换",
  duobao_exchang: "秘境夺宝兑换",
  duobao_exchange: "秘境夺宝兑换",
  duobao_bet: "秘境夺宝投币",
  device_up_coin_error: "投币失败返回",
  game_task: "推币任务",
  online_task: "挂机任务",
};

function findStype(type) {
  if (s_typeMaps[type]) return s_typeMaps[type];

  return "s_type--" + type;
}

export default findStype;
