import { ref, onMounted, onUnmounted } from "vue";

function padZero(num) {
  return num < 10 ? "0" + num : num;
}

function formatTime(date, format = "yyyy-mm-dd hh:mi:ss") {
  const options = {
    yyyy: date.getFullYear(),
    mm: padZero(date.getMonth() + 1),
    dd: padZero(date.getDate()),
    hh: padZero(date.getHours()),
    mi: padZero(date.getMinutes()),
    ss: padZero(date.getSeconds()),
  };

  return format.replace(/(yyyy|mm|dd|hh|mi|ss)/g, (match) => options[match]);
}

export function useRealTime(format = "yyyy-mm-dd hh:mi:ss") {
  const currentTime = ref(new Date());

  const _nowTime = ref(0);

  const timeParts = ref({
    y: currentTime.value.getFullYear(),
    m: padZero(currentTime.value.getMonth() + 1),
    d: padZero(currentTime.value.getDate()),
    h: padZero(currentTime.value.getHours()),
    mi: padZero(currentTime.value.getMinutes()),
    s: padZero(currentTime.value.getSeconds()),
    format: formatTime(currentTime.value, format),
    now: currentTime.value.getTime(),
  });

  const ResetTime = (startTime) => {
    _nowTime.value = Number(startTime) * 1000;
  };

  const updateTime = () => {
    currentTime.value = new Date();
    if (_nowTime.value != 0) {
      _nowTime.value += 1000;
      currentTime.value = new Date(_nowTime.value);
    }
    timeParts.value = {
      y: currentTime.value.getFullYear(),
      m: padZero(currentTime.value.getMonth() + 1),
      d: padZero(currentTime.value.getDate()),
      h: padZero(currentTime.value.getHours()),
      mi: padZero(currentTime.value.getMinutes()),
      s: padZero(currentTime.value.getSeconds()),
      format: formatTime(currentTime.value, format),
      now: currentTime.value.getTime(),
    };
  };

  const interval = setInterval(updateTime, 1000);

  onMounted(() => {
    updateTime();
  });

  onUnmounted(() => {
    clearInterval(interval);
  });

  return { timeParts, ResetTime };
}
