<template>
  <div
    class="norem_st_icon_wrapper"
    :style="{
      left: _left,
      top: _top,
      right: _right,
      bottom: _bottom,
    }"
    @click="store.storeShowToggle('sprog_task_show', true)"
  >
    <div
      class="e_icon"
      :class="{
        'shake-bottom-class': isDoneTask,
      }"
    ></div>

    <div class="line_sss"></div>

    <div class="e_text"></div>
    <div class="e_text2"></div>
  </div>
</template>

<script setup>
import { useStore } from "@/store/store";
import { computed } from "vue";

const store = useStore();
const props = defineProps({
  left: {
    type: [String, Number],
    default: "auto",
  },
  top: {
    type: [String, Number],
    default: "auto",
  },
  right: {
    type: [String, Number],
    default: "auto",
  },
  bottom: {
    type: [String, Number],
    default: "auto",
  },
});

const _top = computed(() => {
  if (props.top == "auto") return "auto";
  const rem = parseFloat(document.documentElement.style.fontSize);
  return (props.top / rem).toFixed(5) + "rem";
});

const _right = computed(() => {
  if (props.right == "auto") return "auto";
  const rem = parseFloat(document.documentElement.style.fontSize);
  return (props.right / rem).toFixed(5) + "rem";
});
const _bottom = computed(() => {
  if (props.bottom == "auto") return "auto";
  const rem = parseFloat(document.documentElement.style.fontSize);
  return (props.bottom / rem).toFixed(5) + "rem";
});

const _left = computed(() => {
  if (props.left == "auto") return "auto";
  const rem = parseFloat(document.documentElement.style.fontSize);
  return (props.left / rem).toFixed(5) + "rem";
});

const isDoneTask = computed(() => {
  if (
    store.sprogTaskData.current_step < 0 ||
    store.sprogTaskData.current_step > 8
  ) {
    return false;
  } else {
    //
    const cc = store.sprogTaskData.datas[
      store.sprogTaskData.current_step - 1
    ].task_info.task.some((itt) => {
      return itt.complete_num > itt.num;
    });
    return !cc;
  }
});
</script>

<style lang="scss" scoped>
.norem_st_icon_wrapper {
  position: absolute;
  width: 52px;
  height: 52px;
  z-index: 5;

  .e_icon {
    position: absolute;
    width: 52px;
    height: 52px;
    background-image: url("https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/sprog/enter_icon.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    z-index: 2;
  }

  .e_text {
    z-index: 3;
    position: absolute;
    width: 48px;
    height: 16px;
    background-image: url("https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/sprog/enter_icon_text.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, 0);
  }

  .e_text2 {
    position: absolute;
    left: 0;
    top: 10px;
    width: 26px;
    height: 14px;
    background-image: url("https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/sprog/enter_icon_text2.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    z-index: 3;
  }

  .line_sss {
    position: absolute;
    left: 50%;
    top: 50%;
    z-index: 1;
    width: 150%;
    height: 150%;
    transform: translate(-50%, -50%);
    background-image: url("https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/spin/win_light_bg.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    animation: wheeling 5s;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
  }
}

.shake-bottom-class {
  -webkit-animation: shake-bottom 3.2s cubic-bezier(0.455, 0.03, 0.515, 0.955)
    both infinite;
  animation: shake-bottom 3.2s cubic-bezier(0.455, 0.03, 0.515, 0.955) both
    infinite;
  animation-delay: 1s;
}

@-webkit-keyframes shake-bottom {
  0%,
  25% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transform-origin: 50% 100%;
    transform-origin: 50% 100%;
  }
  2.5% {
    -webkit-transform: rotate(2deg);
    transform: rotate(2deg);
  }
  5%,
  10%,
  15% {
    -webkit-transform: rotate(-4deg);
    transform: rotate(-4deg);
  }
  7.5%,
  12.5%,
  17.5% {
    -webkit-transform: rotate(4deg);
    transform: rotate(4deg);
  }
  20% {
    -webkit-transform: rotate(-2deg);
    transform: rotate(-2deg);
  }
  22.5% {
    -webkit-transform: rotate(2deg);
    transform: rotate(2deg);
  }
}
@keyframes shake-bottom {
  0%,
  25% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transform-origin: 50% 100%;
    transform-origin: 50% 100%;
  }
  2.5% {
    -webkit-transform: rotate(2deg);
    transform: rotate(2deg);
  }
  5%,
  10%,
  15% {
    -webkit-transform: rotate(-4deg);
    transform: rotate(-4deg);
  }
  7.5%,
  12.5%,
  17.5% {
    -webkit-transform: rotate(4deg);
    transform: rotate(4deg);
  }
  20% {
    -webkit-transform: rotate(-2deg);
    transform: rotate(-2deg);
  }
  22.5% {
    -webkit-transform: rotate(2deg);
    transform: rotate(2deg);
  }
}

@keyframes wheeling {
  0% {
    transform: translate(-50%, -50%) rotate(0deg);
  }

  100% {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}

@-webkit-keyframes wheeling

/* Safari 与 Chrome */ {
  0% {
    transform: translate(-50%, -50%) rotate(0deg);
  }

  100% {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}
</style>
