<template>
  <div class="competition">
    <div class="top_bg_wrapper">
      <van-image
        width="100%"
        height="auto"
        fit="fill"
        position="center"
        :src="tianti_top_bg"
      />

      <div class="top_title_wrapper">
        <van-image
          width="100%"
          height="auto"
          fit="fill"
          position="center"
          :src="tianti_top_title"
          :show-loading="false"
          block
        />

        <div class="end_time_text">
          {{ initTianTiConfig.score.jijie }}赛进行中截止于
          {{ initTianTiConfig.score.stopTime }}
        </div>
      </div>
    </div>

    <div class="user_score_wrapper">
      <div class="user_score_top">
        <p class="season_text">本季积分:</p>
        <span class="season_score"
          >{{ initTianTiConfig.score.seasonScore }}
        </span>
        &nbsp;
        <span class="season_tiem">
          ({{ initTianTiConfig.score.seasonTime }})
        </span>
      </div>
      <div class="user_score_bottom">
        <div class="user_score_item" style="text-align: left">
          <div class="user_score_text">本周</div>
          <div class="user_score_value">
            {{ initTianTiConfig.score.weekScore }}
          </div>
        </div>
        <div class="user_score_item">
          <div class="user_score_text">本月</div>
          <div class="user_score_value">
            {{ initTianTiConfig.score.monthScore }}
          </div>
        </div>
        <div class="user_score_item">
          <div class="user_score_text">天梯等级</div>
          <div class="user_score_value">
            {{ rankLevelIcon[Number(initTianTiConfig.score.level)].level_text }}
          </div>
        </div>
      </div>
    </div>
    <div class="container" style="transform: translateY(-40px)">
      <div class="rank_list_wrapper">
        <div class="rank_tabs_wrapper">
          <div
            :class="[
              'rank_tabs_item',
              rankTabTypeActive == 'rank' && 'rank_tabs_item_active',
            ]"
            @click="toggleTabsActive('rank')"
          >
            天梯排名
          </div>
          <div
            :class="[
              'rank_tabs_item',
              rankTabTypeActive == 'award' && 'rank_tabs_item_active',
            ]"
            @click="toggleTabsActive('award')"
          >
            排名奖励
          </div>
        </div>

        <template v-if="rankTabTypeActive == 'rank'">
          <div class="rank_type_tab_wrapper">
            <div class="rank_type_tab_box">
              <div class="rank_tab_active_bg" ref="rank_tab_active_bg"></div>
              <div class="rank_type_tab">
                <div
                  :class="[
                    'rank_type_tab_item',
                    currentRankTabType == tabTypeItem.id &&
                      'rank_type_tab_item_active',
                  ]"
                  v-for="tabTypeItem in rankTabType"
                  :key="tabTypeItem.id"
                  @click="currentRankTabTypeChange(tabTypeItem.id)"
                >
                  {{ tabTypeItem.text }}
                </div>
              </div>
            </div>
          </div>
          <div class="my_rank_info">
            <div class="my_rank">
              <div class="my_head">
                <HeadPortrait
                  :src="store.userInfo.headimgurl"
                  :level="store.userInfo.level"
                  width="100%"
                  height="100%"
                  ae
                  fe
                  :ae_id="store.userInfo.select_title"
                />
              </div>

              <div class="rank_name">我的排名</div>
            </div>
            <div class="rank_num">
              {{ myRankText }}排名：<span class="bold" style="color: #fee22a">{{
                initTianTiConfig.score.rank
              }}</span>
            </div>
          </div>

          <div class="rank_list_content">
            <div
              :class="[
                'rank_list_item',
                rankIndex == 0 && 'first_item',
                rankIndex == 1 && 'two_item',
                rankIndex == 2 && 'three_item',
                rankIndex > 2 && rankIndex % 2 == 0 ? 'odd_item' : 'eve_item',
              ]"
              v-for="(rankItem, rankIndex) in initTianTiConfig.rankList"
              :key="'uid-' + rankItem.uid"
            >
              <div class="ranking">
                <div class="ranking_text">
                  {{ rankIndex + 1 }}
                </div>
              </div>
              <div class="rank_info">
                <div class="head">
                  <HeadPortrait
                    :level="Number(rankItem.recharge_level)"
                    :width="'100%'"
                    :height="'100%'"
                    :src="rankItem.head ? rankItem.head : rankItem.head_frame"
                    :ae_id="rankItem.select_title"
                    ae
                    fe
                  />
                </div>
                <div class="user_info">
                  <p class="player_name">
                    <van-text-ellipsis
                      :content="decodeBase64String(rankItem.player_name)"
                    />
                  </p>

                  <div class="user_level">
                    <Level
                      :border="false"
                      background="rgba(255,255,255,0.2)"
                      :level_value="rankItem.level ? Number(rankItem.level) : 1"
                    />
                  </div>
                </div>
              </div>
              <div class="rank_wrapper">
                <div class="rank_number">
                  +{{
                    rankItem[
                      currentRankTabType == "week"
                        ? "week_exp"
                        : currentRankTabType == "month"
                        ? "month_exp"
                        : "jidu_exp"
                    ]
                  }}
                </div>
                <div class="rank_item_user_id">
                  UID:{{ uidStringLengthFixed(rankItem.uid) }}
                </div>
              </div>
            </div>
          </div>
        </template>
        <template v-else>
          <div class="rank_type_tab_wrapper">
            <div class="rank_type_tab_box">
              <div
                class="rank_tab_active_bg"
                style="width: 33.33%"
                ref="rank_tab_active_bg"
              ></div>
              <div class="rank_type_tab">
                <div
                  :class="[
                    'rank_type_tab_item',
                    awardActiveItem == tabTypeItem.id &&
                      'rank_type_tab_item_active',
                  ]"
                  v-for="tabTypeItem in awardTabsList"
                  :key="tabTypeItem.id"
                  @click="awardTabsChange(tabTypeItem.id)"
                >
                  {{ tabTypeItem.text }}
                </div>
              </div>
            </div>
          </div>

          <div class="award_list_wrapper">
            <div class="list_title">
              <span class="t1">排行</span>
              <span class="t2">成就奖励</span>
              <span class="t3">金币奖励</span>
            </div>

            <div
              class="award_list_item"
              v-for="(awardItem, awardIndex) in initTianTiConfig.award[
                awardActiveItem
              ]"
              :key="'awardItem' + awardIndex"
            >
              <div class="rank">{{ awardItem.rank }}</div>
              <div
                class="acm_wrap"
                v-if="awardIndex < 3 && awardActiveItem == 'seasonAward'"
              >
                <div class="acm_text">
                  {{
                    `限定${
                      awardIndex == 0
                        ? "冠军"
                        : awardIndex == 1
                        ? "亚军"
                        : awardIndex == 2
                        ? "季军"
                        : "精英"
                    }称号`
                  }}
                </div>
                <div class="acm_icon">
                  <van-image
                    width="100%"
                    height="100%"
                    fit="contain"
                    position="center"
                    :show-loading="false"
                    :src="
                      awardIndex == 0
                        ? s_7
                        : awardIndex == 1
                        ? s_8
                        : awardIndex == 2
                        ? s_9
                        : s_10
                    "
                    block
                  />
                </div>
              </div>
              <div class="award_wrapper">
                {{ awardItem.awardNumber }}
              </div>
            </div>
          </div>
          <div class="f-12 pl-10 pr-10 mt-20 pb-20">
            <p>
              联赛规则说明：<br />
              一、联赛是极客竞技的一种比赛机制。<br />
              二、每届联赛的持续时间为一个季度三个月，当赛季结束是将对排位赛成绩进行结算，用户降低三个等级后，继承到新的段位，联赛排名的前50名，将获得系统发放的奖励。成就奖励在“我的”-“我的成就”中佩戴。<br />
              三、联赛的普通等级为lv1-lv9
              特殊等级为1星大师—5星宗师，大师称号和宗师称号分别需要点亮大师灯牌和宗师灯牌，用户可以通过挑战和其他特殊活动获取大师分。大师称号所需大师分如下：<br />
            </p>
            <van-image
              width="100%"
              height="auto"
              fit="fill"
              position="center"
              :src="tianti_msg"
              block
              :show-loading="false"
            />
            <br />
            <p>
              声明：<br />
              一、电玩币仅为电玩所用，电玩内不能交易不能转让；<br />
              二、本平台体验中心对用户拥有的虚拟货品和积分均不提供任何形式的回购，用户不能参与任何形式的兑换与转让；<br />
              三、本平台体验中心倡导绿色、公平的游戏环境，抵制任何形式赌博等违法行为；<br />
              四、根据《娱乐场所管理条例》和《娱乐场所管理办法》明确规定，5G电玩直播不向未成年提供服务；<br />
            </p>

            <p class="mt-20">
              周奖励将在周日 23：59 锁定，奖励将在下周一 06:00 发放。
            </p>
            <p class="mt-10">
              月奖励将在月底最后一日 23：59 锁定，奖励将在每月第一天 06:00
              发放。
            </p>
            <p class="mt-10">
              季奖励将在季度日期最后一日 23：59 锁定，奖励将在新季度第一天 06:00
              发放。
            </p>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script setup>
import Level from "@/components/Level.vue";
import { decodeBase64String, uidStringLengthFixed } from "@/utils/utils";
import { ref, onBeforeMount, onMounted, reactive } from "vue";
import { useStore } from "@/store/store";
import { asyncSendInstructions } from "@/api/sendData";
import { rankLevelIcon } from "@/utils/levelData";

import HeadPortrait from "@/components/HeadPortrait.vue";

const s_7 =
  "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/achievement/s_7.png";
const s_8 =
  "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/achievement/s_8.png";
const s_9 =
  "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/achievement/s_9.png";
const s_10 =
  "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/achievement/s_10.png";

const store = useStore();

const tianti_top_bg = store.imgBaseUrl + "/rank/tianti_top_bg.png";
const tianti_top_title = store.imgBaseUrl + "/rank/tianti_top_title.png";
const tianti_msg = store.imgBaseUrl + "/rank/tianti_msg.png";

function getSeason(month) {
  if (month >= 1 && month <= 3) {
    return "春季";
  } else if (month >= 4 && month <= 6) {
    return "夏季";
  } else if (month >= 7 && month <= 9) {
    return "秋季";
  } else if (month >= 10 && month <= 12) {
    return "冬季";
  } else {
    return "无效的月份";
  }
}

function getAwards(scores) {
  // 确保输入是数组且长度为100
  // if (!Array.isArray(scores) || scores.length !== 100) {
  //   return "Invalid input. Please provide a 100-element array.";
  // }
  // 对分数进行排序
  // scores.sort((a, b) => a - b);

  // 定义奖牌数量和对应的排名
  const awards = [
    { rank: "第1名", awardNumber: scores[0] },
    { rank: "第2名", awardNumber: scores[1] },
    { rank: "第3名", awardNumber: scores[2] },
    { rank: "第4名", awardNumber: scores[3] },
    { rank: "第5名", awardNumber: scores[4] },
    { rank: "第6~10名", awardNumber: scores[5] },
    { rank: "第11~20名", awardNumber: scores[10] },
    { rank: "第21~50名", awardNumber: scores[25] },
  ];

  return awards;
}
function initTianTiData(data, userInfo) {
  // debugger;
  const score = {
    weekScore: data.my_info.week_exp,
    monthScore: data.my_info.month_exp,
    seasonScore: data.my_info.jidu_exp,
    seasonTime: "2023.6 - 2023.8",
    stopTime: "",
    level: data.my_info.level ? Number(data.my_info.level) : 1,
    rank: "100+",
    jijie: "",
  };

  const dashi_card = !!data.my_info.dashi_card || false;
  const zongshi_card = !!data.my_info.zongshi_card || false;

  let year = new Date().getFullYear();
  let month = new Date().getMonth() + 1;

  let stopMonth = 0;

  // 这里显示是什么季赛
  score.jijie = getSeason(month);

  let seasonTime = "";

  data.tianti_def.jidu_time.some((jiTime) => {
    if (month == 12) {
      stopMonth =
        Number(jiTime.stop.month) - 1 < 1 ? 1 : Number(jiTime.stop.month) - 1;

      seasonTime = `${year}.${jiTime.begin.month} - ${year}.${stopMonth}`;
      return true;
    } else if (month < jiTime.stop.month) {
      stopMonth =
        Number(jiTime.stop.month) - 1 < 1 ? 1 : Number(jiTime.stop.month) - 1;

      seasonTime = `${year}.${jiTime.begin.month} - ${year}.${stopMonth}`;
      return true;
    } else return false;
  });

  score.seasonTime = seasonTime;

  let date = new Date(year, stopMonth, 1);

  let lastDay = new Date(date.getFullYear(), date.getMonth(), 0);

  score.stopTime = `${year}-${stopMonth}-${lastDay.getDate()}`;

  const award = {
    weekAward: [],
    monthAward: [],
    seasonAward: [],
  };

  award.weekAward = getAwards(data.tianti_def.week_reward);
  award.monthAward = getAwards(data.tianti_def.month_reward);
  award.seasonAward = getAwards(data.tianti_def.ji_reward);

  const rankList = data.rank_info;
  // rankList.length > 50 && (rankList.length = 50);
  rankList.forEach((rankItem, rankIndex) => {
    if (parseInt(rankItem.uid) === parseInt(userInfo.uid))
      score.rank = (rankIndex + 1).toString();
  });

  return {
    score,
    award,
    rankList,
    dashi_card,
    zongshi_card,
  };
}

// 排名 / 奖励
const rankTabTypeActive = ref("rank");

// 当前奖励
const awardActiveItem = ref("weekAward");

// 排名类别
const rankTabType = [
  {
    text: "周排名",
    id: "week",
  },
  {
    text: "月排名",
    id: "month",
  },
  {
    text: "赛季排名",
    id: "season",
  },
  {
    text: "上季排名",
    id: "history",
  },
];

const rank_tab_active_bg = ref();

const myRankText = ref("周");

// 当前排名类别
const currentRankTabType = ref("week");

const awardTabsList = [
  {
    text: "季度奖励",
    id: "seasonAward",
  },
  {
    text: "月奖励",
    id: "monthAward",
  },
  {
    text: "周奖励",
    id: "weekAward",
  },
];

// 排名 /奖励切换
const toggleTabsActive = (type) => {
  rankTabTypeActive.value = type;
  currentRankTabType.value = "week";
  awardActiveItem.value = "seasonAward";
};

const initTianTiConfig = ref({
  score: {
    weekScore: "",
    monthScore: "",
    seasonScore: "",
    seasonTime: "",
    level: "1",
    rank: "99+",
    stopTime: "",
    jijie: "",
    dashi_card: 0,
    zongshi_card: 0,
  },
  award: {
    weekAward: [],
    monthAward: [],
    seasonAward: [],
  },
  rankList: [],
});

const currentRankTabTypeChange = async (type) => {
  currentRankTabType.value = type;
  // tianti_week || tianti_month || tianti_jidu || tianti_history

  if (type == "week") {
    myRankText.value = "周";
    // rank_tab_active_bg.value && (rank_tab_active_bg.value.style.left = "0");
    rank_tab_active_bg.value &&
      (rank_tab_active_bg.value.style.transform = "translateX(0)");
  } else if (type == "month") {
    myRankText.value = "月";
    // rank_tab_active_bg.value && (rank_tab_active_bg.value.style.left = "25%");
    rank_tab_active_bg.value &&
      (rank_tab_active_bg.value.style.transform = "translateX(100%)");
  } else if (type == "season") {
    myRankText.value = "赛季";
    // rank_tab_active_bg.value && (rank_tab_active_bg.value.style.left = "50%");
    rank_tab_active_bg.value &&
      (rank_tab_active_bg.value.style.transform = "translateX(200%)");
  } else {
    myRankText.value = "上赛季";
    // rank_tab_active_bg.value && (rank_tab_active_bg.value.style.left = "75%");
    rank_tab_active_bg.value &&
      (rank_tab_active_bg.value.style.transform = "translateX(300%)");
  }

  const rank_type = "tianti_" + (type === "season" ? "jidu" : type);

  const params = {
    msg_type: "get_tianti_rank",
    rank_type,
  };

  try {
    const res = await asyncSendInstructions(params);
    const iniTtData = initTianTiData(res, store.userInfo);
    initTianTiConfig.value = iniTtData;

    store.$patch((state) => {
      state.userInfo.level = iniTtData.score.level;
      state.userInfo.dashi_card = iniTtData.dashi_card;
      state.userInfo.zongshi_card = iniTtData.zongshi_card;
    });
  } catch (error) {}
};

const awardTabsChange = (type) => {
  awardActiveItem.value = type;

  switch (type) {
    case "weekAward":
      rank_tab_active_bg.value &&
        (rank_tab_active_bg.value.style.transform = "translateX(200%)");
      break;
    case "monthAward":
      rank_tab_active_bg.value &&
        (rank_tab_active_bg.value.style.transform = "translateX(100%)");
      break;
    case "seasonAward":
      rank_tab_active_bg.value &&
        (rank_tab_active_bg.value.style.transform = "translateX(0%)");
      break;
  }
};

onBeforeMount(() => {
  currentRankTabTypeChange("week");
});

onMounted(() => {});
</script>

<style lang="scss" scoped>
.competition {
  background: linear-gradient(90deg, #271a18 0%, #110606 51%, #271a18 100%);
  min-height: 100vh;
  .top_bg_wrapper {
    position: relative;

    .top_title_wrapper {
      position: absolute;
      bottom: 12%;
      left: 50%;
      transform: translate(-50%);
      width: 84%;

      .end_time_text {
        position: absolute;
        left: 50%;
        bottom: 10%;
        transform: translateX(-50%);
        font-size: 14px;

        color: #d08626;

        text-shadow: 0px 2px 2px #4e0f00;
        // background: linear-gradient(to top, #d08626, #a55c2e);
        // -webkit-background-clip: text;
        // -webkit-text-fill-color: transparent;

        font-weight: 800;
        white-space: nowrap;
      }
    }
    //
  }

  .user_score_wrapper {
    background-image: url("https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/rank/user_score_bg.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    position: relative;
    // top: -60px;
    transform: translateY(-60px);
    z-index: 2;
    padding: 60px 60px 20px 60px;

    .user_score_top {
      .season_text {
        font-size: 15px;
        font-weight: 600;

        background: linear-gradient(
          180deg,
          #feedbf 0%,
          #f8c998 56%,
          #f4b07c 100%
        );
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
      .season_score {
        font-size: 25px;
        font-weight: 800;
        background: linear-gradient(
          180deg,
          #feedbf 0%,
          #f8c998 56%,
          #f4b07c 100%
        );
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
      .season_tiem {
        font-size: 10px;
        color: rgba(255, 255, 255, 0.6);
      }
    }

    .user_score_bottom {
      position: relative;
      display: flex;
      justify-content: space-between;
      margin-top: 20px;
      &::after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        transform: translate(0, -15px);
        width: 100%;
        height: 2px;
        background-image: url("https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/myview/line_icon.png");
        background-size: 100% 100%;
        background-repeat: no-repeat;
      }
      .user_score_item {
        // flex: 1;
        text-align: center;
        .user_score_text {
          font-weight: 600;
          background: linear-gradient(
            180deg,
            #feedbf 0%,
            #f8c998 56%,
            #f4b07c 100%
          );
          background-clip: text;
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          font-size: 14px;
        }
        .user_score_value {
          font-size: 16px;
          font-weight: 800;
          color: #fee9ba;
        }
      }
    }
  }

  .rank_list_wrapper {
    border-radius: 15px;

    border: 2px solid #fedd79;
    overflow: hidden;
    .rank_tabs_wrapper {
      display: flex;

      background: linear-gradient(270deg, #160e0a 0%, #352623 100%);
      .rank_tabs_item {
        padding: 10px 0;
        text-align: center;
        flex: 1;
        font-size: 14px;
      }
      .rank_tabs_item_active {
        font-weight: 800;
        background: linear-gradient(
          180deg,
          #feedbf 0%,
          #f8c998 56%,
          #f4b07c 100%
        );
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        font-size: 18px;
      }
    }

    .rank_type_tab_wrapper {
      padding: 0 12px;
      margin-top: 12px;
      .rank_type_tab_box {
        background: linear-gradient(180deg, #2b202c 0%, #322f3b 100%);
        border-radius: 30px;
        position: relative;
        .rank_type_tab {
          display: flex;
          .rank_type_tab_item {
            flex: 1;
            text-align: center;
            height: 24px;
            line-height: 24px;
            color: #f4e7cf;
            position: relative;
            z-index: 2;
          }
          .rank_type_tab_item_active {
            color: #400c16;
            font-weight: 800;
          }
        }

        .rank_tab_active_bg {
          position: absolute;
          left: 0;
          top: 0;
          width: 80px;
          height: 100%;
          background-image: url("https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/rank/active_bg.png");
          background-size: 100% 100%;
          background-repeat: no-repeat;
          z-index: 1;
          transition: transform 0.3s;
          transform: translateX(0);
        }
      }
    }

    .my_rank_info {
      margin-top: 8px;
      display: flex;
      justify-content: space-between;
      padding: 4px 15px;

      .my_rank {
        display: flex;
        .my_head {
          width: 40px;
          height: 40px;
        }

        .rank_name {
          line-height: 40px;
        }
      }
      .rank_num {
        line-height: 40px;
      }
    }

    .rank_list_content {
      .first_item {
        background: linear-gradient(
          90deg,
          #dda820 0%,
          rgba(221, 168, 32, 0) 100%
        );
      }
      .two_item {
        background: linear-gradient(
          90deg,
          #358bc8 0%,
          rgba(53, 139, 200, 0) 100%
        );
      }
      .three_item {
        background: linear-gradient(
          90deg,
          #cb652c 0%,
          rgba(203, 101, 44, 0) 100%
        );
      }
      .rank_list_item {
        display: flex;
        padding: 10px 0;
        .ranking {
          width: 60px;
          position: relative;
          .ranking_text {
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            width: 20px;
            height: 20px;

            border: 1px solid #ffffff;
            font-size: 12px;
            border-radius: 50%;
            text-align: center;
            line-height: 20px;
          }
        }
        .rank_info {
          flex: 1;
          padding-left: 20px;
          display: flex;

          .head {
            width: 52px;
            height: 52px;
          }

          .user_info {
            display: flex;
            flex-direction: column;
            justify-content: center;
            flex: 1;
            .player_name {
              white-space: nowrap;
            }

            .user_level {
              width: 48px;
            }
          }
        }
        .rank_wrapper {
          display: flex;
          flex-direction: column;
          justify-content: center;
          padding-right: 6px;
          text-align: right;
          .rank_number {
            font-size: 14px;
          }
          .rank_item_user_id {
            font-size: 10px;

            color: rgba($color: #fff, $alpha: 0.6);
          }
        }
      }

      .right_award {
        display: flex;
        .acm_wrap {
          height: 100%;
          width: 106px;
        }

        .awardnum-wrapper {
          position: relative;
          color: #fee22a;
          padding-right: 22px;
          width: 70px;
          text-align: right;
          .jinbi-icon {
            position: absolute;
            right: 0;
            top: 50%;
            width: 20px;
            height: 20px;
            transform: translateY(-50%);
            background-image: url("https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/global/gold.png");
            background-size: 100% 100%;
            background-repeat: no-repeat;
          }
        }
      }

      .odd_item {
        background: linear-gradient(
          90deg,
          #342522 0%,
          rgba(52, 37, 34, 0) 100%
        );
      }
      .eve_item {
        border-image: linear-gradient(
            180deg,
            rgba(255, 245, 172, 1),
            rgba(254, 221, 121, 1),
            rgba(252, 230, 158, 1)
          )
          2 2;
      }
    }
  }
}

.award_list_wrapper {
  padding: 20px 0px;
  .list_title {
    padding: 6px 10px;
    display: flex;
    justify-content: space-between;
    .t1 {
      width: 60px;
    }
    .t2 {
      width: 106px;

      text-align: center;
    }
    .t3 {
      width: 60px;
    }
  }

  .award_list_item {
    display: flex;
    justify-content: space-between;
    padding: 0px 8px;
    height: 26px;
    line-height: 26px;
    align-items: center;
    &:nth-child(2) {
      background-image: linear-gradient(
        90deg,
        #dda820 0%,
        rgba(221, 168, 32, 0) 100%
      );
    }
    &:nth-child(3) {
      background: linear-gradient(
        90deg,
        #358bc8 0%,
        rgba(53, 139, 200, 0) 100%
      );
    }
    &:nth-child(4) {
      background: linear-gradient(
        90deg,
        #cb652c 0%,
        rgba(203, 101, 44, 0) 100%
      );
    }

    .rank {
      width: 60px;
      font-weight: 500;
    }
    .acm_wrap {
      width: 112px;
      // text-align: center;
      display: flex;
      .acm_text {
        font-size: 10px;
        margin-right: 6px;
        font-weight: 400;
      }
      .acm_icon {
        width: 44px;
        height: 24px;
      }
    }
    .award_wrapper {
      position: relative;
      width: 60px;
      color: #fee22a;
      padding-right: 22px;
      text-align: right;
      &::after {
        content: "";
        position: absolute;
        right: 0;
        top: 50%;
        width: 20px;
        height: 20px;
        transform: translateY(-50%);
        background-image: url("https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/global/gold.png");
        background-size: 100% 100%;
        background-repeat: no-repeat;
      }
    }
  }
}
</style>
