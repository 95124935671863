<template>
  <div>
    <div
      class="game_task_wrapper"
      :style="{
        left: _left,
        top: _top,
        right: _right,
        bottom: _bottom,
      }"
    >
      <div class="task_icon_wrap" @click="handleInfoWrapFold">
        <div
          class="task_icon_2"
          v-if="
            (current_game_task_data.task_list[0] &&
              current_game_task_data.task_list[0].task_type === 'C') ||
            current_game_task_data.task_list.length > 1 ||
            current_game_task_data.statu == 2 ||
            current_game_task_data.task_list.length == 0
          "
        ></div>
        <div class="task_icon" v-else>
          <van-image
            width="100%"
            height="100%"
            :src="current_game_task_data.task_icon"
            block
            :show-loading="false"
          />
        </div>
        <div
          class="task_icon_2_light"
          v-if="current_game_task_data.statu == 1 && !foldShow"
        ></div>

        <div
          class="all_task_done_text2"
          v-if="current_game_task_data.statu == 1"
        >
          已完成
        </div>

        <div class="progress_v" v-show="!foldShow"></div>
      </div>

      <div
        class="game_info_wrap"
        :style="_InfoWrapStyle"
        ref="_InfoWrap"
        @click="handleTaskDone"
      >
        <div
          class="task_done_sequence"
          v-if="current_game_task_data.statu == 1"
        ></div>

        <div
          class="task_reward_block"
          :style="{
            left: props.game_name == 'bbl' ? 'auto' : '0',
            right: props.game_name == 'bbl' ? '0' : 'auto',
          }"
          v-if="
            current_game_task_data.task_award &&
            current_game_task_data.task_award[0] &&
            current_game_task_data.statu != 2
          "
        >
          <div
            class="task_reward_item"
            v-for="oowa in current_game_task_data.task_award"
          >
            <div class="icon">
              <van-image
                width="100%"
                height="100%"
                :src="RewardIconMaps[oowa.type].img_url"
                block
                :show-loading="false"
              />
            </div>
            <div class="num">
              {{ oowa.num }}
            </div>
          </div>
        </div>

        <div class="task_info" v-if="current_game_task_data.statu != 2">
          <div class="task_name">
            <span
              v-if="
                current_game_task_data.task_list[0] &&
                current_game_task_data.task_list[0].task_type !== 'C'
              "
              >击杀</span
            >
            <span
              v-for="(taskI, taskInd) in current_game_task_data.task_list"
              :key="taskI.id + '1'"
            >
              {{ taskI.tname
              }}<i v-if="taskInd != current_game_task_data.task_list.length - 1"
                >/</i
              >
            </span>
          </div>

          <div class="task_progress_v" v-if="current_game_task_data.statu == 0">
            <span
              v-for="taskI in current_game_task_data.task_list"
              :key="taskI.id + '2'"
            >
              {{ taskI.num }}/{{ taskI.complete }}
            </span>
          </div>
          <div class="task_done_text" v-if="current_game_task_data.statu == 1">
            任务完成,请领取
          </div>
        </div>

        <div
          class="all_task_done_text"
          v-if="current_game_task_data.statu == 2"
        >
          今日所有任务已完成
        </div>
      </div>
    </div>

    <RewardPup :show="taskDoneSuccShow" @confirm="handleCloseRP">
      <div class="reward_block">
        <div class="reward_item" v-for="ritem in _Reward_List">
          <div class="reward_type">
            <van-image
              width="100%"
              height="100%"
              fit="contain"
              position="center"
              :src="RewardIconMaps[ritem.type].img_url"
              :show-loading="false"
            />
          </div>
          <div class="reward_num">{{ ritem.num }}</div>
        </div>
      </div>
    </RewardPup>
  </div>
</template>

<script setup>
import { useStore } from "@/store/store";
import { onMounted, computed, ref, watch, reactive } from "vue";
import {
  asyncSendInstructions,
  sendInstructions,
  handleCallBackEvent,
} from "@/api/sendData";
import RewardPup from "@/components/RewardPup.vue";
import { useToggle } from "@vant/use";
import { showToast } from "vant";
import RewardIconMaps from "@/utils/RewardIconMaps";

const store = useStore();

const props = defineProps({
  left: {
    type: [String, Number],
    default: "auto",
  },
  top: {
    type: [String, Number],
    default: "auto",
  },
  right: {
    type: [String, Number],
    default: "auto",
  },
  bottom: {
    type: [String, Number],
    default: "auto",
  },
  game_name: {
    type: String,
    default: "mgc",
  },
});

const _top = computed(() => {
  if (props.top == "auto") return "auto";
  const rem = parseFloat(document.documentElement.style.fontSize);
  return (props.top / rem).toFixed(5) + "rem";
});

const _right = computed(() => {
  if (props.right == "auto") return "auto";
  const rem = parseFloat(document.documentElement.style.fontSize);
  return (props.right / rem).toFixed(5) + "rem";
});
const _bottom = computed(() => {
  if (props.bottom == "auto") return "auto";
  const rem = parseFloat(document.documentElement.style.fontSize);
  return (props.bottom / rem).toFixed(5) + "rem";
});

const _left = computed(() => {
  if (props.left == "auto") return "auto";
  const rem = parseFloat(document.documentElement.style.fontSize);
  return (props.left / rem).toFixed(5) + "rem";
});

const current_game_task_data = computed(
  () => store.gameTaskData[props.game_name] ?? store.gameTaskData.mgc
);

watch(
  () => current_game_task_data.value,
  (newV) => {
    if (newV && newV.statu == 1 && !foldShow.value) {
      handleInfoWrapFold();
    }
  }
);

const _InfoWrap = ref();
const _InfoWrapStyle = reactive({
  "-webkit-transform-origin": "100% 100%",
  transformOrigin: "100% 100%",
  right: "-2px",
  left: "auto",
  transform: "scaleX(1)",
  opacity: "0",
});

if (props.game_name == "bbl") {
  _InfoWrapStyle["-webkit-transform-origin"] = "0 0";
  _InfoWrapStyle.transformOrigin = "0 0";
  _InfoWrapStyle.right = "auto";
  _InfoWrapStyle.left = "-2px";
}

const [foldShow, foldShowToggle] = useToggle(false);
const handleInfoWrapFold = () => {
  // console.log("foldShowfoldShow", foldShow.value);
  // if (!_InfoWrap.value) return;
  if (foldShow.value) {
    foldShowToggle(false);
    _InfoWrapStyle.transform = "scaleX(0)";
    _InfoWrapStyle.opacity = "0";
  } else {
    foldShowToggle(true);
    get_data();
    _InfoWrapStyle.transform = "scaleX(1)";
    _InfoWrapStyle.opacity = "1";
  }
};

// 任务完成 领取奖励
const _Reward_List = ref([]);
const [taskDoneSuccShow, taskDoneSuccShowToggle] = useToggle(false);
const handleCloseRP = () => {
  _Reward_List.value = [];
  taskDoneSuccShowToggle(false);
};

const handleTaskDone = async () => {
  if (!foldShow.value) return;
  if (current_game_task_data.value.statu != 1) return;

  try {
    const res = await asyncSendInstructions({
      msg_type: "get_player_collect_task_reward",
      game_name: props.game_name,
    });

    _Reward_List.value = current_game_task_data.value.task_award;
    console.log("resresresresresres", res);

    if (res.error === "ok") {
      taskDoneSuccShowToggle(true);

      let _lottery = 0;
      let _gold = 0;

      _Reward_List.value.forEach((iii) => {
        if (iii.type == "gold") {
          _gold += iii.num;
        } else if (iii.type == "lottery") {
          _lottery += iii.num;
        }
      });

      store.$patch((state) => {
        if (_lottery > 0) {
          state.userInfo.lottery = state.userInfo.lottery + _lottery;
        }
        if (_gold > 0) {
          state.userInfo.gold = state.userInfo.gold + _gold;
        }
      });
      handleCallBackEvent("get_player_collect_task_result", res);
    } else {
      const errorMsgs = {
        "not complete": "条件未达成",
      };

      showToast({
        type: "fail",
        message: errorMsgs[res.error] ?? res.error ?? "稍后重试1",
        teleport: "#rotate_box",
        onClose() {
          sendInstructions({
            msg_type: "get_player_collect_task",
          });
        },
      });
    }
  } catch (error) {
    showToast({
      type: "fail",
      message: "请稍后重试",
      teleport: "#rotate_box",
      onClose() {
        sendInstructions({
          msg_type: "get_player_collect_task",
        });
      },
    });
  }
};

const get_data = () => {
  const game_name = store.current_machine.product_name;

  if (
    store.gameTaskData[game_name] &&
    store.gameTaskData[game_name].task_list.length == 0
  ) {
    sendInstructions({
      msg_type: "get_player_collect_task",
    });
  }
};

onMounted(() => {
  get_data();
});
</script>

<style lang="scss" scoped>
.game_task_wrapper {
  position: absolute;
  width: 44px;
  height: 44px;

  z-index: 6;

  .task_icon_wrap {
    position: relative;
    z-index: 3;
    width: 44px;
    height: 44px;

    background-image: url("https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/collect/collect_task_entrance_wrap.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;

    .all_task_done_text2 {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background-color: rgba($color: #000000, $alpha: 0.4);
      border-radius: 50%;
      z-index: 4;
      line-height: 44px;
      text-align: center;
      font-size: 10px;
      text-shadow: 0px 1px 1px #000000;
    }

    .task_icon {
      position: absolute;
      left: 7px;
      top: 6px;
      width: 30px;
      height: 30px;
      overflow: hidden;
      // background-color: pink;
      border-radius: 50%;
      //   transform: translate(-50%, -50%);
      z-index: 1;
    }

    .task_icon_2_light {
      position: absolute;
      right: 8px;
      top: 4px;
      width: 8px;
      height: 8px;
      background-color: red;
      border-radius: 50%;
      //transform: translate(-50%, -50%);
      //animation: ccAnimt 1s infinite linear;
      //background-image: url("https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/collect/task_icon_2_light.png");
      //background-size: 100% 100%;
      //background-repeat: no-repeat;
      z-index: 3;
    }

    .task_icon_2 {
      position: relative;
      width: 100%;
      height: 100%;
      background-image: url("https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/collect/collect_task_entrance2_wrap.png");
      background-size: 100% 100%;
      background-repeat: no-repeat;
      z-index: 2;
    }

    .progress_v {
      position: absolute;
      width: 40px;
      height: 14px;
      bottom: 0px;
      left: 50%;
      transform: translate(-50%, 60%);
      font-weight: bold;
      text-align: center;
      font-size: 12px;
      text-shadow: 0px 1px 1px #000000;
      background-image: url("https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/collect/collect_task_entrance_text.png");
      background-size: 100% 100%;
      background-repeat: no-repeat;
      z-index: 3;
    }
  }

  .game_info_wrap {
    position: absolute;
    top: 0px;

    transform: scaleX(0);
    height: 44px;
    width: 240px;
    background: linear-gradient(180deg, #86078960 0%, #a451ff60 100%);
    border: 1px solid #fee22a;
    border-radius: 22px;
    z-index: 2;
    transition: transform 0.5s, opacity 0.5s;

    opacity: 0;

    .task_done_sequence {
      position: absolute;
      left: 50%;
      top: 50%;
      width: 272px;
      height: 54px;
      transform: translate(-50%, -50%);
      background-image: url("https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/collect/task_info_light.png");
      background-size: 100% auto;
      background-repeat: no-repeat;
      background-position: 0 0;
      animation-name: task_done_sequence_a;
      animation-duration: 1.5s;
      animation-timing-function: step-start;
      animation-iteration-count: infinite;
    }

    .task_reward_block {
      position: absolute;
      top: 0;
      min-width: 44px;
      height: 44px;
      display: flex;
      justify-content: center;
      padding-left: 8px;
      .task_reward_item {
        position: relative;
        width: 30px;
        height: 44px;

        .icon {
          position: absolute;
          left: 50%;
          top: 40%;
          width: 24px;
          height: 24px;
          transform: translate(-50%, -50%);
          // background-image: url("https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/global/lottery.png");
          // background-size: 100% 100%;
          // background-repeat: no-repeat;
        }

        .num {
          position: absolute;
          bottom: 0;
          width: 100%;
          text-align: center;
          color: #fee22a;
        }
      }
    }

    .task_info {
      position: absolute;
      top: 0;
      left: 44px;
      width: 152px;
      height: 100%;
      // background-color: pink;
      display: flex;
      flex-direction: column;
      .task_name {
        position: relative;
        height: 24px;
        width: 100%;
        display: flex;
        justify-content: center;

        font-size: 10px;
        line-height: 24px;
        white-space: nowrap;

        &::after {
          content: "";
          position: absolute;
          bottom: 0;
          left: 50%;
          transform: translateX(-50%);
          height: 2px;
          width: 120px;
          background: linear-gradient(
            89deg,
            rgba(253, 229, 72, 0) 0%,
            #fde548 49%,
            rgba(253, 229, 72, 0) 100%
          );
        }
        // border-bottom: 2px solid;
      }
      .task_progress_v {
        width: 100%;
        height: 20px;
        display: flex;
        line-height: 20px;
        justify-content: center;
        span {
          margin-right: 10px;
        }
      }

      .task_done_text {
        width: 100%;
        height: 20px;
        text-align: center;
        line-height: 20px;
        color: #fee22a;
      }
    }

    .all_task_done_text {
      position: absolute;
      top: 0;
      left: 0px;
      width: 196px;
      height: 100%;
      color: #fee22a;
      font-size: 12px;
      line-height: 44px;
      text-align: center;
    }
  }
}

.reward_block {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;

  .reward_item {
    width: 48px;

    .reward_type {
      width: 48px;
      height: 48px;
      background-color: #9369e1;
      border-radius: 6px;
    }

    .reward_num {
      margin-top: 4px;
      width: 100%;
      text-align: center;
      white-space: nowrap;
      color: #724026;
      font-size: 14px;
      padding: 4px 0;
    }
  }
}

@keyframes task_done_sequence_a {
  0% {
    background-position: 0 0;
  }
  7.7% {
    background-position: 0 -54px;
  }
  15.4% {
    background-position: 0 -108px;
  }
  23.1% {
    background-position: 0 -162px;
  }
  30.8% {
    background-position: 0 -216px;
  }
  38.5% {
    background-position: 0 -270px;
  }
  46.2% {
    background-position: 0 -324px;
  }
  53.9% {
    background-position: 0 -378px;
  }
  61.6% {
    background-position: 0 -432px;
  }
  69.3% {
    background-position: 0 -486px;
  }
  77% {
    background-position: 0 -540px;
  }
  84.7% {
    background-position: 0 -594px;
  }
  92.4% {
    background-position: 0 -648px;
  }
  100% {
    background-position: 0 -702px;
  }
}

@-webkit-keyframes task_done_sequence_a {
  0% {
    background-position: 0 0;
  }
  7.7% {
    background-position: 0 -54px;
  }
  15.4% {
    background-position: 0 -108px;
  }
  23.1% {
    background-position: 0 -162px;
  }
  30.8% {
    background-position: 0 -216px;
  }
  38.5% {
    background-position: 0 -270px;
  }
  46.2% {
    background-position: 0 -324px;
  }
  53.9% {
    background-position: 0 -378px;
  }
  61.6% {
    background-position: 0 -432px;
  }
  69.3% {
    background-position: 0 -486px;
  }
  77% {
    background-position: 0 -540px;
  }
  84.7% {
    background-position: 0 -594px;
  }
  92.4% {
    background-position: 0 -648px;
  }
  100% {
    background-position: 0 -702px;
  }
}

@keyframes ccAnimt {
  from {
    transform: translate(-50%, -50%) rotate(0deg);
  }

  to {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}

@-webkit-keyframes ccAnimt

/* Safari 与 Chrome */ {
  from {
    transform: translate(-50%, -50%) rotate(0deg);
  }

  to {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}
</style>
