<template>
  <van-popup
    v-model:show="props.show"
    position="right"
    @click-overlay="handleCloseIconClick"
    :overlay="false"
    :style="{ width: '4rem', height: '100%', background: 'none' }"
  >
    <div class="room_container">
      <div class="left_block">
        <div class="close_icon" @click="handleCloseIconClick"></div>
      </div>

      <div class="room_list_wrapper">
        <div class="title">切换房间</div>
        <div class="room_list" v-if="props.game_name != 'msqy_line'">
          <div class="close_icon"></div>
          <div
            :class="[
              'room_item',
              roomItem.machine_index == store.current_machine.machine_index &&
                roomItem.product_name == store.current_machine.product_name &&
                'current_room_item',
            ]"
            v-for="roomItem in RoomList"
            @click="handleRoomItemClick(roomItem)"
          >
            <div class="maintain_mask" v-if="roomItem.status == 0">
              <div class="text">维护中</div>
            </div>
            <div class="room_info">
              <div class="room_index">{{ roomItem.machine_index }}房</div>
              <div class="exchange_number_wrapper">{{ roomItem.exchange }}</div>
              <div class="room_cover">
                <van-image
                  width="100%"
                  height="auto"
                  :show-loading="false"
                  :src="
                    findGameInfoData(
                      roomItem.child_name || roomItem.product_name
                    ).cover
                  "
                  block
                />
              </div>

              <div class="seats_info">
                <div
                  class="seats_item_head"
                  v-for="seatItem in roomItem.device_info"
                >
                  <div class="no_dev" v-if="seatItem.status == 0">维护</div>

                  <div
                    class="head_box"
                    v-else-if="
                      seatItem.occupy &&
                      seatItem.occupy.player_id &&
                      seatItem.occupy.player_id != ''
                    "
                  >
                    <HeadPortrait
                      :level="seatItem.occupy.recharge_level"
                      :ae_id="seatItem.occupy.select_title"
                      ae
                      fe
                      :src="
                        seatItem.occupy.head != ''
                          ? seatItem.occupy.head
                          : seatItem.occupy.head_frame
                      "
                    />
                  </div>
                  <div v-else class="null_seate">空闲</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="msqy_room_list" v-else>
          <div
            v-for="roomItem in RoomList"
            @click="handleRoomItemClick(roomItem)"
            :class="[
              'room_item',
              store.current_machine.line_reward_index ==
                roomItem.line_reward_index && 'current_room_item',
            ]"
          >
            <div class="room_item_container">
              <div class="room_index">{{ roomItem.line_reward_index }}房</div>

              <div class="exchange_number_wrapper">{{ roomItem.exchange }}</div>
              <div class="room_item_cover">
                <div class="room_line_data">
                  <div class="data_item">
                    <div class="data_name" style="color: #00fdff">jp1</div>
                    <div class="data_value">
                      {{ roomItem.line_reward_data.jp1 }}
                    </div>
                  </div>
                  <div class="data_item">
                    <div class="data_name" style="color: #00ff29">jp2</div>
                    <div class="data_value">
                      {{ roomItem.line_reward_data.jp2 }}
                    </div>
                  </div>
                  <div class="data_item">
                    <div class="data_name" style="color: #ffc700">jp3</div>
                    <div class="data_value">
                      {{ roomItem.line_reward_data.jp3 }}
                    </div>
                  </div>
                  <div class="data_item">
                    <div class="data_name" style="color: #ff0000">ALL</div>
                    <div class="data_value">
                      {{ roomItem.line_reward_data.quanpan }}
                    </div>
                  </div>
                </div>
              </div>
              <div class="seats_info">
                <div
                  class="seats_item_head"
                  v-for="seatItem in roomItem.machines"
                >
                  <div
                    class="head_box"
                    v-if="
                      seatItem.device_info[0].occupy &&
                      seatItem.device_info[0].occupy.player_id &&
                      seatItem.device_info[0].occupy.player_id != ''
                    "
                  >
                    <HeadPortrait
                      :level="seatItem.device_info[0].occupy.recharge_level"
                      :ae_id="seatItem.device_info[0].occupy.select_title"
                      ae
                      fe
                      :src="
                        seatItem.device_info[0].occupy.head != ''
                          ? seatItem.device_info[0].occupy.head
                          : seatItem.device_info[0].occupy.head_frame
                      "
                    />
                  </div>

                  <div v-else class="null_seate">空闲中</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </van-popup>
</template>

<script setup>
import { useStore } from "@/store/store";
import findGameInfoData from "@/utils/gameInfoMap";
import HeadPortrait from "@/components/HeadPortrait.vue";
import { computed, watch } from "vue";
import { sendInstructions } from "@/api/sendData";
import { showToast } from "vant";
import { useRouter } from "vue-router";
const store = useStore();
const router = useRouter();

const props = defineProps({
  show: {
    type: Boolean,
    default: false,
  },
  game_name: {
    type: String,
    default: "mgc",
  },
});

const emits = defineEmits(["close_pup", "toggle_room"]);
const vip_tbj_ps = [
  "bjwg",
  "cssxp",
  "yhtd",
  "bjl",
  "bql",
  "hdcz",
  "bbxc",
  "hjj",
  "cbjl",
];
const tbj_ps = ["mxt", "msqy", "msqy_line"];

watch(
  () => props.show,
  (newV) => {
    if (newV) {
      if (vip_tbj_ps.includes(props.game_name)) {
        sendInstructions({
          msg_type: "get_machine_info_by_group",
          group: "vip_tbj",
        });
      }
      if (
        props.game_name == "msqy" &&
        (store.current_machine.line_reward_index ||
          store.current_machine.child_name == "msqy_line")
      ) {
        sendInstructions({
          msg_type: "get_machine_info_by_group",
          group: "msqy_line",
        });
      }
    }
  }
);

const handleCloseIconClick = () => {
  emits("close_pup");
};

const tbjExitGameRequest = () => {
  const roomInfo = store.current_machine;
  if (
    !store.gameIsWatch &&
    [...tbj_ps, ...vip_tbj_ps].includes(roomInfo.product_name)
  ) {
    const params = {
      msg_type: "return_lottery",
      game_name: roomInfo.product_name,
      machine_index: roomInfo.machine_index,
      device_name: roomInfo.device_info[0].device_name,
    };

    // 第一次
    sendInstructions(params, 0);

    // console.log("退分00---->", params);

    // 第二次
    sendInstructions(params, 1000);
    // console.log("退分11---->", params);
    // 第三次
    sendInstructions(params, 1800);
    console.log("退分22---->", params);

    store.initStatu();
  }
};

const handleRoomItemClick = (roomInfo) => {
  console.log(roomInfo);

  let _roomInfo = roomInfo;

  // 处理联线
  if (roomInfo.line_reward_index || roomInfo.machines) {
    if (store.current_machine.line_reward_index == roomInfo.line_reward_index)
      return emits("close_pup");

    const isNul = roomInfo.machines.some((item) => {
      if (item.occupy.length == 0 && item.status == 1) {
        _roomInfo = item;
        return true;
      } else return false;
    });

    if (!isNul) _roomInfo = roomInfo.machines[0];

    if (_roomInfo.status == 0) {
      showToast({
        type: "text",
        message: "当前机器维护",
        teleport: "#rotate_box",
      });

      return;
    }

    tbjExitGameRequest();

    store.$patch((state) => {
      state.current_machine = _roomInfo;
    });

    emits("toggle_room", _roomInfo.sp_url);

    return;
  }

  if (_roomInfo.status == 0) {
    showToast({
      type: "text",
      message: "当前机器维护",
      teleport: "#rotate_box",
    });

    return;
  }

  const independentArr = ["ttq", "xycs"];
  if (roomInfo.product_name == "other") {
    store.$patch((state) => {
      state.current_machine = roomInfo;
    });

    setTimeout(() => {
      router.replace({
        name: independentArr.includes(roomInfo.child_name)
          ? roomInfo.child_name
          : "other",
      });
    }, 100);

    return;
  }

  // 其他正常游戏
  if (
    store.current_machine.machine_index == roomInfo.machine_index &&
    store.current_machine.product_name == roomInfo.product_name
  ) {
    emits("close_pup");
    return;
  }

  //  推币机等类型在线换房
  tbjExitGameRequest();

  store.$patch((state) => {
    state.current_machine = roomInfo;
  });

  emits("toggle_room", roomInfo.sp_url);
};

const RoomList = computed(() => {
  if (props.game_name == "msqy_line") return store.msqyLineListData;
  console.log("props.game_name", props.game_name);
  if (vip_tbj_ps.includes(props.game_name)) return store.gameDataList.vip_tbj;
  return store.gameDataList[props.game_name];
});
</script>

<style lang="scss" scoped>
.room_container {
  display: flex;
  height: 100%;
  width: 100%;
  .left_block {
    position: relative;
    width: 30px;
    height: 100%;

    .close_icon {
      position: absolute;
      left: 0;
      top: 50%;
      transform: translate(0, -50%);
      width: 36px;
      height: 50px;
      background-image: url("https://jikejingji.oss-cn-guangzhou.aliyuncs.com/appClientStatic/images/global/close_icon_right.png");
      background-size: 100% 100%;
      background-repeat: no-repeat;
      z-index: 2;
    }
  }
}

.room_list_wrapper {
  position: relative;
  flex: 1;
  height: 100%;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  background-color: #502cb560;
  .title {
    height: 25px;
    background: linear-gradient(79deg, #ffb757 0%, #fed6a2 100%);
    line-height: 25px;
    text-shadow: 0px 1px 1px rgba(0, 0, 0, 0.8);
    font-weight: bold;
    text-align: center;
  }

  .room_list {
    position: relative;
    width: 100%;
    flex: 1;
    overflow: auto;
    padding: 4px 2px;

    .room_item {
      position: relative;
      margin-top: 4px;
      width: 100%;
      //   height: 84px;
      border: 4px solid #4f5b90;
      border-radius: 5px;

      .maintain_mask {
        position: absolute;
        z-index: 33;
        width: 100%;
        height: 100%;
        background-color: rgba($color: #000000, $alpha: 0.6);

        .text {
          position: absolute;
          font-size: 12px;
          text-align: center;
          width: 100%;
          top: 50%;
          transform: translateY(-50%);
          font-weight: bold;
          color: #ff0000;
        }
      }

      .room_info {
        position: relative;
        width: 100%;
        height: 100%;
        border-radius: 5px;
        overflow: hidden;

        .room_cover {
          position: relative;
          width: 100%;
          height: 100%;
          z-index: 1;
        }

        .exchange_number_wrapper {
          position: absolute;
          right: 2px;
          top: 2px;
          // padding: 0px 4px 0px 24px;
          height: 20px;
          width: 44px;
          line-height: 20px;
          padding: 0 6px 0 0;
          text-align: right;
          background-color: #3a1b67;
          border-radius: 10px;
          font-size: 12px;
          z-index: 3;
          &::before {
            content: "";
            height: 20px;
            width: 20px;
            position: absolute;
            left: -4px;
            top: 0px;
            background-image: url("https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/global/gold.png");
            background-size: 100% 100%;
            background-repeat: no-repeat;
          }
        }
        .room_index {
          position: absolute;
          left: 0;
          top: 0;
          min-width: 22px;
          font-size: 10px;
          line-height: 14px;
          padding: 0 4px;
          z-index: 2;
          background: linear-gradient(137deg, #e8209a 0%, #a31073 100%);
        }
        .seats_info {
          position: absolute;
          width: 100%;
          height: 20px;
          bottom: 0;
          left: 0;
          background-color: rgba($color: #000000, $alpha: 0.6);
          z-index: 2;

          display: flex;
          justify-content: space-around;
          align-items: center;
          padding: 0 4px;

          .seats_item_head {
            position: relative;
            flex: 1;

            .no_dev {
              width: 100%;
              height: 100%;
              color: #ff0000;
              font-size: 8px;
              white-space: nowrap;
              text-align: center;
              line-height: 20px;
            }

            .head_box {
              position: absolute;
              left: 50%;
              top: 50%;
              transform: translate(-50%, -50%);
              width: 16px;
              height: 16px;
            }

            .null_seate {
              width: 100%;
              height: 100%;
              color: #4ef4e3;
              font-size: 8px;
              white-space: nowrap;
              text-align: center;
              line-height: 20px;
            }
          }
        }
      }
    }

    .current_room_item {
      border: 4px solid #fee22a;
    }
  }

  .msqy_room_list {
    position: relative;
    width: 100%;
    flex: 1;
    overflow: auto;
    padding: 4px 2px;

    .room_item {
      margin-top: 4px;
      width: 100%;
      //   height: 84px;
      border: 4px solid #4f5b90;
      border-radius: 5px;

      .room_item_container {
        position: relative;
        width: 100%;
        height: 84px;

        background-image: url("@/assets/images/game/game_room_msqy_cover_bg.png");
        background-size: 100% 100%;
        background-repeat: no-repeat;

        .exchange_number_wrapper {
          position: absolute;
          right: 2px;
          top: 2px;
          padding: 2px 6px 2px 24px;
          text-align: center;
          background-color: #3a1b67;
          border-radius: 10px;
          font-size: 12px;
          z-index: 3;
          &::before {
            content: "";
            height: 20px;
            width: 20px;
            position: absolute;
            left: -4px;
            top: 0px;
            background-image: url("https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/global/gold.png");
            background-size: 100% 100%;
            background-repeat: no-repeat;
          }
        }

        .room_index {
          position: absolute;
          left: 0;
          top: 0;
          min-width: 22px;
          font-size: 10px;
          line-height: 14px;
          padding: 0 4px;
          z-index: 2;
          background: linear-gradient(137deg, #e8209a 0%, #a31073 100%);
        }

        .room_item_cover {
          position: absolute;
          width: 100%;
          height: 48px;
          bottom: 20px;
          background-image: url("@/assets/images/game/game_room_msqy_cover.png");
          background-size: 100% 100%;
          background-repeat: no-repeat;

          .room_line_data {
            position: absolute;
            top: 16px;
            left: 0;
            padding: 0 2px;
            width: 100%;
            height: 26px;
            display: flex;
            .data_item {
              flex: 1;

              font-size: 8px;
              text-align: center;
              .data_name {
                position: relative;
                top: 2px;
              }
              .data_value {
                text-shadow: 1px 1px 0px #0b0004;
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
              }
            }
          }
        }
        .seats_info {
          position: absolute;
          width: 100%;
          height: 20px;
          bottom: 0;
          left: 0;
          background-color: rgba($color: #000000, $alpha: 0.6);
          z-index: 2;
          padding: 0 6px;
          display: flex;
          justify-content: space-around;
          align-items: center;

          .seats_item_head {
            position: relative;
            flex: 1;
            .head_box {
              position: absolute;
              left: 50%;
              top: 50%;
              transform: translate(-50%, -50%);
              width: 16px;
              height: 16px;
            }

            .null_seate {
              width: 100%;
              height: 100%;
              color: #4ef4e3;
              font-size: 8px;
              white-space: nowrap;
              text-align: center;
              line-height: 20px;
            }
          }
        }
      }
    }
    .current_room_item {
      border: 4px solid #fee22a;
    }
  }
}
</style>
