<template>
  <div
    class="container pb-20 home"
    :style="{
      paddingTop: store.areaInsetTopValue + 'px',
    }"
  >
    <!-- 玩家信息 -->
    <div class="user_info_wrapper">
      <div class="user_info_left">
        <HeadPortrait
          :level="Number(store.userInfo.recharge_level)"
          :src="store.userInfo.headimgurl"
          ae
          fe
          :ae_id="store.userInfo.select_title"
          width="100%"
          height="100%"
        />
      </div>
      <div class="user_info_content">
        <div class="user_name">{{ store.userInfo.player_name }}</div>
        <div class="user_level_wrapper">
          <Level
            style="margin-right: 4px"
            level_type="recharge_level"
            :level_value="Number(store.userInfo.recharge_level)"
          />
          <Level :level_value="Number(store.userInfo.level)" />
        </div>
        <div class="wallet_lottery">
          <UserWallet type="lottery" />
        </div>
        <div class="wallet_gold">
          <UserWallet />
        </div>
      </div>
    </div>

    <!-- 顶部Banner -->
    <van-swipe :autoplay="3000" indicator-color="white">
      <van-swipe-item
        v-for="item in bannerData.banner"
        :key="item.key"
        @click="swipeItemClick(item.key, item.imageUrl)"
      >
        <div class="width h-84">
          <van-image
            :show-loading="false"
            class="b-rounded of-hidden"
            width="100%"
            height="100%"
            :src="item.imageUrl"
          />
        </div>
      </van-swipe-item>
    </van-swipe>

    <!-- 顶部播报 -->
    <van-notice-bar
      background="none"
      color="#Fff"
      v-if="calculateTimeMantissa()"
    >
      <div class="flex">
        <div class="flex-1 flex" v-for="noticeItem in noticeList">
          <span class="mr-8">恭喜</span>

          <HeadPortrait
            :frameshow="false"
            :src="noticeItem.head"
            :width="'22px'"
            :height="'22px'"
            :ae_id="noticeItem.select_title"
            ae
            fe
          />
          <span class="ml-8 mr-4" style="color: #fae523">{{
            noticeItem.userName
          }}</span>
          <span class="mr-4">在{{ noticeItem.gameName }}</span>
          <span class="mr-4" style="color: #fae523">{{
            noticeItem.content
          }}</span>
          <span>&nbsp;&nbsp;</span>
        </div>
      </div>
    </van-notice-bar>
    <van-notice-bar
      v-else
      color="#fff"
      class="width f-12"
      background="none"
      scrollable
      :text="bannerData.notice"
    />

    <!-- 导航条 -->
    <NavList @when_sign_click="handle_when_sign_click" />

    <!-- 游戏退票信息 -->
    <van-swipe
      vertical
      class="notice-swipe"
      :autoplay="3000"
      :touchable="false"
      :show-indicators="false"
    >
      <van-swipe-item v-for="barItem in lotteryList">
        <div class="notice-swipe-item" :key="barItem._id">
          <div class="notice_head">
            <HeadPortrait :src="barItem.head" width="100%" height="100%" />
          </div>
          <div class="notice_text">
            <span class="mr-2" style="color: #ffdf7c"
              >{{ barItem.userName }}:</span
            >
            <span>
              {{ `在${barItem.gameName}${barItem.machine_index}号房` }}</span
            >
            <span>获得了</span>

            <span class="lottery_style">{{ barItem.lottery }}</span>

            <div class="lottery_icon"></div>
          </div>
        </div>
      </van-swipe-item>
    </van-swipe>

    <!-- 热门活动 -->
    <div class="active_list_wrapper">
      <swiper
        :slides-per-view="2"
        :space-between="10"
        :modules="activeModules"
        autoplay
        loop
        navigation
        :pagination="{ clickable: false, type: 'bullets' }"
      >
        <swiper-slide v-for="activeItem in activeListBanner">
          <div class="swiper_item_cotent" @click="activeTypeClick(activeItem)">
            <van-image
              width="100%"
              height="auto"
              :src="activeItem.banner"
              block
              :show-loading="false"
            />
          </div>
        </swiper-slide>
      </swiper>
    </div>

    <!-- 在线玩家头像 -->
    <div class="user_online_list_wrapper">
      <div class="online_list">
        <div
          class="online_item"
          v-for="onlineItem in userOnlineData"
          :key="onlineItem.uid"
        >
          <div class="online_head_wrapper">
            <div class="online_head">
              <HeadPortrait
                :src="
                  onlineItem.headimgurl == ''
                    ? onlineItem.headFrame
                    : onlineItem.headimgurl
                "
                width="100%"
                height="100%"
              />
            </div>

            <div class="online_icon">
              <span class="mr-2">热玩</span>
              <i class="hot_icon"></i>
              <i class="hot_icon"></i>
              <i class="hot_icon"></i>
            </div>
          </div>
          <div class="online_name">
            {{ onlineItem.player_name }}
          </div>
        </div>

        <div class="online_item">
          <div class="online_head_wrapper" style="opacity: 0">
            <div class="online_head"></div>

            <div class="online_icon">
              <span class="mr-2">热玩</span>
            </div>
          </div>
          <div class="online_name" style="opacity: 0">1233</div>

          <i class="more_icon_one"></i>
          <i class="more_icon_to"></i>
          <i class="more_icon_cc"></i>
        </div>
      </div>
    </div>

    <GameList />

    <!-- 悬浮菜单 -->
    <FloatingMenu
      @when_login_solts_close="when_login_solts_close_monitoring_sign_statu"
    />

    <!-- 签到领取 -->
    <SignInPup ref="_Sign" />

    <!-- 挑战断线重来 -->
    <van-popup
      v-model:show="store.get_player_tiaozhan_info_show"
      round
      :close-on-click-overlay="false"
      z-index="2000"
    >
      <div class="net_close_pup">
        <p class="close_title">温馨提示</p>
        <p class="close_content">您正在比赛中,请返回</p>
        <div class="close_btn" @click="continueChallenge">返回比赛</div>
      </div>
    </van-popup>

    <!-- 在线客服图片 -->
    <ServicePup
      :show="serverPupShow"
      @close_icon_click="serverPupShowChange(false)"
      :landscape="false"
    />

    <!-- 卡券通知 -->
    <!-- <van-popup
      v-model:show="store.cardNotifyData.show"
      style="
        background: none;
        width: 100%;
        max-width: 100vw;
        padding: 0;
        margin: 0;
        height: 460px;
      "
      @closed="card_closed"
    >
      <div class="card_notify">
        <van-image
          :show-loading="false"
          width="100%"
          height="100%"
          src="https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/card_notify.png"
          @click="card_closed"
        />
      </div>
    </van-popup> -->
  </div>
</template>

<script setup>
import HeadPortrait from "@/components/HeadPortrait.vue";
import ServicePup from "@/components/ServicePup.vue";
import Level from "@/components/Level.vue";
import NavList from "@/components/NavList.vue";
import GameList from "@/components/GameList.vue";
import UserWallet from "@/components/UserWallet.vue";
import FloatingMenu from "@/components/FloatingMenu.vue";
import SignInPup from "@/components/SignInPup.vue";
import findCollectCardInfo from "@/utils/CollectClassID";
import findGameInfoData from "@/utils/gameInfoMap";
import { useStore } from "@/store/store";
import { getImageFileNameWithoutExtensionFromUrl } from "@/utils/utils";
import { sendInstructions } from "@/api/sendData";
import { useThrottle } from "@/hooks";
import findStype from "@/utils/stypeMaps";
import {
  onBeforeUnmount,
  onMounted,
  onBeforeMount,
  ref,
  reactive,
  computed,
  watch,
  onDeactivated,
  onActivated,
} from "vue";
import { closeToast, showToast, showConfirmDialog } from "vant";
import { useRouter } from "vue-router";
import { homeBanner, userOnline, mailStatus, getSys } from "@/api";

const store = useStore();
const router = useRouter();
onActivated(() => {
  // console.log("home , onActivated");
  getActiveBanner();
});
onDeactivated(() => {
  // console.log("home , onDeactivated");
});

// 卡券通知
// const card_closed = () => {
//   store.$patch((state) => {
//     state.cardNotifyData.show = false;
//     state.cardNotifyData.listdata = [];
//   });

//   router.push("/cardbag");
// };

//#region 继续挑战
const continueChallenge = () => {
  const game_name = store.settlementResult.game_name;
  if (!game_name) {
    store.get_player_tiaozhan_info_show = false;

    return;
  }

  sendInstructions(
    {
      msg_type: "get_machine_info",
      game_name: game_name,
    },
    50
  );

  showToast({
    type: "loading",
    message: "请稍后...",
    duration: 15000,
    forbidClick: true,
  });

  let isTrue = false;

  setTimeout(() => {
    isTrue = store.gameDataList[game_name].some((item) => {
      return item.device_info.some((dev) => {
        if (dev.occupy.player_id == parseInt(store.userInfo.uid)) {
          closeToast();
          store.$patch((state) => {
            state.gameIsWatch = false;
            state.current_machine = item;
            state.current_device = dev;
            state.settlementResult.game_name = item.product_name;
            state.settlementResult.device_name = dev.device_name;
          });
          return true;
        } else return false;
      });
    });

    isTrue &&
      router.push({
        name: game_name,
      });

    if (!isTrue) {
      showToast({
        type: "fail",
        message: "比赛已结束",
      });
      store.get_player_tiaozhan_info_show = false;
    }
  }, 2000);
};
//#endregion

//#region 客服弹窗
const serverPupShow = ref(false);
const serverPupShowChange = (type) => {
  serverPupShow.value = type;
};
//#endregion

//#region  热门活动轮播
import { Swiper, SwiperSlide } from "swiper/vue";

// Import Swiper styles
import "swiper/css";
import { Pagination, Navigation, Autoplay } from "swiper/modules";
import "swiper/css/pagination";

const activeBanner1 =
  "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/active/active_banner_1.png";
const activeBanner2 =
  "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/active/active_banner_2.png";
const activeBanner3 =
  "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/active/active_banner_3.png";

const activeBanner13 =
  "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/active/active_banner_13.png";

const activeModules = [Pagination, Navigation, Autoplay];
const activeListBanner = ref([
  {
    type: "welfare",
    banner: activeBanner1,
    start_time: 0,
    end_time: 0,
  },
  {
    type: "challenge",
    banner: activeBanner2,
    start_time: 0,
    end_time: 0,
  },
]);

// 活动页面跳转
const activeTypeClick = (item) => {
  switch (item.type) {
    case "challenge":
      router.replace("/challenge");
      break;
    case "long":
      store.storeShowToggle("gemShopShow", true);
      break;
    default:
      router.push("/welfareLobby");
      break;
  }
};

const getActiveBanner = async () => {
  try {
    const res = await mailStatus();

    store.$patch((state) => {
      state.long_is_open_time = res.data.long ?? false;
    });
    if (res.data.long) {
      const list = JSON.parse(JSON.stringify(activeListBanner.value));

      const iscz = list.some((ii) => {
        return ii.type == "long";
      });

      if (!iscz) {
        list.unshift({
          type: "long",
          banner: activeBanner13,
          start_time: 0,
          end_time: 0,
        });

        activeListBanner.value = list;
      }
    } else if (!res.data.long) {
      const oldList = JSON.parse(JSON.stringify(activeListBanner.value));
      const newList = oldList.filter((ii) => {
        return ii.type != "long";
      });

      activeListBanner.value = newList;
    }
  } catch (error) {}
};

onBeforeMount(() => {
  getActiveBanner();
});

//#endregion

//#region // 获取公告 公告 banner
const bannerData = reactive({
  banner: [
    {
      imageUrl:
        "https://jikejingji.oss-cn-guangzhou.aliyuncs.com/appClientStatic/other/swipe_2.png",
      key: 1,
    },
  ],
  notice:
    "本平台提供纯娱乐体验服务，禁止卖币/买币，违规账号系统将自行封控处理!请合理安排游戏时间!",
});

// 轮播图点击
const swipeItemClick = useThrottle((key, imageUrl) => {
  const urlName = getImageFileNameWithoutExtensionFromUrl(imageUrl);

  switch (urlName) {
    case "swipe_4":
      router.push("/welfareLobby");
      break;
    case "swipe_11":
      serverPupShowChange(true);
      break;
    case "swipe_12":
      router.push("/halloween");
      break;
    case "swipe_10":
      router.replace("/my");
      break;
  }
}, 1e3);

const noticeList = ref([]);
const lotteryList = ref([]);

// 播报
const getSysNoticeData = async () => {
  try {
    const res = await getSys();
    console.log("ress", res);

    if (res.data) {
      const noticeLista = [];

      for (const key in res.data.sys) {
        const item = res.data.sys[key];
        const obj = {
          userName: item.param.player_name,
          gameName: findGameInfoData(item.param.game_name).game_name,
          head: item.param.head || item.param.head_frame,
          machine_index: item.param.machine_index,
          uid: item.param.uid,
          content: "",
        };

        if (item.param.type == "collect") {
          obj.content = findCollectCardInfo(
            item.param.game_name,
            item.param.class_id
          ).cardName;
          noticeLista.push(obj);
          continue;
        }

        if (item.param.type == "tribe_rank") {
          item.param.type = item.param.game_name + item.param.type;
          let s_typeName = findStype(item.param.type);

          obj.content = `${s_typeName}第${item.param.index}名 ${item.param.reward}电玩币`;
          noticeLista.push(obj);
          continue;
        }

        if (item.param.type == "tiaozhan") {
          obj.content = ` ${item.param.tz_exchange}币房挑战赛挑战成功！`;
          noticeLista.push(obj);
          continue;
        }

        const rank_types = ["msqy_rank", "bird_rank"];
        if (rank_types.includes(item.param.type)) {
          let s_typeName = findStype(item.param.type);

          obj.content = `${s_typeName}第${item.param.index}名 ${item.param.reward}电玩币`;
          noticeLista.push(obj);
          continue;
        }
      }

      noticeList.value = noticeLista;
      const lotteryLista = [];
      console.log("res.data.game", res.data.game);

      for (const k in res.data.game) {
        const gameItem = res.data.game[k];
        const dataInfo = {
          userName: gameItem.param.player_name,
          gameName: findGameInfoData(gameItem.param.game_name).game_name,
          lottery: gameItem.param.lottery || gameItem.param.reward,
          machine_index: gameItem.param.machine_index || gameItem.param.index,
          head: gameItem.param.head || String(gameItem.param.head_frame),
        };

        Number(dataInfo.lottery > 0) && lotteryLista.push(dataInfo);
      }
      lotteryList.value = lotteryLista;
    }
  } catch (error) {
    console.log("errorerror", error);
  }
};

const calculateTimeMantissa = () => {
  if (store.send_marquee_config.show) {
    bannerData.notice = store.send_marquee_config.content;
    return false;
  }
  // return true;
  const currentTime = new Date(); // 创建一个表示当前日期和时间的对象
  const minutes = currentTime.getMinutes(); // 获取当前时间的分
  const arr = String(minutes).split("");
  const Mantissa = arr[arr.length - 1];
  return Number(Mantissa) < 5;
  // return true;
};

onBeforeMount(() => {
  getSysNoticeData();
  // banner/
  homeBanner({
    type: store.channelType == "IOS" ? "ios" : "android",
  }).then((res) => {
    if (res.banner) {
      bannerData.banner = res.banner;
      bannerData.notice = res.notice;
    }
  });
});
//#endregion

//#region // 在线头像人数
const userOnlineData = ref([]);

onBeforeMount(() => {
  userOnline().then((res) => {
    if (Array.isArray(res)) {
      const arr = res || [];

      arr.sort(() => Math.random() - 0.5);
      arr.length > 10 && (arr.length = 10);
      userOnlineData.value = arr;
    }
  });
});

//#endregion

//#region 签到
const _Sign = ref();
const handle_when_sign_click = () => {
  _Sign.value?.sign_show_toggle(true);
};

// 当滚轮关闭
const when_login_solts_close_monitoring_sign_statu = () => {
  // console.log("home1");
  _Sign.value?.monitoringState();
};

//#endregion
</script>

<style lang="scss" scoped>
.home {
  position: relative;
  min-height: 100%;
}

.er_block {
  position: fixed;
  right: 10px;
  bottom: 120px;

  width: 52px;
  // height: 208px;
  height: 104px;
  z-index: 99;

  .slots_entrance {
    position: relative;
    width: 52px;
    height: 52px;
    background-image: url("@/assets/images/slots/entrance.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;

    .slots_num {
      position: absolute;
      width: 100%;
      bottom: 6px;
      font-size: 8px;
      color: #97ff72;
      text-align: center;
    }
  }

  .find_vacancy_icon {
    position: relative;
    width: 52px;
    height: 52px;
    background-image: url("https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/global/home_p_icons2.png");
    background-size: 156px 52px;
    background-repeat: no-repeat;
    background-position: 0 0;
  }

  .update_icon {
    @extend .find_vacancy_icon;
    background-position: -52px 0;
  }

  .service_icon {
    @extend .find_vacancy_icon;
    background-position: -104px 0;
  }

  // .
}

.card_notify {
  position: relative;
  width: 100%;
  height: 440px;

  .card_notify_list {
    position: absolute;
    top: 190px;
    left: 50%;
    width: 230px;
    height: 100px;
    transform: translateX(-48%);

    display: flex;
    justify-content: center;
    .card_notify_item {
      width: 80px;
    }
  }
}

.notice-swipe {
  margin-top: 10px;
  height: 30px;
  line-height: 30px;

  .notice-swipe-item {
    // width: 300px;
    height: 100%;
    border-radius: 16px;
    background: linear-gradient(89deg, #a451ff 0%, #860789 100%);
    // overflow: hidden;
    display: flex;
    .notice_head {
      width: 26px;
      height: 26px;
    }
    .notice_text {
      margin-left: 4px;
      flex: 1;
      font-size: 12px;
      display: flex;
      letter-spacing: 1px;
      .lottery_style {
        font-weight: 1000;
        font-size: 14px;
        margin-left: 2px;

        background: linear-gradient(180deg, #fff1bd 0%, #ffcf44 100%);

        // text-shadow: 0px 2px 0px #ae6209;
        background-clip: text;
        -webkit-background-clip: text;

        -webkit-text-fill-color: transparent;
      }

      .lottery_icon {
        margin-left: 2px;
        width: 26px;
        height: 26px;
        background-image: url("https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/global/lottery.png");
        background-size: 100% 100%;
      }
    }
  }
}

.user_online_list_wrapper {
  width: 100%;
  margin-top: 4px;
  .online_list {
    padding: 6px 0;
    height: 100%;
    white-space: nowrap;
    text-wrap: nowrap;
    overflow-x: auto;
    overflow-y: hidden;
    white-space: nowrap;
    text-wrap: nowrap;

    &::-webkit-scrollbar {
      width: 0;
    }
    .online_item {
      position: relative;
      display: inline-block;
      width: 32px;
      height: 100%;
      // float: left;
      margin-right: 8px;

      .online_head_wrapper {
        position: relative;
        width: 32px;
        height: 32px;
        padding: 1px;
        border-radius: 16px;
        background-image: linear-gradient(
          180deg,
          rgba(127, 0, 255, 1),
          rgba(229, 71, 255, 1)
        );

        .online_head {
          width: 100%;
          height: 100%;
          border-radius: 16px;
          padding: 2px;
          background-color: #3a1b67;
        }

        .online_icon {
          position: absolute;
          bottom: 0px;
          left: 50%;
          transform: translateX(-50%);
          font-size: 8px;
          width: 100%;
          height: 14px;
          line-height: 14px;
          text-align: center;

          background: linear-gradient(90deg, #952bdd 0%, #6e1fc2 100%);
          border-radius: 8px;
          z-index: 12;
          span {
            display: inline-block;

            color: #fff;
          }
          .hot_icon {
            display: inline-block;
            width: 2px;
            height: 6px;
            border-radius: 2px;
            background-color: #fff;
            margin-right: 1px;
            transform: translateY(25%);
            animation: hotAinmeis 0.8s infinite;
            animation-direction: reverse;

            &:nth-child(1) {
              // animation: delay;
              animation-delay: 0.2s;
            }
            &:nth-child(2) {
              // animation: delay;
              animation-delay: 0.4s;
            }
            &:nth-child(3) {
              // animation: delay;
              animation-delay: 0.6s;
            }
          }
        }
      }
      .online_name {
        text-align: center;
        margin-top: 2px;
        font-size: 8px;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      &:last-child {
        // background: red;

        .more_icon_one,
        .more_icon_to,
        .more_icon_cc {
          position: absolute;
          display: block;
          width: 8px;
          height: 8px;

          border-radius: 4px;
          top: 50%;
          left: 0;
          transform: translateY(-50%);
          z-index: 333;
        }
        .more_icon_one {
          background-color: #cc36ff;
        }
        .more_icon_to {
          left: 50% !important;
          transform: translate(-50%, -50%);
          // background-color: transparent !important;
          background-image: linear-gradient(
            93deg,
            #cc36ff 0%,
            #cc36ff 70%,
            rgba(58, 27, 103, 0) 100%
          ) !important;
        }
        .more_icon_cc {
          left: auto !important;
          right: 0 !important;
          // background-color: transparent !important;
          background-image: linear-gradient(
            90deg,
            #cc36ff 0%,
            transparent 80%,
            transparent 100%
          );
        }
      }
    }
  }
}

.active_list_wrapper {
  margin-top: 6px;
  width: 100%;
  height: 152px;
  background-image: url("@/assets/images/active_bg.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  padding-top: 44px;
  padding-left: 14px;
  padding-right: 14px;
  // display: flex;
  // justify-content: space-between;
  .swiper_item_cotent {
    height: 100px;
  }

  // .active_swipe_item {
  //   width: 100%;
  //   height: 100%;
  //   display: flex;
  //   justify-content: space-between;
  //   .active_list_item {
  //     // flex: 1;
  //     width: 49%;
  //     height: 100px;

  //     .img_cover {
  //       width: 100%;
  //       height: auto;
  //       max-height: 84px;
  //     }
  //   }
  // }
}

@keyframes hotAinmeis {
  0% {
    height: 0px;
  }
  100% {
    height: 8px;
  }
}

:root:root {
  --van-notice-bar-padding: 0;
}
.user_info_wrapper {
  height: 60px;

  display: flex;
  align-items: center;
  justify-content: center;
  .user_info_left {
    width: 60px;
    height: 60px;
  }

  .user_info_content {
    flex: 1;
    height: 100%;
    position: relative;
    // padding-left: 10px;
    .user_name {
      position: absolute;
      left: 10px;
      bottom: 26px;
      font-size: 12px;
      font-weight: bold;
    }

    .user_level_wrapper {
      position: absolute;
      left: 10px;
      bottom: 6px;
      height: 14px;

      display: flex;
    }

    .wallet_lottery {
      position: absolute;
      right: 0;
      top: 15px;
    }
    .wallet_gold {
      position: absolute;
      right: 0;
      bottom: 0;
    }
  }
}
</style>
