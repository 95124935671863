<template>
  <div
    class="mxt_view_wrapper"
    id="rotate_box"
    @click="golbalClickEvent($event)"
  >
    <GameLoding v-if="!isReady" />

    <!-- 挑战红包 -->
    <LuckRedBag />

    <Player
      ref="_Player"
      :landscape="false"
      @ready-change="readyChange"
      :src="store.current_machine.sp_url"
    />

    <MenuLc
      ref="menud"
      @menuSoundClick="menuSoundClick"
      @handleExitEvent="handleExitEvent"
      @handle_update="handle_update_player"
      @handle_display="ui_control_show_toggle"
      @handle_toggle_room="toggleRoomShowToggle(true)"
    />

    <div class="wallet_wrapper">
      <UserWallet v-show="ui_control_show" />
      <UserWallet type="lottery" v-show="ui_control_show" />
    </div>

    <!-- <UserGold /> -->
    <GameRoom v-show="ui_control_show" />

    <!-- 时间 -->
    <ServerTime :right="15" :top="80" />

    <!-- 观战计时 -->
    <WatchTime
      :watch_time="Number(store.current_machine.visit_time)"
      @onFinish="exitGameRequest"
      v-if="store.gameIsWatch && isReady && !store.userInfo.is_gm"
    />

    <!-- 占位者 -->
    <div
      class="occupy_info_wrapper"
      v-if="
        store.gameIsWatch &&
        store.current_machine.occupy.length > 0 &&
        ui_control_show
      "
    >
      <div class="user_portrait">
        <HeadPortrait
          width="100%"
          height="100%"
          :src="
            store.current_machine.occupy[0].head ||
            String(store.current_machine.occupy[0].head_frame)
          "
          :level="Number(store.userInfo.recharge_level)"
        />
      </div>
      <div class="user_name">
        {{ decodeBase64String(store.current_machine.occupy[0].player_name) }}
        &nbsp;&nbsp;
        <span style="color: red; font-weight: bold" v-if="store.userInfo.is_gm"
          >UID: {{ store.current_machine.occupy[0].player_id }}</span
        >
      </div>
    </div>
    <!-- 围观者 -->
    <div
      class="visits_list_wrapper"
      v-if="store.current_machine.visits.length > 0 && ui_control_show"
    >
      <div class="visits_text_wrapper">围观：</div>

      <div
        class="visit_item_user_header"
        v-for="(visitsItem, vIndex) in store.current_machine.visits"
        :key="'visits_' + visitsItem.uid"
        :style="{
          transform: `translateX(-${vIndex * 25}%)`,
        }"
      >
        <HeadPortrait
          width="100%"
          height="100%"
          :ae_id="visitsItem.select_title"
          ae
          fe
          :src="visitsItem.head ? visitsItem.head : visitsItem.head_frame"
        />
      </div>
    </div>

    <TopInfo v-if="!store.gameIsWatch && isReady && ui_control_show" />

    <!-- 坐下-- -->
    <Seats @callback="handleSeatCallBack" v-if="store.gameIsWatch && isReady" />

    <!-- 控制台 -->
    <div class="tbj_control_wrapper" v-if="!store.gameIsWatch && isReady">
      <div class="num_coin_btn" @click="coin_up_num(1)" v-show="!coin_status.a">
        <van-image
          width="100%"
          height="auto"
          fit="fill"
          position="center"
          block
          v-show="!coin_status.l"
          :src="upcoin_1"
        />
        <van-image
          width="100%"
          height="auto"
          fit="fill"
          position="center"
          block
          v-show="coin_status.l"
          :src="upcoin_1_active"
        />
      </div>
      <div class="auto_coin_btn" @click="handle_auto_btn_click">
        <van-image
          width="100%"
          height="auto"
          fit="fill"
          position="center"
          block
          v-show="!coin_status.a"
          :src="upcoin_auto"
        />
        <van-image
          width="100%"
          height="auto"
          fit="fill"
          position="center"
          block
          v-show="coin_status.a"
          :src="upcoin_auto_active"
        />
      </div>
      <div
        class="num_coin_btn2"
        @click="coin_up_num(10)"
        v-show="!coin_status.a"
      >
        <van-image
          width="100%"
          height="auto"
          fit="fill"
          position="center"
          block
          v-show="!coin_status.r"
          :src="upcoin_10"
        />
        <van-image
          width="100%"
          height="auto"
          fit="fill"
          position="center"
          block
          v-show="coin_status.r"
          :src="upcoin_10_active"
        />
      </div>
    </div>

    <div class="count_down_time_wrapper">
      <div
        class="count_down_time_img"
        v-for="(
          count_down_time_item, count_down_time_index
        ) in count_down_time_arr"
      >
        <transition name="van-fade">
          <van-image
            v-show="mxtNoOperationBackCount - 1 == count_down_time_index"
            width="100%"
            height="auto"
            fit="fill"
            position="center"
            block
            :src="count_down_time_item"
          />
        </transition>
      </div>
    </div>

    <!-- 消息通知 -->
    <MyNoticeBar :top="20" left="auto" right="auto" />

    <!-- 推币任务 -->
    <transition name="van-fade">
      <Sprites
        :right="15"
        :top="140"
        :bg_index="3"
        @_click="coinPushTaskShowToggle(true)"
        :badge="store.coinPushTask.getCount"
        v-show="
          !coinPushTaskShow &&
          !store.gameIsWatch &&
          ui_control_show &&
          store.current_machine.product_name != 'yhtd' &&
          store.current_machine.product_name != 'cssxp' &&
          store.current_machine.product_name != 'hjj' &&
          store.current_machine.product_name != 'cbjl'
        "
      />
    </transition>

    <CollectHint />
    <CoinPushTask
      :show="coinPushTaskShow"
      ref="_CoinPush"
      @close_pup="coinPushTaskShowToggle(false)"
    />

    <Sprites
      :right="15"
      :top="260"
      :bg_index="store.current_machine.product_name == 'mxt' ? 4 : 8"
      @_click="store.storeShowToggle('dayRankShow', true)"
      v-show="
        ui_control_show &&
        (store.current_machine.product_name == 'mxt' ||
          store.current_machine.product_name == 'msqy')
      "
    />

    <Sprites
      :right="15"
      :top="200"
      :bg_index="0"
      @_click="store.storeShowToggle('gemShopShow', true)"
      v-show="
        ui_control_show &&
        store.current_machine.product_name !== 'yhtd' &&
        store.current_machine.product_name !== 'cssxp' &&
        store.current_machine.product_name != 'hjj' &&
        store.current_machine.product_name != 'cbjl'
      "
    />
    <WheelEntrance :right="15" :top="320" v-show="ui_control_show" />

    <Sprites
      :right="15"
      :top="400"
      :bg_index="2"
      @_click="handleMxtWiperEnd"
      v-show="ui_control_show && !store.gameIsWatch"
    />

    <!-- 马戏团无操作返回 -->
    <div
      class="operation_timer_back_wrapper"
      v-if="!store.gameIsWatch && isReady"
    >
      <van-circle
        :style="{
          position: 'relative',
          zIndex: '2',
        }"
        v-model:current-rate="currentRate"
        rate="100"
        stroke-width="100"
        color="#A1EF5A"
        layer-color="#757A71"
        size="100%"
        text=""
      />
      <div class="operation_timer_bg"></div>
      <div class="operation_timer">
        <span class="timer_number">{{ mxtNoOperationBackCount }}s</span>
      </div>
    </div>

    <!-- 收集提示 -->
    <CollectGemHint />
    <!-- 彩票提示 -->
    <TbjGetLottery @getCallback="mxtNoOperationBackCountReset" />

    <SprogTaskEntrance
      :left="15"
      :top="220"
      v-show="ui_control_show && store.userInfo.register_time"
    />

    <!-- 设置自动投币速度 -->
    <van-popup
      v-model:show="setAutoSpeedValueShow"
      round
      :close-on-click-overlay="false"
    >
      <div class="logout_wrapper">
        <p class="logout_title">设置自动投币速度</p>
        <div class="logout_content">
          <div class="number_s">
            <div
              class="number_item"
              v-for="autoSpeedItem in 3"
              @click="controlProgress(autoSpeedItem)"
            >
              {{ autoSpeedItem }}次/秒
            </div>
          </div>

          <div
            class="number_bar_wrapper"
            ref="bar_wrappper"
            @touchstart="handleSliderTouchStart"
            @touchmove="handleSliderTouchMove"
            @touchend="handleSliderTouchEnd"
          >
            <div class="slider_active_bar" ref="s_active">
              <div class="slider_block" ref="s_block"></div>
            </div>
            <div class="speed_wrapper">
              <div
                class="speed_item"
                v-for="autoSpeedItem in 3"
                ref="speed_item_d"
                @click="controlProgress(autoSpeedItem)"
              ></div>
            </div>
          </div>
        </div>
        <div class="logout_btns">
          <div class="logout_btn" @click="setAutoSpeedValueShowToggle(false)">
            取消
          </div>
          <div
            class="logout_btn"
            style="
              background-image: linear-gradient(
                90deg,
                #ed81ff 0%,
                #5034ff 100%
              );
            "
            @click="confirmStartAuto"
          >
            开启自动
          </div>
        </div>
      </div>
    </van-popup>

    <!-- 游戏日榜 -->
    <DayRank />

    <ToggleGameRoom
      :show="toggleRoomShow"
      :game_name="
        store.current_machine.child_name || store.current_machine.product_name
      "
      @close_pup="toggleRoomShowToggle(false)"
      @toggle_room="handleToggleRoom"
    />
  </div>
</template>

<script setup>
import GameLoding from "@/components/GameLoding.vue";
import Player from "@/components/Player.vue";
import MenuLc from "@/components/MenuLc.vue";
import WatchTime from "@/components/WatchTime.vue";
import GameRoom from "@/components/GameRoom.vue";
import HeadPortrait from "@/components/HeadPortrait.vue";
import Seats from "@/components/Seats.vue";
import MyNoticeBar from "@/components/MyNoticeBar.vue";
import TopInfo from "@/components/TopInfo.vue";
import WheelEntrance from "@/components/WheelEntrance.vue";
import SprogTaskEntrance from "@/components/SprogTaskEntrance.vue";
import Sprites from "@/components/Sprites.vue";
import DayRank from "@/components/DayRank.vue";
import CollectGemHint from "@/components/CollectGemHint.vue";
import UserWallet from "@/components/UserWallet.vue";
import ServerTime from "@/components/ServerTime.vue";
import LuckRedBag from "@/components/LuckRedBag.vue";
import TbjGetLottery from "@/components/TbjGetLottery.vue";
import ToggleGameRoom from "@/components/ToggleGameRoom.vue";
import CoinPushTask from "@/components/CoinPushTask.vue";
import CollectHint from "@/components/CollectHint.vue";

import { controlRequest } from "@/api";
import { sendInstructions } from "@/api/sendData";
import { useStore } from "@/store/store";
import { useRouter } from "vue-router";
import { usePolling, useThrottle } from "@/hooks";
import { decodeBase64String } from "@/utils/utils";
import { showToast } from "vant";
import { useToggle, usePageVisibility } from "@vant/use";
import {
  ref,
  onBeforeMount,
  onBeforeUnmount,
  reactive,
  watch,
  nextTick,
  onMounted,
} from "vue";

import upcoin_1 from "@/assets/images/control/upcoin_1.png";
import upcoin_1_active from "@/assets/images/control/upcoin_1_active.png";
import upcoin_10 from "@/assets/images/control/upcoin_10.png";
import upcoin_10_active from "@/assets/images/control/upcoin_10_active.png";

import upcoin_auto from "@/assets/images/control/upcoin_auto.png";
import upcoin_auto_active from "@/assets/images/control/upcoin_auto_active.png";

const router = useRouter();
const store = useStore();

const count_down_time_1 = store.imgBaseUrl + "/mxt/count_down_time_1.png";
const count_down_time_2 = store.imgBaseUrl + "/mxt/count_down_time_2.png";
const count_down_time_3 = store.imgBaseUrl + "/mxt/count_down_time_3.png";
const count_down_time_4 = store.imgBaseUrl + "/mxt/count_down_time_4.png";
const count_down_time_5 = store.imgBaseUrl + "/mxt/count_down_time_5.png";
const count_down_time_6 = store.imgBaseUrl + "/mxt/count_down_time_6.png";

//#region 数据同步
const polling = usePolling(() => {
  sendInstructions({
    msg_type: "get_machine_info",
    game_name: store.current_machine.product_name,
  });
});

// onMounted(() => {
//   polling.start();
// });

onBeforeUnmount(() => {
  polling.stop();
});
//#endregion

const pageVisibility = usePageVisibility();
watch(
  () => pageVisibility,
  (value) => {
    console.log("visibility: ", value);
  }
);

console.log("visibilit2y: ", pageVisibility.value);

//#region 播放器
const _Player = ref(null);
const isReady = ref(false);

const readyChange = useThrottle((statu) => {
  isReady.value = statu;

  if (statu) {
    setTimeout(() => {
      polling.reset(() => {
        sendInstructions({
          msg_type: "get_machine_info",
          game_name: store.current_machine.product_name,
        });
      });

      polling.start();
    }, 15e2);
  }

  // if (statu) {
  //   sendInstructions(
  //     {
  //       msg_type: "enter_machine",
  //       game_name: store.current_machine.product_name,
  //       machine_index: store.current_machine.machine_index,
  //     },
  //     200
  //   );
  // }
});

const handle_update_player = () => {
  _Player.value?.handlePlayUpdata();
};

//#endregion

const count_down_time_arr = [
  count_down_time_1,
  count_down_time_2,
  count_down_time_3,
  count_down_time_4,
  count_down_time_5,
  count_down_time_6,
];

// 坐下回调
const handleSeatCallBack = () => {
  mxtNoOperationBackCountStart();
  _CoinPush.value?.initCoinPushTaskData();
};

//#region 菜单
const menud = ref();
// 声音
const menuSoundClick = (statu) => {
  _Player.value && _Player.value.videoMuted(statu);
};
// 退出

// 退分/退出观战 协议
const exitGameRequest = (type = "visit") => {
  const backType = type;

  // 退出观战
  if (backType == "visit") {
    const leave_params = {
      msg_type: "leave_machine",
      game_name: store.current_machine.product_name,
      machine_index: store.current_machine.machine_index,
    };
    sendInstructions(leave_params, 0);
    if (store.channelType == "IOS") {
      sendInstructions(leave_params, 50);
      sendInstructions(leave_params, 100);
    }
  }
  // 退分
  if (backType == "exit") {
    // 退分 params
    const params = {
      msg_type: "return_lottery",
      game_name: store.current_machine.product_name,
      machine_index: store.current_machine.machine_index,
      device_name: store.current_device.device_name,
    };

    // 第一次
    sendInstructions(params, 0);
    console.log("退分2");
    // 第二次
    sendInstructions(params, 1000);
    console.log("退分3");
    // 第三次
    sendInstructions(params, 1800);
    console.log("退分4");
  }
};

// 退出游戏重置
const exitRoom = () => {
  store.initStatu();
  router.replace({
    name: "home",
  });
};

//#endregion

// 退出事件
const handleExitEvent = () => {
  // 观战直接退
  if (store.gameIsWatch) {
    isReady.value && exitGameRequest();
    router.replace("/home");

    return;
  }

  // 结算退出
  exitGameRequest("exit");
  if (store.mxtHintDataNumberCount > 0) {
    menud.value && menud.value.exitPupShowToggle(false);
    window.set_data({
      msg_type: "return_lottery_result",
      info: {
        lottery: store.mxtHintDataNumberCount,
        game_name: store.current_machine.product_name,
        machine_index: store.current_machine.machine_index,
        device_name: store.current_device.device_name,
      },
    });
  } else exitRoom();
};

//#region 操作
const mxt_funtion_btns = reactive({
  coin: false,
  coin2: false,
  autoCoin: false,
  wiper: false,
});

// 马戏团无操作返回
const mxtNoOperationBackCount = ref(90);
const MxtBackTime = 90; // 无操作的时间

// 雨刷

const handleMxtWiperStart = (event) => {
  console.log("event", event);

  const target = event.target;
  target.style.transform = `scale(${1.2})`;
};

const handleMxtWiperEnd = () => {
  if (mxt_funtion_btns.wiper) return;
  // const target = evt.target;
  // target.style.transform = `scale(${1})`;
  mxt_funtion_btns.wiper = true;
  const params = {
    device: store.current_device.device_name,
    product_id: store.current_machine.product_id,
    channel: 5,
    type: 2,
    times: 1,
    width: 50,
    interval: 100,
    uid: parseInt(store.userInfo.uid),
  };

  setTimeout(() => {
    mxt_funtion_btns.wiper = false;
  }, 2100);

  controlRequest(params);
};

//#endregion

//#region 投币
const coin_status = reactive({
  l: false, // left 1
  a: false, // auto
  r: false, // right 10
});

// 固定次數投幣
const coin_up_num = async (num) => {
  const _key = num == 1 ? "l" : "r";
  const _sTime = num == 1 ? 1e3 : 3e3;
  if (num == 1 && coin_status[_key]) return;
  if (num == 10 && coin_status[_key]) return;

  coin_status[_key] = true;
  coin_status.a = false;
  await coin_up_request(num);
  mxtNoOperationBackCountReset();
  setTimeout(() => {
    coin_status[_key] = false;
  }, _sTime);
};

// 投币请求
let coin_timer = null;
const coin_up_request = async (num) => {
  const params = {
    msg_type: "up_coin",
    machine_index: store.current_machine.machine_index,
    game_name: store.current_machine.product_name,
    device_name: store.current_device.device_name,
    up_num: num,
  };

  // 幣量不足
  if (
    Number(store.userInfo.gold) <
    Number(store.current_machine.up_coin) * num
  ) {
    coin_status.a = false;
    showToast({
      type: "fail",
      message: "币量不足\n请先充值",
      onClose() {
        store.payPupToggle(true);
      },
    });
    return;
  }

  if (store.await_up_coin_result) return;

  store.$patch((state) => {
    state.await_up_coin_result = true;
  });

  coin_timer && clearTimeout(coin_timer);
  coin_timer = setTimeout(() => {
    store.await_up_coin_result && (store.await_up_coin_result = false);
  }, 5000);

  sendInstructions(params);

  console.log("now", Date.now());

  console.log("store.await_up_coin_result", store.await_up_coin_result);

  // try {
  //   const res = await asyncSendInstructions(params, 5e3);

  //   if (res.error === "ok") {
  //     store.$patch((state) => {
  //       state.userInfo.gold =
  //         Number(state.userInfo.gold) - Number(res.dec_gold);
  //     });
  //   } else {
  //     coin_status.a = false;
  //     showToast({
  //       type: "fail",
  //       message: "请稍后重试",
  //     });
  //   }
  // } catch (error) {
  //   sendInstructions(params);
  //   // coin_status.a = false;
  //   // showToast({
  //   //   type: "fail",
  //   //   message: "请稍后重试",
  //   // });
  // }
  // 自动投币
  if (coin_status.a) {
    setTimeout(() => {
      coin_up_request(autoSpeed.value);
    }, 15e2);
  }
};

// 自动
const [setAutoSpeedValueShow, setAutoSpeedValueShowToggle] = useToggle(false);
const autoSpeed = ref(1);
const handle_auto_btn_click = () => {
  if (coin_status.a) {
    coin_status.a = false;
  } else {
    setAutoSpeedValueShowToggle(true);
  }
};

// 控制进度  自动投币的进度条
const s_block = ref();
const s_active = ref();
const bar_wrappper = ref();
const speed_item_d = ref();
const controlProgress = (index) => {
  if (!s_block.value || !s_active.value || !bar_wrappper.value) return;
  let _w = 0;
  if (index == 1) _w = 0;
  if (index == 2) _w = 50;
  if (index == 3) _w = 100;

  s_active.value.style.width = `${_w}%`;

  autoSpeed.value = index;
};
let isCorrectTarget = false;
let silderStartX = 0;
let silderEndX = 0;
const handleSliderTouchStart = (evt) => {
  const target = evt.target;
  isCorrectTarget =
    target.classList.contains("slider_block") ||
    target.className.indexOf("slider_block") > -1;

  if (
    speed_item_d.value &&
    (evt.target.classList.contains("slider_active_bar") ||
      target.className.indexOf("slider_active_bar") > -1)
  ) {
    const cX = evt.touches[0].clientX;
    speed_item_d.value.some((dom, domIndex) => {
      const { x, width } = dom.getBoundingClientRect();
      const sX = x;
      const eX = sX + width;
      if (cX >= sX && cX < eX) {
        controlProgress(domIndex + 1);
        return true;
      } else return false;
    });
  }

  if (!isCorrectTarget) return;
  silderStartX = evt.touches[0].clientX;
};
const handleSliderTouchMove = (evt) => {
  if (!isCorrectTarget) return;
  silderEndX = evt.touches[0].clientX - silderStartX;
};
const handleSliderTouchEnd = (evt) => {
  if (!isCorrectTarget) return;

  if (silderEndX > 25) {
    controlProgress(autoSpeed.value == 1 ? 2 : 3);
  } else if (silderEndX < -25) {
    controlProgress(autoSpeed.value == 3 ? 2 : 1);
  }
};

// 确认开启自动
const confirmStartAuto = () => {
  setAutoSpeedValueShowToggle(false);
  coin_status.a = true;
  coin_up_request(autoSpeed.value);
};

watch(
  () => coin_status.a,
  (newV) => {
    if (newV) {
      mxtNoOperationBackCountReset();
      mxtNoOperationBackCountEnd();
    } else mxtNoOperationBackCountStart();
  }
);

//#endregion

//#region
// 监听马戏团退票信息
const currentRate = ref(100);

let mxtNoOperationBackTimer = null;

const mxtNoOperationBackCountStart = () => {
  mxtNoOperationBackCountEnd();
  mxtNoOperationBackTimer = setInterval(() => {
    mxtNoOperationBackCount.value = mxtNoOperationBackCount.value - 1;

    currentRate.value = parseInt(
      (mxtNoOperationBackCount.value / MxtBackTime) * 100
    );

    if (mxtNoOperationBackCount.value - 1 < 0) {
      handleExitEvent();
      mxtNoOperationBackCountEnd();
    }
  }, 1000);
};

const mxtNoOperationBackCountReset = () => {
  mxtNoOperationBackCount.value = MxtBackTime;
};
const mxtNoOperationBackCountEnd = () => {
  mxtNoOperationBackTimer != null && clearInterval(mxtNoOperationBackTimer);
};

//#endregion

//#region 每日推币任务
const [coinPushTaskShow, coinPushTaskShowToggle] = useToggle(false);
const _CoinPush = ref();
//#endregion

onBeforeUnmount(() => {
  mxtNoOperationBackCountEnd();
  if (coin_status.a) {
    coin_status.a = false;
  }
});

// 全局点击
const golbalClickEvent = (evt) => {
  evt.stopPropagation && evt.stopPropagation();
  evt.preventDefault && evt.preventDefault();
  menud.value && menud.value.menuOpenStatu && menud.value.menuStatuToggle(evt);
};

//#region 部分ui控制
const ui_control_show = ref(true);
let ui_flag = false;
const ui_control_show_toggle = () => {
  if (ui_flag) return;
  ui_flag = true;

  ui_control_show.value = !ui_control_show.value;
  setTimeout(() => {
    ui_flag = false;
  }, 5e2);
};

//#endregion

//#region 更换房间
const [toggleRoomShow, toggleRoomShowToggle] = useToggle(false);
const handleToggleRoom = (src) => {
  coinPushTaskShow.value = false;
  isReady.value = false;
  mxtNoOperationBackCountReset();
  mxtNoOperationBackCountEnd();
  toggleRoomShowToggle(false);
  _Player.value?.initPlayerVideo(src);
};

//#endregion
</script>

<style scoped lang="scss">
.mxt_view_wrapper {
  position: relative;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  .occupy_info_wrapper {
    position: absolute;
    top: 50px;
    left: 15px;
    background-color: rgba($color: #000, $alpha: 0.4);
    border-radius: 40px;
    display: flex;
    .user_portrait {
      width: 50px;
      height: 50px;
    }
    .user_name {
      //   width: ;
      line-height: 50px;
      padding: 0 10px;
    }
  }
}

.wallet_wrapper {
  position: absolute;
  left: 15px;
  top: 140px;
  height: 60px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.tbj_control_wrapper {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 80px;
  z-index: 4;
  // display: flex;
  padding: 0 12px;
  // justify-content: space-between;
  .num_coin_btn {
    position: absolute;
    top: 50%;
    left: 12px;
    width: 85px;
    height: 50px;
    transform: translateY(-50%);
  }

  .num_coin_btn2 {
    @extend .num_coin_btn;
    left: auto;
    right: 12px;
  }

  .auto_coin_btn {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 156px;
    height: 66px;
  }
}

.logout_wrapper {
  width: 90vw;
  padding-top: 20px;
  padding-bottom: 20px;
  // height: 200px;
  background: linear-gradient(180deg, #bbd8f9 0%, #ffffff 58%, #eef6f9 100%);
  text-align: center;

  .logout_title {
    font-size: 22px;
    background: linear-gradient(90deg, #3967ff 0%, #7091ff 44%, #8c85ff 100%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-weight: 1000;
  }

  .logout_content {
    margin-top: 20px;

    font-size: 16px;
    margin-bottom: 34px;
    padding: 0 26px;

    .number_s {
      width: 100%;
      display: flex;
      .number_item {
        flex: 1;
        color: #6843ff;
        font-size: 14px;
        font-weight: 1000;
        &:nth-child(1) {
          text-align: left;
        }
        &:nth-child(3) {
          text-align: right;
        }
      }
    }

    .number_bar_wrapper {
      position: relative;
      margin-top: 10px;
      width: 100%;
      height: 14px;
      border-radius: 8px;
      background-color: #c8c8fd;
      box-shadow: 0px 0px 4px 1px rgba(41, 9, 172, 0.4);
      // overflow: hidden;

      .slider_active_bar {
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        border-radius: 8px;
        width: 0%;
        background-color: #6843ff;
        z-index: 10;
        transition: width 0.5s;
        .slider_block {
          background-color: #d977ff;
          width: 22px;
          height: 22px;
          position: absolute;
          right: 0;
          transform: translateX(50%);
          top: -4px;
          transition: transform 0.4s;
          z-index: 5;
          border-radius: 12px;
        }
      }

      .speed_wrapper {
        position: relative;
        z-index: 3;
        width: 100%;
        height: 100%;
        border-radius: 8px;
        display: flex;
        overflow: hidden;
        .speed_item {
          flex: 1;
        }
      }
    }
  }

  .logout_btns {
    display: flex;
    justify-content: center;
    .logout_btn {
      width: 40%;
      height: 40px;
      background: linear-gradient(180deg, #81c3ff 0%, #5034ff 100%);
      margin-right: 10px;
      line-height: 40px;
      border-radius: 20px;
    }
  }
}

.operation_timer_back_wrapper {
  position: absolute;
  right: 20px;
  bottom: 92px;
  z-index: 3;
  width: 52px;
  height: 52px;
  padding: 4px;
  // background-color: orange;
  background-image: url("https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/mxt/operation_timer_bg.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  border-radius: 50%;

  .operation_timer {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 4;
    font-size: 16px;
    font-weight: bold;

    .timer_number {
      display: inline-block;
      background: radial-gradient(ellipse, #f5f35b, #f2a03f);
      background-clip: text;
      -webkit-background-clip: text;
      color: transparent;
      text-shadow: 2px 2px 4px rgba($color: #9e233f, $alpha: 0.6);
      // text-stroke: 2px #f5f35b;
      // -webkit-text-stroke: 2px #f5f35b;
    }
  }

  .operation_timer_bg {
    position: absolute;
    left: 50%;
    top: 50%;
    width: 80%;
    height: 80%;
    background-color: #9843e5;
    transform: translate(-50%, -50%);
    border-radius: 50%;
    z-index: 1;
  }
}

// 倒计时
.count_down_time_wrapper {
  position: absolute;
  left: 50%;
  top: 42%;
  width: 190px;
  height: 190px;
  transform: translate(-50%, -50%);
  z-index: 3;
  .count_down_time_img {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }
}

// 围观者
.visits_list_wrapper {
  // display: flex;
  z-index: 4;
  position: absolute;
  top: 92px;
  right: 15px;
  // width: 110px;
  height: 26px;
  padding: 0;
  margin: 0;
  display: flex;

  .visits_text_wrapper {
    height: 100%;
    line-height: 26px;
  }
  .visit_item_user_header {
    width: 26px;
    height: 100%;
    border-radius: 50%;
  }
}
</style>
