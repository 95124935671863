<template>
  <van-overlay :show="store.dayRankShow" z-index="2020" :lock-scroll="false">
    <div
      class="day_rank_wrapper"
      :style="{
        height: _autoH,
      }"
      @click.stop
    >
      <div class="day_rank_title">
        <div class="title_img">
          <van-image
            :src="title_imgs[store.current_machine.product_name]"
            :show-loading="false"
            width="100%"
            height="100%"
          />
        </div>
      </div>

      <div
        class="close_icon"
        @click="store.storeShowToggle('dayRankShow', false)"
      ></div>

      <div class="day_rank_up_data_time">
        {{
          store.current_machine.product_name == "duobao"
            ? "每周一 00:00 更新排行"
            : "每日 00:00 更新排行"
        }}
      </div>

      <div class="day_rank_container">
        <div class="day_rank_list_head">
          <div class="rank_num">排名</div>
          <div class="rank_user">玩家</div>
          <div class="rank_score">成绩</div>
        </div>
        <div class="day_rank_list_body">
          <div class="day_rank_list">
            <div
              class="rank_item"
              v-for="(dayRankItem, dayRankNum) in dayRankList"
            >
              <div class="rank_num">
                <div class="rank_top_num_icon" v-if="dayRankNum <= 2">
                  <van-image
                    :src="top_num_icon[dayRankNum + 1]"
                    :show-loading="false"
                    width="100%"
                    height="auto"
                  />
                </div>

                <div class="rank_num_icon" v-else>
                  <span>{{ dayRankNum + 1 }}</span>
                </div>
              </div>

              <div class="rank_user_head">
                <HeadPortrait
                  fe
                  ae
                  :level="dayRankItem.recharge_level"
                  :ae_id="dayRankItem.select_title"
                  :src="dayRankItem.head"
                />
              </div>
              <div class="rank_user_info">
                <div class="rank_user_name">
                  {{ dayRankItem.player_name }}
                </div>
                <div class="rank_level_wrapper">
                  <Level
                    level_type="recharge_level"
                    :level_value="dayRankItem.recharge_level"
                    :height="18"
                    style="margin-right: 6px"
                    :border="false"
                  />

                  <!-- <Level
                    :level_value="dayRankItem.level"
                    :height="18"
                    :border="false"
                  /> -->
                </div>
              </div>

              <div class="rank_user_score">
                <div class="rank_score">
                  <div class="rank_score_value">{{ dayRankItem.num }}</div>
                  <div
                    class="rank_score_icon"
                    v-if="
                      store.current_machine.product_name == 'mgc' ||
                      store.current_machine.product_name == 'duobao'
                    "
                  >
                    <van-image
                      :src="score_icons[store.current_machine.product_name]"
                      :show-loading="false"
                      width="100%"
                      height="100%"
                    />
                  </div>
                </div>
                <div class="rank_reward">
                  <div class="rank_reward_value">{{ dayRankItem.reward }}</div>
                  <div class="rank_reward_icon"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </van-overlay>
</template>

<script setup>
import { useStore } from "@/store/store";
import { computed, reactive, ref, watch } from "vue";
import { decodeBase64String } from "@/utils/utils";
import { asyncSendInstructions, sendInstructions } from "@/api/sendData";

import HeadPortrait from "@/components/HeadPortrait.vue";
import Level from "@/components/Level.vue";
const store = useStore();

const placing1 = store.imgBaseUrl + "/rank/placing1.png";
const placing2 = store.imgBaseUrl + "/rank/placing2.png";
const placing3 = store.imgBaseUrl + "/rank/placing3.png";

const top_num_icon = {
  1: placing1,
  2: placing2,
  3: placing3,
};

const title_imgs = {
  mgc: "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/rank/day_rank_mgc.png",
  mxt: "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/rank/day_rank_mxt.png",
  bbl: "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/rank/day_rank_bbl.png",
  hw3: "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/rank/day_rank_hw3.png",
  msqy: "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/rank/day_rank_msqy.png",
  duobao:
    "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/rank/day_rank_duobao.png",
};

const score_icons = {
  duobao:
    "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/game/duobao_zhihui.png",
  mgc: "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/game/nangua_icon.png",
};

const emits = defineEmits(["closeIconClick"]);

const _autoH = computed(() => {
  return store.isLandscape ? "8rem" : "12.1067rem";
});

const dayRankList = ref([]);

const get_day_rank_data = async () => {
  const game_name = store.current_machine.product_name;

  let _type = game_name == "mgc" ? "nangua" : game_name;

  if (_type == "duobao") {
    get_tribe_rank_data();
    return;
  }

  try {
    const res = await asyncSendInstructions({
      msg_type: "get_nangua_rank",
      type: _type,
    });

    const { rank_data, collect_def } = res;

    const initRankList = [];

    const _gK = game_name == "mgc" ? "nangua_reward" : game_name + "_reward";

    rank_data.forEach((item, index) => {
      const iniRankItem = {
        ...item,
        player_name: decodeBase64String(item.player_name),
        reward: 0,
        head: item.head || item.head_frame,
      };

      if (collect_def[game_name][_gK][index]) {
        iniRankItem.reward = collect_def[game_name][_gK][index];
      }

      initRankList.push(iniRankItem);
    });

    dayRankList.value = initRankList;

    console.log("initRankListinitRankList", initRankList);
  } catch (error) {
    console.log("errorerror", error);
  }
};

const get_tribe_rank_data = async (game_name, type) => {
  const params = {
    msg_type: "get_tribe_rank",
    game_name: "duobao",
    type: "rank",
  };

  try {
    const res = await asyncSendInstructions(params);

    const { rank, tribe_rank_def } = res;

    const initRankList = [];
    if (Array.isArray(rank)) {
      rank.forEach((item, index) => {
        const iniRankItem = {
          ...item,
          player_name: decodeBase64String(item.player_name),
          reward: 0,
          head: item.head || item.head_frame,
        };

        if (tribe_rank_def.zhihui[index]) {
          iniRankItem.reward = tribe_rank_def.zhihui[index];
        }
        initRankList.push(iniRankItem);
      });
    }
    dayRankList.value = initRankList;
  } catch (error) {
    console.log("errrr", error);
  }
};

watch(
  () => store.dayRankShow,
  (newV) => {
    if (newV) {
      get_day_rank_data();
    }
  }
);
</script>

<style lang="scss" scoped>
.day_rank_wrapper {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -46%);
  width: 336px;
  background-image: url("https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/rank/day_rank_bg.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  padding: 40px 18px 20px 18px;

  .close_icon {
    position: absolute;
    width: 40px;
    height: 40px;
    right: -10px;
    top: -10px;
    background-image: url("https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/global/close_icon_orange.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    z-index: 33;
  }

  .day_rank_title {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%, -40px);
    width: 278px;
    height: 70px;
    background-image: url("https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/rank/day_rank_titile_bg.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;

    .title_img {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      width: 90px;
      height: 44px;
    }
  }

  .day_rank_up_data_time {
    position: absolute;
    left: 0;
    top: 20px;
    // background-color: skyblue;
    width: 100%;
    height: 14px;
    line-height: 14px;
    text-align: center;
  }

  .day_rank_container {
    height: 100%;
    display: flex;
    flex-direction: column;
    .day_rank_list_head {
      height: 20px;
      border-radius: 10px;
      background: linear-gradient(180deg, #d09e68 0%, #ce9c66 100%);
      margin-bottom: 10px;
      display: flex;
      color: #865534;
      justify-content: space-around;
      font-weight: bold;
      // .rank_num {
      // }

      // .rank_user {
      // }

      // .rank_score {
      // }
    }
    .day_rank_list_body {
      // height: ;
      flex: 1;
      border-radius: 10px;
      overflow: hidden;
      .day_rank_list {
        width: 100%;
        height: 100%;
        overflow: auto;

        .rank_item {
          position: relative;
          width: 100%;
          height: 50px;
          // background: linear-gradient( 180deg, #FCECD3 0%, #F4DCB3 100%);

          &:nth-child(even) {
            background: linear-gradient(180deg, #ffdfad 0%, #f5c26b 100%);
          }
          &:nth-child(odd) {
            background: linear-gradient(180deg, #fcecd3 0%, #f4dcb3 100%);
          }

          .rank_num {
            position: absolute;
            left: 26px;
            top: 50%;
            width: 20px;
            transform: translateY(-50%);

            .rank_top_num_icon {
              width: 100%;
            }

            .rank_num_icon {
              width: 100%;
              line-height: 20px;
              text-align: center;
              font-size: 14px;
              background: linear-gradient(
                180deg,
                #865534 0%,
                rgba(134, 85, 52, 0) 100%
              );
              border-radius: 50%;
            }
          }

          .rank_user_head {
            position: absolute;
            left: 70px;
            top: 50%;
            width: 35px;
            height: 35px;
            transform: translate(0, -50%);
          }

          .rank_user_info {
            position: absolute;
            left: 112px;
            top: 50%;
            // width: 35px;
            // height: 35px;
            max-width: 110px;
            // background-color: pink;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            transform: translate(0, -50%);
            // background-color: pink;
            .rank_user_name {
              font-size: 12px;
              color: #865534;
            }
            // .rank_level_wrapper{

            // }
          }

          .rank_user_score {
            position: absolute;
            right: 18px;
            display: flex;
            height: 100%;
            justify-content: center;
            flex-direction: column;
            .rank_score {
              display: flex;
              height: 16px;
              color: #865534;
              font-weight: bold;
              justify-content: end;
              .rank_score_value {
                line-height: 16px;
              }
              .rank_score_icon {
                width: 16px;
                height: 16px;
              }
            }

            .rank_reward {
              display: flex;
              height: 16px;
              justify-content: end;

              .rank_reward_value {
                line-height: 16px;
                color: #f9712b;
              }
              .rank_reward_icon {
                width: 16px;
                height: 16px;
                background-image: url("https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/global/gold.png");
                background-size: 100% 100%;
                background-repeat: no-repeat;
              }
            }
          }

          // .rank_user_info{
          //   position: absolute;
          //   top:
          // }
        }
      }
    }
  }
}

.pumpkin_rank_wrapper {
  width: 100%;
  min-height: 300px;
  padding: 0 10px 32px 10px;
  position: relative;

  .rank_bottom_icon_left {
    position: absolute;
    bottom: 0;
    left: -6px;
    width: 60px;
    height: 60px;
    background-image: url("https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/rank_bottom_left_icon.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
  }
  .rank_bottom_icon_right {
    position: absolute;
    bottom: 0;
    right: -6px;
    width: 60px;
    height: 60px;
    background-image: url("https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/rank_bottom_right_icon.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
  }
  .top_image_wrapper {
    position: absolute;
    left: 50%;
    top: 0;
    transform: translate(-50%, -56%);
    width: 240px;
  }

  .rank_list_wrapper {
    width: 100%;
    padding: 110px 18px 18px 18px;
    // border: 2px solid #ffc8a4;
    border-radius: 8px;

    background-image: url("https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/rank_day_bg.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;

    .pup_close_icon {
      position: absolute;
      right: 0;
      top: 0;
      transform: translate(0px, -10px);
      width: 34px;
      height: 34px;
      background-image: url("https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/pup_close2.png");
      background-size: 100% 100%;
      background-repeat: no-repeat;
    }

    .rank_title_wrapper {
      height: 32px;
      line-height: 32px;
      display: flex;
      text-align: center;
      background: linear-gradient(180deg, #d09e68 0%, #ce9c66 100%);
      border-radius: 20px;
      position: relative;
      .rank {
        width: 50px;
      }
      .player_info {
        flex: 1;
      }
      .reward {
        width: 80px;
      }

      &::after {
        content: "每日 0:00 名次更新";
        position: absolute;
        font-size: 10px;
        left: 50%;
        top: 0;
        color: #fff;
        transform: translate(-50%, -80%);
      }
    }

    .rank_list_content_wrapper {
      margin-top: 10px;
      border-radius: 8px;
      height: 310px;
      overflow: auto;

      .rank_list_item {
        display: flex;
        // min-height: 60px;
        padding: 10px 0;
        .rank {
          position: relative;
          width: 50px;

          .rank_icon {
            width: 20px;
            height: auto;
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
          }
          .rank_text {
            width: 20px;
            height: 20px;
            height: auto;
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            border-radius: 50%;

            background: linear-gradient(180deg, #59cef1 0%, #0a3e8d 100%);

            border: 1px solid #f5deb8;
            text-align: center;
            line-height: 20px;
            font-size: 14px;
          }
        }
        .player_info {
          flex: 1;
          display: flex;
          // flex-direction: column;
          justify-content: start;
          align-items: center;
          padding-left: 28px;
          .player_info_content {
            display: flex;
            flex: 1;
            .player_info_head {
              height: 38px;
              width: 38px;
            }
            .player_info_name_and_grade {
              padding-left: 10px;
              flex: 1;
              .user_name {
                font-size: 12px;
                color: #865534;
              }
              // .user_grade {}
            }
          }
        }
        .reward {
          width: 80px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          // align-items: end;
          // flex-wrap: wrap;
          .score {
            width: 100%;
            position: relative;
            font-size: 12px;
            padding-right: 32px;
            text-align: right;
            font-weight: 800;
            text-wrap: nowrap;

            color: #865534;

            .nanguaiconaaa {
              position: absolute;
              right: 10px;
              top: 50%;
              transform: translateY(-50%);
              width: 22px;
              height: 22px;
              background-image: url("https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/game/nangua_icon.png");
              background-size: 100% 100%;
              background-repeat: no-repeat;
            }

            // &::after {
            //   content: "";
            //   position: absolute;
            //   right: 10px;
            //   top: 50%;
            //   transform: translateY(-50%);
            //   width: 18px;
            //   height: 18px;
            //   background-image: url("https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/global/lottery.png");
            //   background-size: 100% 100%;
            //   background-repeat: no-repeat;
            // }
          }

          .reward_value {
            width: 100%;
            position: relative;
            font-size: 12px;
            padding-right: 32px;
            text-align: right;
            color: #f9712b;
            font-weight: 800;

            &::after {
              content: "";
              position: absolute;
              right: 10px;
              top: 50%;
              transform: translateY(-50%);
              width: 18px;
              height: 18px;
              background-image: url("https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/global/gold.png");
              background-size: 100% 100%;
              background-repeat: no-repeat;
            }
          }
        }

        &:nth-child(odd) {
          background: linear-gradient(180deg, #fcecd3 0%, #f4dcb3 100%);
        }

        &:nth-child(even) {
          background: linear-gradient(180deg, #d09e68 0%, #ce9c66 100%);
        }
      }
    }
  }
}

.landscape_pumpkin_rank_wrapper {
  width: 100%;
  height: 100%;
  padding-right: 30px;
  padding-top: 10px;
  display: flex;
  overflow: hidden;
  // background-color: red;
  .left_title_img_wrapper {
    width: 230px;
    position: relative;

    .left_title_img {
      &::after {
        content: "每日 0:00 名次更新";
        position: absolute;
        font-size: 10px;
        left: 50%;
        bottom: 0;
        color: #fff;
        transform: translate(-50%, 110%);
      }
    }
  }
  .rank_list_wrapper {
    flex: 1;
    padding: 18px 18px 18px 18px;
    // border: 2px solid #ffc8a4;
    border-radius: 8px;

    background-image: url("https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/rank_day_bg.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    height: 100%;
    // overflow: hidden;

    position: relative;
    .pup_close_icon {
      position: absolute;
      right: 0;
      top: 0;
      transform: translate(0px, -10px);
      width: 34px;
      height: 34px;
      background-image: url("https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/pup_close2.png");
      background-size: 100% 100%;
      background-repeat: no-repeat;
    }

    .rank_bottom_icon_left {
      position: absolute;
      bottom: 0px;
      left: -30px;
      width: 60px;
      height: 60px;
      background-image: url("https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/rank_bottom_left_icon.png");
      background-size: 100% 100%;
      background-repeat: no-repeat;
      z-index: 12;
    }
    .rank_bottom_icon_right {
      position: absolute;
      bottom: 0px;
      right: -30px;
      width: 60px;
      height: 60px;
      background-image: url("https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/rank_bottom_right_icon.png");
      background-size: 100% 100%;
      background-repeat: no-repeat;
      z-index: 12;
    }

    .rank_list_content_wrapper {
      height: 100%;
      display: flex;
      flex-direction: column;

      .rank_title_wrapper {
        height: 32px;
        line-height: 32px;
        display: flex;
        text-align: center;
        background: linear-gradient(180deg, #d09e68 0%, #ce9c66 100%);
        border-radius: 20px;
        position: relative;
        .rank {
          width: 50px;
        }
        .player_info {
          flex: 1;
        }
        .reward {
          width: 80px;
        }
      }

      .rank_list_content {
        flex: 1;
        height: calc(100% - 50px);
        background-color: #f9712b;
        overflow: hidden;
        margin-top: 10px;
        border-radius: 12px;

        .rank_list {
          height: 100%;
          overflow: auto;
          .rank_list_item {
            display: flex;
            // min-height: 60px;
            padding: 10px 0;
            .rank {
              position: relative;
              width: 50px;

              .rank_icon {
                width: 20px;
                height: auto;
                position: absolute;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);
              }
              .rank_text {
                width: 20px;
                height: 20px;
                height: auto;
                position: absolute;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);
                border-radius: 50%;

                background: linear-gradient(180deg, #59cef1 0%, #0a3e8d 100%);

                border: 1px solid #f5deb8;
                text-align: center;
                line-height: 20px;
                font-size: 14px;
              }
            }
            .player_info {
              flex: 1;
              display: flex;
              // flex-direction: column;
              justify-content: start;
              align-items: center;
              padding-left: 30px;
              .player_info_content {
                display: flex;
                flex: 1;
                .player_info_head {
                  height: 38px;
                  width: 38px;
                }
                .player_info_name_and_grade {
                  padding-left: 10px;
                  flex: 1;
                  .user_name {
                    font-size: 12px;
                    color: #865534;
                  }
                  // .user_grade {}
                }
              }
            }
            .reward {
              width: 80px;
              display: flex;
              flex-direction: column;
              justify-content: center;
              // align-items: end;
              // flex-wrap: wrap;
              .score {
                width: 100%;
                position: relative;
                font-size: 12px;
                text-align: right;
                font-weight: 800;
                text-wrap: nowrap;

                color: #865534;

                .nanguaiconaaa {
                  position: absolute;
                  right: 10px;
                  top: 50%;
                  transform: translateY(-50%);
                  width: 22px;
                  height: 22px;
                  background-image: url("https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/game/nangua_icon.png");
                  background-size: 100% 100%;
                  background-repeat: no-repeat;
                }
              }

              .reward_value {
                width: 100%;
                position: relative;
                font-size: 12px;
                padding-right: 32px;
                text-align: right;
                color: #f9712b;
                font-weight: 800;

                &::after {
                  content: "";
                  position: absolute;
                  right: 10px;
                  top: 50%;
                  transform: translateY(-50%);
                  width: 18px;
                  height: 18px;
                  background-image: url("https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/global/gold.png");
                  background-size: 100% 100%;
                  background-repeat: no-repeat;
                }
              }
            }

            &:nth-child(odd) {
              background: linear-gradient(180deg, #fcecd3 0%, #f4dcb3 100%);
            }

            &:nth-child(even) {
              background: linear-gradient(180deg, #d09e68 0%, #ce9c66 100%);
            }
          }
        }
      }
    }
  }
}
</style>
