<template>
  <div class="c_wrapper">
    <div
      class="user_gold_wapper"
      @click="
        () => {
          store.payPupToggle(true);
        }
      "
    >
      {{ addCommas(store.userInfo.gold) }}
    </div>
    <div class="room_id_wrapper">
      {{
        findGameInfoData(
          store.current_machine.child_name || store.current_machine.product_name
        ).game_name + store.current_machine.machine_index
      }}号 {{ store.current_machine.exchange }}币房
    </div>
  </div>
</template>

<script setup>
import { useStore } from "@/store/store";
import { addCommas } from "@/utils/utils";
import { ref, nextTick, onMounted } from "vue";
import findGameInfoData from "@/utils/gameInfoMap";

const store = useStore();

onMounted(() => {});
</script>

<style lang="scss" scoped>
.c_wrapper {
  position: absolute;
  display: flex;
  left: 20px;
  top: 80px;
  z-index: 4;
}

.room_id_wrapper {
  padding: 0 4px;
  height: 20px;
  line-height: 20px;
  text-align: center;
  display: inline-block;
  background-color: rgba($color: #000, $alpha: 0.4);
  border-radius: 12px;
  font-size: 12px;
  margin-right: 4px;
}
.user_gold_wapper {
  background-color: rgba($color: #000, $alpha: 0.4);
  padding: 0px 24px 0px 24px;
  height: 20px;
  line-height: 20px;
  border-radius: 12px;
  position: relative;
  &::before {
    content: "";
    position: absolute;
    left: 0px;
    top: 50%;
    transform: translateY(-50%);
    width: 22px;
    height: 22px;
    background-image: url("https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/global/gold.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
  }
  &::after {
    content: "";
    position: absolute;
    right: 0px;
    top: 50%;
    transform: translateY(-50%);
    width: 18px;
    height: 18px;
    background-image: url("https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/gold_add_icon.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
  }
}
</style>
