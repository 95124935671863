<template>
  <div class="welfare_lobby_wrapper" :style="{
    paddingTop: store.areaInsetTopValue + 'px',
  }">
    <NavBar />

    <div class="container">
      <div class="code_list_wrapper">
        <div class="no_list_wrapper" v-if="codelist.length == 0">
          更多福利正在陆续到来，请及时关注官方最新消息。
        </div>

        <div class="code_item" v-for="(codeItem, codeIndex) in codelist.sort(
          (a, b) => Number(a.id) - Number(b.id)
        )">
          <div class="level_text_icon" v-if="codeItem.level > 1">
            {{ codeItem.level_name }} 及以上
          </div>

          <div class="code_item_cotent">
            <div class="gold_icon_wrapper">
              <van-image width="100%" height="100%" fit="fill" :src="codeItem.level > 1 ? gold_icon2 : gold_icon1" />
            </div>

            <div class="code_item_info">
              <p class="num">{{ codeItem.gold }}金币</p>
              <div class="code_time_value">
                {{ `${codeItem.stratTime} 开始` }}
              </div>
              <div class="code_time_value">
                {{ `${codeItem.endTime} 过期` }}
              </div>
            </div>

            <!-- <div class="code_item_btn" @click="handleMustLookAd(codeItem)" v-if="codeItem.must_guanggao">
              <van-image width="100%" height="100%" fit="fill" :src="handleMustIcon(codeItem)" />
            </div> -->

            <div class="code_item_btn" @click="handleShowAdDialog(codeItem)">
              <van-image width="100%" height="100%" fit="fill" :src="codeItem.must_guanggao
                  ? handleMustIcon(codeItem)
                  : btnimgs[codeItem.statu]
                " />
            </div>
          </div>

          <!-- <div class="code_gold_value">
            <span class="number">{{ codeItem.gold }}</span>
            <span> 金币</span>
          </div>
          <div class="code_time_value">
            {{ `${codeItem.stratTime} 开始` }}
          </div>
          <div class="code_time_value">
            {{ `${codeItem.endTime} 过期` }}
          </div>

          <div class="get_btn">
            <div
              class="get_btn_0"
              v-if="codeItem.statu == 0"
              @click="getChangeCodeAward(codeItem)"
            >
              点击领取
            </div>
            <div class="get_btn_1" v-if="codeItem.statu == 1">未开始</div>
            <div class="get_btn_2" v-if="codeItem.statu == 2">已过期</div>
          </div>

          <div class="done_statu" v-if="codeItem.statu == 3"></div> -->
        </div>
      </div>

      <div class="welfare_lobby_msg_wrapper">
        <van-image width="100%" height="auto" fit="fill" :src="welfare_lobby_msg" />
      </div>
    </div>
  </div>

  <div class="again_dialog" v-if="dialog_show">
    <div class="again_wrap scale_in_center_class">
      <div class="again_bg">
        <div class="again_close_icon" @click="dialog_show_toggle(false)"></div>
        <div class="bg_box">
          <div class="bg_text_1">双倍奖励</div>
          <div class="bg_text_2">观看广告领取双倍金币</div>
          <div class="again_btns">
            <div class="to_pay" @click="directClaim">
              <div class="to_icon"></div>
              <div class="to_text">直接领取</div>
            </div>
            <div class="to_Ad" @click="handleAdPlay">
              <div class="to_icon"></div>
              <div class="to_text">看广告</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- <div class="reward_wrap" v-show="reward_show">
    <div class="win_pup_wrap scale_in_center_class">
      <div class="gold_item_wrap">
        <div class="gold_item"></div>
        <div class="gold_num">{{ rewardGold }}</div>
      </div>
      <div class="get_btn" @click="handeAdReward">领取</div>
    </div>
  </div> -->
</template>

<script setup>
// import { ErrorTypes, useRouter } from "vue-router";
import { useStore } from "@/store/store";
import { lobbyCode, isExchange, changeAwardSend, addGuanggaoCode } from "@/api";
import { onBeforeMount, onMounted, ref, reactive, watch } from "vue";
import { formatTimestamp } from "@/utils/utils";
import { getCurrentDevice } from "@/utils/utils";
import { sendInstructions, asyncSendInstructions } from "@/api/sendData";
import { useToggle } from "@vant/use";
import NavBar from "@/components/NavBar.vue";

const welfare_lobby_msg =
  "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/welfare/welfare_lobby_msg.png";

const gold_icon1 =
  "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/global/gold.png";
const gold_icon2 =
  "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/welfare/gold_icon_2.png";

const btn0 =
  "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/welfare/get_btn.png";
const btn1 =
  "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/welfare/inactive.png";
const btn2 =
  "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/welfare/timer_out.png";
const btn3 =
  "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/welfare/done_btn.png";
import btn4 from "@/assets/images/welfareAdBtn.png";

import { gradLevelIcon } from "@/utils/levelData";
import { showToast } from "vant";

// const router = useRouter();
const store = useStore();
const codelist = ref([]);
const btnimgs = [btn0, btn1, btn2, btn3, btn4];
const [dialog_show, dialog_show_toggle] = useToggle(false);
const [reward_show, reward_show_toggle] = useToggle(false);

watch(
  () => store.welfareAdData.ad_status,
  (newV) => {
    if (newV) {
      getChangeCodeAward();
      dialog_show_toggle(false);
    }
  }
);

const getListData = async () => {
  const res = await lobbyCode();
  if (res.data) {
    res.data.forEach(async (item) => {
      const stratTime = formatTimestamp(item.start_time, "mm-dd hh:mi");
      const endTime = formatTimestamp(item.end_time, "mm-dd hh:mi");

      const codeItem = {
        id: item.id,
        gold: item.gold,
        code: item.code,
        stratTime: stratTime,
        endTime: endTime,
        statu: item.type,
        level: Number(item.level),
        level_name: gradLevelIcon[Number(item.level)]?.level_text ?? "",
        is_guanggao: item.is_guanggao,
        must_guanggao: item.must_guanggao,
      };

      const statu = await isExchange({
        uid: parseInt(store.userInfo.uid),
        code: item.code,
      });

      statu.is_exchange && (codeItem.statu = 3);
      codelist.value.push(codeItem);
    });
  }
};

let codeItemData = reactive({
  id: "",
  gold: 0,
  code: "",
  stratTime: "",
  endTime: "",
  statu: 0,
  level: 1,
  level_name: "",
  is_guanggao: false,
  must_guanggao: false,
  timestamp: "",
});
const handleShowAdDialog = (codeItem) => {
  if (store.userInfo.recharge_level < codeItem.level) {
    showToast({
      type: "fail",
      message: `等级不足`,
    });

    return;
  }

  codeItemData = codeItem;

  if (!shouldShowAd(codeItem) && codeItem.statu == 0) getChangeCodeAward(); //领取
  if (shouldShowAd(codeItem) && codeItem.statu == 0)
    codeItem.must_guanggao ? handleAdPlay("must") : dialog_show_toggle(true); //看广告
};

const handleMustLookAd = (codeItem) => {
  if (store.userInfo.recharge_level < codeItem.level) {
    showToast({
      type: "fail",
      message: `等级不足`,
    });

    return;
  }
  codeItemData = codeItem;

  if (!shouldShowAd(codeItem) && codeItem.statu == 0) getChangeCodeAward();
  if (shouldShowAd(codeItem) && codeItem.statu == 0) handleAdPlay("must");
};

const getChangeCodeAward = async () => {
  const res = await changeAwardSend({
    uid: parseInt(store.userInfo.uid),
    code: codeItemData.code,
  });

  if (res.errorCode == 0 || res.errorMsg == "兑换成功") {
    codelist.value.some((item) => {
      if (item.code == codeItemData.code) {
        item.statu = 3;
        return true;
      } else return false;
    });
  }
};

const directClaim = () => {
  dialog_show_toggle(false);
  getChangeCodeAward();
};

//播放广告
let rewardGold = ref(0);
const handleAdPlay = async (type) => {
  if (store.userInfo.ios_version != "2.0.0" && store.channelType != "IOS") {
    store.isUpdatePop = true;
    return;
  }

  store.welfareAdData.ad_status = false;

  const res = await addGuanggaoCode({
    uid: parseInt(store.userInfo.uid),
    code: codeItemData.code,
    prime_rit:
      type == "must" ? mustExchangeReward.prime_rit : exchangeReward.prime_rit,
  });

  if (res.Message == "ok") {
    const _params = {
      msg_type: "play_ad",
      id:
        type == "must"
          ? mustExchangeReward.prime_rit
          : exchangeReward.prime_rit,
    };

    sendInstructions(_params);

    showToast({
      type: "loading",
      forbidClick: true,
      message: "加载广告中,\n请稍后...",
      duration: 3000,
      zIndex: 2024,
    });

    // sendInstructions({ msg_type: "sync_guanggao_reward" });
  }
};

//领取广告奖励
// const handeAdReward = async () => {
//   getListData();
//   reward_show_toggle(false);
// };

let exchangeReward = reactive({
  level: 1,
  reward: {},
  prime_rit: "",
});

let mustExchangeReward = reactive({
  level: 1,
  reward: {},
  prime_rit: "",
});

const getAdConfig = () => {
  if (getCurrentDevice() == "IOS" || !store.initAdStatus) return false;

  store.adConfigData.forEach((item) => {
    const reward = JSON.parse(item.reward);
    if (reward[0].type == "must_exchange_code") {
      mustExchangeReward.level = item.level;
      mustExchangeReward.reward = reward[0];
      mustExchangeReward.prime_rit = item.prime_rit;
    }
    if (reward[0].type == "exchange_code") {
      exchangeReward.level = item.level;
      exchangeReward.reward = reward[0];
      exchangeReward.prime_rit = item.prime_rit;
    }
  });

  // console.log('exchangeReward====', exchangeReward);
  // console.log('mustExchangeReward====', mustExchangeReward);
};

const handleMustIcon = (codeItem) => {
  if (codeItem.statu == 0 && codeItem.must_guanggao && shouldShowAd(codeItem)) {
    return btn4;
  } else {
    return btnimgs[codeItem.statu];
  }
};

// const getDayOfWeekByTimestamp = (timestamp) => {
//   const newTimes = timestamp.toString().padEnd(13, 0);
//   const date = new Date(Number(newTimes));
//   const dayOfWeek = date.getDay();
//   const weekDays = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
//   return weekDays[dayOfWeek];
// };

// const handleAdWeek = (timestamp) => {
//   const weekendDays = ['Fri', 'Sat', 'Sun'];
//   return weekendDays.includes(getDayOfWeekByTimestamp(timestamp));
// };

const shouldShowAd = (codeItem) => {
  const device = getCurrentDevice() == "IOS" ? "ios" : "android";
  return (
    device == "android" &&
    store.initAdStatus &&
    (codeItem.is_guanggao || codeItem.must_guanggao)
  );
};

onBeforeMount(async () => {
  await getListData();
  getAdConfig();
});
</script>

<style lang="scss" scoped>
.welfare_lobby_wrapper {
  min-height: 100vh;
  background-image: url("https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/welfare/top_bg.png");
  background-size: 100% auto;
  background-repeat: no-repeat;
  background-color: #251c41;

  .nav_bar_wrapper {
    position: relative;
    height: 40px;

    .nav_bar_text {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      width: 96px;
      height: 36px;
      background-size: 100% auto;
      background-image: url("@/assets/images/payment_cente_title.png");
      background-repeat: no-repeat;
    }

    .nav_bar_back_wrapper {
      position: absolute;
      left: 0;
      top: 0;
      width: 80px;
      height: 40px;
      background-size: 100% 100%;
      background-image: url("@/assets/images/navbar_back_icon.png");
      background-repeat: no-repeat;
    }
  }

  .container {
    padding-top: 168px;
    padding-bottom: 20px;

    .title {
      width: 124px;
      margin: 0 auto;
    }

    .code_list_wrapper {
      display: flex;
      flex-wrap: wrap;

      padding: 20px 14px;

      background-color: #231b66;
      border-radius: 10px;
      border: 1px solid #8dc5fc;

      .no_list_wrapper {
        width: 100%;
        height: 120px;
        text-align: center;
        line-height: 120px;
      }

      .code_item {
        position: relative;
        margin-top: 10px;
        width: 100%;
        height: 96px;

        background-image: url("https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/welfare/code_item_bg.png");
        background-size: 100% 100%;
        background-repeat: no-repeat;

        .level_text_icon {
          position: absolute;
          top: 0;
          left: 0;
          border-top-left-radius: 10px;
          border-bottom-right-radius: 10px;
          padding: 2px 10px;
          background: linear-gradient(90deg, #240c63 0%, #000000 100%);
          color: #fff;
          font-size: 10px;
          text-align: center;
        }

        .code_item_cotent {
          display: flex;
          padding: 0 18px;
          height: 100%;
          // justify-content: center;
          align-items: center;

          .gold_icon_wrapper {
            width: 50px;
            height: 50px;
          }

          .code_item_info {
            flex: 1;
            height: 60px;

            padding-left: 12px;

            .num {
              font-size: 20px;
              font-weight: 800;
            }

            .code_time_value {
              font-size: 10px;
              color: #ffffff60;
            }
          }

          .code_item_btn {
            width: 84px;
            height: 30px;
            // background-color: skyblue;
          }
        }
      }
    }

    .welfare_lobby_msg_wrapper {
      margin-top: 12px;
    }
  }
}

.again_dialog {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 5;

  background-color: rgba($color: #000000, $alpha: 0.7);

  .again_wrap {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -62%);

    .again_bg {
      width: 319px;
      height: 203px;
      background-image: url("@/assets/images/slots/pup_bg.png");
      background-size: 100% 100%;
      position: relative;

      .again_close_icon {
        position: absolute;
        right: 0px;
        top: 0px;
        width: 32px;
        height: 32px;
        background-image: url("@/assets/images/slots/icon_quxiao.png");
        background-size: 100% 100%;
      }

      .bg_box {
        position: absolute;
        left: 50%;
        top: 40px;
        transform: translateX(-50%);

        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .bg_text_1 {
          color: #6135ff;
          font-size: 20px;
          text-shadow: 0 -1px #fff, 1px 0px #fff, 0 1px #fff, -1px 0 #fff,
            -1px -1px #fff, 1px 1px #fff, 1px -1px #fff, -1px 1px #fff;
        }

        .bg_text_2 {
          color: #6135ff;
          font-size: 14px;
        }

        .again_btns {
          display: flex;
          margin-top: 20px;

          .to_pay {
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 14px;
            margin-right: 8px;
            width: 100px;
            height: 38px;
            background-image: url("@/assets/images/slots/btn_chongzhi.png");
            background-size: 100% 100%;

            .to_icon {
              width: 23px;
              height: 23px;
              background-image: url("@/assets/images/slots/icon_chognzhi.png");
              background-size: 100% 100%;
              margin-right: 5px;
            }

            .to_text {
              text-shadow: 0 -1px #632007, 1px 0px #632007, 0 1px #632007,
                -1px 0 #632007, -1px -1px #632007, 1px 1px #632007,
                1px -1px #632007, -1px 1px #632007;
            }
          }

          .to_Ad {
            @extend .to_pay;
            margin-right: 0px;
            margin-left: 8px;
            background-image: url("@/assets/images/slots/btn_guanggao.png");

            .to_icon {
              background-image: url("@/assets/images/slots/icon_guanggao.png");
            }

            .to_text {
              text-shadow: 0 -1px #076331, 1px 0px #076331, 0 1px #076331,
                -1px 0 #076331, -1px -1px #076331, 1px 1px #076331,
                1px -1px #076331, -1px 1px #076331;
            }
          }
        }
      }
    }
  }
}

.reward_wrap {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 5;

  background-color: rgba($color: #000000, $alpha: 0.3);

  .win_pup_wrap {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -62%);
    width: 350px;
    height: 346px;
    background-image: url("https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/active/win_pup_bg.png");
    background-size: 100% auto;
    background-repeat: no-repeat;

    .gold_item_wrap {
      position: absolute;
      top: 200px;
      width: 48px;
      height: 62px;
      left: 50%;
      transform: translateX(-50%);

      .gold_item {
        width: 48px;
        height: 48px;
        background-color: #9369e1;
        border-radius: 6px;
        background-image: url("https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/global/gold.png");
        background-size: 100% 100%;
        background-repeat: no-repeat;
      }

      .gold_num {
        width: 100%;
        text-align: center;
        white-space: nowrap;
        color: #724026;
        font-size: 14px;
        padding: 4px 0;
      }
    }

    .get_btn {
      position: absolute;
      bottom: 0;
      left: 50%;
      width: 120px;
      height: 32px;
      transform: translate(-50%, 20px);
      color: #693015;
      line-height: 32px;
      text-align: center;
      border-radius: 16px;
      font-size: 16px;
      background: linear-gradient(180deg, #fcd15a 0%, #ebad00 100%);
    }
  }
}
</style>
