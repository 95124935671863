<template>
  <van-overlay
    :show="store.turntable_show"
    z-index="99999"
    :lock-scroll="false"
    :lazy-render="true"
  >
    <div id="_rotate_box">
      <div class="box_container">
        <div
          class="close_icon"
          @click="store.storeShowToggle('turntable_show', false)"
        ></div>
        <div class="sm_icom" @click="msg_show_toggle(true)">转盘说明</div>
        <!-- <div class="sm_text">
          说明：转盘玩法由极客竞技制定，与苹果公司无关。
        </div> -->
        <!-- 外壳 -->
        <div class="turntable_wrapper">
          <van-image
            width="100%"
            height="100%"
            fit="fill"
            block
            :show-loading="false"
            position="center"
            :src="turntable_bg_1"
            v-show="cur_turntable_type == 1"
          />
          <van-image
            width="100%"
            height="100%"
            fit="fill"
            block
            :show-loading="false"
            position="center"
            :src="turntable_bg_2"
            v-show="cur_turntable_type == 2"
          />
          <van-image
            width="100%"
            height="100%"
            fit="fill"
            block
            :show-loading="false"
            position="center"
            :src="turntable_bg_3"
            v-show="cur_turntable_type == 3"
          />

          <div class="light_1_1" v-if="cur_turntable_type == 1"></div>
          <div class="light_1_2" v-if="cur_turntable_type == 1"></div>

          <div class="light_2_1" v-if="cur_turntable_type == 2"></div>
          <div class="light_2_2" v-if="cur_turntable_type == 2"></div>

          <div class="light_3_1" v-if="cur_turntable_type == 3"></div>
          <div class="light_3_2" v-if="cur_turntable_type == 3"></div>
        </div>

        <!-- 转盘 -->
        <div class="wheel_wrap">
          <div class="spin_center">
            <div class="spin_number">
              {{ store.player_turntable_data.progress >= 100 ? 1 : 0 }}
            </div>
          </div>
          <div class="win_item_wrap" v-show="_win_show" ref="_Win_item"></div>
          <div class="turntable_spin_wheel" ref="_Turntable_spin">
            <div class="wheel_bg">
              <van-image
                width="100%"
                height="100%"
                fit="fill"
                block
                position="center"
                :show-loading="false"
                :src="wheel_bg_1"
                v-show="cur_turntable_type == 1"
              />
              <van-image
                width="100%"
                height="100%"
                fit="fill"
                block
                :show-loading="false"
                position="center"
                :src="wheel_bg_2"
                v-show="cur_turntable_type !== 1"
              />
            </div>
            <div class="wheel_container">
              <div
                class="spin_item"
                v-for="(_v, _k) in store.player_turntable_data.turn_info[
                  cur_turntable_type
                ].reward"
                :key="_k"
                :style="{
                  transform: `translateX(-50%) rotate(-${(_k - 1) * 36}deg)`,
                }"
              >
                <div class="spin_value">
                  <span class="jp_t" v-if="_v.type == 'jp'">
                    J <br />
                    P
                  </span>
                  <span v-else>{{ _v.reward_num }}</span>
                </div>
                <div class="lottery_icon" v-if="_v.type == 'lottery'"></div>
              </div>
            </div>
          </div>
        </div>

        <!-- 按钮 -->
        <div class="toggle_level_wrapper">
          <div
            class="level_btn"
            v-for="levelItem in turntable_level_btns"
            @click="turntable_toggle(levelItem.key)"
          >
            <van-image
              width="100%"
              height="100%"
              fit="fill"
              block
              position="center"
              :show-loading="false"
              :src="levelItem.inactive_bg"
              v-show="cur_turntable_type !== levelItem.key"
            />
            <van-image
              width="100%"
              height="100%"
              fit="fill"
              block
              :show-loading="false"
              position="center"
              :src="levelItem.active_bg"
              v-show="cur_turntable_type == levelItem.key"
            />
          </div>
        </div>

        <!-- 指针 -->
        <div class="pointer"></div>

        <!-- 开始按钮 -->
        <div class="start_btn_wrap" @click="handleStartBtnClick">
          <van-image
            width="100%"
            height="auto"
            fit="fill"
            position="center"
            :show-loading="false"
            :src="start_btn"
            v-show="!start_btn_statu"
          />
          <van-image
            v-show="start_btn_statu"
            width="100%"
            height="auto"
            fit="fill"
            position="center"
            :src="start_btn_active"
            :show-loading="false"
          />
        </div>

        <!-- Jackpot -->
        <div class="jackpot_wrapper">
          <div class="jackpot_bg"></div>

          <div class="jackpot_star"></div>

          <div class="win_light_bg"></div>

          <div
            class="gold_animation_wraper"
            @click="jp_log_show_toggle(!jp_log_show)"
          >
            <van-image
              width="100%"
              height="auto"
              fit="fill"
              position="center"
              :src="gold_animation"
              :show-loading="false"
            />
          </div>
          <div class="jp_wrap">
            <div class="jp_content">
              <div class="lottery_icon"></div>
              <div class="jp_val">
                {{ dynamic_digit }}
              </div>
            </div>

            <transition name="van-fade">
              <div class="jp_log_wrapper" v-show="jp_log_show">
                <div
                  class="jp_log_item"
                  v-for="(jpLogitem, jpLogindex) in store.player_turntable_data
                    .jp_log"
                >
                  <div class="num">{{ jpLogindex + 1 }}</div>
                  <div class="head_wrap">
                    <HeadPortrait :src="jpLogitem.head" />
                  </div>
                  <div class="user_name">{{ jpLogitem.player_name }}</div>
                  <div class="lottery_value">{{ jpLogitem.num }}</div>
                </div>
              </div>
            </transition>
          </div>
        </div>

        <div class="win_log_wrapper">
          <van-swipe
            vertical
            :autoplay="3000"
            :touchable="false"
            :show-indicators="false"
          >
            <van-swipe-item
              v-for="logItem in store.player_turntable_data.win_log"
            >
              <div class="win_log_item">
                <div class="head_wrap">
                  <HeadPortrait :src="logItem.head" />
                </div>
                <div class="user_name">{{ logItem.player_name }}</div>
                <div class="lottery_value">
                  <span class="t1">获得 </span>
                  <span class="t2">{{ logItem.num }}</span>
                  <span class="l_icon"></span>
                </div>
              </div>
            </van-swipe-item>
          </van-swipe>
        </div>
      </div>

      <transition name="van-fade">
        <div class="win_mask" v-show="win_show_result.show">
          <div class="win_light_bg"></div>
          <!-- <div class="win_gold"></div> -->
          <div class="win_lottery" v-show="win_show_result.type == 'lottery'">
            <div class="lottery_val" v-show="win_show_result.type == 'lottery'">
              x{{ win_show_result.num }}
            </div>
          </div>

          <div class="win_start">
            <van-image
              width="100%"
              height="auto"
              fit="fill"
              position="center"
              :src="win_start"
              :show-loading="false"
            />
          </div>

          <div class="win_fire_wrap" v-show="win_show_result.type == 'jp'">
            <van-image
              width="100%"
              height="auto"
              fit="fill"
              position="center"
              :src="win_fire"
              :show-loading="false"
            />
          </div>
          <div class="win_fire_wrap">
            <van-image
              width="100%"
              height="auto"
              fit="fill"
              position="center"
              :src="win_fire2"
              :show-loading="false"
            />
          </div>

          <div class="win_jackpot" v-show="win_show_result.type == 'jp'"></div>
          <div class="win_jackpot_val" v-show="win_show_result.type == 'jp'">
            <div class="ic"></div>

            <div class="r_num">
              <van-rolling-text
                class="my-rolling-text"
                :start-num="0"
                :target-num="win_show_result.num"
                direction="up"
              />
            </div>
          </div>

          <div class="close_btn" @click="win_show_toggle(false)"></div>
        </div>
      </transition>

      <transition name="van-fade">
        <div class="win_mask" v-show="msg_show">
          <div class="sm_text_wrapper">
            <div class="close_icon" @click="msg_show_toggle(false)"></div>
            <div class="text_wrap">
              <!-- <span style="font-size: 14px; color: #ffae00"
                >说明：转盘玩法由极客竞技制定，与苹果公司无关。</span
              > -->
              <br />
              <br />
              1、幸运转盘需要达成积分要求即可启动（进度完成100%），积分可在所有带有奖项提示的游戏中获得，不同奖项积分不同，不同币值的房间获得积分也不相同，币值越高的房间获得的积分越多。
              <br />
              2、幸运转盘初级、中级、高级分别对应不同的会员等级，达成100%进度需要选择对应会员等级的转盘方可启动，高于会员等级或者低于会员等级的转盘都无法启动。
              <br />
              3、JP随全服奖项数据增长，每周一零点清零，积分数据亦随之清零。<br />
              4、中级转盘获得jp彩金的50%奖励，高级转盘获得JP彩金的100%奖励。
            </div>
          </div>
        </div>
      </transition>
    </div>
  </van-overlay>
</template>

<script setup>
import HeadPortrait from "@/components/HeadPortrait.vue";

const start_btn =
  "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/spin/start_btn.png";
const start_btn_active =
  "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/spin/start_btn_active.png";

const level_1_btn =
  "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/spin/level_btn_1.png";
const level_1_btn_active =
  "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/spin/level_btn_1_active.png";

const level_2_btn =
  "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/spin/level_btn_2.png";
const level_2_btn_active =
  "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/spin/level_btn_2_active.png";

const level_3_btn =
  "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/spin/level_btn_3.png";
const level_3_btn_active =
  "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/spin/level_btn_3_active.png";

const turntable_bg_1 =
  "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/spin/turntable_1_bg.png";
const turntable_bg_2 =
  "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/spin/turntable_2_bg.png";
const turntable_bg_3 =
  "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/spin/turntable_3_bg.png";

const wheel_bg_1 =
  "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/spin/wheel_1.png";
const wheel_bg_2 =
  "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/spin/wheel_2.png";

const win_start =
  "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/spin/start.gif";
const win_fire =
  "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/spin/win_fire.gif";
const win_fire2 =
  "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/spin/win_fire2.gif";
const gold_animation =
  "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/spin/gold_animation.gif";

import bg_music from "@/assets/audios/turntable_bg.mp3";
import turntable_sping from "@/assets/audios/turntable_sping.mp3";
import turntable_toggle_mp3 from "@/assets/audios/turntable_toggle.mp3";
import super_gold_hint from "@/assets/audios/super_gold_hint.mp3";
import turntable_start_mp3 from "@/assets/audios/turntable_start.mp3";

import Decimal from "decimal.js";
import { ref, watch, reactive, computed, nextTick } from "vue";
import { useStore } from "@/store/store";
import { sendInstructions, asyncSendInstructions } from "@/api/sendData";
import { addCommas } from "@/utils/utils";
import { Howl, Howler } from "howler";
import { showToast } from "vant";

const turntableBGSound = new Howl({
  src: [bg_music],
  autoplay: false,
  loop: true,
  onplayerror() {
    turntableBGSound.once("unlock", function () {
      turntableBGSound.play();
    });
  },
});
const turntableSpingSound = new Howl({
  src: [turntable_sping],
  autoplay: false,
  loop: false,
});
const turntableToggleSound = new Howl({
  src: [turntable_toggle_mp3],
  autoplay: false,
  loop: false,
});

const resultSound = new Howl({
  src: [super_gold_hint],
  autoplay: false,
  loop: false,
});

const StartSound = new Howl({
  src: [turntable_start_mp3],
  autoplay: false,
  loop: false,
});

const store = useStore();

const cur_turntable_type = ref(1);
const turntable_level_btns = reactive([
  {
    key: 1,
    active_bg: level_1_btn_active,
    inactive_bg: level_1_btn,
  },
  {
    key: 2,
    active_bg: level_2_btn_active,
    inactive_bg: level_2_btn,
  },
  {
    key: 3,
    active_bg: level_3_btn_active,
    inactive_bg: level_3_btn,
  },
]);

const turntable_toggle = (key) => {
  if (start_btn_statu.value || cur_turntable_type.value == key) return;
  cur_turntable_type.value = key;
  turntableToggleSound.play();
};

const _Turntable_spin = ref();
const _Win_item = ref();
const start_btn_statu = ref(false);
let startdeg = 0;

let _win_show = ref(false);
let timers = null;
const _Win_item_an = () => {
  if (!_Win_item.value) return;

  // -3.41333rem 0
  const _cc = 3.41333;
  // background-position: -128px 0;
  let index = 0;
  let num = 0;
  timers = setInterval(() => {
    _Win_item.value.style.backgroundPosition = `-${_cc * (index % 13)}rem 0`;
    index++;

    if (index == 13 || index == 26 || index == 39) num++;
    if (num > 2) {
      clearInterval(timers);
      timers = null;
    }
  }, 100);
};

const handleStartBtnClick = async () => {
  if (store.player_turntable_data.progress < 100) {
    showToast({
      type: "fail",
      message: "次数不足",
      teleport: "#_rotate_box",
    });
    return;
  }

  const start_level =
    store.player_turntable_data.turn_info[cur_turntable_type.value]
      .level_limit[0];
  const max_level =
    store.player_turntable_data.turn_info[cur_turntable_type.value]
      .level_limit[1];

  if (store.userInfo.recharge_level < start_level) {
    showToast({
      type: "fail",
      message: "等级不足",
      teleport: "#_rotate_box",
    });
    return;
  }

  if (store.userInfo.recharge_level > max_level) {
    showToast({
      type: "fail",
      message: "等级不符",
      teleport: "#_rotate_box",
    });
    return;
  }

  if (start_btn_statu.value) return;
  start_btn_statu.value = true;

  StartSound.play();

  // start(Math.floor(Math.random() * 10) + 1);

  // return;
  try {
    const res = await asyncSendInstructions({
      msg_type: "spin_player_turntable",
      index: cur_turntable_type.value,
    });

    if (res.error == "ok") {
      store.$patch((state) => {
        state.player_turntable_data.jp_num = res.jp_num;
        state.player_turntable_data.progress = 0;
        state.userInfo.lottery =
          state.userInfo.lottery + res.reward_info.reward_num;
      });

      win_show_result.type = res.reward_info.type;

      win_show_result.num = res.reward_info.reward_num;
      start(res.reward_index);
    } else {
      start_btn_statu.value = false;
      showToast({
        type: "fail",
        message: res.error,
        teleport: "#_rotate_box",
        onClose() {
          turntableRotateInching();
        },
      });
    }
  } catch (error) {
    start_btn_statu.value = false;
    showToast({
      type: "fail",
      message: "请稍后重试",
      teleport: "#_rotate_box",
      onClose() {
        turntableRotateInching();
      },
    });
  }
};

const step_val = new Decimal(0.02);
const turntableRotateInching = () => {
  if (!_Turntable_spin.value) return;
  if (start_btn_statu.value) return;
  if (!store.turntable_show) return;

  let s = new Decimal(startdeg);

  startdeg = s.add(step_val).toNumber();
  _Turntable_spin.value.style.transform = `rotate(${startdeg}deg)`;
  // _Turntable_spin.value.style.transition = `transform .5s`;
  setTimeout(() => {
    turntableRotateInching();
  }, 0);

  // try {
  //   requestAnimationFrame(turntableRotateInching);
  // } catch (error) {
  //   turntableRotateInching();
  // }
};
const start = (prizeIndex) => {
  if (!_Turntable_spin.value) return;
  startdeg = Math.floor(startdeg % 360);
  const rounds = Math.floor(Math.random() * 2) + 3; // 定义转盘转动的圈数
  const deg = (prizeIndex - 1) * (360 / 10) + 1080 * rounds; // 计算出目标角度
  // -
  // 18 +
  // Math.floor(Math.random() * 36) +
  // 1;

  let step = 0;
  // 缓进渐出
  const easeInOutQuad = (t) => (t < 0.5 ? 2 * t * t : -1 + (4 - 2 * t) * t);
  // 序列帧动画
  const sequenceFrameAnimate = () => {
    step++;
    const progress = step / deg;
    const currentDeg = easeInOutQuad(progress) * deg;
    const targetDeg = currentDeg + startdeg > deg ? deg : currentDeg + startdeg;
    _Turntable_spin.value.style.transform = `rotate(${targetDeg}deg)`;
    if (step < deg) {
      try {
        requestAnimationFrame(sequenceFrameAnimate);
      } catch (error) {
        sequenceFrameAnimate();
      }
    } else {
      setTimeout(() => {
        startdeg = Math.floor(deg % 360);
        start_btn_statu.value = false;
        turntableRotateInching();
      }, 2e3);
    }
  };
  // 自动化过度
  const handleTransitionendEvent = () => {
    setTimeout(() => {
      win_show_toggle(true);
      _win_show.value = false;
    }, 3e3);
    startdeg = Math.floor(deg % 360);

    _win_show.value = true;
    _Win_item_an();
    _Turntable_spin.value.removeEventListener(
      "transitionend",
      handleTransitionendEvent
    );
  };
  const automationTransition = () => {
    _Turntable_spin.value.style.transform = `rotate(${deg}deg)`;
    _Turntable_spin.value.style.transition = `transform 4s`;
    turntableSpingSound.play();
    _Turntable_spin.value.addEventListener(
      "transitionend",
      handleTransitionendEvent
    );
  };
  automationTransition();
};

const turntableReset = () => {
  start_btn_statu.value = false;
  timers != null && clearInterval(timers);
  nextTick(() => {
    _Turntable_spin.value.style.transition = `transform 0s`;
    turntableRotateInching();
  });
};

const win_show_result = reactive({
  show: false,
  type: "jp",
  num: 0,
});
const win_show_toggle = (statu) => {
  win_show_result.show = statu;
  if (statu) resultSound.play();
  if (!statu) turntableReset();
};

const jp_num_ing = ref(1000);

const dynamic_digit = computed(() => {
  return addCommas(store.player_turntable_data.jp_num - jp_num_ing.value);
});

let jp_num_ing_statu = false;
// const roundTimes = [15e2, 2e3, 1e3, 5e2, 8e2];
const jp_num_ing_start = () => {
  if (jp_num_ing.value - 1 <= 0) {
    jp_num_ing_statu = false;
    return;
  }

  jp_num_ing.value = jp_num_ing.value - 1;

  // const time = roundTimes[Math.floor(Math.random() * 6)];

  if (jp_num_ing_statu) {
    setTimeout(() => {
      jp_num_ing_start();
    }, 3e2);
  }
};

const jp_num_ing_reset = () => {
  jp_num_ing.value = 1000;
};

const jp_log_show = ref(false);

const jp_log_show_toggle = (statu) => {
  jp_log_show.value = statu;
};

const msg_show = ref(false);
const msg_show_toggle = (statu) => {
  msg_show.value = statu;
};

watch(
  () => store.turntable_show,
  (newV) => {
    if (newV) {
      sendInstructions({
        msg_type: "get_player_turntable",
      });
      // start_btn_statu.value = false;
      nextTick(() => {
        turntableBGSound.play();
        turntableRotateInching();
        jp_num_ing_statu = true;
        // jp_num_ing_reset();
        jp_num_ing_start();
      });
    } else {
      // start_btn_statu.value = true;
      turntableBGSound.pause();
      jp_num_ing_statu = false;
      jp_num_ing_reset();
    }
  }
);
</script>

<style scoped lang="scss">
#_rotate_box {
  position: absolute;
  left: 0;
  top: 0;
  width: 100vh;
  height: 100vw;
  transform: rotate(90deg) translateY(-100%);
  transform-origin: left top;
  font-family: alibabapuhui;
  .box_container {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 604px;
    height: 274px;
    background-image: url("https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/spin/bg.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    z-index: 1;

    .sm_icom {
      position: absolute;
      left: 50px;
      top: 6px;
      text-wrap: nowrap;
      font-size: 12px;

      padding-right: 16px;

      &::after {
        position: absolute;
        content: "";
        width: 12px;
        height: 12px;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        background-image: url("https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/title_icon.png");
        background-size: 100% 100%;
        background-repeat: no-repeat;
      }
    }

    .sm_text {
      position: absolute;
      left: 50%;
      bottom: 0px;
      text-wrap: nowrap;
      font-size: 12px;
      transform: translate(-50%, 80%);
      color: #fff;
      z-index: 111;
    }

    .close_icon {
      position: absolute;
      // bottom: 0px;
      top: -30px;
      right: -10px;
      width: 40px;
      height: 40px;
      z-index: 8;
      background-image: url("https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/pup_close.png");
      background-size: 100% 100%;
      background-repeat: no-repeat;
    }

    .turntable_wrapper {
      position: absolute;
      width: 430px;
      height: 335px;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      z-index: 1;

      .light_1_1 {
        position: absolute;
        width: 300px;
        height: 300px;
        left: 50%;
        top: 10px;
        transform: translateX(-50%);
        animation-name: lighting;
        animation-duration: 0.5s;
        animation-timing-function: linear;
        animation-delay: 0;
        animation-iteration-count: infinite;
        animation-direction: alternate-reverse;
        z-index: 2;
        background-image: url("https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/spin/turntable_1_light_1.png");
        background-size: 100% 100%;
        background-repeat: no-repeat;
      }

      .light_1_2 {
        @extend .light_1_1;
        animation-direction: alternate;
        background-image: url("https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/spin/turntable_1_light_2.png");
      }

      .light_2_1 {
        position: absolute;
        width: 300px;
        height: 300px;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        animation-name: lighting;
        animation-duration: 0.5s;
        animation-timing-function: linear;
        animation-delay: 0;
        animation-iteration-count: infinite;
        animation-direction: alternate-reverse;
        z-index: 2;
        background-image: url("https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/spin/turntable_2_light_1.png");
        background-size: 100% 100%;
        background-repeat: no-repeat;
      }

      .light_2_2 {
        @extend .light_2_1;
        animation-direction: alternate;
        background-image: url("https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/spin/turntable_2_light_2.png");
      }

      .light_3_1 {
        position: absolute;
        width: 310px;
        height: 100px;
        left: 50%;
        bottom: 0;
        transform: translateX(-50%);
        animation-name: lighting;
        animation-duration: 0.5s;
        animation-timing-function: linear;
        animation-delay: 0;
        animation-iteration-count: infinite;
        animation-direction: alternate-reverse;
        z-index: 2;
        background-image: url("https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/spin/turntable_3_light_1.png");
        background-size: 100% 100%;
        background-repeat: no-repeat;
      }
      .light_3_2 {
        @extend .light_3_1;
        animation-direction: alternate;
        background-image: url("https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/spin/turntable_3_light_2.png");
      }
    }

    .wheel_wrap {
      position: absolute;
      left: 50%;
      top: 24px;
      transform: translateX(-50%);
      width: 210px;
      height: 210px;
      z-index: 2;

      .wheel_bg {
        position: relative;
        width: 100%;
        height: 100%;
        z-index: 1;
      }
      .turntable_spin_wheel {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        transition: transform 0s;
        -webkit-transition: transform 0s;
        will-change: transform;
        z-index: 3;
        // background-color: skyblue;
        .wheel_bg {
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
          z-index: 1;
        }

        .wheel_container {
          position: relative;
          width: 100%;
          height: 100%;
          z-index: 3;
          .spin_item {
            position: absolute;
            left: 50%;
            width: 105px;
            height: 105px;
            text-align: center;
            transform-origin: center bottom;
            .spin_value {
              position: absolute;
              left: 50%;
              top: 6px;
              transform: translate(-50%, 0%);
              font-size: 16px;
              text-shadow: 0px 2px 2px rgba(0, 0, 0, 0.8);
              color: #f4e445;
              font-weight: 1000;
              .jp_t {
                font-size: 18px;
              }
            }
            .lottery_icon {
              position: absolute;
              left: 50%;
              bottom: 48px;
              transform: translateX(-50%);
              width: 20px;
              height: 20px;
              background-image: url("https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/global/lottery.png");
              background-size: 100% 100%;
              background-repeat: no-repeat;
              margin-right: 8px;
            }
          }
        }
      }

      .win_item_wrap {
        position: absolute;
        width: 128px;
        height: 168px;
        left: 50%;
        top: 0;
        transform: translate(-50%, -42px);
        background-image: url("https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/spin/win_item.png");
        background-size: auto 168px;
        background-repeat: no-repeat;
        background-position: 0 0;
        z-index: 4;
      }

      .spin_center {
        position: absolute;
        left: 50%;
        top: 50%;
        width: 80px;
        height: 80px;
        transform: translate(-50%, -50%);
        z-index: 5;
        background-image: url("https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/spin/spin_center.png");
        background-size: 100% 100%;
        background-repeat: no-repeat;

        .spin_number {
          position: absolute;
          left: 50%;
          bottom: 14px;
          transform: translateX(-50%);
          font-size: 20px;
          color: #ecce05;
          font-weight: 1000;
        }
      }
    }

    .toggle_level_wrapper {
      position: absolute;
      top: 74px;
      left: 28px;
      // height: 150px;
      width: 123px;
      font-weight: 800;
      display: flex;
      justify-content: space-between;
      flex-direction: column;
      z-index: 3;
      .level_btn {
        margin-top: 6px;
        position: relative;
        width: 100%;
        height: 50px;
      }
    }

    .pointer {
      position: absolute;
      z-index: 10;
      left: 50%;
      top: 0;
      transform: translate(-50%, -32px);
      width: 44px;
      height: 64px;
      background-image: url("https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/spin/pointer_icon@2x.png");
      background-size: 100% 100%;
      background-repeat: no-repeat;
    }

    .start_btn_wrap {
      position: absolute;
      width: 108px;
      height: 62px;
      right: 34px;
      bottom: 34px;
      z-index: 2;
    }

    .jackpot_wrapper {
      position: absolute;
      width: 200px;
      height: 130px;
      right: 0px;
      top: 0px;

      z-index: 3;
      transform: translate(0px, -30px);

      .jackpot_bg {
        position: absolute;
        width: 100%;
        height: 100%;
        background-image: url("https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/spin/jackpot-3.gif");
        background-size: 100% auto;
        background-position: center center;
        background-repeat: no-repeat;
        z-index: 3;
      }

      .jackpot_star {
        position: absolute;
        right: -4px;
        top: 22px;
        width: 50px;
        height: 60px;
        z-index: 4;
        background-image: url("https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/spin/star_icon.png");
        background-size: 100% auto;
        background-repeat: no-repeat;
        animation-name: lighting;
        animation-duration: 0.5s;
        animation-timing-function: linear;
        animation-delay: 0;
        animation-iteration-count: infinite;
        animation-direction: alternate-reverse;
      }

      .win_light_bg {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -62%);
        transform-origin: center center;
        width: 120px;
        height: 120px;
        background-image: url("https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/spin/win_light_bg.png");
        background-size: 100% 100%;
        background-repeat: no-repeat;
        animation: wheeling2 6s;
        animation-timing-function: linear;
        animation-iteration-count: infinite;
        z-index: 2;
      }

      .gold_animation_wraper {
        position: absolute;
        left: 0;
        top: -50%;
        z-index: 10;
        width: 100%;
        height: auto;
        z-index: 4;
      }

      .jp_wrap {
        position: absolute;
        width: 140px;
        height: 40px;
        bottom: 0px;
        left: 50%;
        transform: translateX(-50%);
        line-height: 40px;

        z-index: 3;

        background-image: url("https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/spin/jp_value_wrap.png");
        background-size: 100% 100%;
        background-repeat: no-repeat;

        .jp_content {
          position: absolute;
          z-index: 1;
          width: 100%;
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          .lottery_icon {
            width: 22px;
            height: 22px;
            background-image: url("https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/global/lottery.png");
            background-size: 100% 100%;
            background-repeat: no-repeat;
            margin-right: 8px;
          }

          .jp_val {
            font-size: 26px;
            color: #ffeb14;
            text-align: center;
            font-weight: 1000;
            text-shadow: 1px 1px 0px #ff3c00;
            // background: linear-gradient(to bottom, #ffed5c 0%, #ffa600 100%);
            // -webkit-background-clip: text;
            // -webkit-text-fill-color: transparent;
          }
        }

        .jp_log_wrapper {
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
          height: 210px;
          z-index: 2;
          display: flex;
          flex-direction: column;
          // justify-content: space-between;
          .jp_log_item {
            width: 100%;
            height: 40px;
            border-radius: 6px 6px 6px 6px;
            border: 1px solid #fae22f;
            //
            background: linear-gradient(90deg, #5b0c5f 0%, #d122a2 100%);
            &:nth-child(1) {
              background: linear-gradient(90deg, #1d16b0 0%, #23a6f6 100%);
            }

            display: flex;
            // justify-content: center;
            align-items: center;

            .num {
              width: 12px;
              height: 12px;
              border-radius: 6px;
              background-color: #fcdd2c;
              color: #e95100;
              font-size: 8px;
              line-height: 12px;
              text-align: center;
              margin: 0 6px;
            }

            .head_wrap {
              width: 18px;
              height: 18px;
            }

            .user_name {
              max-width: 45px;
              flex: 1;
              padding-left: 2px;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
              line-height: 40px;
              font-size: 10px;
            }

            .lottery_value {
              position: relative;
              width: 46px;
              padding-left: 12px;
              &::after {
                content: "";
                position: absolute;
                left: 0;
                top: 50%;
                transform: translateY(-50%);
                width: 10px;
                height: 10px;
                background-image: url("https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/global/lottery.png");
                background-size: 100% 100%;
                background-repeat: no-repeat;
              }
            }
          }
        }
      }
    }

    .win_log_wrapper {
      position: absolute;
      top: 102px;
      left: 440px;
      width: 150px;
      height: 30px;
      overflow: hidden;
      font-size: 12px;
      .win_log_item {
        width: 100%;
        height: 30px;
        display: flex;

        align-items: center;

        color: #ffeb14;
        font-weight: 500;
        .head_wrap {
          width: 22px;
          height: 22px;
        }

        .user_name {
          width: 50px;
          padding-left: 4px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          line-height: 30px;
          font-size: 12px;
        }

        .lottery_value {
          position: relative;
          flex: 1;
          // padding-left: 12px;
          white-space: nowrap;
          display: flex;
          align-items: center;

          .t1 {
            height: 30px;
            line-height: 30px;
          }
          .t2 {
            height: 30px;
            line-height: 30px;
          }

          .l_icon {
            width: 12px;
            height: 12px;
            background-image: url("https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/global/lottery.png");
            background-size: 100% 100%;
            background-repeat: no-repeat;
          }
        }
      }
    }
  }

  .win_mask {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.6);
    z-index: 11;
    // transform: translate(-50);

    .win_light_bg {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      transform-origin: center center;
      width: 256px;
      height: 256px;
      background-image: url("https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/spin/win_light_bg.png");
      background-size: 100% 100%;
      background-repeat: no-repeat;
      animation: wheeling 4s;
      animation-timing-function: linear;
      animation-iteration-count: infinite;
    }
    .win_gold {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      width: 472px;
      height: 372px;
      background-image: url("https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/spin/win_gold.gif");
      background-size: 100% 100%;
      background-repeat: no-repeat;
      z-index: 1;
    }
    .win_lottery {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      width: 270px;
      height: 270px;
      background-image: url("https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/spin/win_bg.png");
      background-size: 100% 100%;
      background-repeat: no-repeat;
      z-index: 3;

      .lottery_val {
        position: absolute;
        bottom: 86px;
        left: 50%;
        width: 200px;
        height: 20px;

        transform: translateX(-50%);
        font-weight: 800;
        font-size: 14px;
        color: #fff734;
        line-height: 20px;
        text-align: center;
        font-style: normal;
        text-transform: none;
        text-shadow: 1px 1px 0px #ff3c00;
      }
    }

    .win_jackpot {
      position: absolute;
      left: 50%;
      top: 20%;
      transform: translateX(-50%);
      width: 244px;
      height: 90px;
      background-image: url("https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/spin/jackpot.png");
      background-size: 100% 100%;
      background-repeat: no-repeat;
      z-index: 5;
    }

    .win_jackpot_val {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -30%);
      width: 232px;
      height: 82px;
      background-image: url("https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/spin/jp_value_wrap2.png");
      background-size: 100% 100%;
      background-repeat: no-repeat;
      z-index: 2;
      text-align: center;
      line-height: 82px;
      font-size: 30px;
      color: #ffeb14;
      display: flex;
      justify-content: center;
      align-items: center;
      text-shadow: 0px 2px 2px #220800;

      .ic {
        width: 22px;
        height: 22px;
        background-image: url("https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/global/lottery.png");
        background-size: 100% 100%;
        background-repeat: no-repeat;
        margin-right: 6px;
        font-weight: 800;
      }

      .r_num {
        height: 100%;
        // width: ;

        .my-rolling-text {
          // --van-rolling-text-background: #1989fa;
          --van-rolling-text-color: #ffeb14;
          --van-rolling-text-font-size: 20px;
          --van-rolling-text-gap: 0px;
          // --van-rolling-text-item-border-radius: 5px;
          --van-rolling-text-item-width: 22px;

          font-weight: 1000;
          transform: translateY(-4px);
        }
      }
    }

    .win_start {
      position: absolute;
      left: 50%;
      top: 50%;
      width: 220px;
      z-index: 3;
      transform: translate(-50%, -50%);
    }

    .win_fire_wrap {
      position: absolute;
      left: 50%;
      bottom: 0;
      width: 423px;
      z-index: 2;
      transform: translate(-50%, 0%);
    }

    .close_btn {
      position: absolute;
      left: 50%;
      bottom: 60px;
      width: 100px;
      height: 32px;
      transform: translateX(-50%);
      background-image: url("https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/spin/win_btn.png");
      background-size: 100% 100%;
      background-repeat: no-repeat;
      z-index: 10;
    }
  }

  .sm_text_wrapper {
    position: absolute;
    width: 667px;
    height: 300px;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    background-image: url("https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/spin/msg_bg.png");
    background-size: 100% auto;
    background-repeat: no-repeat;

    .close_icon {
      position: absolute;
      // bottom: 0px;
      top: 30px;
      right: 40px;
      width: 40px;
      height: 40px;
      z-index: 8;
      background-image: url("https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/pup_close.png");
      background-size: 100% 100%;
      background-repeat: no-repeat;
    }

    .text_wrap {
      position: absolute;
      left: 360px;
      top: 100px;
      height: 140px;
      width: 220px;
      overflow: auto;
    }
  }
}

@keyframes stroke {
  to {
    stroke-dashoffset: 0;
  }
}

@keyframes wheeling {
  0% {
    transform: translate(-50%, -50%) rotate(0deg);
  }

  100% {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}

@-webkit-keyframes wheeling

/* Safari 与 Chrome */ {
  0% {
    transform: translate(-50%, -50%) rotate(0deg);
  }

  100% {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}
@keyframes wheeling2 {
  0% {
    transform: translate(-50%, -62%) rotate(0deg);
  }

  100% {
    transform: translate(-50%, -62%) rotate(360deg);
  }
}

@-webkit-keyframes wheeling2

/* Safari 与 Chrome */ {
  0% {
    transform: translate(-50%, -62%) rotate(0deg);
  }

  100% {
    transform: translate(-50%, -62%) rotate(360deg);
  }
}

@keyframes lighting {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@-webkit-keyframes lighting

/* Safari 与 Chrome */ {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}
</style>
