<template>
  <transition name="van-slide-right">
    <div class="task_content_wrapper" v-show="props.show">
      <div class="task_head_title">
        <div class="task_title_icon"></div>
        推币任务
        <div class="task_back_icon" @click="close_pup"></div>
      </div>
      <div class="task_container">
        <div class="task_all_wrapper">
          <div class="task_all_task_name">
            {{ store.coinPushTask.allTaskName }}
          </div>
          <div
            class="task_all_task_reward"
            v-if="store.coinPushTask.allTaskStatu == 0"
          >
            <span>奖励:</span>
            <div class="reward_icon">
              <van-image width="100%" height="100%" block :src="ticket_icon" />
            </div>
            <span>+{{ store.coinPushTask.allTaskReward }}</span>
          </div>

          <div
            class="task_all_task_get"
            v-else-if="store.coinPushTask.allTaskStatu == 1"
            @click="getGameViewTaskDone(store.coinPushTask.allTaskIndex)"
          >
            领取
          </div>

          <div class="task_all_task_done" v-else>已领取</div>
        </div>

        <div class="task_list_wrapper">
          <div
            class="task_list_item"
            v-for="taskItem in store.coinPushTask.taskList"
          >
            <div class="task_name">{{ taskItem.taskName }}</div>
            <div class="task_reward">
              <span>奖励:</span>
              <div class="reward_icon">
                <van-image
                  width="100%"
                  height="100%"
                  block
                  :src="ticket_icon"
                />
              </div>
              <span>+{{ taskItem.reward }}</span>
            </div>
            <div class="task_btn_wrapper">
              <div class="task_num" v-if="taskItem.statu === 0">
                {{ `${taskItem.currentNumer}/${taskItem.complete}` }}
              </div>
              <div
                class="task_get_btn"
                v-else-if="taskItem.statu === 1"
                @click="getGameViewTaskDone(taskItem.taskIndex)"
              >
                领取
              </div>
              <div class="task_done" v-else>已领取</div>
            </div>
          </div>
          <div class="task_footer">任务每天0点开始重新计算</div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script setup>
import { useStore } from "@/store/store";
import { watch, onMounted } from "vue";
import { sendInstructions } from "@/api/sendData";

const store = useStore();
const ticket_icon = store.imgBaseUrl + "/ticket.png";
const props = defineProps({
  show: {
    type: Boolean,
    default: false,
  },
});

const emits = defineEmits(["close_pup"]);

const initCoinPushTaskData = () => {
  if (store.coinPushTask.gameName != store.current_machine.product_name) {
    sendInstructions({
      msg_type: "get_player_game_task",
      game_name: store.current_machine.product_name,
    });
  }
};

watch(
  () => props.show,
  (newV) => {
    if (newV) {
      initCoinPushTaskData();
    }
  }
);

const close_pup = () => {
  emits("close_pup");
};

const getGameViewTaskDone = (index) => {
  sendInstructions({
    msg_type: "get_player_game_task_reward",
    game_name: store.current_machine.product_name,
    index,
  });
};

defineExpose({ initCoinPushTaskData });
</script>

<style lang="scss" scoped>
.task_content_wrapper {
  position: absolute;
  right: 15px;
  top: 21.5892vh;
  width: 224px;
  z-index: 6;

  .task_head_title {
    border-top-right-radius: 15px;
    border-top-left-radius: 15px;
    background: linear-gradient(79deg, #ffb757 0%, #fed6a2 100%);
    height: 24px;
    line-height: 24px;
    text-shadow: 0px 1px 1px rgba(0, 0, 0, 0.8);

    padding-left: 42px;

    position: relative;

    .task_title_icon {
      position: absolute;
      top: -10px;
      left: 8px;
      background-image: url("https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/mxt/task_icon.png");
      background-size: 100% 100%;
      background-repeat: no-repeat;
      width: 34px;
      height: 34px;
    }
    .task_back_icon {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 8px;
      background-image: url("https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/global/close_icon_purple_right.png");
      background-size: 100% 100%;
      background-repeat: no-repeat;
      width: 22px;
      height: 22px;
    }
  }

  .task_container {
    background-color: rgba($color: #502cb5, $alpha: 0.6);
    border-bottom-right-radius: 15px;
    border-bottom-left-radius: 15px;
    overflow: hidden;

    .task_all_wrapper {
      background-color: #6a66de;
      height: 34px;
      line-height: 34px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 6px;
      .task_all_task_reward {
        display: flex;
        align-items: center;
        justify-content: space-between;
        color: #fee22a;
        .reward_icon {
          width: 16px;
          height: 16px;
        }
      }

      .task_all_task_get {
        width: 66px;
        height: 20px;
        border-radius: 10px;
        background: linear-gradient(90deg, #ffd884 0%, #ffc018 100%);
        color: #280923;
        font-size: 12px;
        text-shadow: none;
        height: 20px;
        text-align: center;
        line-height: 20px;
      }
      .task_all_task_done {
        color: #fee22a;
      }
    }

    .task_list_wrapper {
      // max-height: 276px;
      // overflow-y: auto;
      // overflow: auto;
      padding: 0 4px;
      text-shadow: 0px 1px 1px rgba(0, 0, 0, 0.8);
      &::-webkit-scrollbar {
        width: 0;
      }
      .task_list_item {
        display: flex;
        background-color: rgba($color: #4f5b90, $alpha: 0.8);
        padding: 8px 0;

        margin-top: 6px;

        .task_name {
          width: 50px;
          text-align: center;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
        }
        .task_reward {
          flex: 1;
          display: flex;
          align-items: center;
          justify-content: start;
          color: #fee22a;
          text-align: center;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
          padding: 0 5px;
          .reward_icon {
            width: 16px;
            height: 16px;
            margin: 0 2px;
          }
        }

        .task_btn_wrapper {
          width: 66px;
          height: 20px;
          line-height: 20px;
          text-align: center;
          .task_num {
            background-color: #5048a3;
            border-radius: 6px;
            height: 20px;
          }

          .task_get_btn {
            border-radius: 10px;
            background: linear-gradient(90deg, #ffd884 0%, #ffc018 100%);
            color: #280923;
            font-size: 12px;
            text-shadow: none;
            height: 20px;
          }

          .task_done {
            height: 20px;
            color: #fee22a;
          }
        }
      }

      .task_footer {
        height: 30px;
        line-height: 30px;
        text-align: center;
      }
    }
  }
}
</style>
