<template>
  <div class="mxt_hint_wrapper">
    <!-- 马戏团赚的币提示 -->
    <div
      class="mxt_hint_item"
      v-for="item in store.mxtHintDataList"
      :key="item.key"
    >
      +{{ item.lottery }}
    </div>
  </div>

  <!-- 马戏团高分提示 -->
  <transition name="van-fade">
    <div class="super_lottery_hint" v-show="SuperGoloHintShow">
      <van-image
        width="100%"
        height="auto"
        fit="fill"
        position="center"
        block
        :src="super_gold"
      />
    </div>
  </transition>
</template>

<script setup>
import { useStore } from "@/store/store";
import { watch, ref } from "vue";
import { useToggle } from "@vant/use";
// import openBoxHint from "@/assets/audios/sign_hint.mp3";
// import { Howl, Howler } from "howler";

const store = useStore();
const super_gold = store.imgBaseUrl + "/mxt/super_gold.gif";

const emits = defineEmits(["getCallback"]);

// 得票声音
// const openBoxSound = new Howl({
//   src: [openBoxHint],
//   autoplay: false,
//   loop: false,
// });

const [SuperGoloHintShow, SuperGoloHintShowToggle] = useToggle();

const getLotteryCallBack = () => {
  //   openBoxSound.play();
  emits("getCallback");
};

watch(
  () => store.mxtHintDataList,
  (newV) => {
    if (newV.length && newV[newV.length - 1].lottery >= 1000) {
      SuperGoloHintShowToggle(true);
      setTimeout(() => {
        SuperGoloHintShowToggle(false);
      }, 3e3);
    }
    newV.length > 0 && getLotteryCallBack();
  },
  {
    deep: true,
  }
);
</script>

<style lang="scss" scoped>
.super_lottery_hint {
  position: absolute;
  left: 50%;
  top: 60%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 300px;
  z-index: 5;
}
.mxt_hint_wrapper {
  width: 40vw;
  height: 320px;
  position: absolute;
  left: 0;
  bottom: 80px;
  overflow: hidden;
  z-index: 3;

  .mxt_hint_item {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, 100%) scale(1);
    color: #fee22a;
    font-weight: 800;
    height: 36px;
    line-height: 36px;
    font-size: 24px;
    padding: 0px 0px 0px 36px;
    opacity: 1;
    text-shadow: 0px 4px 2px rgba(0, 0, 0, 0.8);

    animation: mxt_hint 5s 1;
    // animation-duration: 3s;
    // animation-timing-function: ease-in;

    &::before {
      content: "";
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      width: 34px;
      height: 34px;
      background-image: url("https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/global/lottery.png");
      background-size: 100% 100%;
    }
  }
}

@keyframes mxt_hint {
  0% {
    transform: translate(-50%, 0) scale(1);
    opacity: 1;
  }

  100% {
    transform: translate(-50%, -600%) scale(1);
    opacity: 0;
  }
}

@-webkit-keyframes mxt_hint

/* Safari 与 Chrome */ {
  0% {
    transform: translate(-50%, 0) scale(1);
    opacity: 1;
  }

  100% {
    transform: translate(-50%, -600%) scale(1);
    opacity: 0;
  }
}
</style>
