<template>
  <div
    class="long_entrance_wrapper"
    :style="{
      left: _left,
      top: _top,
      right: _right,
      bottom: _bottom,
      transform: `scale(${scaleValue})`,
    }"
    @touchstart="handleTouchStart"
    @touchend="handleTouchEnd"
  >
    <div class="long_entrance_text">
      <van-image
        width="100%"
        height="auto"
        fit="contain"
        position="center"
        :src="entrance_text_img"
        :show-loading="false"
      />
    </div>
  </div>
</template>

<script setup>
import { computed, ref } from "vue";
import { useStore } from "@/store/store";
const entrance_text_img =
  "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/longshop/entrance_text.png";
const store = useStore();
const props = defineProps({
  left: {
    type: [String, Number],
    default: "auto",
  },
  top: {
    type: [String, Number],
    default: "auto",
  },
  right: {
    type: [String, Number],
    default: "auto",
  },
  bottom: {
    type: [String, Number],
    default: "auto",
  },

  bg_index: {
    type: Number,
    default: 0,
  },
  badge: {
    type: [String, Number],
    default: 0,
  },
});

const emits = defineEmits(["_click"]);

const rem = parseFloat(document.documentElement.style.fontSize);

const _top = computed(() => {
  if (props.top == "auto") return "auto";
  return (props.top / rem).toFixed(5) + "rem";
});

const _right = computed(() => {
  if (props.right == "auto") return "auto";
  return (props.right / rem).toFixed(5) + "rem";
});
const _bottom = computed(() => {
  if (props.bottom == "auto") return "auto";
  return (props.bottom / rem).toFixed(5) + "rem";
});

const _left = computed(() => {
  if (props.left == "auto") return "auto";
  return (props.left / rem).toFixed(5) + "rem";
});

const scaleValue = ref(1);
const handleTouchStart = () => {
  scaleValue.value = 1.2;
};
const handleTouchEnd = () => {
  scaleValue.value = 1;
  emits("_click");
  store.storeShowToggle("longShopShow", true);
};
</script>

<style lang="scss" scoped>
.long_entrance_wrapper {
  position: absolute;
  width: 52px;
  height: 52px;
  // background-image: url("https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/game/sprites.png");
  background-image: url("https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/longshop/entrance_icon.gif");
  background-size: 42px 42px;
  background-repeat: no-repeat;
  background-position: top center;
  z-index: 5;

  .long_entrance_text {
    position: absolute;
    left: 50%;
    bottom: 0;
    width: 44px;
    transform: translateX(-50%);
    height: auto;
  }
}
</style>
