import { createRouter, createWebHashHistory } from "vue-router";
import { useStore } from "@/store/store";
import Main from "../views/Main.vue";
import MyView from "../views/MyView.vue";
import Rank from "../views/Ranking.vue";
import Competition from "../views/Competition.vue";
import VIPCenter from "../views/VIPCenter.vue";
import Home from "../views/Home.vue";
import TaskView from "../views/TaskCenter.vue";
import Invitation from "../views/Invitation.vue";
import SetView from "../views/SetView.vue";
import RecordView from "../views/RecordView.vue";

import Challenge from "../views/Challenge.vue";
import PrivacyPolicy from "../views/PrivacyPolicy.vue";
import UserAgreement from "../views/UserAgreement.vue";

import Forum from "../views/Forum.vue";
import MxtGameView from "../views/MxtGameView.vue";
import MsqyLineGameView from "../views/MsqyLineGameView.vue";
import CreateForum from "../views/CreateForum.vue";
import MyForums from "../views/MyForums.vue";
import ActiveRankView from "../views/ActiveRankView.vue";
import WelfareLobby from "../views/WelfareLobby.vue";

import TestView from "../views/TestView.vue";
import CardBag from "@/views/CardBag.vue";
import ZwwGameView from "@/views/ZwwGameView.vue";
import OtherGameView from "@/views/OtherGameView.vue";
import BirdGameView from "@/views/BirdGameView.vue";
import MgcGameview from "@/views/MgcGameview.vue";
import Hw3GameView from "@/views/Hw3GameView.vue";
import BblGameView from "@/views/BblGameView.vue";
import MaryGameView from "@/views/MaryGameView.vue";
import TtqGameView from "@/views/TtqGameView.vue";

import MyAchievement from "@/views/MyAchievement.vue";
import XycsGameView from "@/views/XycsGameView.vue";

import Question from "@/views/Question.vue";

import ForumDetails from "@/views/ForumDetails.vue";
import ForumView from "@/views/ForumView.vue";

const routes = [
  {
    path: "/",
    name: "main",
    component: Main,
    redirect: "/home",

    children: [
      {
        path: "/home",
        name: "home",
        component: Home,
      },
      {
        path: "/rank",
        name: "rank",
        component: Rank,
      },
      {
        path: "/challenge",
        name: "challenge",
        component: Challenge,
      },
      {
        path: "/competition",
        name: "competition",
        component: Competition,
      },
      {
        path: "/my",
        name: "my",
        component: MyView,
      },
    ],
  },
  {
    path: "/mxt",
    name: "mxt",
    component: MxtGameView,
  },
  {
    path: "/msqy_line",
    name: "msqy_line",
    component: MsqyLineGameView,
  },
  {
    path: "/vip",
    name: "vip",
    component: VIPCenter,
  },
  {
    path: "/task",
    name: "task",
    component: TaskView,
  },
  {
    path: "/invitation",
    name: "invitation",
    component: Invitation,
  },
  {
    path: "/set",
    name: "set",
    component: SetView,
  },
  {
    path: "/record",
    name: "record",
    component: RecordView,
  },

  {
    path: "/privacyPolicy",
    name: "privacyPolicy",
    component: PrivacyPolicy,
  },
  {
    path: "/userAgreement",
    name: "userAgreement",
    component: UserAgreement,
  },
  {
    path: "/forum",
    name: "forum",
    component: Forum,
    meta: { keepAlive: true },
  },
  {
    path: "/createForum",
    name: "createForum",
    component: CreateForum,
  },
  {
    path: "/myForums",
    name: "myForums",
    component: MyForums,
  },
  {
    path: "/activeRank",
    name: "activeRank",
    component: ActiveRankView,
  },
  {
    path: "/welfareLobby",
    name: "welfareLobby",
    component: WelfareLobby,
  },
  {
    path: "/cardbag",
    name: "cardbag",
    component: CardBag,
  },
  {
    path: "/zww",
    name: "zww",
    component: ZwwGameView,
  },
  {
    path: "/mgc",
    name: "mgc",
    component: MgcGameview,
  },
  {
    path: "/hw3",
    name: "hw3",
    component: Hw3GameView,
  },
  {
    path: "/bbl",
    name: "bbl",
    component: BblGameView,
  },
  {
    path: "/other",
    name: "other",
    component: OtherGameView,
  },
  {
    path: "/bird",
    name: "bird",
    component: BirdGameView,
  },
  {
    path: "/mary",
    name: "mary",
    component: MaryGameView,
  },
  {
    path: "/ttq",
    name: "ttq",
    component: TtqGameView,
  },
  {
    path: "/xycs",
    name: "xycs",
    component: XycsGameView,
  },

  {
    path: "/achievement",
    name: "achievement",
    component: MyAchievement,
  },
  {
    path: "/question",
    name: "question",
    component: Question,
  },

  {
    path: "/test",
    name: "test",
    component: TestView,
  },
  {
    path: "/forumDetails",
    name: "forumDetails",
    component: ForumDetails,
  },
  {
    path: "/forumview",
    name: "forumview",
    component: ForumView,
  },
  {
    path: "/:catchAll(.*)*",
    redirect: "/home",
  },
];

const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from) => {
  const store = useStore();
  const landscapeGames = [
    "bbl",
    "mgc",
    "hw3",
    "other",
    "ttq",
    "xycs",
    "game",
    "bird",
    "yhfh",
    "ttq",
  ];

  if (landscapeGames.includes(to.name)) {
    store.$patch((state) => {
      state.isLandscape = true;
    });
  } else if (!store.isLandscape) {
    store.$patch((state) => {
      state.isLandscape = false;
    });
  } else
    store.$patch((state) => {
      state.isLandscape = false;
    });
});
export default router;
