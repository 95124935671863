<template>
  <div id="game_result_wrapper" v-show="store.settlementResult.result_show">
    <div class="result_container">
      <template v-if="store.settlementResult.type == 'challenge'">
        <div class="challenge_result">
          <template v-if="store.settlementResult.ret != 'faild'">
            <div class="succee_result_bg">
              <van-image
                width="100%"
                height="100%"
                fit="fill"
                position="center"
                :show-loading="false"
                :src="result_succeed_img"
              />
            </div>

            <div class="succee_reward_wrapper">
              <div class="succee_reward_title">恭喜您获得以下奖励</div>
              <div class="succee_reward_list">
                <!-- 金币 -->
                <div
                  class="result_reward_item"
                  v-if="store.settlementResult.reward > 0"
                >
                  <div class="result_reward_value">
                    {{ store.settlementResult.reward }}
                  </div>
                  <div class="result_reward_gold_icon"></div>
                </div>
                <!-- 彩券 -->
                <div
                  class="result_reward_item"
                  v-if="store.settlementResult.lottery > 0"
                >
                  <div class="result_reward_value">
                    {{ store.settlementResult.lottery }}
                  </div>
                  <div class="result_reward_lottery_icon"></div>
                </div>
                <!-- 大师分 -->
                <div
                  class="result_reward_item"
                  v-if="store.settlementResult.dashi_score > 0"
                >
                  <div class="result_reward_value">
                    {{ store.settlementResult.dashi_score }}
                  </div>
                  <div class="result_reward_dashi_icon"></div>
                </div>
                <!-- 大师卡 -->
                <div
                  class="result_reward_item"
                  v-if="store.settlementResult.dashi_card"
                >
                  <div class="result_reward_value">大师卡</div>
                  <div class="result_reward_dashi_card_icon"></div>
                </div>
                <!-- 宗师卡 -->
                <div
                  class="result_reward_item"
                  v-if="store.settlementResult.zongshi_card"
                >
                  <div class="result_reward_value">宗师卡</div>
                  <div class="result_reward_zongshi_card_icon"></div>
                </div>
              </div>
            </div>
          </template>
          <template v-else>
            <div class="succee_result_bg">
              <van-image
                width="100%"
                height="100%"
                fit="fill"
                position="center"
                :src="result_faild_img"
                :show-loading="false"
              />
            </div>
          </template>
        </div>
      </template>
      <template v-if="store.settlementResult.type == 'active'">
        <div class="active_result_wrapper">
          <div class="active_reward_list">
            <div class="active_reward_item">
              当前排名:{{ store.settlementResult.index }}
            </div>
            <div class="active_reward_item">
              比赛积分:{{
                store.settlementResult.score == 0
                  ? "请查看榜单"
                  : store.settlementResult.score
              }}
            </div>
            <div class="active_reward_item">
              <span>{{ store.settlementResult.lottery }}</span>
              <div class="result_reward_lottery_icon"></div>
            </div>
          </div>
        </div>
      </template>

      <template v-if="store.settlementResult.type == 'normal'">
        <div class="normal_wrapper">
          <p class="normal_lottery">
            {{ store.settlementResult.lottery }}
            <span class="f-18"> 彩券</span>
          </p>
        </div>
      </template>

      <div class="confirm_btn" @click="exitRoomEvent">
        确认<span>({{ ConfirmCountDown.current.value.seconds }}s)</span>
      </div>
    </div>
  </div>
</template>

<script setup>
import { useStore } from "@/store/store";

const store = useStore();

const result_succeed_img = store.imgBaseUrl + "/challenge/result_succeed.png";
const result_faild_img = store.imgBaseUrl + "/challenge/result_faild.png";
import { Howl, Howler } from "howler";
import result_succeed_sound from "@/assets/audios/result_succeed_sound.mp3";

import { watch } from "vue";
import { useCountDown } from "@vant/use";

const props = defineProps({
  show: {
    type: Boolean,
    default: false,
  },
});

const emits = defineEmits(["exitBtnClick"]);

const resultSucceedSound = new Howl({
  src: [result_succeed_sound],
  autoplay: false,
  loop: false,
});

watch(
  () => store.settlementResult,
  (newV) => {
    if (newV.result_show) {
      newV.ret != "faild" && resultSucceedSound.play();
      ConfirmCountDown.reset();
      ConfirmCountDown.start();
    }
  },
  {
    deep: true,
  }
);

const ConfirmCountDown = useCountDown({
  // 倒计时
  time: 5e3,
  onFinish() {
    emits("exitBtnClick");
  },
});

const exitRoomEvent = () => {
  ConfirmCountDown.reset();
  ConfirmCountDown.pause();

  emits("exitBtnClick");
};
</script>

<style lang="scss" scoped>
#game_result_wrapper {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  inset: 0;
  z-index: 9999;
  background-color: rgba(0, 0, 0, 0.6);

  .result_container {
    position: absolute;
    left: 50%;
    top: 50%;
    width: 375px;
    height: 188px;
    transform: translate(-50%, -60%);

    .challenge_result {
      position: relative;
      width: 100%;
      height: 100%;
      padding-top: 110px;
      .succee_result_bg {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        z-index: 1;
      }

      .succee_reward_wrapper {
        position: relative;
        z-index: 2;
        text-align: center;
        color: #ffffff;
        text-shadow: 0px 1px 3px #00466f;
        font-weight: 800;
        .succee_reward_title {
          font-size: 16px;
        }
        .succee_reward_list {
          margin-top: 4px;
          display: flex;
          justify-content: center;
          font-size: 14px;
          .result_reward_item {
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 0 6px;

            .result_reward_gold_icon {
              width: 24px;
              height: 24px;
              background-image: url("https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/global/gold.png");
              background-size: 100% 100%;
              background-repeat: no-repeat;
            }

            .result_reward_dashi_icon {
              width: 24px;
              height: 24px;
              background-image: url("https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/dashi_icon.png");
              background-size: 100% 100%;
              background-repeat: no-repeat;
            }

            .result_reward_lottery_icon {
              width: 24px;
              height: 24px;
              background-image: url("https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/global/lottery.png");
              background-size: 100% 100%;
              background-repeat: no-repeat;
            }

            .result_reward_dashi_card_icon {
              width: 24px;
              height: 24px;
              background-image: url("https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/challenge/dashicard_active.png");
              background-size: 100% 100%;
              background-repeat: no-repeat;
            }
            .result_reward_zongshi_card_icon {
              width: 24px;
              height: 24px;
              background-image: url("https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/challenge/zongshicard_active.png");
              background-size: 100% 100%;
              background-repeat: no-repeat;
            }
          }
        }
      }
    }

    .active_result_wrapper {
      position: relative;
      width: 100%;
      height: 100%;
      padding-top: 128px;
      background-repeat: no-repeat;
      background-size: 100% 100%;
      background-image: url("https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/return_lotter_bg.png");
      text-align: center;

      .active_reward_list {
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 14px;
        font-weight: bold;
        .active_reward_item {
          display: flex;
          justify-content: center;
          align-items: center;
          margin-right: 8px;
          .result_reward_lottery_icon {
            margin-left: 4px;
            width: 24px;
            height: 24px;
            background-image: url("https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/global/lottery.png");
            background-size: 100% 100%;
            background-repeat: no-repeat;
          }
        }
      }
    }

    .normal_wrapper {
      position: relative;
      width: 100%;
      height: 100%;
      padding-top: 128px;
      background-repeat: no-repeat;
      background-size: 100% 100%;
      background-image: url("https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/challenge/return_lotter_bg.png");
      text-align: center;
      .normal_lottery {
        font-size: 24px;
      }
    }

    .confirm_btn {
      text-align: center;
      position: absolute;
      left: 50%;
      bottom: -20px;
      transform: translate(-50%, 100%);
      width: 90px;
      height: 30px;
      line-height: 30px;
      border-radius: 15px;
      color: #1d0053;
      font-weight: bold;
      font-size: 12px;
      background-image: linear-gradient(90deg, #b798fa 0%, #e6dbff 100%);
    }
  }
}
</style>
