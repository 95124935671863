import { gradLevelIcon } from "@/utils/levelData";
import findGameInfoData from "@/utils/gameInfoMap";
import momentJs from "moment";
import findCollectCardInfo from "./CollectClassID";
import findStype from "./stypeMaps";

export function addCommas(num) {
  return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export function getCurrentDevice() {
  const userAgent = navigator.userAgent.toLowerCase();

  const androidIndexOf = userAgent.indexOf("android");
  const iphoneIndexOf = userAgent.indexOf("iphone");
  const ipadIndexOf = userAgent.indexOf("ipad");

  const androidIncludes = userAgent.includes("android");
  const iphoneIncludes = userAgent.includes("iphone");
  const ipadIncludes = userAgent.includes("ipad");

  if (androidIndexOf > -1 || androidIncludes) return "Android";
  if (iphoneIndexOf > -1 || iphoneIncludes) return "IOS";
  if (ipadIndexOf > -1 || ipadIncludes) return "IOS";

  return "Other";
}

export function formatTimestamp(
  timestamp = new Date() / 1000,
  format = "yyyy-mm-dd hh:mi:ss"
) {
  const _timestamp = timestamp ?? new Date() / 1000;

  let date = new Date(_timestamp * 1000);
  const pad = (num) => `0${num}`.slice(-2);
  const map = {
    yyyy: date.getFullYear(),
    mm: pad(date.getMonth() + 1),
    dd: pad(date.getDate()),
    hh: pad(date.getHours()),
    mi: pad(date.getMinutes()),
    ss: pad(date.getSeconds()),
  };

  return format.replace(/(yyyy|mm|dd|hh|mi|ss)/g, (match) => map[match]);
}

export function isToday(timestamp, currentTimestamp = 0) {
  const date = new Date(timestamp * 1000);
  const currentDate =
    currentTimestamp == 0 ? new Date() : new Date(currentTimestamp * 1000);

  const dateYear = date.getFullYear();
  const dateMonth = date.getMonth();
  const dateDay = date.getDate();

  const currentDateYear = currentDate.getFullYear();
  const currentDateMonth = currentDate.getMonth();
  const currentDateDay = currentDate.getDate();

  // 判断两个日期对象是否相等
  return (
    dateYear == currentDateYear &&
    dateMonth == currentDateMonth &&
    dateDay == currentDateDay
  );
}

export function uidStringLengthFixed(uid) {
  // const lengthNumber = 6;
  // const uidString = uid.toString();
  // let newUID = "";
  // const uIDlength = uidString.length;
  // const Difference = lengthNumber - uIDlength;
  // const uidArr = uidString.split("");
  // for (let i = 0; i < Difference; i++) {
  //   uidArr.unshift("0");
  // }
  // newUID = uidArr.join("");

  // 最新写法
  const uidString = uid.toString();
  const newUID = uidString.padStart(6, "0");
  return newUID;
}

export function decodeBase64String(code) {
  if (code == undefined || code == null) return "";
  try {
    const base64String = code;
    const decodedBytes = atob(base64String);
    const arrayBuffer = new Uint8Array(decodedBytes.length);
    for (let i = 0; i < decodedBytes.length; i++) {
      arrayBuffer[i] = decodedBytes.charCodeAt(i);
    }
    const decoder = new TextDecoder();
    const decodedString = decoder.decode(arrayBuffer);
    return decodedString;
  } catch (error) {
    return code;
  }
}

export function initRecordData(info, payData) {
  const cloneInfo = JSON.parse(JSON.stringify(info));
  // 只要五十条信息 模拟分页 !!!
  const spliceStart = 50;
  cloneInfo.length > spliceStart &&
    cloneInfo.splice(spliceStart, cloneInfo.length);

  const recordData = {
    type: "none",
    list: [],
  };

  if (cloneInfo.length == 0) return recordData;

  recordData.type = cloneInfo[0].type;
  cloneInfo.forEach((logItem) => {
    const logObj = {
      time: formatTimestamp(logItem.time),
      text: findRecordText(logItem),
      text2: "",
      type: logItem.type,
      lottery: logItem.lottery || 0,
      gold: logItem.gold || 0,
      tiaozhan_ticket: logItem.tiaozhan_ticket || 0,
      dashi_score: logItem.dashi_score || 0,
      add_gold: 0,
      item_type: logItem.item_type || "",
      add_mul: 0,
      rmb_mul: logItem.rmb_mul || 0,
    };

    if (logItem.s_type && logItem.s_type == "tz_card") {
      logObj.tiaozhan_ticket = logItem.info.num;
    }

    // if (
    //   logItem.item_type &&
    //   logItem.item_type == "zhekou_card" &&
    //   logItem.rmb_mul < 1
    // ) {
    //   const _parice = (
    //     payData[logItem.s_type][Number(logItem.index) - 1].price *
    //     logItem.rmb_mul
    //   ).toFixed(2);

    //   logObj.text2 = "使用了" + logItem.rmb_mul * 10 + "折卡 " + _parice + "￥";
    // }

    if (
      logItem.item_type &&
      logItem.item_type == "jiacheng_card" &&
      logItem.add_mul > 1
    ) {
      logObj.text2 =
        "使用了" +
        parseInt((Number(logItem.add_mul) - 1) * 100) +
        "%" +
        "加成卡";

      logObj.add_mul = parseInt(
        Number(logItem.base_gold) * (Number(logItem.add_mul) - 1)
      );
    }

    if (logItem.base_gold && logItem.base_gold > 0) {
      logObj.add_gold = parseInt(logItem.base_gold * logItem.add_radio);
      logObj.gold = logItem.base_gold;
    }

    if (logItem.tz_gold && logItem.tz_gold != 0 && logItem.gold == 0) {
      logObj.gold = -Math.abs(logItem.tz_gold);
    }

    if (logItem.tz_gold && logItem.tz_gold != 0 && logItem.gold < 0) {
      logObj.gold = -Math.abs(logItem.tz_gold);
    }
    recordData.list.push(logObj);
  });
  return recordData;
}

const findRecordText = (item) => {
  const type = item.type;
  const s_type = item.s_type ? item.s_type : "none";
  const gameName = findGameInfoData(item.game_name).game_name;

  const s_typeName = findStype(s_type);

  // 游戏记录
  if (type == "game_log") {
    const m_index = parseInt(item.machine_index) || "无";
    const d_index = parseInt(item.device_index) || "无";

    // 魔鬼城10币挑战赛1号房2号位;
    if (item.is_tiaozhan || item.tz_exchange)
      return `${gameName}${item.tz_exchange}币挑战赛${m_index}号房${d_index}号位`;

    return `${gameName}${m_index}号房${d_index}号位`;
  }

  // 金币记录
  if (type == "gold_log") {
    // 挑战成功
    if (s_type == "tiaozhan_success")
      // 魔鬼城10币挑战赛挑战成功金币奖励500;
      return `${gameName}${item.tz_exchange}币挑战赛挑战成功`;

    // 投币
    if (s_type == "up_coin") {
      const m_index = parseInt(item.machine_index) || "无";
      const d_index = parseInt(item.device_index) || "无";

      //  魔鬼城10币挑战赛1号房2号位投币 —500
      if (item.tz_gold || item.tz_exchange)
        return `${gameName}${item.tz_exchange}币挑战赛${m_index}号房${d_index}号位投币`;

      return `${gameName}${m_index}号房${d_index}号位${s_typeName}`;
    }

    // 比赛
    if (s_type == "active")
      return `${decodeBase64String(item.active_name)}参赛费`;

    if (s_type == "apply_tiaozhan")
      return `${gameName}${item.tz_exchange}币挑战赛报名`;

    if (s_type == "recharge") return `购买${findStype(item.recharge_type)}`;

    return s_typeName;
  }

  // 充值记录
  if (type == "recharge_log") {
    return `购买${s_typeName}￥${item.info.rmb}`;
  }

  // 挑战卡记录
  if (type == "tiaozhan_ticket_log") {
    // 魔鬼城10币挑战赛报名;
    if (s_type == "apply_tiaozhan")
      return `${gameName}${item.tz_exchange}币挑战赛报名`;

    return s_typeName;
  }

  // 积分记录
  if (type == "lottery_log") {
    const m_index = parseInt(item.machine_index) || "无";
    const d_index = parseInt(item.device_index) || "无";
    // 挑战
    // 魔鬼城10币挑战赛1号房2号位结算;
    if (item.is_tiaozhan || item.tz_gold || item.tz_exchange)
      return `${gameName}${item.tz_exchange}币挑战赛${m_index}号房${d_index}号位结算`;

    if (s_type == "duobao_exchang")
      return `${gameName}${m_index}号房${d_index}号位`;

    if (s_type == "game_log") return `${gameName}${m_index}号房${d_index}号位`;

    if (s_type == "game_task") return "游戏任务";

    return s_typeName;
  }

  //大师分记录
  if (type == "dashi_score_log") {
    // 魔鬼城10币挑战赛挑战成功大师分 + 1;
    if (s_type == "tiaozhan")
      return `${gameName}${item.tz_exchange}币挑战赛挑战成功`;

    return s_typeName;
  }

  return `type_${type}_s_type_${s_type}`;
};

/**
 *
 * @param {String} username
 * @returns {Boolean}
 */

export const validateUsername = (username) => {
  // 不能为空
  if (username === "") return false;

  // 不能有空格
  if (/\s/.test(username)) return false;

  // 不能以特殊字符开头
  const specialCharacters = /[!@#$%^&*(),.?":{}|<>]/;
  if (specialCharacters.test(username)) return false;

  // 定义敏感词列表  后续 申请 接入第三方敏感词库
  const sensitiveWords = [
    "广告",
    "卖币",
    "微信",
    "他妈的",
    "傻逼",
    "客服",
    "极客竞技",
  ];

  // 检查敏感词
  for (let i = 0; i < sensitiveWords.length; i++) {
    if (username.includes(sensitiveWords[i])) return false; // 返回不合法
  }

  // 检查是否包含非法字符：
  // const illegalCharacters = /[^a-zA-Z0-9]/;
  // if (illegalCharacters.test(username)) return false;

  // 昵称验证通过
  return true; // 返回合法
};

// 游戏任务
export const initGameViewTaskData = (info) => {
  const initData = {
    gameName: info[0].game_name,
    allTaskIndex: -1,
    allTaskName: "完成今日所有任务",
    allTaskReward: 0,
    allTaskRewardType: "lottery",
    allTaskStatu: 1, // 0 未完成 1 待领取 2 已完成
    getCount: 0,
    taskList: [],
  };

  let allTaskData = {};

  if (info[info.length - 1].type_name == "all_complete") {
    allTaskData = info[info.length - 1];
  } else {
    const findData = info.find((task) => {
      return task.type_name == "all_complete";
    });

    allTaskData = findData;
  }

  initData.allTaskIndex = allTaskData.index;
  initData.allTaskReward = allTaskData.reward;
  initData.allTaskRewardType = allTaskData.reward_type;
  initData.allTaskStatu = allTaskData.is_get ? 2 : 1;

  info.forEach((task) => {
    if (task.type_name != "all_complete") {
      const obj = {
        taskName: "undefined",
        taskIndex: 1,
        task_type: task.type_name,
        reward: 100,
        rewardType: "lottery",
        currentNumer: 100,
        complete: 200,
        statu: 0,
      };

      if (task.type_name == "up_coin") {
        obj.taskName = "累计投币";
      }

      if (task.type_name == "collect") {
        obj.taskName = `${
          findCollectCardInfo(task.game_name, task.class_id).cardName
        }`;

        obj.task_type = obj.task_type + "_" + task.class_id;
      }

      const step_index = Number(task.step) - 1;
      const step_data = task.data[step_index];

      obj.taskIndex = task.index;
      obj.currentNumer = task.num;
      obj.complete = step_data.complete;
      obj.reward = step_data.reward;
      obj.rewardType = step_data.reward_type;

      obj.statu = task.num >= step_data.complete && !step_data.is_get ? 1 : 0;

      step_data.is_get && (obj.statu = 2);

      if (obj.statu == 1) {
        initData.getCount++;
      }
      if ((obj.statu == 0 || obj.statu == 1) && initData.allTaskStatu == 1) {
        initData.allTaskStatu = 0;
      }

      initData.taskList.push(obj);
    }
  });

  return initData;
};

// 初始化比赛数据
export const initActiveConfigData = (info) => {
  const initActiveConfig = [];

  info.forEach((item) => {
    const initActiveConfigObj = {
      title: "魔鬼城3000金币积分挑战赛",
      statu: 1,
      gold_select_list: [
        // {
        //   gold: 100,
        //   sign_index: 1,
        //   sign_num: 1,
        // },
      ],
      exchange: item.config.exchange,
      join_num_limit: item.config.join_num_limit,
      join_num: item.join_num,
      enter_num_limit: item.config.enter_num_limit,
      match_time: Number(item.config.match_time) / 60,
      is_up_coin: item.config.is_up_coin,
      end_time: "03-05 17:00",
      start_time: "03-05 17:00",
      active_index: item.active_index,
      game_name: item.game_name,
      level_name:
        item.config.level_limit > 0
          ? gradLevelIcon[Number(item.config.level_limit)].level_text
          : "",
      level_limit: item.config.level_limit,
      sign_up_gold: 0,
      ticket: 0,
      reward_data_list: [],
      active_type: item.active_type,
      rank_num: item.config.rank_num,
    };

    // 默认是开启的
    initActiveConfigObj.statu = 1;

    if (item.status == "waitting") initActiveConfigObj.statu = 0;
    // 等待结算
    if (item.status == "wait_settle_accounts") initActiveConfigObj.statu = 3;

    // 已经结算完毕 完全结束了
    if (item.status == "ending") initActiveConfigObj.statu = 2;

    const sign_up_gold = item.config.sign_up_info?.sign_up_gold ?? 0;
    const ticket = item.config.sign_up_info?.ticket ?? 0;

    if (sign_up_gold > 0) initActiveConfigObj.sign_up_gold = sign_up_gold;
    if (ticket > 0) initActiveConfigObj.ticket = ticket;
    // 入座携带费
    if (item.config.sign_up_info) {
      const sign_index_datas = Object.keys(item.config.sign_up_info.site_gold);
      sign_index_datas.forEach((signIndex) => {
        initActiveConfigObj.gold_select_list.push({
          ...item.config.sign_up_info.site_gold[signIndex],
          sign_index: Number(signIndex),
        });
      });
    }

    // 结束时间
    initActiveConfigObj.end_time = formatTimestamp(item.config.end_time);
    // 开始时间
    initActiveConfigObj.start_time = formatTimestamp(item.config.start_time);

    // 奖励列表
    const reward_data_list = [];
    if (item.config.reward_data) {
      const reward_data_keys = Object.keys(item.config.reward_data);

      reward_data_keys.forEach((rewardItem) => {
        const obj = {
          ...item.config.reward_data[rewardItem],
          reward_index: rewardItem,
        };

        if (obj.reward_type == "gift")
          obj.reward.name = decodeBase64String(obj.reward.name);
        reward_data_list.push(obj);
      });
    }

    initActiveConfigObj.title = decodeBase64String(item.config.active_name);
    initActiveConfigObj.reward_data_list = reward_data_list;
    initActiveConfig.unshift(initActiveConfigObj);
  });

  return initActiveConfig;
};

export const initActiveRankData = (info) => {
  const list = [];
  if (info.length == 0) return [null];

  info.forEach((item) => {
    const rankItem = {
      enter_num: item.enter_num,
      num: item.num,
      record_list: [],
      record_num: 0,
      head: item.head != "" ? item.head : item.head_frame,
      uid: item.uid,
      active_type: item.active_type,
      player_name: decodeBase64String(item.player_name),
      recharge_level: item.recharge_level,
      game_name: item.game_name,
      tianti_level: item.tianti_level,
      active_index: item.active_index,
    };

    const recordClassIDs = Object.keys(item.record_class);

    if (recordClassIDs.length > 0) {
      recordClassIDs.forEach((classId) => {
        rankItem.record_num += Number(item.record_class[classId]);
        rankItem.record_list.push({
          ...findCollectCardInfo(item.game_name, classId),
          num: item.record_class[classId],
        });
      });
    }

    list.push(rankItem);
  });

  return list;
};

export function transformGameData(data) {
  const map = {};
  data.forEach((item) => {
    map[item.id] = { ...item, children: [] };
  });

  data.forEach((item) => {
    const parent = map[item.parent_id];
    if (parent) {
      parent.children.push(item);
    }
  });

  return Object.values(map).filter((item) => item.parent_id == 0);
}

export function propValueUnificationString(value) {
  if (typeof value === "number") return value + "px";
  return value;
}

export function showTime(time) {
  let date =
    typeof time === "number"
      ? new Date(time)
      : new Date((time || "").replace(/-/g, "/"));

  let diff = (new Date().getTime() - date.getTime()) / 1000;

  let dayDiff = Math.floor(diff / 86400);

  let isValidDate =
    Object.prototype.toString.call(date) === "[object Date]" &&
    !isNaN(date.getTime());

  if (!isValidDate) {
    window.console.error("不是有效日期格式");
  }
  const formatDate = function (date) {
    let today = new Date(date);
    let year = today.getFullYear();
    let month = ("0" + (today.getMonth() + 1)).slice(-2);
    let day = ("0" + today.getDate()).slice(-2);
    let hour = today.getHours();
    let minute = today.getMinutes();
    let second = today.getSeconds();
    return `${year}-${month}-${day} ${hour}:${minute}:${second}`;
  };

  //小于0或者大于等于31显示原时间
  if (isNaN(dayDiff) || dayDiff < 0 || dayDiff >= 31) {
    return formatDate(date);
  }
  return (
    (dayDiff === 0 &&
      ((diff < 60 && "刚刚") ||
        (diff < 120 && "1分钟前") ||
        (diff < 3600 && Math.floor(diff / 60) + "分钟前") ||
        (diff < 7200 && "1小时前") ||
        (diff < 86400 && Math.floor(diff / 3600) + "小时前"))) ||
    (dayDiff === 1 && "昨天") ||
    (dayDiff < 7 && dayDiff + "天前") ||
    (dayDiff < 31 && Math.ceil(dayDiff / 7) + "周前")
  );
}

export function parseDateStringWithMoment(dateString) {
  // 定义日期字符串的格式
  const format = "MM月DD日 HH:mm:ss";

  // 使用 Moment.js 解析日期字符串
  const momentDate = momentJs(dateString, format, true); // 第三个参数 true 表示如果解析失败则返回一个无效的 Moment 对象

  // 检查 Moment 对象是否有效
  if (!momentDate.isValid()) {
    throw new Error("日期格式不正确或日期无效");
  }

  // 获取时间戳（毫秒）
  const timestamp = momentDate.valueOf();

  // 返回时间戳
  return timestamp;
}
// 根据文件地址得到文件名
export function getImageFileNameWithoutExtensionFromUrl(url) {
  const regex = /([^/?#]+)\.[^/?#]+$/;
  const matches = url.match(regex);
  return matches && matches[1] ? matches[1] : "";
}
