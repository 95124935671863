<template>
  <div
    class="my-view"
    :style="{
      paddingTop: store.areaInsetTopValue + 10 + 'px',
    }"
  >
    <div class="container">
      <div class="my_user_info">
        <div class="user_id_wrapper" @click="handleCopyValueClick">
          UID:
          {{ store.userInfo.uid }}
        </div>
        <div class="user_head">
          <HeadPortrait
            width="100%"
            height="100%"
            :src="store.userInfo.headimgurl"
            :level="Number(store.userInfo.recharge_level)"
            :ae_id="store.userInfo.select_title"
            ae
            fe
          />
        </div>
        <div
          class="icon_setting"
          @click="
            () => {
              router.push('/set');
            }
          "
        ></div>
      </div>

      <div class="player_user_name">
        <p class="user_name">
          <span>{{ store.userInfo.player_name }}</span>
          <span
            class="auth_name_icon w-50 h-20"
            v-if="!store.userInfo.id_verification"
          ></span>
          <span
            class="auth_name_active_icon w-50 h-20"
            v-if="store.userInfo.id_verification"
          ></span>
        </p>
      </div>
      <div class="user_grade_wrapper">
        <Level
          :border="false"
          :level_value="Number(store.userInfo.recharge_level)"
          level_type="recharge_level"
          background="linear-gradient(130deg, rgba(53,138,198,0.48) 0%, rgba(51,22,94,0.14) 49%, rgba(53,138,198,0.48) 100%)"
        />
        <span style="display: inline-block; width: 4px"></span>
        <Level
          :border="false"
          :level_value="Number(store.userInfo.level)"
          background="linear-gradient(130deg, rgba(53,138,198,0.48) 0%, rgba(51,22,94,0.14) 49%, rgba(53,138,198,0.48) 100%)"
        />
      </div>

      <div class="my_info_box_wrapper">
        <div class="my_info_box_left">
          <div class="info_value">
            {{ addCommas(store.userInfo.lottery) }}
          </div>
        </div>
        <div class="my_info_box_right">
          <div class="info_value">{{ addCommas(store.userInfo.gold) }}</div>
        </div>
      </div>

      <div class="menu_list_wrapper">
        <div class="menu_list">
          <div
            class="menu_item"
            v-for="menuItem in menuListData"
            :key="menuItem.type"
            @click="handleCellClick(menuItem.type)"
          >
            <div class="menu_cover">
              <van-image
                width="100%"
                height="100%"
                fit="fill"
                position="center"
                :src="menuItem.icon"
              />
            </div>
            <div class="menu_text">
              {{ menuItem.text }}
            </div>
          </div>
        </div>
      </div>

      <div class="copyright_wrapper">Copyright © 2023 蔚品网络科技</div>
    </div>

    <!-- 奖项领取 -->
    <van-popup
      v-model:show="awardPupShow"
      :style="{ background: 'none' }"
      round
    >
      <div class="award_pup_wrapper">
        <div class="award_list_wrapper">
          <div class="award_list_item" v-for="awardItem in gameRewardList">
            <div class="award_text">
              <span>恭喜你获得</span>
              <div
                class="gold_icon_wrapper"
                v-if="awardItem.type == 'gold'"
              ></div>
              <div
                class="lottery_icon_wrapper"
                v-else-if="awardItem.type == 'lottery'"
              ></div>
              <span>x{{ awardItem.num }}</span>
            </div>
            <p class="award_declare">来自【{{ awardItem.typeText }}】</p>
            <p class="award_time">{{ awardItem.time }}</p>
            <div class="award_btn" @click="extract_game_reward(awardItem)">
              领取
            </div>
          </div>
        </div>

        <div class="no_award_wrapper" v-if="gameRewardList.length == 0">
          <van-image
            width="100%"
            height="auto"
            fit="fill"
            position="center"
            :src="no_award_img"
          />
        </div>
      </div>
    </van-popup>

    <van-popup v-model:show="awardGetSuss" style="background: none">
      <div class="award_suss_wrapper">
        <van-image
          width="100%"
          height="auto"
          fit="fill"
          position="center"
          :src="getRewardItem.type == 'gold' ? award_gold_bg : award_lottery_bg"
        />

        <div class="award_title">{{ getRewardItem.typeText }}</div>
        <div class="award_type_num">
          {{ getRewardItem.num }}
          {{ getRewardItem.type == "gold" ? "金币" : "彩券" }}
        </div>

        <div
          class="close_award_pup"
          @click="() => (awardGetSuss = false)"
        ></div>
      </div>
    </van-popup>

    <!-- 绑定手机号 -->
    <van-popup
      v-model:show="bindPhoneNumOkShow"
      style="background: none; overflow: visible"
    >
      <van-image
        width="100%"
        height="auto"
        block
        :src="ok_img1"
        @click="bindPhoneNumOkShowToggle(false)"
      />

      <div class="text_num">
        {{ store.userInfo.phone.replace(/^(\d{3})\d+(\d{4})$/, "$1****$2") }}
      </div>
    </van-popup>

    <van-popup
      v-model:show="bindPhoneNumShow"
      :close-on-click-overlay="false"
      style="background: none; overflow: visible"
    >
      <div class="bind_phone_wrap">
        <div class="close_icon" @click="bindPhoneNumShowToggle(false)"></div>

        <div class="bind_phone_container">
          <div class="text1"></div>

          <div class="phone_icon"></div>

          <van-cell-group inset>
            <van-field
              v-model="phone_num"
              label="手机号"
              placeholder="请输入手机号"
              label-width="1.25rem"
              type="number"
            />
            <van-field
              v-model="sms_code"
              center
              clearable
              label="验证码"
              placeholder="请输入短信验证码"
              label-width="1.25rem"
              type="number"
            >
              <template #button>
                <div class="sms_btn_wrap" @click="send_Sms_code">
                  {{ sms_statu ? `${sms_time}s重试` : "发送验证码" }}
                </div>
              </template>
            </van-field>
          </van-cell-group>

          <div class="btn_wrap">
            <div class="btn" @click="__ok('phone')">确定</div>
          </div>
        </div>
      </div>
    </van-popup>

    <!-- 绑定地址 -->
    <van-popup
      v-model:show="bindAddressNumShow"
      style="background: none; overflow: visible"
      :close-on-click-overlay="false"
    >
      <div class="bind_address_wrap">
        <div class="close_icon" @click="bindAddressNumShowToggle(false)"></div>

        <div class="bind_address_container">
          <div class="text1"></div>

          <div class="phone_icon"></div>

          <van-cell-group inset>
            <van-field
              v-model="address_val"
              label="地址"
              placeholder="填写详细的地址"
              label-width="1.25rem"
            />
            <!-- <van-field
              v-model="phone_num"
              label="门牌号"
              placeholder="请填写门牌号"
              label-width="1.25rem"
              type="number"
            />
            <van-field
              v-model="phone_num"
              label="收货人"
              placeholder="收货人姓名"
              label-width="1.25rem"
              type="number"
            /> 
            <van-field
              v-model="phone_num"
              label="手机号"
              placeholder="请填写手机号码"
              label-width="1.25rem"
              type="number"
            />-->
          </van-cell-group>

          <div class="btn_wrap">
            <div class="btn" @click="__ok('address')">确定</div>
          </div>
        </div>
      </div>
    </van-popup>

    <ServicePup
      :show="serverPupShow"
      @close_icon_click="serverPupShowChange(false)"
    />
  </div>
</template>

<script setup>
import { ref, reactive, onMounted } from "vue";
import { useRouter } from "vue-router";

import { closeToast, showToast } from "vant";
import { addCommas, decodeBase64String } from "@/utils/utils";
import Level from "@/components/Level.vue";
import ServicePup from "@/components/ServicePup.vue";
import findStype from "@/utils/stypeMaps";
import { formatTimestamp } from "@/utils/utils";

import { sendInstructions, asyncSendInstructions } from "@/api/sendData";
import { useStore } from "@/store/store";

import ok_img1 from "@/assets/images/my/ok_img1.png";
import useClipboard from "vue-clipboard3";

import { getCode } from "@/api";
import HeadPortrait from "@/components/HeadPortrait.vue";

const { toClipboard } = useClipboard();
const store = useStore();
const router = useRouter();

// const menu_icon1 = store.imgBaseUrl + "/myview/menu_icon1.png";
const menu_icon2 = store.imgBaseUrl + "/myview/menu_icon2.png";
const menu_icon3 = store.imgBaseUrl + "/myview/menu_icon3.png";
const menu_icon4 = store.imgBaseUrl + "/myview/menu_icon4.png";
const menu_icon5 = store.imgBaseUrl + "/myview/menu_icon5.png";
const menu_icon6 = store.imgBaseUrl + "/myview/menu_icon6.png";
const menu_icon7 = store.imgBaseUrl + "/myview/menu_icon7.png";
const menu_icon8 = store.imgBaseUrl + "/myview/menu_icon8.png";
const menu_icon9 = store.imgBaseUrl + "/myview/menu_icon9.png";
const menu_icon10 = store.imgBaseUrl + "/myview/menu_icon10.png";
const menu_icon11 = store.imgBaseUrl + "/myview/menu_icon11.png";
const no_award_img = store.imgBaseUrl + "/myview/no_award_img.png";

const award_lottery_bg = store.imgBaseUrl + "/myview/award_lottery_bg.png";
const award_gold_bg = store.imgBaseUrl + "/myview/award_gold_bg.png";

const initGameRewardList = (info) => {
  console.log("info", info);

  if (!Array.isArray(info)) return [];
  if (info.length == 0) return [];
  if (info.length > 30) info.length = 30;

  function findTypeName(type, index) {
    const curType = findStype(type);

    if (!index) return curType;

    if (index) return curType + `第${index}名奖励`;
  }

  const list = [];

  info.forEach((item) => {
    const awardItem = {
      type: item.reward_type ?? item.gold ? "gold" : "lottery",
      num: item.gold,
      key: item.key,
      time: 1,
      typeText: findTypeName(item.type, item.index),
    };

    if (item.time) {
      awardItem.time = formatTimestamp(item.time);
    } else {
      awardItem.time = new Date().getMonth() + 1 + "/" + new Date().getDay();
    }

    list.push(awardItem);
  });

  return list;
};

//#region 奖项领取
const gameRewardList = ref([]);

const awardPupShow = ref(false);
const awardGetSuss = ref(false);
const awardPupShowOpen = async () => {
  showToast({
    type: "loading",
    duration: 6e3,
    forbidClick: true,
  });

  try {
    const res = await asyncSendInstructions({
      msg_type: "get_game_reward",
    });
    closeToast();
    gameRewardList.value = initGameRewardList(res);

    console.log("gameRewardList.value", gameRewardList.value);
  } catch (error) {
    console.log("error", error);

    closeToast();
  }

  awardPupShow.value = true;
};
const awardPupShowClose = () => {
  awardPupShow.value = false;
};

const getRewardItem = reactive({
  num: 2000,
  typeText: "充值等级达成奖励",
  type: "gold",
});

let useThrottleFlage = false;
const extract_game_reward = async (item) => {
  if (useThrottleFlage) return;
  useThrottleFlage = true;

  const params = {
    msg_type: "extract_game_reward",
    key: item.key,
  };

  try {
    getRewardItem.num = item.num;
    getRewardItem.type = item.type;
    getRewardItem.typeText = item.typeText;

    awardGetSuss.value = true;
    const res = await asyncSendInstructions(params);

    if (res.error == "ok" || res.gold) {
      store.$patch((state) => {
        if (res.reward_type && res.reward_type == "lottery") {
          if (res.lottery) {
            state.userInfo.lottery =
              Number(state.userInfo.lottery) + Number(res.lottery);
          } else {
            state.userInfo.lottery =
              Number(state.userInfo.lottery) + Number(res.gold);
          }
        } else {
          res.gold &&
            (state.userInfo.gold =
              Number(state.userInfo.gold) + Number(res.gold));
        }
      });
      gameRewardList.value = gameRewardList.value.filter(
        (item) => item.key !== res.key
      );
    } else {
      showToast({
        type: "fail",
        message: "网络超时,稍后重试",
        onClose() {
          awardPupShowOpen();
        },
      });

      console.log("erssss", res);
    }

    useThrottleFlage = false;
  } catch (error) {
    useThrottleFlage = false;

    showToast({
      type: "fail",
      message: "领取太快啦~\n 请稍后重试",
      onClose() {
        awardPupShowOpen();
      },
    });

    console.log("error", error);
  }
};

//#endregion

// 客服弹窗
const serverPupShow = ref(false);
const serverPupShowChange = (type) => {
  serverPupShow.value = type;
};

//#region 菜单

const handleCopyValueClick = async () => {
  try {
    await toClipboard(store.userInfo.uid);
    showToast({
      type: "success",
      message: "复制成功!",
    });
  } catch (e) {
    console.error(e);
    showToast({
      type: "fail",
      message: "复制失败!",
    });
  }
};

const menuListData = reactive([
  {
    text: "奖项领取",
    icon: menu_icon2,
    type: "award",
    show: true,
  },
  {
    text: "我的卡券",
    icon: menu_icon7,
    type: "cardbag",
    show: true,
  },
  {
    text: "记录查询",
    icon: menu_icon3,
    type: "record",
    show: true,
  },
  {
    text: "我的成就",
    icon: menu_icon8,
    type: "achievement",
    show: true,
  },
  {
    text: "实名认证",
    icon: menu_icon4,
    type: "authuser",
    show: true,
  },
  {
    text: "游戏反馈",
    icon: menu_icon5,
    type: "feedback",
    show: true,
  },
  // {
  //   text: "手机绑定",
  //   icon: menu_icon6,
  //   type: "phone",
  //   show: true,
  // },
  {
    text: "在线客服",
    icon: menu_icon11,
    type: "sever",
    show: true,
  },
  {
    text: "我的地址",
    icon: menu_icon10,
    type: "address",
    show: true,
  },
  {
    text: "青少年模式",
    icon: menu_icon9,
    type: "child",
    show: true,
  },
  // {
  //   text: "测试页面",
  //   icon: menu_icon6,
  //   type: "test",
  //   show: true,
  // },
]);

const handleCellClick = (type) => {
  switch (type) {
    case "test":
      store.storeShowToggle("longShopShow", true);
      // router.push("/test");
      break;
    case "award":
      // router.push("/award");
      awardPupShowOpen();
      break;
    case "authuser":
      store.storeShowToggle("auth_user_show", true);
      break;
    case "record":
      router.push("/record");
      break;
    case "feedback":
      router.push("/question");
      break;
    case "child":
      store.child_info.child_show = true;
      break;
    case "cardbag":
      router.push("/cardbag");
      break;
    case "achievement":
      router.push("/achievement");
      break;
    case "sever":
      // const params = {
      //   u_cust_id: parseInt(store.userInfo.uid),
      //   u_cust_name: parseInt(store.userInfo.uid),
      // };

      // const instruct = Object.entries(params)
      //   .map(([key, value]) => `${key}=${value}`)
      //   .join("&");
      // console.log("instruct", instruct);

      // window.location.href = "/dialog_1.htm?" + instruct;
      try {
        window.$_c53_api.push("cmd", "member");
        window.$_c53_api.push("id", parseInt(store.userInfo.uid));
        window.$_c53_api.push("name", parseInt(store.userInfo.uid));
        window.$_c53_api.push("email", "x");
        window.$_c53_api.push("qq", "x");
        window.$_c53_api.push("wechat", "x");
        window.$_c53_api.push("phone", "x");
        window.$_c53_api.push("company", "x");
        window.$_c53_api.push("address", "x");
        window.$_c53_api.push("notes", "我的-在线客服");

        window.$_c53_api.query();
        window.$_c53_api.push("cmd", "kfclient");
        window.$_c53_api.push("type", "popup");
        window.$_c53_api.query();
      } catch (error) {
        showToast({
          type: "fail",
          message: "暂无客服",
        });
      }
      break;
    case "address":
      bindAddressNumShowToggle(true);
      break;
  }
};
//#endregion

//#region 绑定手机号

const bindPhoneNumOkShow = ref(false);
const bindPhoneNumShow = ref(false);

const bindPhoneNumOkShowToggle = (statu) => {
  bindPhoneNumOkShow.value = statu;
};
const bindPhoneNumShowToggle = (statu) => {
  bindPhoneNumShow.value = statu;
};

const phone_num = ref("");
const sms_code = ref("");
const sms_statu = ref(false);
const sms_time = ref(60);

const sms_time_reset = () => {
  if (sms_time.value - 1 <= 0) {
    sms_statu.value = false;
    sms_time.value = 60;
  } else {
    sms_time.value = sms_time.value - 1;
    setTimeout(() => {
      sms_time_reset();
    }, 1e3);
  }
};

const send_Sms_code = async () => {
  if (sms_statu.value) return;

  try {
    const res = await getCode({
      phone: phone_num.value,
    });

    if (res.error == 0) {
      sms_statu.value = true;
      sms_time_reset();
    } else {
      showToast({
        type: "fail",
        message: res.msg,
      });
    }
  } catch (error) {}
};

const __ok = async (type) => {
  if (type == "address" && removeSpaces(address_val.value) == "") {
    showToast({
      message: "请先填写地址",
    });
    return;
  }
  if (type == "phone" && phone_num.value == "") {
    showToast({
      message: "请先填写号码",
    });
    return;
  }
  if (type == "phone" && sms_code.value == "") {
    showToast({
      message: "请先填写验证码",
    });
    return;
  }

  showToast({
    type: "loading",
    forbidClick: true,
    duration: 0,
  });
  const params = {
    msg_type: "bind_phone",
    phone: phone_num.value,
    code: sms_code.value,
    address: removeSpaces(address_val.value),
    type,
  };

  try {
    const res = await asyncSendInstructions(params);
    if (res.error == "ok") {
      closeToast();
      showToast({
        type: "success",
        message: "绑定成功",
      });

      store.$patch((state) => {
        state.userInfo.phone = res.phone;
        state.userInfo.address = decodeBase64String(res.address);
      });
      type == "phone"
        ? bindPhoneNumShowToggle(false)
        : bindAddressNumShowToggle(false);
    } else {
      closeToast();

      showToast({
        type: "fail",
        message: res.error,
      });
    }
  } catch (error) {
    closeToast();
    showToast({
      type: "fail",
      message: "网络不佳\n稍后重试",
    });

    // store.errorstingmm22 = error;
  }
};

//#endregion

//#region

function removeSpaces(str) {
  return str.replace(/\s+/g, "");
}
const address_val = ref("");
const bindAddressNumShow = ref(false);
const bindAddressNumShowToggle = (statu) => {
  bindAddressNumShow.value = statu;
};

//#endregion

const GetUserBindInfo = async () => {
  try {
    const res = await asyncSendInstructions({
      msg_type: "get_player_address",
    });

    store.$patch((state) => {
      state.userInfo.phone = res.phone ?? "";
      state.userInfo.address = decodeBase64String(res.address) ?? "";
    });

    phone_num.value = res.phone;
    address_val.value = decodeBase64String(res.address);
  } catch (error) {}
};

onMounted(() => {
  GetUserBindInfo();
});
</script>

<style lang="scss" scoped>
.my-view {
  background: linear-gradient(
    180deg,
    #3a0a24 0%,
    #160c54 25%,
    #3a1b67 44%,
    #3a1b67 100%
  );
  overflow: hidden;
  position: relative;
  padding-bottom: 60px;

  .my_user_info {
    position: relative;

    height: 90px;
    .user_id_wrapper {
      position: absolute;
      left: 0;
      top: 0;
      background-color: rgba($color: #fff, $alpha: 0.3);
      padding: 2px 24px 2px 6px;
      border-radius: 20px;
      width: 100px;
      height: 22px;
      white-space: nowrap;
      color: #fff;
      &::before {
        content: "";

        position: absolute;

        right: 6px;
        top: 50%;
        transform: translateY(-50%);
        width: 12px;
        height: 12px;

        background-image: url("https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/myview/copy_icon.png");
        background-size: 100% 100%;
        background-repeat: no-repeat;
      }
    }

    .user_head {
      position: absolute;
      width: 90px;
      height: 90px;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }
    .icon_setting {
      position: absolute;
      right: 0;
      top: 0;
      width: 24px;
      height: 24px;
      background-size: 100% 100%;
      background-repeat: no-repeat;
      background-image: url("https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/myview/setting_icon.png");
    }
  }

  .player_user_name {
    display: flex;
    justify-content: center;
    .user_name {
      // transform: translateX(25px);
      display: flex;
      justify-content: center;

      .auth_name_icon {
        background-image: url("https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/myview/auth_name_icon.png");
        background-size: 100% 100%;
        background-repeat: no-repeat;
        margin-left: 4px;
      }
      .auth_name_active_icon {
        margin-left: 4px;
        background-image: url("https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/myview/auth_name_active_icon.png");
        background-size: 100% 100%;
        background-repeat: no-repeat;
      }
    }
  }

  .copyright_wrapper {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
  }

  .user_grade_wrapper {
    position: relative;
    text-align: center;
    padding: 4px 0 14px 0;
    &::after {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 1px;
      background-image: url("https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/myview/line_icon.png");
      background-size: 100% 100%;
      background-repeat: no-repeat;
    }
  }

  .my_info_box_wrapper {
    position: relative;
    // border-radius: 20px;
    background-image: url("https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/myview/box_bg.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    min-height: 89px;
    margin-top: 20px;
    display: flex;
    .my_info_box_left {
      position: relative;
      flex: 1;
      .info_value {
        position: absolute;
        left: 50%;
        top: 38%;
        transform: translate(-50%, -50%);
        padding-left: 22px;
        font-size: 20px;
        font-weight: 800;

        &::before {
          content: "";
          position: absolute;
          width: 20px;
          height: 20px;
          left: 0;
          top: 50%;
          transform: translateY(-40%);
          background-image: url("https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/global/lottery.png");
          background-size: 100% 100%;
          background-repeat: no-repeat;
        }

        &::after {
          content: "彩券";
          position: absolute;
          left: 50%;
          bottom: 0;
          transform: translate(-50%, 120%);
          font-size: 12px;
          color: rgba($color: #fff, $alpha: 0.6);
          white-space: nowrap;
          text-wrap: nowrap;
        }
      }
    }
    .my_info_box_right {
      flex: 1;
      position: relative;
      .info_value {
        position: absolute;
        left: 50%;
        top: 38%;
        transform: translate(-50%, -50%);
        padding-left: 22px;
        font-size: 20px;
        font-weight: 800;
        &::before {
          content: "";
          position: absolute;
          width: 20px;
          height: 20px;
          left: 0;
          top: 50%;
          transform: translateY(-40%);
          background-image: url("https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/global/gold.png");
          background-size: 100% 100%;
          background-repeat: no-repeat;
        }

        &::after {
          content: "电玩币";
          position: absolute;
          left: 50%;
          bottom: 0;
          transform: translate(-50%, 120%);
          font-size: 12px;
          color: rgba($color: #fff, $alpha: 0.6);
          white-space: nowrap;
          text-wrap: nowrap;
        }
      }
    }

    &::after {
      content: "";
      position: absolute;
      left: 50%;
      top: 50%;
      height: 50%;
      width: 1px;
      background-color: #fff;
      transform: translateY(-50%);
    }
  }

  .menu_list_wrapper {
    padding: 10px 20px;
    border-radius: 15px;
    border: 1px solid #9544e2;
    margin-top: 20px;
    .menu_list {
      display: flex;
      flex-wrap: wrap;
      .menu_item {
        width: 33.33%;
        margin-top: 10px;
        padding-bottom: 10px;
        .menu_cover {
          width: 50px;
          height: 50px;
          margin: 0 auto;
        }
        .menu_text {
          text-align: center;
          font-size: 14px;
        }
      }
    }
  }
}

.in_code_wrapper {
  padding: 20px 10px;
  width: 310px;
  .in_code_title {
    font-size: 20px;
    font-weight: bold;
    text-align: center;
    color: #000000;
  }

  .in_code_input_wrapper {
    margin-top: 20px;
    margin-bottom: 20px;
    .in_code_input {
      width: 100%;
      background-color: #eeeeee;
      height: 40px;
      border-radius: 10px;
      outline: none;
      border: none;
      line-height: 40px;
      font-size: 14px;
      text-indent: 1em;
      color: #8a8a8a;
    }
  }

  .in_code_btn {
    width: 230px;
    height: 40px;
    margin: 0 auto;
    background: linear-gradient(180deg, #f0d24e 0%, #fe8304 100%);
    text-align: center;
    font-weight: 800;
    font-size: 20px;
    color: #000000;
    border-radius: 20px;
    line-height: 40px;
  }
}

.award_pup_wrapper {
  background-image: url("https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/myview/award_wrapper_bg.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  width: 90vw;
  height: 452px;
  padding: 36px 24px 13px 24px;
  position: relative;

  .award_list_wrapper {
    height: 100%;
    overflow-y: auto;
    .award_list_item {
      margin-top: 10px;
      border: 1px solid #9879cc;
      background: #462b72;
      border-radius: 10px;
      padding: 10px;
      position: relative;

      .award_text {
        display: flex;
        height: 22px;
        justify-content: start;
        align-items: center;

        span {
          font-size: 14px;
          font-family: Source Han Sans CN, Source Han Sans CN;
          font-weight: bold;
          background: linear-gradient(180deg, #fff2f7 31%, #d85099 100%);
          background-clip: text;
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
        }

        .gold_icon_wrapper {
          width: 16px;
          height: 16px;
          background-image: url("https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/global/gold.png");
          background-size: 100% 100%;
          background-repeat: no-repeat;
        }
        .lottery_icon_wrapper {
          width: 16px;
          height: 16px;
          background-image: url("https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/global/lottery.png");
          background-size: 100% 100%;
          background-repeat: no-repeat;
        }
      }

      .award_declare {
        color: #ffffff;
        margin-top: 4px;
      }

      .award_time {
        margin-top: 14px;
        font-size: 10px;
        color: rgba($color: #fff, $alpha: 0.6);
      }

      .award_btn {
        width: 60px;
        height: 20px;
        background: linear-gradient(180deg, #fab9e3 0%, #ff77c0 100%);
        border-radius: 50px 50px 50px 50px;
        opacity: 1;
        position: absolute;
        bottom: 10px;
        right: 10px;
        text-align: center;
        line-height: 20px;
      }
    }
  }

  .no_award_wrapper {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 140px;
  }
}

.award_suss_wrapper {
  position: relative;

  .award_title {
    position: absolute;
    top: 190px;
    width: 100%;
    text-align: center;
  }
  .award_type_num {
    position: absolute;
    bottom: 126px;
    width: 100%;
    text-align: center;
    color: #fee22a;
  }

  .close_award_pup {
    width: 100%;
    height: 72px;
    position: absolute;
    bottom: 0;
  }
}

.text_num {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: 110px;
  color: #9f9f9f;
  z-index: 3;
  font-size: 14px;
}

.bind_phone_wrap {
  position: relative;
  .close_icon {
    position: absolute;
    right: 0;
    top: 0;
    transform: translate(30%, -50%);
    width: 40px;
    height: 40px;
    background-image: url("https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/pup_close2.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    z-index: 3;
  }

  .bind_phone_container {
    position: relative;
    width: 300px;
    height: 280px;
    padding-top: 90px;
    background: linear-gradient(
        224deg,
        rgba(198, 248, 251, 0.72) 0%,
        rgba(228, 217, 247, 0) 20%,
        rgba(228, 217, 247, 0) 74%,
        rgba(255, 207, 243, 0.79) 96%
      ),
      linear-gradient(
        139deg,
        rgba(196, 252, 249, 0) 0%,
        rgba(196, 252, 249, 0) 83%,
        #c4fcf9 100%
      ),
      linear-gradient(180deg, #bbd8f9 0%, #ffffff 58%, #eef6f9 100%);

    border-radius: 30px 30px 30px 30px;

    .text1 {
      position: absolute;
      top: 22px;
      left: 18px;
      width: 120px;
      height: 36px;
      background-image: url("@/assets/images/my/text1.png");
      background-size: 100% auto;
      background-repeat: no-repeat;
      &::after {
        content: "绑定手机防止账号丢失";
        position: absolute;
        bottom: 0;
        left: 0;
        font-size: 12px;
        transform: translateY(50%);
        color: #3e405640;
      }
    }

    .phone_icon {
      position: absolute;
      right: 40px;
      top: 0;
      transform: translateY(-14px);
      width: 98px;
      height: 98px;
      background-image: url("@/assets/images/my/phone_icon.png");
      background-size: 100% 100%;
      background-repeat: no-repeat;
    }

    .sms_btn_wrap {
      font-size: 12px;
      color: #ff6d11;
    }

    .btn_wrap {
      padding-top: 28px;
      .btn {
        margin: 0 auto;
        width: 164px;
        height: 42px;
        background: linear-gradient(90deg, #81c3ff 0%, #5034ff 100%);
        box-shadow: inset 0px -5px 13px 0px #bed9ff;
        border-radius: 22px;
        text-align: center;
        line-height: 42px;
        color: #fff;
        font-size: 16px;
      }
    }
  }
}
.bind_address_wrap {
  position: relative;
  .close_icon {
    position: absolute;
    right: 0;
    top: 0;
    transform: translate(30%, -50%);
    width: 40px;
    height: 40px;
    background-image: url("https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/pup_close2.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    z-index: 3;
  }

  .bind_address_container {
    position: relative;
    width: 300px;
    height: 220px;
    padding-top: 90px;
    background: linear-gradient(
        224deg,
        rgba(198, 248, 251, 0.72) 0%,
        rgba(228, 217, 247, 0) 20%,
        rgba(228, 217, 247, 0) 74%,
        rgba(255, 207, 243, 0.79) 96%
      ),
      linear-gradient(
        139deg,
        rgba(196, 252, 249, 0) 0%,
        rgba(196, 252, 249, 0) 83%,
        #c4fcf9 100%
      ),
      linear-gradient(180deg, #bbd8f9 0%, #ffffff 58%, #eef6f9 100%);

    border-radius: 30px 30px 30px 30px;

    .phone_icon {
      position: absolute;
      right: 40px;
      top: 0;
      transform: translateY(-14px);
      width: 98px;
      height: 98px;
      background-image: url("@/assets/images/my/address_icon.png");
      background-size: 100% 100%;
      background-repeat: no-repeat;
    }

    .text1 {
      position: absolute;
      top: 22px;
      left: 18px;
      width: 120px;
      height: 36px;
      background-image: url("@/assets/images/my/text2.png");
      background-size: 100% auto;
      background-repeat: no-repeat;
      &::after {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        font-size: 12px;
        transform: translateY(50%);
        color: #3e405640;
      }
    }

    .btn_wrap {
      padding-top: 28px;
      .btn {
        margin: 0 auto;
        width: 164px;
        height: 42px;
        background: linear-gradient(90deg, #81c3ff 0%, #5034ff 100%);
        box-shadow: inset 0px -5px 13px 0px #bed9ff;
        border-radius: 22px;
        text-align: center;
        line-height: 42px;
        color: #fff;
        font-size: 16px;
      }
    }
  }
}
</style>
