<template>
  <div
    class="invitation-wrapper"
    :style="{
      paddingTop: store.areaInsetTopValue + 'px',
    }"
  >
    <van-nav-bar
      left-arrow
      @click-left="router.back()"
      :border="false"
      :placeholder="true"
    />

    <div class="invitation_container container">
      <div class="invitation_block">
        <div class="block_title">
          <van-image
            width="100%"
            height="auto"
            fit="fill"
            :show-loading="false"
            :src="invitation_code_title"
          />
        </div>
        <div class="code_wrapper">
          <span class="code">{{ store.userInfo.share_code }}</span>
        </div>
        <p class="t-center pt-4 pb-4" style="color: rgba(255, 255, 255, 0.6)">
          点击“立即邀请”自动复制成功
        </p>

        <div class="copy_code_btn" @click="handleGoToShare"></div>
      </div>

      <div class="mt-12 mb-12 h-24 flex flex-center" style="line-height: 24px">
        <p>
          邀请人可获得
          <span
            style="
              font-weight: 800;
              font-size: 16px;
              background: linear-gradient(180deg, #ffe7c2 20%, #d68930 100%);
              -webkit-background-clip: text;
              background-clip: text;
              -webkit-text-fill-color: transparent;
            "
            >388</span
          ><span>电玩币</span>
        </p>
        <div
          class="pl-20 pr-20 ml-8"
          style="
            background: linear-gradient(180deg, #696fff 0%, #353bc9 100%);
            border-radius: 6px;
          "
          @click="fillInCodeShowToggle"
        >
          填写邀请码
        </div>
      </div>

      <van-image
        width="100%"
        height="auto"
        fit="fill"
        block
        :show-loading="false"
        :src="invitation_msg"
      />

      <div class="invitation_block" style="margin-top: 32px">
        <div class="block_title">
          <van-image
            width="100%"
            height="auto"
            fit="fill"
            :show-loading="false"
            :src="invitation_log_title"
          />
        </div>

        <div class="invitation_log_wrapper">
          <div class="invitation_log_title">
            <span>昵称</span>
            <span>奖励</span>
          </div>
          <div class="invitation_log_list_wrapper">
            <template v-if="inviteLogList.length > 0">
              <div
                class="invitation_log_list_item"
                v-for="logItem in inviteLogList"
                :key="logItem.uid"
              >
                <div class="log_item_name">
                  {{ decodeBase64String(logItem.player_name) }}
                  <!-- {{ logItem.player_name }} -->
                </div>
                <div class="log_item_reward">
                  {{ logItem.gold || logItem.reward }}
                </div>
              </div></template
            >
            <template v-else>
              <div
                class="invitation_log_list_item t-center"
                style="color: #bdbdbd"
              >
                <p class="flex-1">暂无邀请记录，马上去邀请好友拿奖励吧！</p>
              </div></template
            >
          </div>
        </div>
      </div>
    </div>

    <!-- 日常奖励 :style="{ background: 'none' }" -->
    <van-popup v-model:show="invitation_code_show" round>
      <div class="in_code_wrapper">
        <div class="in_code_title">填写邀请码</div>
        <div class="in_code_input_wrapper">
          <input
            class="in_code_input"
            placeholder="请输入邀请码"
            v-model="fill_code_value"
          />
        </div>
        <div
          class="in_code_btn van-haptics-feedback"
          @click="fillInCodeConfirm"
        >
          立即领币
        </div>
      </div>
    </van-popup>

    <!-- 分享面板 -->

    <van-share-sheet
      v-model:show="showShare"
      :options="shareOptions"
      title="立即分享给好友"
      description="点击复制链接,发给您的好友!"
      style="color: #221c2b"
      @select="onSelect"
    />
  </div>
</template>

<script setup>
import { useRouter } from "vue-router";
import { useStore } from "@/store/store";
import { decodeBase64String } from "@/utils/utils";
import { sendInstructions, asyncSendInstructions } from "@/api/sendData";
import useClipboard from "vue-clipboard3";
import { ref, onBeforeMount } from "vue";
import { showToast } from "vant";

const { toClipboard } = useClipboard();
const router = useRouter();
const store = useStore();

const invitation_log_title = store.imgBaseUrl + "/invitation_log_title.png";
const invitation_code_title = store.imgBaseUrl + "/invitation_code_title.png";
// const invitation_box = store.imgBaseUrl +  "/invitation_box.png";
const invitation_msg = store.imgBaseUrl + "/invitation_msg.png";

const invitation_code_show = ref(false);
const fill_code_value = ref("");

const fillInCodeShowToggle = async () => {
  try {
    const res = asyncSendInstructions({
      msg_type: "get_invite_info",
    });

    if (res.invite_uid) {
      showToast({
        type: "fail",
        message: "已领取",
      });
      return;
    }
    invitation_code_show.value = true;
  } catch (error) {}
};

const fillInCodeConfirm = async () => {
  if (fill_code_value.value == "") {
    showToast({
      type: "fail",
      message: "请输入邀请码",
    });

    return;
  }

  const params = {
    msg_type: "bind_invite",
    invite_code: fill_code_value.value,
  };

  const res = await asyncSendInstructions(params);

  if (res.error == "ok") {
    showToast({
      type: "success",
      message: "绑定成功！",
    });

    if (res.gold) {
      store.$patch((state) => {
        state.userInfo.gold = Number(state.userInfo.gold) + Number(res.gold);
      });
    }
  } else {
    showToast({
      type: "fail",
      message: res.error,
    });
  }

  // 最新log
  sendInstructions(
    {
      msg_type: "get_invite_reward_log",
    },
    500
  );

  invitation_code_show.value = false;
};

//#region 分享邀请
const showShare = ref(false);
const shareOptions = [
  [
    { name: "微信", icon: "wechat" },
    { name: "朋友圈", icon: "wechat-moments" },
    { name: "复制链接", icon: "link" },
  ],
];
const handleGoToShare = () => {
  showShare.value = true;
  // let str = "http://aly.weipinwl.com/index.php/Home/Share?uid=8";
};

const onSelect = async (option, index) => {
  if (index != 2) {
    sendInstructions(
      {
        msg_type: "share_game",
        type: index + 1,
      },
      0
    );
  }

  if (index == 2) {
    const shareUrl =
      "http://aly.weipinwl.com/index.php/Home/Share?uid=" +
      parseInt(store.userInfo.uid);

    try {
      await toClipboard(shareUrl);
      showToast({
        type: "success",
        message: "复制成功!",
      });
    } catch (e) {
      console.error(e);
      showToast({
        type: "fail",
        message: "复制失败!",
      });
    }
  }

  showShare.value = false;
};
//#endregion

const inviteLogList = ref([]);

const getLogData = async () => {
  try {
    const res = asyncSendInstructions({
      msg_type: "get_invite_reward_log",
    });

    inviteLogList.value = res;
  } catch (error) {}
};

onBeforeMount(() => {
  getLogData();
});
</script>

<style lang="scss" scoped>
.invitation-wrapper {
  min-height: 100vh;
  background-image: url("https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/invitation_top_bg.png");
  background-repeat: no-repeat;
  background-size: 100% auto;
  .invitation_container {
    padding-top: 156px;
    .invitation_block {
      position: relative;
      border: 2px solid #fff;
      border-radius: 24px;
      padding: 26px 20px 20px;
      background: linear-gradient(90deg, #6c28e9 0%, #3948d4 100%);

      box-shadow: inset 0px 0px 11px 8px rgba(82, 15, 227, 0.8);
      .block_title {
        position: absolute;
        top: 0;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 179px;
        height: 32px;
      }

      .code_wrapper {
        background: rgba(15, 19, 147, 0.2);
        text-align: center;
        padding: 4px 0;
        border-radius: 25px;
        height: 45px;
        line-height: 45px;
        .code {
          font-size: 28px;
          letter-spacing: 1px;
          background: linear-gradient(180deg, #ffe7c2 20%, #d68930 100%);
          -webkit-background-clip: text;
          background-clip: text;
          -webkit-text-fill-color: transparent;
          font-weight: 1000;
        }
      }

      .copy_code_btn {
        width: 170px;
        height: 43px;
        background-image: url("https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/copy_code_btn.png");
        background-size: 100% 100%;
        background-repeat: no-repeat;
        margin: 0 auto;
      }

      .invitation_log_wrapper {
        .invitation_log_title {
          display: flex;
          justify-content: space-between;
          padding: 5px 0;
          color: #fff;
          border-bottom: 1px solid #3de0c2;
          font-size: 12px;
        }

        .invitation_log_list_wrapper {
          .invitation_log_list_item {
            display: flex;
            height: 26px;
            line-height: 26px;
            justify-content: space-between;
            .log_item_name {
              flex: 1;
              font-weight: bold;
              color: #fff;
            }
            .log_item_reward {
              position: relative;
              color: #fff;
              padding-right: 28px;
              &::after {
                content: "";
                position: absolute;
                top: 50%;
                right: 0;
                transform: translateY(-50%);
                width: 26px;
                height: 26px;
                background-image: url("https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/global/gold.png");
                background-size: 100% 100%;
                background-repeat: no-repeat;
              }
            }
          }
        }
      }
    }
  }
}

.in_code_wrapper {
  padding: 20px 10px;
  width: 310px;
  .in_code_title {
    font-size: 20px;
    font-weight: bold;
    text-align: center;
    color: #000000;
  }

  .in_code_input_wrapper {
    margin-top: 20px;
    margin-bottom: 20px;
    .in_code_input {
      width: 100%;
      background-color: #eeeeee;
      height: 40px;
      border-radius: 10px;
      outline: none;
      border: none;
      line-height: 40px;
      font-size: 14px;
      text-indent: 1em;
      color: #8a8a8a;
    }
  }

  .in_code_btn {
    width: 230px;
    height: 40px;
    margin: 0 auto;
    background: linear-gradient(180deg, #f0d24e 0%, #fe8304 100%);
    text-align: center;
    font-weight: 800;
    font-size: 20px;
    color: #000000;
    border-radius: 20px;
    line-height: 40px;
  }
}
</style>
