const MGC_CLASS_ID = {
  0: {
    cardName: "修罗巨魔",
    url: "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/collect/card_01@2x.png",
  },
  1: {
    cardName: "千年南瓜怪",
    url: "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/collect/card_02@2x.png",
  },
  2: {
    cardName: "巨石炎魔",
    url: "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/collect/card_03@2x.png",
  },
  3: {
    cardName: "幻影死神",
    url: "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/collect/card_04@2x.png",
  },
  4: {
    cardName: "狂暴飞龙",
    url: "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/collect/card_05@2x.png",
  },
  5: {
    cardName: "电光破",
    url: "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/collect/card_06@2x.png",
  },
  6: {
    cardName: "系统爆机",
    url: "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/collect/card_13%402x.png",
  },
  7: {
    cardName: "连环爆裂",
    url: "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/collect/card_07@2x.png",
  },
  8: {
    cardName: "锁链封印",
    url: "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/collect/card_08@2x.png",
  },
  9: {
    cardName: "驱魔烈焰",
    url: "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/collect/card_09@2x.png",
  },
  10: {
    cardName: "骷髅暴君",
    url: "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/collect/card_10@2x.png",
  },
  11: {
    cardName: "魔旋弹",
    url: "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/collect/card_11@2x.png",
  },
  12: {
    cardName: "魔法召唤",
    url: "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/collect/card_12@2x.png",
  },
};
const BBL_CLASS_ID = {
  0: {
    cardName: "不可思议",
    url: "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/bbl/c03@2x.png",
  },
  1: {
    cardName: "你是传奇",
    url: "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/bbl/c04@2x.png",
  },
  2: {
    cardName: "太棒啦",
    url: "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/bbl/c01@2x.png",
  },
  3: {
    cardName: "幸运降临",
    url: "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/bbl/c02@2x.png",
  },
  4: {
    cardName: "连爆大挑战",
    url: "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/bbl/c05@2x.png",
  },
};
const MXT_CLASS_ID = {
  0: {
    cardName: "JP1",
    url: "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/collect/tbj_jp1.png",
  },
  1: {
    cardName: "JP2",
    url: "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/collect/tbj_jp2.png",
  },
  2: {
    cardName: "JP3",
    url: "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/collect/tbj_jp3.png",
  },
  3: {
    cardName: "全盘奖",
    url: "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/collect/tbj_all.png",
  },
  4: {
    cardName: "叠叠乐",
    url: "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/collect/tbj_ddl.png",
  },
  5: {
    cardName: "小玛莉",
    url: "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/collect/tbj_mary.png",
  },
};
const MSQY_CLASS_ID = {
  0: {
    cardName: "无中奖",
    url: "",
  },
  1: {
    cardName: "JP3",
    url: "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/collect/tbj_jp3.png",
  },
  2: {
    cardName: "JP2",
    url: "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/collect/tbj_jp2.png",
  },
  3: {
    cardName: "JP1",
    url: "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/collect/tbj_jp1.png",
  },
  4: {
    cardName: "叠叠乐",
    url: "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/collect/tbj_ddl.png",
  },
  5: {
    cardName: "小玛莉",
    url: "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/collect/tbj_mary.png",
  },
  6: {
    cardName: "连线奖",
    url: "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/collect/tbj_lx.png",
  },
  7: {
    cardName: "全盘奖",
    url: "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/collect/tbj_all.png",
  },
  8: {
    cardName: "开心60S",
    url: "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/collect/tbj_60s.png",
  },
};
const HW3_CLASS_ID = {
  0: {
    cardName: "史前巨鳄",
    url: "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/hw/hw_card_4.png",
  },
  1: {
    cardName: "旋风鱼",
    url: "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/hw/hw_card_11.png",
  },
  2: {
    cardName: "暗夜巨兽",
    url: "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/hw/hw_card_1.png",
  },
  3: {
    cardName: "深海八爪",
    url: "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/hw/hw_card_3.png",
  },
  4: {
    cardName: "电磁炮",
    url: "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/hw/hw_card_6.png",
  },
  5: {
    cardName: "至尊神龙",
    url: "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/hw/hw_card_13.png",
  },
  6: {
    cardName: "觉醒",
    url: "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/hw/hw_card_16.png",
  },
  7: {
    cardName: "赤炎龙龟",
    url: "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/hw/hw_card_12.png",
  },
  8: {
    cardName: "转盘蟹",
    url: "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/hw/hw_card_14.png",
  },
  9: {
    cardName: "连环炸弹蟹",
    url: "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/hw/hw_card_7.png",
  },
  10: {
    cardName: "钻头炮",
    url: "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/hw/hw_card_10.png",
  },
  11: {
    cardName: "闪电连锁",
    url: "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/hw/hw_card_8.png",
  },
  12: {
    cardName: "雷神锤",
    url: "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/hw/hw_card_15.png",
  },
  13: {
    cardName: "雷霸龙",
    url: "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/hw/hw_card_5.png",
  },
  14: {
    cardName: "霸王蟹",
    url: "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/hw/hw_card_9.png",
  },
  15: {
    cardName: "霸王鲸",
    url: "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/hw/hw_card_2.png",
  },
  16: {
    cardName: "系统爆机",
    url: "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/collect/card_13%402x.png",
  },
  17: {
    cardName: "美人鱼",
    url: "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/hw/hw_card_17.png",
  },
  18: {
    cardName: "浴火凤凰",
    url: "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/hw/hw_card_18.png",
  },
};
const HDCZ_CLASS_ID = {
  0: {
    cardName: "jp1",
    url: "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/collect/tbj_jp1.png",
  },
  1: {
    cardName: "jp2",
    url: "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/collect/tbj_jp2.png",
  },
  2: {
    cardName: "jp3",
    url: "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/collect/tbj_jp3.png",
  },
  3: {
    cardName: "全盘奖",
    url: "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/collect/tbj_all.png",
  },
  4: {
    cardName: "小玛莉",
    url: "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/collect/tbj_mary.png",
  },
  5: {
    cardName: "藏宝图",
    url: "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/collect/tbj_cbt.png",
  },
  6: {
    cardName: "叠叠乐",
    url: "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/collect/tbj_ddl.png",
  },
};
const BJWG_CLASS_ID = {
  0: {
    cardName: "JP1",
    url: "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/collect/tbj_jp1.png",
  },
  1: {
    cardName: "JP2",
    url: "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/collect/tbj_jp2.png",
  },
  2: {
    cardName: "JP3",
    url: "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/collect/tbj_jp3.png",
  },
  3: {
    cardName: "叠叠乐",
    url: "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/collect/tbj_ddl.png",
  },
  4: {
    cardName: "藏宝图",
    url: "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/collect/tbj_cbt.png",
  },
  5: {
    cardName: "北极宝箱",
    url: "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/collect/tbj_bjbx.png",
  },
};
const BBXC_CLASS_ID = {
  0: {
    cardName: "JP1",
    url: "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/collect/tbj_jp1.png",
  },
  1: {
    cardName: "JP2",
    url: "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/collect/tbj_jp2.png",
  },
  2: {
    cardName: "JP3",
    url: "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/collect/tbj_jp3.png",
  },
  3: {
    cardName: "全盘奖",
    url: "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/collect/tbj_all.png",
  },
  4: {
    cardName: "叠叠乐",
    url: "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/collect/tbj_ddl.png",
  },
  5: {
    cardName: "好运连连",
    url: "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/collect/tbj_hyll.png",
  },
};
const BJL_CLASS_ID = {
  0: {
    cardName: "JP1",
    url: "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/collect/tbj_jp1.png",
  },
  1: {
    cardName: "JP2",
    url: "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/collect/tbj_jp2.png",
  },
  2: {
    cardName: "JP3",
    url: "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/collect/tbj_jp3.png",
  },
  3: {
    cardName: "全盘奖",
    url: "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/collect/tbj_all.png",
  },
  4: {
    cardName: "小玛莉",
    url: "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/collect/tbj_mary.png",
  },
  5: {
    cardName: "叠叠乐",
    url: "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/collect/tbj_ddl.png",
  },
  6: {
    cardName: "一推到底",
    url: "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/collect/tbj_ytdd.png",
  },
};

/**
 *
 * @param {string} game_name
 * @param {string | number} class_id
 * @returns   {Object}
 *
 */
const findCollectCardInfo = (game_name, class_id) => {
  const collectClassIDs = {
    mgc: MGC_CLASS_ID,
    bbl: BBL_CLASS_ID,
    mxt: MXT_CLASS_ID,
    msqy: MSQY_CLASS_ID,
    hw3: HW3_CLASS_ID,
    bjl: BJL_CLASS_ID,
    bbxc: BBXC_CLASS_ID,
    hdcz: HDCZ_CLASS_ID,
    bjwg: BJWG_CLASS_ID,
  };

  if (!collectClassIDs[game_name])
    return new Error(`Couldn't find a Game ${game_name} Data`);

  return collectClassIDs[game_name][class_id];
};

export default findCollectCardInfo;
