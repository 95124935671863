<template>
  <van-overlay :show="slots_show" z-index="2002">
    <div class="slot_wrapper scale_in_center_class">
      <div class="close_icon" @click="exposeOuterControlSlotShow(false)"></div>
      <div class="sm_icom" @click="rule_wrap_show_toggle(true)">规则说明</div>
      <div class="switch_wrapper">
        <van-image :src="switch_btn" :show-loading="false" width="100%" height="100%" v-show="!start_statu" />
        <van-image :src="switch_btn_active" :show-loading="false" width="100%" height="100%" v-show="start_statu" />
      </div>
      <div class="outer_block"></div>
      <transition name="van-fade">
        <div class="win_light" v-show="winLightShow"></div>
      </transition>

      <div class="free_num_wrapp">剩余{{ data_config.free_num }}次</div>

      <div class="win_gold_wrap">
        <div class="slots_gold_item" v-show="SlotsGoldNumerResult > 0">
          <div class="gold_item">
            {{ SlotsGoldNumerResult }}
          </div>
        </div>

        <van-swipe v-show="SlotsGoldNumerResult == 0" :autoplay="3000" :duration="2600" :show-indicators="false">
          <van-swipe-item v-for="logItem in slotsLogs">
            <div class="log_item_wrapper">
              <div class="head_w">
                <HeadPortrait width="100%" height="100%" :src="logItem.head" />
              </div>
              <div class="user_text">
                {{ logItem.player_name }}

                获得
              </div>

              <div class="log_win_num">
                {{ logItem.total_gold }}
              </div>
            </div>
          </van-swipe-item>
        </van-swipe>
      </div>

      <div class="bg_light"></div>
      <div class="runner_wrapper">
        <div class="runner">
          <div class="runner_item" ref="_SlotItem" v-for="runner_item in data_config.runner_list">
            <div class="blur_background"></div>

            <div class="lock_mask" v-if="!runner_item.active">
              <div class="level_txt">{{ runner_item.level_name }}</div>
            </div>
          </div>
        </div>
      </div>

      <div class="start_btn" v-if="!againBtnShow" @click="requestGetSlotsResult">
        <van-image :src="start_btn" :show-loading="false" width="100%" height="100%" v-show="!start_statu" />
        <van-image :src="start_btn_active" :show-loading="false" width="100%" height="100%" v-show="start_statu" />
      </div>

      <div class="start_btn" v-if="againBtnShow" @click="store.soltsAdData.showAgainBtn = true">
        <van-image :src="again_btn" :show-loading="false" width="100%" height="100%" />
      </div>
    </div>

    <div class="reward_wrap" v-show="reward_wrap_show">
      <div class="win_pup_wrap scale_in_center_class">
        <div class="gold_item_wrap">
          <div class="gold_item"></div>
          <div class="gold_num">{{ reward_gold_num }}</div>
        </div>
        <div class="get_btn" @click="handleResetSlots">领取</div>
      </div>
    </div>

    <div class="rule_wrap" v-show="rule_wrap_show">
      <div class="rule_block scale_in_center_class">
        <div class="close_icon" @click="rule_wrap_show_toggle(false)"></div>
        <div class="title">转轮说明</div>
        <div class="content">
          1.所有玩家每日登陆可免费启动转一次；<br />
          2.每充值100元可额外获得一次；<br />
          3.当转到+1次时，会自动启动一次转轮，总赢金币为两次结果的相加； 当转到
          x N时，总赢金币为N倍。<br />
          4.转轮每日启动上限为99次 ；<br />
          5.游戏次数每日24点清理，不累计。<br />
        </div>
      </div>
    </div>

    <div class="again_dialog" v-if="store.soltsAdData.showAgainBtn">
      <div class="again_wrap scale_in_center_class">
        <div class="again_bg">
          <div class="again_close_icon" @click="store.soltsAdData.showAgainBtn = false"></div>
          <div class="bg_box">
            <div class="bg_text_1">再来一次</div>
            <div class="bg_text_2">领取一次抽奖次数</div>
            <div class="again_btns">
              <div class="to_pay" @click="handleToPay">
                <div class="to_icon"></div>
                <div class="to_text">去充值</div>
              </div>
              <div class="to_Ad" @click="handleAgain">
                <div class="to_icon"></div>
                <div class="to_text">看广告</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="ad_reward_wrap" v-show="store.soltsAdData.showAdReward">
      <div class="win_pup_wrap scale_in_center_class">
        <div class="gold_item_wrap">
          <div class="gold_item"></div>
          <div class="gold_num">抽奖次数+1</div>
        </div>
        <div class="get_btn" @click="handeAdReward">确认</div>
      </div>
    </div>
  </van-overlay>
</template>

<script setup>
import { sendInstructions, asyncSendInstructions } from "@/api/sendData";
import {
  reactive,
  ref,
  onMounted,
  watch,
  onBeforeUnmount,
  onActivated,
} from "vue";
import { showToast, closeToast } from "vant";
import { loginSlotsLog, getGuanggaoConfig } from "@/api";
import { getCurrentDevice } from "@/utils/utils";

import HeadPortrait from "@/components/HeadPortrait.vue";
import start_btn from "@/assets/images/slots/start_btn.png";
import start_btn_active from "@/assets/images/slots/start_btn_active.png";
import again_btn from "@/assets/images/slots/btn_zailai.png";

import switch_btn from "@/assets/images/slots/switch_btn.png";
import switch_btn_active from "@/assets/images/slots/switch_btn_active.png";
import { gradLevelIcon } from "@/utils/levelData";
import { useToggle } from "@vant/use";
import { useStore } from "@/store/store";
import { Howl, Howler } from "howler";
import { useRouter } from "vue-router";
import slotsItemOk from "@/assets/audios/slots_item_ok.mp3";
import slotsItem3Ok from "@/assets/audios/slots_item3_ok.mp3";
import slotsSpin from "@/assets/audios/slots_spin.mp3";
import getSuccHint from "@/assets/audios/get_hint_1.mp3";
import slots_bg_ms from "@/assets/audios/slots_bg_ms.mp3";
const store = useStore();
const [slots_show, slots_show_toggle] = useToggle(false);

const _SlotItem = ref();

const router = useRouter();
const emits = defineEmits(["when_close"]);
//#region 声音
const getSuccHintSoundSound = new Howl({
  src: [getSuccHint],
  autoplay: false,
  loop: false,
});

const slots_bg_msSound = new Howl({
  src: [slots_bg_ms],
  autoplay: false,
  loop: true,
});

const slotsItemOkSound = new Howl({
  src: [slotsItemOk],
  autoplay: false,
  loop: false,
});
const slotsItem3OkSound = new Howl({
  src: [slotsItem3Ok],
  autoplay: false,
  loop: false,
});
const slotsSpindSound = new Howl({
  src: [slotsSpin],
  autoplay: false,
  loop: false,
});

watch(
  () => slots_show.value,
  (newV) => {
    if (newV) {
      slots_bg_msSound.play();
    } else {
      slots_bg_msSound.pause();
    }
  }
);

//#endregion

watch(
  () => router.currentRoute.value,
  (newV) => {
    if (slots_show.value) {
      slots_show_toggle(false);
    }
  }
);

//#region 数据配置

const exposeOuterControlSlotShow = async (statu = false) => {
  if (start_statu.value) return;

  // statu && getConfigData();
  if (statu) {
    await getConfigData();
    againBtnShow.value = shouldShowAgainBtn();
  }

  if (store.isFirstOpen && !statu) emits("when_close");

  slots_show_toggle(statu);
};

// 用户配置 [true, false, false, false]
const data_config = reactive({
  free_num: 0,
  runner_list: [
    {
      active: false,
      level_name: "黄金II解锁",
    },
    {
      active: false,
      level_name: "",
    },
    {
      active: false,
      level_name: "",
    },
    {
      active: false,
      level_name: "",
    },
  ],
});

let _c = 0;
const getConfigData = async () => {
  console.log("getConfigDatagetConfigData");

  try {
    const res = await asyncSendInstructions({
      msg_type: "get_player_login_slots",
    });

    const userL = store.userInfo.recharge_level;

    let _count_free_num = 0;
    const _free_num = Number(res.player_login_slots.free_num);
    const _recharge_free_num = Number(res.player_login_slots.recharge_free_num);

    store.soltsAdData.ad_status = res.player_login_slots.guanggao;
    !isNaN(_free_num) && (_count_free_num += _free_num);
    !isNaN(_recharge_free_num) && (_count_free_num += _recharge_free_num);

    store.ad_status = res.player_login_slots.guanggao;

    data_config.free_num = _count_free_num;

    if (_count_free_num > 0 && store.isFirstOpen) {
      store.isFirstOpen = false;
      setTimeout(() => {
        slots_show_toggle(true);
      }, 1e3);
    } else if (_count_free_num == 0 && store.isFirstOpen) {
      setTimeout(() => {
        exposeOuterControlSlotShow(false);
        store.isFirstOpen = false;
      }, 1e3);
    }
    // const aa = [1,2,3,4]
    let lock1dlevel = res.level_def[2].level[0];
    let lock4dlevel = res.level_def[1].level[0];

    data_config.runner_list[0].level_name =
      gradLevelIcon[lock1dlevel].level_text + "激活";
    data_config.runner_list[3].level_name =
      gradLevelIcon[lock4dlevel].level_text + "激活";

    res.level_def.forEach((rit, rind) => {
      const min_l = rit.level[0];
      const max_l = rit.level[1];
      if (userL >= min_l && userL <= max_l) {
        data_config.runner_list[0].active = rit.slots_num[0];
        data_config.runner_list[1].active = rit.slots_num[1];
        data_config.runner_list[2].active = rit.slots_num[2];
        data_config.runner_list[3].active = rit.slots_num[3];
      }
    });
  } catch (error) {
    if (_c == 0) {
      _c++;
      getConfigData();
    }
  }
};

onMounted(() => {
  // await getConfigData();
  // getAdConfig();
});

onActivated(() => {
  getConfigData();
  if (store.channelType == "IOS" && store.isFirstOpen) {
    setTimeout(() => {
      getConfigData();
    }, 200);
  }

  // getAdConfig();
});

onBeforeUnmount(() => {
  slots_bg_msSound.pause();
  slots_show_toggle(false);
});

// 转结果
const SlotsResult = reactive([]);
const SlotsGoldNumerResult = ref(0);
const [winLightShow, winLightShowToggle] = useToggle(false);
const requestGetSlotsResult = async () => {
  if (data_config.free_num == 0) {
    showToast({
      type: "fail",
      message: "次数不足",
    });
    return;
  }

  if (start_statu.value) return;
  start_statu_toggle(true);
  showToast({
    type: "loading",
    duration: 0,
  });
  try {
    const res = await asyncSendInstructions({
      msg_type: "spin_login_slots",
    });

    if (res.error == "ok") {
      closeToast();
      data_config.free_num = data_config.free_num - 1;

      res.reward_info.forEach((_res) => {
        const _sl_res = [null, null, null, null];

        for (const index_key in _res) {
          const reward_item = _res[index_key];

          const index_num = Number(index_key) - 1;
          _sl_res[index_num] =
            index_num == 3 ? reward_item.index - 1 : reward_item.index;
        }

        SlotsResult.push(_sl_res);
      });

      // res.reward_info[0].

      reward_gold_num.value = res.total_gold;

      store.$patch((state) => {
        state.userInfo.gold = state.userInfo.gold + Number(res.total_gold);
      });

      // console.log("_sl_res", SlotsResult);

      start(SlotsResult[0]);
    } else {
      closeToast();
    }
  } catch (error) { }
};
//#endregion

//#region 抽奖记录
const slotsLogs = ref([]);

const getLogList = async () => {
  const res = await loginSlotsLog();
  const initList = [];
  if (res.data) {
    for (const key in res.data) {
      const initdata = {
        player_name: "",
        total_gold: 0,
        head: "",
        id: "",
      };

      const item = res.data[key];

      initdata.id = item._id;
      initdata.player_name = item.player_name;
      initdata.total_gold = item.total_gold;
      initdata.head = item.headimgurl || item.headFrame;

      initList.push(initdata);
    }
  }

  slotsLogs.value = initList;
};

onMounted(() => {
  if (slotsLogs.value.length == 0) {
    getLogList();
  }
});

// loginSlotsLog

//#endregion

//#region 动画
const [start_statu, start_statu_toggle] = useToggle(false);
const start = (indexs) => {
  if (!_SlotItem.value) return;

  slotsSpindSound.play();
  // const _rem = parseFloat(document.documentElement.style.fontSize);
  // console.log("_rem_rem_rem", _rem);
  let last_index = 0;

  indexs.forEach((_i, li) => {
    if (_i) {
      last_index = li;
    }
  });

  const _ph = 1.6;
  let imgH = 17.6;
  const allTask = [];
  let q = 4;
  for (let i = 0; i < _SlotItem.value.length; i++) {
    if (indexs[i] === null) continue;
    q++;
    i == 3 && (q += 12) && (imgH = 11.2);

    const element = _SlotItem.value[i];
    const targetPy = imgH - indexs[i] * _ph + 0.69333 + q * imgH; // 目标值
    // const duration = i + 1 + "e3" - 0; // 持续时间（废弃方案）
    let handle_index = i;
    if (i == last_index) handle_index = 99;

    allTask.push(
      handleSequenceFrameAnimate(element, targetPy, indexs[i], handle_index)
    );
  }

  Promise.all(allTask).then((res) => {
    winLightShowToggle(true);
    if (res[3] === 6 && SlotsResult.length == 2) {
      let _count_n = "";
      SlotsResult[0].forEach((ii, indx) => {
        if (indx != 3) _count_n += ii;
      });
      SlotsGoldNumerResult.value = Number(_count_n);
      setTimeout(() => {
        winLightShowToggle(false);
        start(SlotsResult[1]);
      }, 2e3);
    } else {
      SlotsGoldNumerResult.value = reward_gold_num.value;
      setTimeout(() => {
        start_statu_toggle(false);
        reward_wrap_show_toggle(true);
        winLightShowToggle(false);
        SlotsGoldNumerResult.value = 0;
        SlotsResult.length = 0;
      }, 2e3);
    }
  });
};

const handleSequenceFrameAnimate = (element, targetPy, index, eleIndex) => {
  // 缓进渐出
  const easeInOutQuad = (t) => (t < 0.5 ? 2 * t * t : -1 + (4 - 2 * t) * t);
  // const easeInOutQuad = (t) => t;
  element.style.filter = `blur(0px)`;
  element.style["-webkit-filter"] = `blur(0px)`;
  return new Promise((resolve, reject) => {
    let startV = 0;
    const sequenceFrameAnimate = () => {
      startV += 0.4;
      const progress = startV / targetPy;
      if (progress > 0.1 || progress > 0.7) {
        element.style.filter = `blur(1px)`;
        element.style["-webkit-filter"] = `blur(1px)`;
      }
      if (progress > 0.2 || progress > 0.6) {
        element.style.filter = `blur(2px)`;
        element.style["-webkit-filter"] = `blur(2px)`;
      }
      if (progress > 0.3 || progress > 0.5) {
        element.style.filter = `blur(3px)`;
        element.style["-webkit-filter"] = `blur(3px)`;
      }
      if (progress > 0.4 && progress < 0.5) {
        element.style.filter = `blur(4px)`;
        element.style["-webkit-filter"] = `blur(4px)`;
      }

      if (progress > 0.8) {
        element.style.filter = `blur(0px)`;
        element.style["-webkit-filter"] = `blur(0px)`;
      }

      const currentPy = easeInOutQuad(progress) * targetPy;
      element.style["background-position"] = `0 ${currentPy}rem`;
      if (startV <= targetPy && progress < 1) {
        try {
          requestAnimationFrame(sequenceFrameAnimate);
        } catch (error) {
          sequenceFrameAnimate();
        }
      } else {
        eleIndex == 99 ? slotsItem3OkSound.play() : slotsItemOkSound.play();
        element.style["background-position"] = `0 ${targetPy}rem`;
        resolve(index);
      }
    };

    sequenceFrameAnimate();
  });
};
//#endregion

//#region 领取
const reward_gold_num = ref(0);
const [reward_wrap_show, reward_wrap_show_toggle] = useToggle(false);

const handleResetSlots = async () => {
  reward_gold_num.value - 0;
  getSuccHintSoundSound.play();
  for (let i = 0; i < _SlotItem.value.length; i++) {
    const element = _SlotItem.value[i];
    let targetPy = 0.69333;
    if (i == 0) targetPy = 2.29333;
    element.style["background-position"] = `0 ${targetPy}rem`;
  }

  reward_wrap_show_toggle(false);
  getLogList();
  await getConfigData();
  if (shouldShowAgainBtn()) {
    setTimeout(() => {
      store.soltsAdData.showAgainBtn = true;
      againBtnShow.value = true;
    }, 300);
  }
  // slots_show_toggle(false);
};

//#endregion

//#region 规则说明
// const reward_gold_num = ref(0);
const [rule_wrap_show, rule_wrap_show_toggle] = useToggle(false);

//#endregion

//再来一次 -> 观看广告
const handleAgain = async () => {
  if (store.userInfo.ios_version != "2.0.0" && store.channelType != "IOS") {
    store.isUpdatePop = true;
    return;
  }

  let prime_rit;
  store.adConfigData.forEach((item) => {
    const reward = JSON.parse(item.reward);
    if (reward[0].type == "login_slots") {
      prime_rit = item.prime_rit;
    }
  });

  const _params = {
    msg_type: "play_ad",
    id: prime_rit,
  };

  console.log('_params', _params);

  sendInstructions(_params);

  showToast({
    type: "loading",
    forbidClick: true,
    message: "加载广告中,\n请稍后...",
    duration: 3000,
    zIndex: 2024,
  });

  // sendInstructions({ msg_type: "sync_guanggao_reward" });
};

//去充值
const handleToPay = () => {
  store.soltsAdData.showAgainBtn = false;
  slots_show_toggle(false);
  setTimeout(() => {
    store.payPupToggle(true, "first");
  }, 200);
};

//领取广告奖励
const handeAdReward = async () => {
  store.soltsAdData.showAdReward = false;
  getLogList();
  await getConfigData();
  againBtnShow.value = false;
};

const againBtnShow = ref(false);
const shouldShowAgainBtn = () => {
  const device = getCurrentDevice() == "IOS" ? "ios" : "android";
  return (
    data_config.free_num == 0 &&
    !store.soltsAdData.ad_status &&
    device == "android" &&
    store.initAdStatus
  );
};

defineExpose({ exposeOuterControlSlotShow, data_config: data_config });
</script>

<style lang="scss" scoped>
.reward_wrap {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 2;

  background-color: rgba($color: #000000, $alpha: 0.3);

  .win_pup_wrap {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -62%);
    width: 350px;
    height: 346px;
    background-image: url("https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/active/win_pup_bg.png");
    background-size: 100% auto;
    background-repeat: no-repeat;

    .gold_item_wrap {
      position: absolute;
      top: 200px;
      width: 48px;
      height: 62px;
      left: 50%;
      transform: translateX(-50%);

      .gold_item {
        width: 48px;
        height: 48px;
        background-color: #9369e1;
        border-radius: 6px;
        background-image: url("https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/global/gold.png");
        background-size: 100% 100%;
        background-repeat: no-repeat;
      }

      .gold_num {
        width: 100%;
        text-align: center;
        white-space: nowrap;
        color: #724026;
        font-size: 14px;
        padding: 4px 0;
      }
    }

    .get_btn {
      position: absolute;
      bottom: 0;
      left: 50%;
      width: 120px;
      height: 32px;
      transform: translate(-50%, 20px);
      color: #693015;
      line-height: 32px;
      text-align: center;
      border-radius: 16px;
      font-size: 16px;
      background: linear-gradient(180deg, #fcd15a 0%, #ebad00 100%);
    }
  }
}

.ad_reward_wrap {
  @extend .reward_wrap;

  .win_pup_wrap {
    .gold_item_wrap {
      width: 80px;
      height: 75px;
      display: flex;
      flex-direction: column;
      align-items: center;

      .gold_item {
        width: 55px;
        height: 50px;
        background: none;
        background-image: url("@/assets/images/slots/muls_2.png");
        background-size: 65px 420px;
        background-position: -8px 53px;
      }
    }
  }
}

.again_dialog {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 2;

  background-color: rgba($color: #000000, $alpha: 0.7);

  .again_wrap {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -62%);

    .again_bg {
      width: 319px;
      height: 203px;
      background-image: url("@/assets/images/slots/pup_bg.png");
      background-size: 100% 100%;
      position: relative;

      .again_close_icon {
        position: absolute;
        right: 0px;
        top: 0px;
        width: 32px;
        height: 32px;
        background-image: url("@/assets/images/slots/icon_quxiao.png");
        background-size: 100% 100%;
      }

      .bg_box {
        position: absolute;
        left: 50%;
        top: 40px;
        transform: translateX(-50%);

        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .bg_text_1 {
          color: #6135ff;
          font-size: 20px;
          text-shadow: 0 -1px #fff, 1px 0px #fff, 0 1px #fff, -1px 0 #fff,
            -1px -1px #fff, 1px 1px #fff, 1px -1px #fff, -1px 1px #fff;
        }

        .bg_text_2 {
          color: #6135ff;
          font-size: 14px;
        }

        .again_btns {
          display: flex;
          margin-top: 20px;

          .to_pay {
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 14px;
            margin-right: 8px;
            width: 100px;
            height: 38px;
            background-image: url("@/assets/images/slots/btn_chongzhi.png");
            background-size: 100% 100%;

            .to_icon {
              width: 23px;
              height: 23px;
              background-image: url("@/assets/images/slots/icon_chognzhi.png");
              background-size: 100% 100%;
              margin-right: 5px;
            }

            .to_text {
              text-shadow: 0 -1px #632007, 1px 0px #632007, 0 1px #632007,
                -1px 0 #632007, -1px -1px #632007, 1px 1px #632007,
                1px -1px #632007, -1px 1px #632007;
            }
          }

          .to_Ad {
            @extend .to_pay;
            margin-right: 0px;
            margin-left: 8px;
            background-image: url("@/assets/images/slots/btn_guanggao.png");

            .to_icon {
              background-image: url("@/assets/images/slots/icon_guanggao.png");
            }

            .to_text {
              text-shadow: 0 -1px #076331, 1px 0px #076331, 0 1px #076331,
                -1px 0 #076331, -1px -1px #076331, 1px 1px #076331,
                1px -1px #076331, -1px 1px #076331;
            }
          }
        }
      }
    }
  }
}

.rule_wrap {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
  background-color: rgba($color: #000000, $alpha: 0.3);

  .rule_block {
    position: absolute;
    left: 50%;
    top: 50%;
    width: 335px;
    height: 260px;
    background-image: url("https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/global/sm_pup_bg.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    transform: translate(-50%, -50%);

    .close_icon {
      position: absolute;
      top: 0px;
      right: 0px;
      width: 30px;
      height: 30px;
      background-image: url("https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/pup_close_icon.png");
      background-size: 100% 100%;
      background-repeat: no-repeat;
      z-index: 99;
    }

    .title {
      position: absolute;
      width: 120px;
      height: 36px;
      left: 50%;
      transform: translate(-50%, 0);
      text-align: center;
      line-height: 36px;
      font-size: 16px;
      color: #621064;
    }

    .content {
      position: absolute;
      width: 84%;
      left: 50%;
      top: 50px;
      transform: translate(-50%, 0);
      max-height: 200px;
      line-height: 26px;
    }
  }
}

.slot_wrapper {
  position: absolute;
  left: 50%;
  top: 50%;
  width: 340px;
  height: 340px;
  transform: translate(-50%, -50%);
  z-index: 1;

  .close_icon {
    position: absolute;
    top: 80px;
    right: 20px;
    width: 30px;
    height: 30px;
    background-image: url("https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/pup_close_icon.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    z-index: 99;
  }

  .sm_icom {
    position: absolute;
    left: 30px;
    top: 90px;
    text-wrap: nowrap;
    font-size: 12px;

    padding-right: 16px;
    z-index: 99;

    &::after {
      position: absolute;
      content: "";
      width: 12px;
      height: 12px;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
      background-image: url("https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/title_icon.png");
      background-size: 100% 100%;
      background-repeat: no-repeat;
    }
  }

  .free_num_wrapp {
    position: absolute;
    bottom: 0px;
    width: 100%;
    text-align: center;
  }

  .win_gold_wrap {
    position: absolute;
    left: 84px;
    bottom: 60px;
    height: 28px;
    width: 170px;

    z-index: 999;

    .log_item_wrapper {
      width: 100%;
      height: 28px;
      display: flex;
      justify-content: center;

      .head_w {
        width: 28px;
        height: 28px;
        padding: 2px;
      }

      .user_text {
        font-size: 12px;
        color: #fff;
        line-height: 28px;
        max-width: 70px;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .log_win_num {
        position: relative;
        padding-left: 24px;
        color: #ff9600;
        line-height: 28px;
        font-weight: bold;

        &::after {
          content: "";
          position: absolute;
          top: 50%;
          left: 2px;
          transform: translateY(-50%);
          width: 18px;
          height: 18px;
          background-image: url("https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/global/gold.png");
          background-size: 100% 100%;
          background-repeat: no-repeat;
        }
      }
    }

    .slots_gold_item {
      display: flex;
      justify-content: center;

      .gold_item {
        position: relative;
        padding-left: 24px;
        color: #ff9600;
        font-size: 16px;
        font-weight: bold;
        line-height: 28px;

        &::after {
          content: "";
          position: absolute;
          left: 0;
          top: 50%;
          transform: translateY(-50%);
          width: 20px;
          height: 20px;
          background-image: url("https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/global/gold.png");
          background-size: 100% 100%;
          background-repeat: no-repeat;
          margin-right: 4px;
        }
      }
    }
  }

  .switch_wrapper {
    position: absolute;
    width: 42px;
    height: 152px;
    right: 0;
    top: 100px;
    z-index: 9;
  }

  .start_btn {
    position: absolute;
    left: 50%;
    bottom: 0px;
    width: 138px;
    height: 50px;
    transform: translate(-50%, 60px);
  }

  .outer_block {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 3;

    background-image: url("@/assets/images/slots/outer.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
  }

  .bg_light {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 1;
    background-image: url("@/assets/images/slots/bg_light.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
  }

  .runner_wrapper {
    position: absolute;
    width: 100%;
    height: 100%;
    background-image: url("@/assets/images/slots/runner.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    z-index: 2;

    .runner {
      position: absolute;
      top: 130px;
      left: 56px;
      width: 234px;
      height: 112px;
      display: flex;

      .runner_item {
        position: relative;
        flex: 1;
        background-image: url("@/assets/images/slots/numbers.png");
        background-size: 56px 660px;
        background-repeat: repeat-y;
        background-position: 0 26px;
        filter: blur(0px);
        -webkit-filter: blur(0px);

        &:nth-child(1) {
          background-position: 0 86px;
        }

        &:nth-child(4) {
          background-position: 0 146px;
        }

        // filter: blur(5px);
        // -webkit-filter: blur(5px);

        .blur_background {
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
        }

        .lock_mask {
          position: absolute;
          width: 100%;
          height: 100%;
          background-color: rgba($color: #000000, $alpha: 0.46);
          background-image: url("https://jikejingji.oss-cn-guangzhou.aliyuncs.com/appClientStatic/images/global/lock.png");

          background-size: 20px 20px;
          background-position: center 30%;
          background-repeat: no-repeat;

          .level_txt {
            position: absolute;
            top: 42%;
            width: 100%;
            // transform: ;
            font-size: 8px;
            color: #fff;
            text-align: center;
          }
        }

        &:last-child {
          background-image: url("@/assets/images/slots/muls_2.png");
          background-size: 60px 420px;
        }
      }
    }
  }

  .win_light {
    position: absolute;
    width: 100%;
    height: 100%;
    background-image: url("@/assets/images/slots/win_light.png");
    background-size: 100% 2040px;
    background-repeat: no-repeat;
    background-position: 0 0;
    z-index: 4;
    animation-name: slotWinSequenceframe;
    animation-duration: 1s;
    animation-timing-function: step-start;
    // animation-delay: 1s;
    animation-iteration-count: infinite;
  }
}

@-webkit-keyframes slotWinSequenceframe {
  0% {
    background-position: 0 0px;
  }

  16% {
    background-position: 0 -340px;
  }

  33% {
    background-position: 0 -680px;
  }

  50% {
    background-position: 0 -1020px;
  }

  66% {
    background-position: 0 -1360px;
  }

  83% {
    background-position: 0 -1700px;
  }

  100% {
    background-position: 0 0px;
  }
}

@keyframes slotWinSequenceframe {
  0% {
    background-position: 0 0px;
  }

  16% {
    background-position: 0 -340px;
  }

  33% {
    background-position: 0 -680px;
  }

  50% {
    background-position: 0 -1020px;
  }

  66% {
    background-position: 0 -1360px;
  }

  83% {
    background-position: 0 -1700px;
  }

  100% {
    background-position: 0 0px;
  }
}
</style>
