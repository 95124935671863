<template>
  <div class="norem_game_menu_wrapper" ref="_Menu">
    <div
      class="toggle_icon"
      @click="menuStatuToggle($event)"
      :style="{
        transform: menuOpenStatu ? 'rotateY(0deg)' : 'rotateY(180deg)',
      }"
    >
      <van-image
        width="100%"
        height="auto"
        fit="fill"
        position="center"
        :src="icon_retract"
        :show-loading="false"
      />
    </div>
    <div class="menu_items_wrapper" v-show="menuOpenStatu">
      <div
        class="menu_item"
        v-for="mItem in menuList"
        @click="handleMenuItemClick($event, mItem.type)"
      >
        <van-image
          width="100%"
          height="auto"
          fit="fill"
          position="center"
          :src="mItem.icon"
          :show-loading="false"
        />
      </div>
    </div>
    <!-- <transition name="van-slide-down">

    </transition> -->
  </div>

  <!-- 在线客服图片 -->
  <ServicePup
    :show="serverPupShow"
    @close_icon_click="serverPupShowChange(false)"
  />

  <!-- 报修 -->
  <repairsPup :pupshow="repairsShow" @pup-close="repairsShowToggle(false)" />

  <!-- 玩法 -->
  <TutorialPup
    :tutoShow="tutoShow"
    @handleCloseIconClick="tutoShowToggle(false)"
    @handleClickOverlay="tutoShowToggle(false)"
  />
  <!-- 退出提示 -->
  <van-popup
    v-model:show="exitPupShow"
    :style="{
      borderRadius: '8px',
      background: 'none',
    }"
    :close-on-click-overlay="false"
  >
    <div class="exit_game_pup_wapper">
      <p class="f-16 t-center">结算下机</p>

      <div class="t-center mt-20">
        <van-space size="20px">
          <van-button
            type="primary"
            @click="exitPupShowToggle(false)"
            color="#F5B633"
            >继续游戏</van-button
          >
          <van-button
            type="primary"
            color="#19CFCA"
            @click="handleExitPupConfirm"
            >确认下机</van-button
          >
        </van-space>
      </div>
    </div>
  </van-popup>
</template>

<script setup>
import { reactive, ref } from "vue";
import { useStore } from "@/store/store";
import ServicePup from "@/components/ServicePup.vue";
import repairsPup from "@/components/RepairsPup.vue";
import TutorialPup from "@/components/TutorialPup.vue";
import findGameInfoData from "@/utils/gameInfoMap";
const store = useStore();

const icon_retract = store.imgBaseUrl + "/control/menu_open_icon.png";

const icon_exit = store.imgBaseUrl + "/control/menu_exit_icon.png";

const icon_sound = store.imgBaseUrl + "/control/menu_sound_1_icon.png";
const icon_rule = store.imgBaseUrl + "/control/menu_rule_icon.png";
const icon_customer_service =
  store.imgBaseUrl + "/control/menu_service_icon.png";
const icon_repair = store.imgBaseUrl + "/control/menu_repair_icon.png";
const icon_mute = store.imgBaseUrl + "/control/menu_sound_0_icon.png";
const reset_icon = store.imgBaseUrl + "/control/menu_reset_icon.png";

const display_show = store.imgBaseUrl + "/control/menu_display_show_icon.png";
const display_none = store.imgBaseUrl + "/control/menu_display_none_icon.png";

const menu_toggle_room =
  store.imgBaseUrl + "/control/menu_toggle_room_icon.png";

const menuList = reactive([
  {
    icon: icon_exit,
    type: "exit",
  },
  {
    icon: menu_toggle_room,
    type: "room",
  },
  {
    icon: display_none,
    type: "display",
  },
  {
    icon: reset_icon,
    type: "update",
  },

  {
    icon: icon_repair,
    type: "xiu",
  },
  {
    icon: icon_customer_service,
    type: "kefu",
  },
  {
    icon: icon_sound,
    type: "sound",
  },

  {
    icon: icon_rule,
    type: "explain",
  },
]);

const _Menu = ref();

const emits = defineEmits([
  "menuSoundClick",
  "handleExitEvent",
  "handle_update",
  "handle_display",
  "handle_toggle_room",
]);

const menuOpenStatu = ref(false);

let ffs = false;

const menuStatuToggle = (evt) => {
  evt && evt.stopPropagation();
  evt && evt.preventDefault();
  if (ffs) return;
  ffs = true;
  if (_Menu.value) {
    menuOpenStatu.value = !menuOpenStatu.value;
    _Menu.value.style.height = menuOpenStatu.value ? "390px" : "48px";
  }

  setTimeout(() => {
    ffs = false;
  }, 5e2);
};

const handleMenuItemClick = (evt, type) => {
  evt && evt.stopPropagation();
  evt && evt.preventDefault();
  switch (type) {
    // 客服
    case "kefu":
      try {
        window.$_c53_api.push("cmd", "member");
        window.$_c53_api.push("id", parseInt(store.userInfo.uid));
        window.$_c53_api.push("name", parseInt(store.userInfo.uid));
        window.$_c53_api.push("email", "x");
        window.$_c53_api.push("qq", "x");
        window.$_c53_api.push("wechat", "x");
        window.$_c53_api.push("phone", "x");
        window.$_c53_api.push("company", "x");
        window.$_c53_api.push("address", "x");

        const _notes = `${
          findGameInfoData(
            store.current_machine.child_name ||
              store.current_machine.product_name
          ).game_name
        }${store.current_machine.machine_index}号${
          store.current_machine.exchange
        }币房`;

        window.$_c53_api.push("notes", _notes);
        window.$_c53_api.query();
        window.$_c53_api.push("cmd", "kfclient");
        window.$_c53_api.push("type", "popup");
        window.$_c53_api.query();
      } catch (error) {
        showToast({
          type: "fail",
          message: "暂无客服",
        });
      }
      break;
    // 声音
    case "sound":
      soundStatuToggle();
      break;
    // 玩法
    case "explain":
      tutoShowToggle(true);
      break;
    // 保修
    case "xiu":
      repairsShowToggle(true);
      break;
    // 退出
    case "exit":
      if (store.gameIsWatch) {
        emits("handleExitEvent");
      } else exitPupShowToggle(true);
      break;

    //刷新
    case "update":
      emits("handle_update");
      break;
    //刷新
    case "display":
      handleDisplayChange();
      // emits("handle_update");
      break;
    case "room":
      emits("handle_toggle_room");
      break;

    default:
      menuOpenStatu.value = false;
      break;
  }
};

// 客服弹窗
const serverPupShow = ref(false);
const serverPupShowChange = (type) => {
  serverPupShow.value = type;
};

// 报修
const repairsShow = ref(false);
const repairsShowToggle = (type) => {
  repairsShow.value = type;
};

//#region 游戏说明
const tutoShow = ref(false);
const tutoShowToggle = (type) => {
  tutoShow.value = type;
};
//#endregion

// 声音
const soundStatu = ref(false);
const soundStatuToggle = () => {
  soundStatu.value = !soundStatu.value;

  menuList.some((item) => {
    if (item.type == "sound") {
      item.icon = soundStatu.value ? icon_sound : icon_mute;
      return true;
    } else return false;
  });

  emits("menuSoundClick", soundStatu.value);
};

// 退出
const exitPupShow = ref(false);
const exitPupShowToggle = (type) => {
  exitPupShow.value = type;
};

const handleExitPupConfirm = () => {
  emits("handleExitEvent");
  exitPupShowToggle(false);
};

// 清屏收屏
const display_statu = ref(true);
const handleDisplayChange = () => {
  display_statu.value = !display_statu.value;

  menuList.some((item) => {
    if (item.type == "display") {
      item.icon = display_statu.value ? display_none : display_show;
      return true;
    } else return false;
  });

  emits("handle_display");
  menuStatuToggle();
};

defineExpose({ menuStatuToggle, menuOpenStatu, exitPupShowToggle });
</script>

<style lang="scss" scoped>
.norem_game_menu_wrapper {
  height: 48px;
  position: absolute;
  right: 20px;
  top: 20px;
  overflow: hidden;
  width: 48px;
  // width: 343px;

  background-color: rgba($color: #4b2383, $alpha: 0.8);
  border: 1px solid #2cceca;
  border-radius: 24px;
  z-index: 10;
  transition: height 0.3s;

  .toggle_icon {
    position: absolute;
    right: 5px;
    top: 5px;
    transform: rotateY(180deg);

    width: 38px;
    height: 38px;
    z-index: 3;
  }

  .menu_items_wrapper {
    position: absolute;
    left: 0px;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top: 40px;
    .menu_item {
      width: 38px;
      height: 38px;
      margin-bottom: 2px;
    }
  }
}

.exit_game_pup_wapper {
  background-image: linear-gradient(180deg, #4b2383 0%, #261242 100%);
  border: 2px solid #9a44e7;
  border-radius: 8px 8px 8px 8px;
  font-size: 16px;
  width: 280px;
  padding: 20px;
}
</style>
